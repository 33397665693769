import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Table from "react-bootstrap/Table";
import "./PathologyCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
import { InputGroup } from "react-bootstrap";
import PathologyModalfirst from "./AllPathologyModal/PathologyModalfirst";
library.add(fas);

function Price() {
  const [modalTest, setModalTest] = React.useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const { id } = useParams();
  const handleDateChange = (date1) => {
    const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;

    // Update formData state with the formatted date
    setFormData({ ...formData, date: formattedDate });
  }

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = async () => {
    await setFormData({
      id: "",
      name: "",
      description: "",
      price: "",
      discounted_price: "",
      test_description: "",
      test_description_value: "",
      test_count: 0,
      test_included: [],
      organisation_id: id,
    });

    await setErrors();
    setShow(false);
    setOpdEdit(false);
  }
  const handleShow = () => setShow(true);

  const handleClose2 = async () => {
    setShow2(false);
    await setFormData({
      id: "",
      name: "",
      description: "",
      price: "",
      discounted_price: "",
      test_description: "",
      test_description_value: "",
      test_count: 0,
      test_included: [],
      organisation_id: id,
    });

    await setErrors();
    setOpdEdit(false);
  }
  const handleShow2 = () => setShow2(true);



  const { getData, postData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    price: "",
    discounted_price: "",
    test_description: [],
    test_description_value: [],
    test_count: 0,
    test_included: [],
    organisation_id: id,
  });

  const handleChange = async (e) => {
    if (
      e?.name === "country_id"
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
      if (e?.target?.name === "test_count") {

      }
    }
  }
  const handleCurrentSelect2Change = async (selectedOption) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        test_description: selectedOption.map((item) => item.value),
        test_description_value: selectedOption,
      };
    });
  };

  const handleTestChange = (e, index) => {
    const { name, value } = e.target;

    // If it's a test_included field, update the corresponding index in the array
    if (name === 'test_included') {
      const updatedTestIncluded = [...formData.test_included];
      updatedTestIncluded[index] = value;

      setFormData((prevFormData) => ({
        ...prevFormData,
        test_included: updatedTestIncluded,
      }));
    } else {
      // Otherwise, update other fields in formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }
    if (!formData.price) {
      errors.price = "Price is required";
    }
    if (!formData.discounted_price) {
      errors.discounted_price = "Discounted Price is required";
    }



    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`organisation_id`, formData.organisation_id);
        finalData.append(`name`, formData.name);
        finalData.append(`description`, formData.description);
        finalData.append(`price`, formData.price);
        finalData.append(`discounted_price`, formData.discounted_price);
        finalData.append(`test_description`, formData.test_description);
        finalData.append(`test_count`, formData.test_count);
        finalData.append(`test_included`, formData.test_included);

        const result = await postData(
          "organisation/dashboard/apis/test-create-api/",
          formData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            name: "",
            description: "",
            price: "",
            discounted_price: "",
            test_description: "",
            test_description_value: "",
            test_count: 0,
            test_included: [],
            organisation_id: id,
          });

          await setErrors();
          handleClose();
          getOPD();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`organisation`, formData.organisation_id);
        finalData.append(`name`, formData.name);
        finalData.append(`description`, formData.description);
        finalData.append(`price`, formData.price);
        finalData.append(`discounted_price`, formData.discounted_price);

        const result = await editPatchData(
          `organisation/dashboard/apis/opd-edit-api/${formData.id}/`,
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            name: "",
            description: "",
            price: "",
            discounted_price: "",
            test_description: "",
            test_description_value: "",
            test_count: 0,
            test_included: [],
            organisation_id: id,
          });

          await setErrors();
          handleClose();
          getOPD();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [testPrice, settestPrice] = useState();
  const [opdEdit, setOpdEdit] = useState(false);
  const getOPD = async (page) => {
    var res;
    if (page) {
      res = await getData(`organisation/dashboard/apis/test-list-api/?${page}`);
    } else {
      res = await getData(`organisation/dashboard/apis/test-list-api/?organisation_id=${id}`);
    }

    console.log(res);
    settestPrice(res);
    if (res?.success) {

    }
  }

  const handleEdit = async (index, action) => {
    console.log(testPrice?.results[index]);
    await setFormData({
      id: testPrice?.results[index]?.id,
      name: testPrice?.results[index]?.name,
      description: testPrice?.results[index]?.description,
      price: testPrice?.results[index]?.price,
      discounted_price: testPrice?.results[index]?.discounted_price,
    
    });


    if (action === "edit") {
      handleShow();
      setOpdEdit(true);
    } else {
      handleShow2();
    }

  }

  const handleDelete = async (id) => {
    const res = await deleteData(`organisation/dashboard/apis/opd-edit-api/${id}/`);
    await getOPD();
  }

  const [testDesc, setTestDesc] = useState();
  const getMaster = async () => {
    {
      const res = await getData(`organisation/dashboard/apis/test-description-get/?organisation_id=${id}`);
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.description,
          name: "test_description",
        }));
        setTestDesc(result);
      }
    }

  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getOPD();
    getMaster();
  }, [formData]);

  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* doctor data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">Price & details</h4>
              <div className="row search-holder  flex items-center space-x-2">
                <div className="col-md-6">
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    /> <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="col-md-6">
                  <Button
                    variant="success"
                    onClick={handleClose}
                    className="search btn-style btn-size text bg-green"
                  >
                    Create test
                  </Button>
                </div>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">Test Name</span>
                    </th>

                    <th>
                      <span className="text table-data-heading">
                        Description
                      </span>
                    </th>

                    <th>
                      <span className="text table-data-heading">Price</span>
                    </th>

                    {/* <th>
                      <span className="text table-data-heading">Date</span>
                    </th> */}

                    <th>
                      <span className="text table-data-heading">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {testPrice?.results?.map((value, index) => (
                    <tr>
                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.name}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.description}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">{value?.price}</p>
                        </div>
                      </td>

                      {/* <td>
                      <div class="user-name-holder">
                        <p className="text text-size mb-0 data-line ">
                          12-01-2024
                        </p>
                      </div>
                    </td> */}

                      <td>
                        <div className="action">

                          <Link to={`/diagnosticSlot/${id}/${value?.id}`}>
                            <Button className="action-btn" onClick={() => handleEdit(index, "view")}>
                              <FontAwesomeIcon
                                className="text"
                                icon="fa-solid fa-eye"
                              />
                            </Button>
                          </Link>

                          {/* <Button className="action-btn " onClick={() => handleEdit(index, "edit")}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button> */}
                          <Button className="action-btn" onClick={() => handleDelete(value?.id)}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  {testPrice?.previous && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getOPD(testPrice?.previous.split("?")[1])}>
                        <span aria-hidden="true">‹</span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[...Array(testPrice?.count && Math.ceil(testPrice?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getOPD("page=" + (index + 1))}>
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                  {testPrice?.next && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getOPD(testPrice?.next.split("?")[1])}>
                        <span aria-hidden="true">›</span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </li>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* doctor data table end */}

          {/* add Visitor  modal start */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">{opdEdit ? 'Edit' : 'Add'} Pathology </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Pathology Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={formData?.name}
                    className="text"
                    type="text"
                    placeholder="Enter Name"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.name && (
                    <span style={errorStyle}>{errors?.name}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Description</Form.Label>
                  <Form.Control
                    name="description"
                    value={formData?.description}
                    className="text"
                    type="text"
                    placeholder="Enter Description"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.description && (
                    <span style={errorStyle}>{errors?.description}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Price</Form.Label>
                  <Form.Control
                    name="price"
                    value={formData?.price}
                    className="text"
                    type="text"
                    placeholder="Enter Price"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                  />
                  {errors?.price && (
                    <span style={errorStyle}>{errors?.price}</span>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Discounted Price</Form.Label>
                  <Form.Control
                    name="discounted_price"
                    value={formData?.discounted_price}
                    className="text"
                    type="text"
                    placeholder="Enter Discounted Price"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                  />
                  {errors?.discounted_price && (
                    <span style={errorStyle}>{errors?.discounted_price}</span>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Test Description</Form.Label>
                  <Select
                    placeholder="Select Test Description"
                    className="select-line"
                    isMulti
                    value={formData?.test_description_value}
                    onChange={(selectOption) => handleCurrentSelect2Change(selectOption)}
                    options={testDesc}
                  />
                  {errors?.test_description && (
                    <span style={errorStyle}>{errors?.test_description}</span>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Test Included Count</Form.Label>
                  <Form.Control
                    name="test_count"
                    value={formData?.test_count}
                    className="text"
                    type="text"
                    placeholder="Enter Test Included Count"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                  />
                  {errors?.test_count && (
                    <span style={errorStyle}>{errors?.test_count}</span>
                  )}
                </Form.Group>
                {formData?.test_count && (
                  <>
                    {[...Array(parseInt(formData?.test_count))]?.map((_, index) => (
                      <Form.Group key={index} className="mb-2 text">
                        <Form.Label className="text">Test Name</Form.Label>
                        <div className="mb-2">
                          <Form.Control
                            name='test_included'
                            value={formData?.test_included?.[index] || ''}
                            className="text"
                            type="text"
                            placeholder="Enter Name"
                            onChange={(e) => handleTestChange(e, index)}
                          />
                          {errors?.[`test_included${index}`] && (
                            <span style={errorStyle}>{errors?.[`test_included${index}`]}</span>
                          )}
                        </div>
                      </Form.Group>
                    ))}
                  </>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              {opdEdit ?
                (
                  <Button variant="success" onClick={handleEditSubmit}>
                    Edit
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleSubmit}>
                    Add
                  </Button>
                )}
            </Modal.Footer>
          </Modal>
          {/* add Visitor  modal end */}

          {/* details  modal start */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Pathology Details</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="Details-card">
                <Card>
                  <Card.Body>
                    <div className="visitor-patient-card">
                      <div className="row me-0 ms-0">
                        <div className="col-md-12">
                          <div className="details-card">
                            <Card.Title>
                              <h4 className="text ">Test Details</h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="details">
                                <div className="visitor-name ">
                                  <FontAwesomeIcon
                                    className="text me-2"
                                    icon="fa-solid fa-vial"
                                  />

                                  <p className="text mb-0">{formData?.name}</p>
                                </div>

                                <p className="text">
                                  <b>Description</b>
                                </p>

                                <p className="text ">
                                  {formData?.description}
                                </p>

                                <p className="text ">
                                  <b>Price:- </b>{formData?.price}
                                </p>

                                {/* <p className="text ">
                                  <b>Date:- </b>12-01-2024{" "}
                                </p> */}
                              </div>
                            </Card.Text>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Close
              </Button>
              {/* <Button variant="success" onClick={handleClose2}>
                Add
              </Button> */}
            </Modal.Footer>
          </Modal>
          {/* details  modal end */}
        </div>
      </div>
      {/* <PathologyModalfirst
        HedaingName="Create Test"
        show={modalTest}
        onHide={() => setModalTest(false)}
      /> */}
    </>
  );
}

export default Price;
