import React from "react";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Sidebar from "./components/dashboard/Sidebar/Sidebar";
import Login from "./components/Login/LogIn";
import Header from "./components/dashboard/Header/Header";

import "./App.css";
import "./index.css";

import All_Routes from "./components/AllRoutes/All_Routes";
import AppContext from "./utils/context";



function App() {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isLoginPage = location.pathname === "/";

  return (
    <>
      <AppContext>
        <div className="main">
          {isLoginPage ? (
            <Login />
          ) : (
            <div className="row me-0 ms-0">
              <div className="col-xl-2 col-md-3 p-0">
                <Sidebar />
              </div>

              <div className="col-xl-10 col-md-9 p-0">
                <div className="main-content">
                  <Header />

                      <All_Routes />
                  
                </div>
              </div>
            </div>
          )}
        </div>
      </AppContext>
    </>
  );
}

export default App;
