import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "./Categories.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../utils/context";
library.add(fas);

function Categories() {

 
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);

  const handleClose = async () => {
    await setFormData({
      title : "",
      description: "",
      icon:undefined,
    });
    await setErrors();
    setIconPreview(false);
    setShow(false);
  }
  const handleShow = async () => {
    setShow(true);
    await setOpdEdit(false);
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };


  
  const [iconPreview, setIconPreview] = useState(false);


  const handleImageIconChange = async (e) => {
    const file = e.target.files[0];

    await setFormData({ ...formData, icon: file });

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setIconPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const { getData, postData,editPatchData,editStatusData,deleteData, imageUrl, Select2Data,orgID, setOrgId} = useContext(Context);

  const [formData, setFormData] = useState({
    id : "",
   title : "",
   description: "",
   icon:undefined,
  });

  const handleChange = async (e) => {
    if (
      e?.name === "country_id" 
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.title) {
      errors.title = "Title is required";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }

    // if (formData.icon === undefined) {
    //   errors.icon = "Image is required";
    // }

    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`icon`, formData.icon);
        finalData.append(`title`, formData.title);
        finalData.append(`description`, formData.description);

        const result = await postData(
          "organisation/dashboard/apis/category-create-api/",
          finalData
        );

        if (result?.success) {
          await setFormData({
            id : "",
            title : "",
            description: "",
            icon:undefined,
          });

          await setErrors();
          getCategory();
          handleClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();


    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        if(formData.icon !== undefined){
          finalData.append(`icon`, formData.icon);
        }
        
        finalData.append(`title`, formData.title);
        finalData.append(`description`, formData.description);

        const result = await editPatchData(
          `organisation/dashboard/apis/category-edit/${formData.id}/`,
          finalData
        );

        if (result?.success) {
          await setFormData({
            id : "",
            title : "",
            description: "",
            icon:undefined,
          });

          await setErrors();
          getCategory();
          handleClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteCategory = async (id) =>{
      const res = await deleteData(`/organisation/dashboard/apis/category-edit/${id}/`);
      getCategory();
  }

  const [categorys, setCategory] = useState();
  const [opdEdit, setOpdEdit] = useState(false);
  const getCategory = async (page) => {
    var res;
    if(page){
      res = await getData(`organisation/dashboard/apis/category-list-api-with/?${page}`);
    }else{
      res = await getData(`organisation/dashboard/apis/category-list-api-with/`);
    }
      setCategory(res);
  }

  const handleEdit = async (index,action) => {
    console.log(categorys?.results[index]);
    await setFormData({
      id: categorys?.results[index]?.id,
      title: categorys?.results[index]?.title,
      description: categorys?.results[index]?.description,
     
    });
    setIconPreview(imageUrl+categorys?.results[index]?.icon);

    if(action === "edit"){
      handleShow(); 
      setOpdEdit(true);
    }
   
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategory();
  }, []);


  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* Categories data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">All Categories</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search :{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                <Button
                  variant="success"
                  onClick={handleShow}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Categories
                </Button>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">Icon</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Title</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Description
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categorys?.results?.map((value,index) => (
                    <tr>
                    <td>
                      <span className="text text-size data-line ">
                        <div className="img-holder">
                          <img
                            src=
                            {value?.icon ? (
                                imageUrl + value?.icon
                            ):(
                              "https://s3stpl.s3.amazonaws.com/media/organisation/CategoryModel/icon/images.png"
                            )}
                            
                            alt="user-img "
                            class="username-img"
                          />
                        </div>
                      </span>
                    </td>
                    <td>
                      <p className="text text-size data-line ">
                        {value?.title}
                      </p>
                    </td>
                    <td>
                      <p className="text text-size data-line">{value?.description}</p>
                    </td>
                    <td>
                        <div className="action">
                          {/* <Link to="/OPDCardDetails"> */}
                          
                          {/* </Link> */}
                          <Button className="action-btn " onClick={() => handleEdit(index,"edit")}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>
                          <Button className="action-btn" onClick={() => deleteCategory(value?.id)}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button>
                        </div>
                      </td>
                  </tr>
                  ))}
                  
                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                {categorys?.previous && (
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0"  onClick={() => getCategory( categorys?.previous.split("?")[1])}>
                      <span aria-hidden="true">‹</span>
                      <span class="visually-hidden">Previous</span>
                    </a>
                  </li>
                )}
                  {[...Array(categorys?.count && Math.ceil(categorys?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getCategory("page="+(index+1))}>
                          {index+1}
                        </a>
                      </li>
                    );
                  })}
                    {categorys?.next && (
                      <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" onClick={() => getCategory( categorys?.next.split("?")[1])}>
                      <span aria-hidden="true">›</span>
                      <span class="visually-hidden">Next</span>
                    </a>
                  </li>
                    )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}
          {/* Categories data table end */}

{/* Add Category modal start */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Add Category</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={formData?.title}
                    className="text"
                    type="text"
                    placeholder="Enter Title"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.title && (
                    <span style={errorStyle}>{errors?.title}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Descriptions</Form.Label>
                  <Form.Control
                    name="description"
                    value={formData?.description}
                    className="text"
                    type="text"
                    placeholder="Enter Description"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.description && (
                    <span style={errorStyle}>{errors?.description}</span>
                  )}
                </Form.Group>

                {/* choose image start */}
                <div className="row ">
                  <div className="col-md-9">
                    <Form.Group
                      controlId="exampleForm.ControlInput2"
                      className="mb-3 text"
                    >
                      <Form.Label className="text">Icon</Form.Label>
                      <Form.Control
                        type="file"
                        className="text"
                        onChange={handleImageIconChange}
                        accept="image/*"
                      />
                      {errors?.icon && (
                    <span style={errorStyle}>{errors?.icon}</span>
                  )}
                    </Form.Group>
                  </div>

                  <div className="col-md-3">
                    <div className="image-preview-holder">
                      <span className="text">preview</span>
                      {iconPreview && (
                        <img
                          src={iconPreview}
                          alt="Image Preview"
                          className="img-preview"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* choose image  end */}

                     
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              {opdEdit ?
                (
                  <Button variant="success" onClick={handleEditSubmit}>
                    Edit
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleSubmit}>
                    Add
                  </Button>
                )}
            </Modal.Footer>
          </Modal>
          {/* Add Category modal end */}
        </div>
      </div>
    </>
  );
}

export default Categories;
