import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Table from "react-bootstrap/Table";
import "./PathologyCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import Select from "react-select";
import { Context } from "../../../../utils/context";
library.add(fas);

function TestAppointment() {
  const [selectedDate, setSelectedDate] = useState(null);
  const { id } = useParams();
  const handleDateChange = (date1) => {
    const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;

    // Update formData state with the formatted date
    setFormData({ ...formData, date: formattedDate });
  }

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = async () => {
    await setFormData({
      id: "",
      patient_name: "",
      address: "",
      mobile_number: "",
      hospital_description: "",
      is_booked: "",
      is_completed: "",
      rating: "",
      hospital_name: "",
      organisation_id: id,
    });

    await setErrors();
    setShow(false);
    setOpdEdit(false);
  }
  const handleShow = () => setShow(true);

  const handleClose2 = async () => {
    setShow2(false);
    await setFormData({
      id: "",
      patient_name: "",
      address: "",
      mobile_number: "",
      hospital_description: "",
      is_booked: "",
      is_completed: "",
      rating: "",
      hospital_name: "",
      organisation_id: id,
    });

    await setErrors();
    setOpdEdit(false);
  }
  const handleShow2 = () => setShow2(true);



  const { getData, postData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);

  const [formData, setFormData] = useState({
    id: "",
    patient_name: "",
    address: "",
    mobile_number: "",
    hospital_description: "",
    is_booked: "",
    is_completed: "",
    rating: "",
    hospital_name: "",
    organisation_id: id,
  });

  const [hospitalName, setHospitalName] = useState();

  const gethospital = async () => {
    {
      const res = await getData("organisation/dashboard/apis/organisation-list-api/`");
      if (res?.success) {
        const data = await Select2Data(res.data, "hospital_name", false);
        setHospitalName(data);
      }
    }

  };


  const handleChange = async (e) => {
    if (
      e?.name === "country_id" || e?.name === "hospital_name"
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.patient_name) {
      errors.patient_name = "Patient Name is required";
    }

    if (!formData.address) {
      errors.address = "Address is required";
    }

    if (!formData.mobile_number) {
      errors.mobile_number = "Mobile Number is required";
    } else if (formData.mobile_number.length < 10) {
      errors.mobile_number = "Invalid Phone number";
    }

    if (!formData.hospital_description) {
      errors.hospital_description = "Hospital Description is required";
    }

    // if (!formData.is_booked) {
    //   errors.is_booked = "Is Booked is required";
    // }

    // if (!formData.is_completed) {
    //   errors.is_completed = "Is Completed is required";
    // }

    if (!formData.hospital_name) {
      errors.hospital_name = "Hospital Name is required";
    }

    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`organisation `, formData.organisation_id);
        finalData.append(`patient_name`, formData.patient_name);
        finalData.append(`address`, formData.address);
        finalData.append(`mobile_number`, formData.mobile_number);
        finalData.append(`hospital_description`, formData.hospital_description);
        finalData.append(`is_booked`, formData.is_booked);
        finalData.append(`is_completed`, formData.is_completed);
        finalData.append(`hospital_name`, formData.hospital_name.value);

        const result = await postData(
          "slot_booking/dashboard/apis/test-bookings-get/",
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            patient_name: "",
            address: "",
            mobile_number: "",
            hospital_description: "",
            is_booked: "",
            is_completed: "",
            hospital_name: "",
            organisation_id: id,
          });

          await setErrors();
          handleClose();
          getTestAppointments();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`organisation`, formData.organisation_id);
        finalData.append(`patient_name`, formData.patient_name);
        finalData.append(`address`, formData.address);
        finalData.append(`mobile_number`, formData.mobile_number);
        finalData.append(`hospital_description`, formData.hospital_description);
        finalData.append(`is_booked`, formData.is_booked);
        finalData.append(`is_completed`, formData.is_completed);
        finalData.append(`hospital_name`, formData.hospital_name.value);

        const result = await editPatchData(
          `organisation/dashboard/apis/opd-edit-api/${formData.id}/`,
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            patient_name: "",
            address: "",
            mobile_number: "",
            hospital_description: "",
            is_booked: "",
            is_completed: "",
            hospital_name: "",
          });

          await setErrors();
          handleClose();
          getTestAppointments();
          gethospital()
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const [expandedRows, setExpandedRows] = useState([]);
  const toggleRow = (index) => {
    const updatedExpandedRows = [...expandedRows];
    updatedExpandedRows[index] = !updatedExpandedRows[index];
    setExpandedRows(updatedExpandedRows);
  };
  const [hospitaltotalslot, setHospitalTotalSlot] = useState();
  const [testPrice, settestPrice] = useState();
  const [opdEdit, setOpdEdit] = useState(false);
  const getTestAppointments = async (page) => {
    var res;
    if (page) {
      res = await getData(`slot_booking/dashboard/apis/test-bookings-get/?${page}`);
    } else {
      res = await getData(`slot_booking/dashboard/apis/test-bookings-get/?hospital_name=${id}`);
    }
    console.log(testPrice)

    console.log(res);
    setHospitalTotalSlot(res);
    if (res?.success) {

    }
  }


  const handleEdit = async (index, action) => {
    console.log(testPrice?.results[index]);
    await setFormData({
      id: testPrice?.results[index]?.id,
      patient_name: testPrice?.results[index]?.patient_name,
      address: testPrice?.results[index]?.address,
      mobile_number: testPrice?.results[index]?.mobile_number,
      hospital_description: testPrice?.results[index]?.hospital_description,
      is_booked: testPrice?.results[index]?.is_booked,
      is_completed: testPrice?.results[index]?.is_completed,
      hospital_name: testPrice?.results[index]?.hospital_name,


    });


    if (action === "edit") {
      handleShow();
      setOpdEdit(true);
    } else {
      handleShow2();
    }

  }

  // const handleDelete = async (id) => {
  //   const res = await deleteData(`organisation/dashboard/apis/opd-edit-api/${id}/`);
  //   await getTestAppointments();
  // }
  useEffect(() => {
    window.scrollTo(0, 0);
    gethospital()
    getTestAppointments();
  }, [formData]);
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* doctor data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">Test Appointment</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search:{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                <Button
                  variant="success"
                  onClick={handleShow}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Test Appointment
                </Button>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading"></span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Booking ID
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Date
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Start's At
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">End's At</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Booking Status
                      </span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">Records</span>
                    </th> */}
                    {/* <th>
                      <span className="text table-data-heading">Actions</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {expandedRows[0] && (
                    <tr>
                      <td colSpan="7">
                        {/* Content to show below the row when expanded */}
                        <div className="expanded-content">
                          {/* Your expanded content goes here */}
                          <p>This is the expanded content for the first row.</p>
                        </div>
                      </td>
                    </tr>
                  )}


                  {hospitaltotalslot?.results?.map((value, index) => (
                    <tr>
                      <td>
                        <span className="text text-size data-line">
                          <FontAwesomeIcon
                            className={`text ${expandedRows[1] ? "fa-angle-down" : "fa-angle-right"
                              }`}
                            icon="fa-solid fa-angle-right"
                            onClick={() => toggleRow(1)}
                          />
                        </span>
                      </td>
                      <td>
                        <p className="text text-size data-line ">
                          {value?.patient_name}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line">
                          {value?.booking_details?.date}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line">
                          {value?.booking_details?.start_time}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line">
                          {value?.booking_details?.end_time}
                        </p>
                      </td>
                      {/* <td>
                        <p className="text text-size data-line  completed">
                          {value.name}
                        </p>
                      </td> */}
                      <td>
                        <p className="text text-size data-line">{value?.is_completed?.name}</p>
                      </td>
                      <td>
                        <div className="action">
                          <Button className="action-btn " onClick={handleShow2}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                              onClick={() => handleEdit(index, "edit")}
                            />
                          </Button>
                          {/* <Button
                            variant="outline-success"
                            className="search btn-style btn-size text bg-green"
                          >
                            Add Records
                          </Button> */}
                        </div>
                      </td>
                    </tr>
                  ))}

                  {expandedRows[1] && (
                    <tr>
                      <td colSpan="7">
                        {/* Content to show below the row when expanded */}
                        <div className="expanded-content">
                          {/* Your expanded content goes here */}
                          <p>This is the expanded content for the first row.</p>
                        </div>
                      </td>
                    </tr>
                  )}




                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  {testPrice?.previous && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getTestAppointments(testPrice?.previous.split("?")[1])}>
                        <span aria-hidden="true">‹</span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[...Array(testPrice?.count)].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getTestAppointments("page=" + (index + 1))}>
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                  {testPrice?.next && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getTestAppointments(testPrice?.next.split("?")[1])}>
                        <span aria-hidden="true">›</span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </li>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* doctor data table end */}

          {/* add Visitor  modal start */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">{opdEdit ? 'Edit' : 'Add'} Pathology </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Patient Name</Form.Label>
                  <Form.Control
                    name="patient_name"
                    value={formData?.patient_name}
                    className="text"
                    type="text"
                    placeholder="Enter Patient Name"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.patient_name && (
                    <span style={errorStyle}>{errors?.patient_name}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Address</Form.Label>
                  <Form.Control
                    name="address"
                    value={formData?.address}
                    className="text"
                    type="text"
                    placeholder="Enter Address"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.address && (
                    <span style={errorStyle}>{errors?.address}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Mobile Number</Form.Label>
                  <Form.Control
                    name="mobile_number"
                    value={formData?.mobile_number}
                    className="number"
                    type="text"
                    placeholder="Mobile Number"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.address && (
                    <span style={errorStyle}>{errors?.address}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Hospital Description</Form.Label>
                  <Form.Control
                    name="hospital_description"
                    value={formData?.hospital_description}
                    className="text"
                    type="text"
                    placeholder="Hospital Description"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.hospital_description && (
                    <span style={errorStyle}>{errors?.hospital_description}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                  <Form.Label className="text">Booked</Form.Label>

                  {/* Toggle Button for is_booked */}
                  <Form.Check
                    type="switch"
                    id="is_booked-switch"
                    label="Booked"
                    checked={formData?.is_booked || false}
                    onChange={(e) => handleChange({ target: { name: 'is_booked', value: e.target.checked } })}
                  />

                  {errors?.is_booked && (
                    <span style={errorStyle}>{errors?.is_booked}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                  <Form.Label className="text">Completed</Form.Label>

                  {/* Toggle Button for is_booked */}
                  <Form.Check
                    type="switch"
                    id="is_completed-switch"
                    label="Completed"
                    checked={formData?.is_completed || false}
                    onChange={(e) => handleChange({ target: { name: 'is_completed', value: e.target.checked } })}
                  />

                  {errors?.is_completed && (
                    <span style={errorStyle}>{errors?.is_completed}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text">
                  <Form.Label className="text">Hospital Name</Form.Label>
                  <Select
                    placeholder="Select Hopital Name"
                    value={formData?.hospital_name}
                    onChange={handleChange}
                    options={hospitalName}
                    isSearchable={true}
                    id="sector"
                  />
                  {errors?.hospital_name && (
                    <span style={errorStyle}>{errors?.hospital_name}</span>
                  )}
                </Form.Group>

                {/* <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Hospital Description</Form.Label>
                  <Form.Control
                    name="hospital_description"
                    value={formData?.rating}
                    className="text"
                    type="text"
                    placeholder="Rati"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.hospital_description && (
                    <span style={errorStyle}>{errors?.hospital_description}</span>
                  )}
                </Form.Group> */}





              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              {opdEdit ?
                (
                  <Button variant="success" onClick={handleEditSubmit}>
                    Edit
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleSubmit}>
                    Add
                  </Button>
                )}



            </Modal.Footer>
          </Modal>
          {/* add Visitor  modal end */}

          {/* details  modal start */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Pathology Details</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="Details-card">
                <Card>
                  <Card.Body>
                    <div className="visitor-patient-card">
                      <div className="row me-0 ms-0">
                        <div className="col-md-12">
                          <div className="details-card">
                            <Card.Title>
                              <h4 className="text ">Test Appointment </h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="details">
                                <div className="visitor-name ">
                                  <FontAwesomeIcon
                                    className="text me-2"
                                    icon="fa-solid fa-vial"
                                  />

                                  <p className="text mb-0">{formData?.patient_name}</p>
                                </div>

                                <p className="text">
                                  <b>Address</b>
                                </p>

                                <p className="text ">
                                  {formData?.address}
                                </p>

                                <p className="text ">
                                  <b>Mobile Number:- </b>{formData?.mobile_number}
                                </p>

                                <p className="text ">
                                  <b>Hospital Description:- </b>{formData?.hospital_description}
                                </p>

                                <p className="text ">
                                  <b>Is Booked:- </b>{formData?.is_booked}
                                </p>

                                <p className="text ">
                                  <b>Is Completed:- </b>{formData?.is_completed}
                                </p>

                                <p className="text ">
                                  <b>Hospital Name:- </b>{formData?.hospital_name}
                                </p>


                                {/* <p className="text ">
                                  <b>Date:- </b>12-01-2024{" "}
                                </p> */}
                              </div>
                            </Card.Text>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Close
              </Button>
              {/* <Button variant="success" onClick={handleClose2}>
                Add
              </Button> */}
            </Modal.Footer>
          </Modal>
          {/* details  modal end */}
        </div>
      </div>
    </>
  );
}

export default TestAppointment;
