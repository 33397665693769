import React from 'react';
import { Button, Card, Col, InputGroup, Modal, Nav, Row, Tab, Table, } from 'react-bootstrap';
import PathologyModalfirst from './AllPathologyModal/PathologyModalfirst';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { useContext } from 'react';
import { Context } from '../../../../utils/context';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import EditTestModal from './AllPathologyModal/EditTestModal';
import Select from "react-select";
const PathoSetting = () => {
    const [activeTab, setActiveTab] = useState('first');
    const [modalTest, setModalTest] = useState(false);
    const { id } = useParams();

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [errors, setErrors] = useState();

    const handleDateChange = (date1) => {
        const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;
        setFormData({ ...formData, date: formattedDate });
    };

    const handleClose = async () => {
        await setFormData({
            id: '',
            test: '',
            organisation_id: id,
            parameters: [],
            unit: '',
            reference: '',
        });
        await setErrors();
        setShow(false);
        // setOpdEdit(false);
        setModalTest(false);
    };

    const handleShow = () => {
        setShow(true);
        setModalTest(true);
    };

    const handleClose2 = async () => {
        setShow2(false);
        await setFormData({
            id: '',
            test: '',
            organisation_id: id,
            parameters: [],
            unit: '',
            reference: '',
        });
        await setErrors();
        // setOpdEdit(false);
    };

    const handleShow2 = () => setShow2(true);

    const handleKeyPressContact = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!/^\d$/.test(keyValue)) {
            event.preventDefault();
        }
    };

    const {
        getData,
        postData,
        editPatchData,
        deleteData,
        imageUrl,
        Select2Data,
        orgID,
        setOrgId,
    } = useContext(Context);

    const [formData, setFormData] = useState({
        id: '',
        test: '',
        organisation_id: id,
        parameters: [],
        unit: '',
        reference: '',
        test_included: [],
    });

    const handleChange = async (e) => {
        if (e?.name === 'test') {
            setFormData({ ...formData, [e?.name]: e });
        } else {
            setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
        }
    };

    const errorStyle = {
        color: 'red',
        marginLeft: '5px',
    };

    const validateForm = () => {
        let errors = {};
        const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
        const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append('organisation_id', formData.organisation_id);
                finalData.append('test', formData.test);
                finalData.append('parameters', JSON.stringify(formData.parameters));
                finalData.append('unit', formData.unit);
                finalData.append('reference', formData.reference);

                const result = await postData(
                    'organisation/dashboard/apis/parameter-test/',
                    finalData
                );

                if (result?.success) {
                    await setFormData({
                        id: '',
                        test: '',
                        organisation_id: id,
                        parameters: [],
                        unit: '',
                        reference: '',
                    });
                    await setErrors();
                    handleClose();
                    getTests();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append('organisation_id', formData.organisation_id);

                const result = await editPatchData(
                    `organisation/dashboard/apis/opd-edit-api/${formData.id}/`,
                    finalData
                );

                if (result?.success) {
                    await setFormData({
                        id: '',
                        test: '',
                        organisation_id: id,
                        parameters: [],
                        unit: '',
                        reference: '',
                    });
                    await setErrors();
                    handleClose();
                    getTests();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const [testList, setTestList] = useState();
    const [testPrice, settestPrice] = useState();
    const [opdEdit, setOpdEdit] = useState(false);
    console.log(testList,'test')
    const getTests = async (page) => {
        var res;
        if (page) {
            res = await getData(
                `organisation/dashboard/apis/parameter-test/?${page}`
            );
        } else {
            res = await getData(
                `organisation/dashboard/apis/parameter-test/?organisation_id=${id}`
            );
        }
        setTestList(res);
    };

console.log(testList,"testList testList");
    const getOPD = async (page) => {
        var res;
        if (page) {
            res = await getData(
                `organisation/dashboard/apis/test-list-api/?${page}`
            );
        } else {
            res = await getData(
                `organisation/dashboard/apis/test-list-api/?organisation_id=${id}`
            );
        }
        settestPrice(res);
    };

    const handleEdit = async (index, action) => {
        await setFormData({
            id: testList?.results[index]?.id,
            test: testList?.results[index]?.test,
            organisation_id: testList?.results[index]?.organisation_id,
            parameters: testList?.results[index]?.parameters,
            unit: testList?.results[index]?.unit,
            reference: testList?.results[index]?.reference,
        });

        if (action === 'edit') {
            handleShow();
            setOpdEdit(true);
        } else {
            handleShow2();
        }
    };

    const handleDelete = async (parameterId) => {
        const res = await deleteData(`organisation/dashboard/apis/parameter-test/${parameterId}/`);
        await getTests();
    };

    const [tests, setTest] = useState();
    const [paramterType, setParameterType] = useState();

    const getMaster = async () => {
        {
            const res = await getData(
                `organisation/dashboard/apis/test-list-without-api/?organisation_id=${id}`
            );
            if (res?.success) {
                const result = res.data.map((data) => ({
                    value: data?.id,
                    label: data?.name,
                    name: 'test',
                }));
                setTest(result);
            }
        }
        {
            const res = await getData(
                `organisation/dashboard/apis/parameters-type-api/`
            );
            if (res?.success) {
                const result = res.data.map((data) => ({
                    value: data?.id,
                    label: data?.name,
                    name: 'parameter_type',
                }));
                setParameterType(result);
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getTests();
        getOPD();
        getMaster();
    }, []);

    const handleParameterForm = async () => {
        await setFormData({ ...formData, parameters: parameters });
    };

    const [parameters, setParameters] = useState([
        {
            name: '',
            type_value: '',
            type: '',
            reference: [
                {
                    age: 'adult',
                    sex: 'M',
                    minValue: '',
                    maxValue: '',
                    lessThanValue: '',
                    moreThanValue: '',
                },
            ],
        },
    ]);

    const handleAddParameter = () => {
        const updatedParameters = [...parameters];
        updatedParameters.push({
            name: '',
            type_value: '',
            type: '',
            reference: [
                {
                    age: 'adult',
                    sex: 'M',
                    minValue: '',
                    maxValue: '',
                    lessThanValue: '',
                    moreThanValue: '',
                },
            ],
        });
        setParameters(updatedParameters);
    };

    const handleRemoveParameter = (index) => {
        const updatedParameters = [...parameters];
        updatedParameters.splice(index, 1);
        setParameters(updatedParameters);
    };

    const handleAddReference = (paramIndex) => {
        const updatedParameters = [...parameters];
        updatedParameters[paramIndex].reference.push({
            age: 'adult',
            sex: 'M',
            minValue: '',
            maxValue: '',
            lessThanValue: '',
            moreThanValue: '',
        });
        setParameters(updatedParameters);
    };

    const handleRemoveReference = (paramIndex, refIndex) => {
        const updatedParameters = [...parameters];
        updatedParameters[paramIndex].reference.splice(refIndex, 1);
        setParameters(updatedParameters);
    };

    const handleParameterChange = (index, key, value) => {
        const updatedParameters = [...parameters];
        updatedParameters[index][key] = value;
        setParameters(updatedParameters);
    };

    const handleReferenceChange = (
        paramIndex,
        refIndex,
        attribute,
        value,
        counterpartValue
    ) => {
        const updatedParameters = [...parameters];
        const currentReference = updatedParameters[paramIndex].reference[refIndex];

        currentReference[attribute] = value;

        setParameters(updatedParameters);
    };

    useEffect(() => {
        handleParameterForm();
    }, [parameters]);

    const handleCloseEdit = async () => {
        setShowEdit(false);
        await setFormData({
            id: '',
            test: '',
            organisation_id: '',
            parameters: [],
            unit: '',
            reference: '',
        });
    };

    const handleShowEdit = () => setShowEdit(true);

    const handleEditModal = async (index, action) => {
        const selectedTest = testList?.results[index];
        await setFormData({
            id: selectedTest?.id,
            test: selectedTest?.test,
            organisation_id: selectedTest?.organisation_id,
            parameters: selectedTest?.parameters,
            unit: selectedTest?.unit,
            reference: selectedTest?.reference,
        });

        if (action === 'edit') {
            handleShowEdit();
        }
    };

    const updateTestData = (id, updatedTest) => {
        setTestList((prevTestList) => {
            const updatedResults = prevTestList.results.map(test =>
                test.id === id ? updatedTest : test
            );
            return { ...prevTestList, results: updatedResults };
        });
    };
    // rate-list-code-start
    const [showRate, setShowRate] = useState(false);
    const [showRate2, setShowRate2] = useState(false);
    const handleCloseRate = async () => {
        await setFormData({
            id: "",
            name: "",
            description: "",
            price: "",
            discounted_price: "",
            test_description: "",
            test_description_value: "",
            test_count: 0,
            test_included: [],
            organisation_id: id,
        });

        await setErrors();
        setShowRate(false);
        setOpdEdit(false);
    }

    const handleShowRate = () => setShowRate(true);
    const handleCloseRate2 = async () => {
        setShowRate2(false);
        await setFormData({
            id: "",
            name: "",
            description: "",
            price: "",
            discounted_price: "",
            test_description: "",
            test_description_value: "",
            test_count: 0,
            test_included: [],
            organisation_id: id,
        });

        await setErrors();
        setOpdEdit(false);
    }
    const handleShowRate2 = () => setShowRate2(true);

    const handleChangeRate = async (e) => {
        if (
            e?.name === "country_id"
        ) {
            setFormData({ ...formData, [e?.name]: e });
        } else {
            setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
            if (e?.target?.name === "test_count") {

            }
        }
    }
    const handleCurrentSelect2Change = async (selectedOption) => {
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                test_description: selectedOption.map((item) => item.value),
                test_description_value: selectedOption,
            };
        });
    };
    const handleTestChange = (e, index) => {
        const { name, value } = e.target;

        // If it's a test_included field, update the corresponding index in the array
        if (name === 'test_included') {
            const updatedTestIncluded = [...formData.test_included];
            updatedTestIncluded[index] = value;

            setFormData((prevFormData) => ({
                ...prevFormData,
                test_included: updatedTestIncluded,
            }));
        } else {
            // Otherwise, update other fields in formData
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };
    const handleSubmitRate = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();

        console.log(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append(`organisation_id`, formData.organisation_id);
                finalData.append(`name`, formData.name);
                finalData.append(`description`, formData.description);
                finalData.append(`price`, formData.price);
                finalData.append(`discounted_price`, formData.discounted_price);
                finalData.append(`test_description`, formData.test_description);
                finalData.append(`test_count`, formData.test_count);
                finalData.append(`test_included`, formData.test_included);
                // finalData.append('test_description', JSON.stringify(formData.test_description));
                // finalData.append('test_included', JSON.stringify(formData.test_included));

                const result = await postData(
                    "organisation/dashboard/apis/test-create-api/",
                    formData
                );

                if (result?.success) {
                    await setFormData({
                        id: "",
                        name: "",
                        description: "",
                        price: "",
                        discounted_price: "",
                        test_description: "",
                        test_description_value: "",
                        test_count: 0,
                        test_included: [],
                        organisation_id: id,
                    });

                    await setErrors();
                    getOPD();
                    handleCloseRate();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    const handleEditSubmitRate = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();

        console.log(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append(`organisation`, formData.organisation_id);
                finalData.append(`name`, formData.name);
                finalData.append(`description`, formData.description);
                finalData.append(`price`, formData.price);
                finalData.append(`discounted_price`, formData.discounted_price);
                finalData.append(` test_included`, formData.test_included);
                const result = await editPatchData(
                    `/organisation/dashboard/apis/test-list-api/${formData.id}/`,
                    finalData
                );

                if (result?.success) {
                    await setFormData({
                        id: "",
                        name: "",
                        description: "",
                        price: "",
                        discounted_price: "",
                        test_description: "",
                        test_description_value: "",
                        test_count: 0,
                        test_included: [],
                        organisation_id: id,
                    });

                    await setErrors();
                    handleCloseRate();
                    getOPD();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleEditRate = async (index, action) => {
        console.log(testPrice?.results[index]);
        await setFormData({
            id: testPrice?.results[index]?.id,
            name: testPrice?.results[index]?.name,
            description: testPrice?.results[index]?.description,
            price: testPrice?.results[index]?.price,
            discounted_price: testPrice?.results[index]?.discounted_price,
            test_included: testPrice?.results[index]?.test_included,
        });


        if (action === "edit") {
            handleShowRate();
            setOpdEdit(true);
        } else {
            handleShowRate2();
        }

    }
    const handleDeleteRate = async (testId) => {
        try {
            const res = await getData(`organisation/dashboard/apis/test-list-api/?organisation_id=${id}&test_id=${testId}`);
            if (res?.success) {
                // window.location.reload();
                await getOPD();
            } else {
                // window.location.reload();
                console.error("Failed to delete test");
            }
        } catch (error) {
            console.error("Error deleting test:", error);
        }
    };
    const [testDescRate, setTestDescRate] = useState();
    const getMasterRate = async () => {
        {
            const res = await getData(`organisation/dashboard/apis/test-description-get/?organisation_id=${id}`);
            if (res?.success) {
                const result = res.data.map((data) => ({
                    value: data?.id,
                    label: data?.description,
                    name: "test_description",
                }));
                setTestDescRate(result);
            }
        }

    };
    useEffect(() => {
        window.scrollTo(0, 0);
        getOPD();
        getMasterRate();
    }, [formData]);

    // test-list-serach-start
    const [search, setSearch] = useState();
    const getTestRecords = async (page, searchIn) => {
        setSearch(searchIn);
        var res;
        if (page) {
            if (searchIn) {
                res = await getData(
                    `/organisation/dashboard/apis/parameter-test/?${page}&test_name=${searchIn}`
                );
            } else {
                res = await getData(
                    `/organisation/dashboard/apis/parameter-test/?${page}`
                );
            }
        } else {
            if (searchIn) {
                res = await getData(
                    `/organisation/dashboard/apis/parameter-test/?organisation_id=${id}&test_name=${searchIn}`
                );
            } else {
                res = await getData(
                    `/organisation/dashboard/apis/parameter-test/?organisation_id=${id}`
                );
            }
        }
        setTestList(res);
    };
    // test-list-serach-end

    // Rate-list-serach-start
    const [searchRate, setSearchRate] = useState('');

    const getTestRecordsRate = async (page) => {
        console.log(searchRate);
        let res;
        try {
            if (page) {
                if (searchRate) {
                    res = await getData(`/organisation/dashboard/apis/test-list-api/?${page}&test_name=${searchRate}`);
                } else {
                    res = await getData(`/organisation/dashboard/apis/test-list-api/?${page}`);
                }
            } else {
                if (searchRate) {
                    res = await getData(`/organisation/dashboard/apis/test-list-api/?test_name=${searchRate}`);
                } else {
                    res = await getData(`/organisation/dashboard/apis/test-list-api/?organisation_id=${id}`);
                }
            }
            settestPrice(res);
            console.log(res, "searchInsearchInsearchIn");
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error state or display error message
        }
    };

    console.log(formData?.test_count, "formData?.test_count");
    console.log(formData.test_included, "formData.test_included");
    // Rate-list-serach-end
    return (
        <>
            <div className="main">
                <div className="container-fluid p-2">
                    <div className="row">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col lg={8}>
                                    <Nav variant="pills" className="patho-setting-tabs" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" className="me-2">
                                                Test
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" className="me-2">
                                                Rate List
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg={4}>
                                    <div className="row patho-setting-tabs">
                                        <div className="col-md-7">
                                            <div className="text-end">
                                                {activeTab === 'first' && (
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            value={search}
                                                            aria-describedby="basic-addon1"
                                                            onChange={(e) => setSearch(e.target.value)}
                                                        />
                                                        <InputGroup.Text id="basic-addon1" onClick={() => getTestRecords('', search)}>
                                                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                )}
                                                {activeTab === 'second' && (
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            value={searchRate}
                                                            aria-describedby="basic-addon1"
                                                            onChange={(e) => setSearchRate(e.target.value)}
                                                        />
                                                        <InputGroup.Text id="basic-addon1" onClick={() => getTestRecordsRate(searchRate)}>
                                                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="text-end">
                                                {activeTab === 'first' && (
                                                    <Button
                                                        variant="success"
                                                        onClick={handleShow}
                                                        className="search btn-style btn-size text bg-green"
                                                    >
                                                        Create test
                                                    </Button>
                                                )}
                                                {activeTab === 'second' && (
                                                    <Button
                                                        variant="success"
                                                        onClick={handleShowRate}
                                                        className="search btn-style btn-size text bg-green"
                                                    >
                                                        Add Pathology
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12}>
                                <Tab.Content>
    <Tab.Pane eventKey="first">
        <div className="test-table">
            <Table responsive>
                <thead>
                    <tr>
                        <th>Test code</th>
                        <th>Test name</th>
                        <th>Subtest</th>
                        <th>Parameter name</th>
                        <th>Parameter type</th>
                        <th>Default</th>
                        <th>Unit</th>
                        <th>Reference range</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {testList?.results?.map((value, index) => {
                        if (value?.parameters?.length === 0) {
                            return (
                                <tr key={index}>
                                    <td colSpan="9" className="text-center"></td>
                                </tr>
                            );
                        }

                        const renderedSubtests = new Set();

                        return value?.parameters?.map((param, paramIndex) => {
                            const isSubtestRendered = renderedSubtests.has(param.subtest);
                            if (!isSubtestRendered) {
                                renderedSubtests.add(param.subtest);
                            }

                            const references = param?.reference?.length > 0 ? param?.reference : [{ value: 'N/A' }];

                            return references.map((ref, refIndex) => {
                                return (
                                    <tr key={`${index}-${paramIndex}-${refIndex}`}>
                                        {paramIndex === 0 && refIndex === 0 && (
                                            <>
                                                <td rowSpan={value.parameters.reduce((acc, p) => acc + (p.reference?.length || 1), 0)}>
                                                    <div className="user-name-holder">
                                                        <p className="text text-size mb-0">{value?.test_code}</p>
                                                    </div>
                                                </td>
                                                <td rowSpan={value.parameters.reduce((acc, p) => acc + (p.reference?.length || 1), 0)}>
                                                    <div className="user-name-holder">
                                                        <p className="text text-size mb-0">{value?.name}</p>
                                                    </div>
                                                </td>
                                            </>
                                        )}

                                        {!isSubtestRendered && refIndex === 0 && (
                                            <td rowSpan={value.parameters.filter(p => p.subtest === param.subtest).reduce((acc, p) => acc + (p.reference?.length || 1), 0)}>
                                                <div className="user-name-holder">
                                                    <p className="text text-size mb-0">{param?.subtest}</p>
                                                </div>
                                            </td>
                                        )}

                                        {refIndex === 0 && (
                                            <>
                                                <td rowSpan={references.length}>
                                                    <div className="user-name-holder">
                                                        <p className="text text-size mb-0">{param?.name}</p>
                                                    </div>
                                                </td>
                                                <td rowSpan={references.length}>
                                                    <div className="user-name-holder">
                                                        <p className="text text-size mb-0">{param?.parameter_type?.name}</p>
                                                    </div>
                                                </td>
                                                <td rowSpan={references.length}>
                                                    <div className="user-name-holder">
                                                        <p className="text text-size mb-0">{param?.default}</p>
                                                    </div>
                                                </td>
                                                <td rowSpan={references.length}>
                                                    <div className="user-name-holder">
                                                        <p className="text text-size mb-0">{param?.unit}</p>
                                                    </div>
                                                </td>
                                            </>
                                        )}

                                        <td>
                                            <div className="user-name-holder">
                                            <p className="text text-size mb-0">
                                                        {ref.age_group ? (
                                                            `${ref.age_group} - ${ref.sex}  :  ${ref.min_value && ref.max_value ? `${ref.min_value}-${ref.max_value}` : ''}`
                                                        ) : (
                                                            ''
                                                        )}
                                                        {ref.par_range === '1' ? ` < ${ref.value}` : ''}
                                                        {ref.par_range === '2' ? ` > ${ref.value}` : ''}
                                                        {ref.par_range === '4' ? ` < = ${ref.value}` : ''}
                                                        {ref.par_range === '5' ? ` > = ${ref.value}` : ''}
                                                    </p>
                                            </div>
                                        </td>

                                        {paramIndex === 0 && refIndex === 0 && (
                                            <td rowSpan={value.parameters.reduce((acc, p) => acc + (p.reference?.length || 1), 0)}>
                                                <div className="action">
                                                    <Button className="action-btn" onClick={() => handleDelete(param.id)}>
                                                        <FontAwesomeIcon className="text" icon="fa-solid fa-trash" />
                                                    </Button>
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                );
                            });
                        });
                    })}
                </tbody>
            </Table>
        
                {/* pegignation start */}
                <div className="Pagination-holder">
                    <ul className="pagination">
                        {testList?.previous && (
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    role="button"
                                    tabIndex="0"
                                    onClick={() => getTests(testList.previous.split('?')[1])}
                                >
                                    <span aria-hidden="true">‹</span>
                                    <span className="visually-hidden">Previous</span>
                                </a>
                            </li>
                        )}
                        {[...Array(testList?.count && Math.ceil(testList?.count / 10))].map((_, index) => (
                            <li key={index} className="page-item">
                                <a
                                    className="page-link"
                                    role="button"
                                    tabIndex="0"
                                    onClick={() => getTests(`organisation_id=${id}&page=${index + 1}`)}
                                >
                                    {index + 1}
                                </a>
                            </li>
                        ))}
                        {testList?.next && (
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    role="button"
                                    tabIndex="0"
                                    onClick={() => getTests(testList.next.split('?')[1])}
                                >
                                    <span aria-hidden="true">›</span>
                                    <span className="visually-hidden">Next</span>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                {/* pegignation end */}
            </div>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
            <div className="test-table">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Test name</th>
                            <th>Test Price</th>
                            <th>Discounted Price</th>
                            <th>Test Description</th>
                            <th>Discount Price</th>
                            <th>Test Included</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {testPrice?.results?.map((value, index) => (
                            <tr key={index}>
                                <>
                                    <td>
                                        <div className="user-name-holder">
                                            <p className="text text-size mb-0 ">{value?.name}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="user-name-holder">
                                            <p className="text text-size mb-0 ">{value?.price}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="user-name-holder">
                                            <p className="text text-size mb-0 ">{value?.discounted_price}</p>
                                        </div>
                                    </td>
                                </>
                                <>
                                    <td>
                                        <div className="user-name-holder">
                                            <p className="text text-size mb-0 ">{value?.description}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="user-name-holder">
                                            <p className="text text-size mb-0 ">{value?.discounted_price}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="user-name-holder">
                                            {Array.isArray(value?.test_included) ? (
                                                value.test_included.map((test, idx) => (
                                                    <p key={idx} className="text text-size mb-0 ">{test.name},</p>
                                                ))
                                            ) : (
                                                <p className="text text-size mb-0 ">{value?.test_included?.name}</p>
                                            )}
                                        </div>
                                    </td>
                                </>

                                <td>
                                    <div className="action">
                                        <Link to={`/diagnosticSlot/${id}/${value?.id}`}>
                                            <Button className="action-btn" onClick={() => handleEditRate(index, "view")}>
                                                <FontAwesomeIcon
                                                    className="text"
                                                    icon="fa-solid fa-eye"
                                                />
                                            </Button>
                                        </Link>
                                        <Button className="action-btn" onClick={() => handleDeleteRate(value?.id)}>
                                            <FontAwesomeIcon
                                                className="text"
                                                icon="fa-solid fa-trash"
                                            />
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* pegignation start */}
                <div className="row me-0 ms-0">
                    <div className="col-md-12">
                        <div className="Pagination-holder">
                            <ul className="pagination">
                                {testPrice?.previous && (
                                    <li className="page-item">
                                        <a className="page-link" role="button" tabIndex="0" onClick={() => getOPD(testPrice?.previous.split("?")[1])}>
                                            <span aria-hidden="true">‹</span>
                                            <span className="visually-hidden">Previous</span>
                                        </a>
                                    </li>
                                )}
                                {[...Array(testPrice?.count && Math.ceil(testPrice?.count / 10))].map(function (_, index) {
                                    return (
                                        <li key={index} className="page-item">
                                            <a className="page-link" role="button" tabIndex="0" onClick={() => getOPD("page=" + (index + 1))}>
                                                {index + 1}
                                            </a>
                                        </li>
                                    );
                                })}
                                {testPrice?.next && (
                                    <li className="page-item">
                                        <a className="page-link" role="button" tabIndex="0" onClick={() => getOPD(testPrice?.next.split("?")[1])}>
                                            <span aria-hidden="true">›</span>
                                            <span className="visually-hidden">Next</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* pegignation end */}
            </div>
        </Tab.Pane>
    </Tab.Content>
</Col>

                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 className="text">Pathology Details</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="Details-card">
                        <Card>
                            <Card.Body>
                                <div className="visitor-patient-card">
                                    <div className="row me-0 ms-0">
                                        <div className="col-md-12">
                                            <div className="details-card">
                                                <Card.Title>
                                                    <h4 className="text ">Test Details</h4>
                                                </Card.Title>
                                                <Card.Text>
                                                    <div className="details">
                                                        <div className="visitor-name ">
                                                            <FontAwesomeIcon
                                                                className="text me-2"
                                                                icon="fa-solid fa-vial"
                                                            />

                                                            <p className="text mb-0">{formData?.name}</p>
                                                        </div>

                                                        <p className="text">
                                                            <b>Description</b>
                                                        </p>

                                                        <p className="text ">{formData?.description}</p>

                                                        <p className="text ">
                                                            <b>Price:- </b>
                                                            {formData?.price}
                                                        </p>
                                                    </div>
                                                </Card.Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose2}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <PathologyModalfirst
                HedaingName="Create Test"
                show={modalTest}
                onHide={() => setModalTest(false)}
                formData={formData}
                setFormData={setFormData}
                getTests={getTests}
            />
            <EditTestModal
                show={showEdit}
                handleClose={handleCloseEdit}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleEditSubmit}
                tests={tests}
                setFormData={setFormData}
                paramterType={paramterType}
                parameters={parameters}
                handleParameterChange={handleParameterChange}
                handleReferenceChange={handleReferenceChange}
                handleAddParameter={handleAddParameter}
                handleRemoveParameter={handleRemoveParameter}
                handleAddReference={handleAddReference}
                handleRemoveReference={handleRemoveReference}
                errorStyle={errorStyle}
                errors={errors}
                updateTestData={updateTestData}
            />
            <Modal show={showRate} onHide={handleCloseRate}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 className="text">{opdEdit ? 'Edit' : 'Add'} Pathology </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3 text"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className="text">Pathology Name</Form.Label>
                            <Form.Control
                                name="name"
                                value={formData?.name}
                                className="text"
                                type="text"
                                placeholder="Enter Name"
                                autoFocus
                                onChange={handleChangeRate}
                            />
                            {errors?.name && (
                                <span style={errorStyle}>{errors?.name}</span>
                            )}
                        </Form.Group>

                        <Form.Group
                            className="mb-3 text"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className="text">Description</Form.Label>
                            <Form.Control
                                name="description"
                                value={formData?.description}
                                className="text"
                                type="text"
                                placeholder="Enter Description"
                                autoFocus
                                onChange={handleChangeRate}
                            />
                            {errors?.description && (
                                <span style={errorStyle}>{errors?.description}</span>
                            )}
                        </Form.Group>

                        <Form.Group
                            className="mb-3 text"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className="text">Price</Form.Label>
                            <Form.Control
                                name="price"
                                value={formData?.price}
                                className="text"
                                type="text"
                                placeholder="Enter Price"
                                autoFocus
                                onChange={handleChangeRate}
                                onKeyDown={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        if (event.key != "Backspace")
                                            event.preventDefault();
                                    }
                                }}
                            />
                            {errors?.price && (
                                <span style={errorStyle}>{errors?.price}</span>
                            )}
                        </Form.Group>
                        <Form.Group
                            className="mb-3 text"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className="text">Discounted Price</Form.Label>
                            <Form.Control
                                name="discounted_price"
                                value={formData?.discounted_price}
                                className="text"
                                type="text"
                                placeholder="Enter Discounted Price"
                                autoFocus
                                onChange={handleChangeRate}
                                onKeyDown={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        if (event.key != "Backspace")
                                            event.preventDefault();
                                    }
                                }}
                            />
                            {errors?.discounted_price && (
                                <span style={errorStyle}>{errors?.discounted_price}</span>
                            )}
                        </Form.Group>
                        <Form.Group
                            className="mb-3 text"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className="text">Test Description</Form.Label>
                            <Select
                                placeholder="Select Test Description"
                                className="select-line"
                                isMulti
                                value={formData?.test_description_value}
                                onChange={(selectOption) => handleCurrentSelect2Change(selectOption)}
                                options={testDescRate}
                            />
                            {errors?.test_description && (
                                <span style={errorStyle}>{errors?.test_description}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                            <Form.Label className="text">Test Included Count</Form.Label>
                            <Form.Control
                                name="test_count"
                                value={formData?.test_count}
                                className="text"
                                type="text"
                                placeholder="Enter Test Included Count"
                                autoFocus
                                onChange={handleChangeRate}
                                onKeyDown={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        if (event.key !== "Backspace") event.preventDefault();
                                    }
                                }}
                            />
                            {errors?.test_count && (
                                <span style={errorStyle}>{errors?.test_count}</span>
                            )}
                        </Form.Group>
                        {formData?.test_count && (
                            <>
                                {Array.isArray(formData.test_included) && (
                                    [...Array(parseInt(formData?.test_count))].map((_, index) => (
                                        <Form.Group key={index} className="mb-2 text">
                                            <Form.Label className="text">Test Name</Form.Label>
                                            <div className="mb-2">
                                                <Form.Control
                                                    name='test_included'
                                                    value={formData?.test_included?.[index] || ''}
                                                    className="text"
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    onChange={(e) => handleTestChange(e, index)}
                                                />
                                                {errors?.[`test_included${index}`] && (
                                                    <span style={errorStyle}>{errors?.[`test_included${index}`]}</span>
                                                )}
                                            </div>
                                        </Form.Group>
                                    ))
                                )}
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseRate}>
                        Close
                    </Button>
                    {opdEdit ?
                        (
                            <Button variant="success" onClick={handleEditSubmitRate}>
                                Edit
                            </Button>
                        ) : (
                            <Button variant="success" onClick={handleSubmitRate}>
                                Add
                            </Button>
                        )}
                </Modal.Footer>
            </Modal>

            <Modal show={showRate2} onHide={handleCloseRate2}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 className="text">Pathology Details</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="Details-card">
                        <Card>
                            <Card.Body>
                                <div className="visitor-patient-card">
                                    <div className="row me-0 ms-0">
                                        <div className="col-md-12">
                                            <div className="details-card">
                                                <Card.Title>
                                                    <h4 className="text ">Test Details</h4>
                                                </Card.Title>
                                                <Card.Text>
                                                    <div className="details">
                                                        <div className="visitor-name ">
                                                            <FontAwesomeIcon
                                                                className="text me-2"
                                                                icon="fa-solid fa-vial"
                                                            />

                                                            <p className="text mb-0">{formData?.name}</p>
                                                        </div>

                                                        <p className="text">
                                                            <b>Description</b>
                                                        </p>

                                                        <p className="text ">
                                                            {formData?.description}
                                                        </p>

                                                        <p className="text ">
                                                            <b>Price:- </b>{formData?.price}
                                                        </p>

                                                        {/* <p className="text ">
                                  <b>Date:- </b>12-01-2024{" "}
                                </p> */}
                                                    </div>
                                                </Card.Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Card.Body>
                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseRate2}>
                        Close
                    </Button>
                    {/* <Button variant="success" onClick={handleClose2}>
                Add
              </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PathoSetting;
