import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "./DiagnosticDetails.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../utils/context";
import { useContext } from "react";
library.add(fas);

function DiagnosticDetails() {


  const { id } = useParams();
  const { getData, postData, imageUrl, Select2Data, orgID, setOrgId, indexDetails, setIndexDetail } = useContext(Context);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    await setDoctorData({ ...docterData, image: file });
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };


  const [docterData, setDoctorData] = useState({
    organisation_id: id,
    name: "",
    phone_number: "",
    category_id: "",
    price: "",
    experience: "",
    about: "",
    image: undefined,
  });


  const [category, setCategory] = useState();
  const getMaster = async () => {
    {
      const res = await getData("organisation/dashboard/apis/category-list-api");
      console.log(res, 'lllll');
      if (res?.success) {
        const data = await Select2Data(res.data, "category_id", false);
        setCategory(data);
      }
    }
  };


  const handleChange = async (e) => {
    if (
      e?.name === "category_id"
    ) {
      setDoctorData({ ...docterData, [e?.name]: e });
    } else {
      setDoctorData({ ...docterData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();


  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!docterData.name) {
      errors.name = "Name is required";
    }
    if (!docterData.phone_number) {
      errors.phone_number = "Phone Number is required";
    }else if(docterData.phone_number.length < 10){
      errors.phone_number = "Invalid Phone number";
    }

    if (!docterData.category_id) {
      errors.category_id = "Category is required";
    }
    if (!docterData.price) {
      errors.price = "Price is required";
    }
    if (!docterData.experience) {
      errors.experience = "Experience is required";
    }

    if (!docterData.about) {
      errors.about = "About is required";
    }


    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`image`, docterData.image);
        finalData.append(`name`, docterData.name);
        finalData.append(`mobile_number`, docterData.phone_number);
        finalData.append(`category_id`, docterData.category_id?.value);
        finalData.append(`about`, docterData.about);
        finalData.append(`experience`, docterData.experience);
        finalData.append(`price`, docterData.price);
        finalData.append(`organisation_id`, docterData.organisation_id);

        const result = await postData(
          "/organisation/dashboard/apis/organisation-doctor-detail-generic-api/",
          finalData
        );

        if (result?.success) {
          await setDoctorData({
            organisation_id: id,
            name: "",
            phone_number: "",
            category_id: "",
            price: "",
            experience: "",
            about: "",
          });
          await setImagePreview(false);
          await setErrors();
          handleClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [hospitals, setHospital] = useState();
  const getHospital = async () => {
   
      const res =await getData(`organisation/dashboard/apis/single-org/?organisation_id=${id}`);
      if(res?.success){
        setHospital(res?.data);
      }
    
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    getMaster();
    getHospital();
  }, []);
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          <section className="hospital-details">
            <div className="row me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="top-section">
                  <div className="img-holder">
                    <img
                      className="details-img"
                      src={
                        imageUrl +
                        hospitals?.icon
                      }
                      alt="hospitaldetails-img"
                    />
                  </div>

                  <div className="small-img">
                    <img
                      className="small-hospital-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/dashboard/hospital/hospitalsdetails/Clinic_dummy.png"
                      }
                      alt="hospital-img"
                    />
                  </div>
                </div>

                <div className="hospital-info">
                  <div className="info-details">
                    <div className="hospital-name">
                      <h4 className="text info-style data-line">
                      {hospitals?.title}
                      </h4>
                    </div>

                    <div className="info ">
                      <div className="text slot-info">
                        <p className="info-title data-line">{hospitals?.org_type}</p>
                      </div>

                      <div className="text slot-info">
                        <p className="data-line">
                          {" "}
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-phone"
                          />
                          {hospitals?.phone_number}
                        </p>
                      </div>

                      <div className="text slot-info">
                        <p className="data-line">
                          {" "}
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-location-dot"
                          />
                          <strong>State:</strong>
                        </p>
                        <p className="data-line">{hospitals?.state?.title}</p>
                      </div>

                      <div className="text slot-info">
                        <span className="">
                          <p className="data-line">
                            <strong>City:</strong>
                          </p>
                          <p className="data-line">{hospitals?.city?.title}</p>
                        </span>
                      </div>

                      <div className="text slot-info">
                        <p className="data-line">
                          <strong>Country:</strong>
                        </p>
                        <p className="data-line">{hospitals?.country?.title}</p>
                      </div>
                    </div>
                  </div>

                  <div className="hospital-rating ">
                  {[...Array(hospitals?.rating && (parseInt(hospitals?.rating)))]?.map((index) => (
                                        <FontAwesomeIcon
                                          className="yellow"
                                          icon="fa-solid fa-star"
                                        />
                                      ))}
                                      {[...Array(hospitals?.rating && (5 - parseInt(hospitals?.rating)))]?.map((index) => (
                                        <FontAwesomeIcon
                                          className="grey"
                                          icon="fa-solid fa-star"
                                        />
                                      ))}
                  </div>
                </div>

                <div className="description">
                  <strong className="text">Address 1:</strong>
                  <p className="text">{hospitals?.address1}</p>
                  <strong className="text">Address 2:</strong>
                  <p className="text">
                    {hospitals?.address2}
                  </p>
                  <strong className="text mt-2">Description: </strong>
                  <p className="text">{hospitals?.description}</p>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default DiagnosticDetails;
