import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Table from "react-bootstrap/Table";
import "./PathologyCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import Select from "react-select";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
library.add(fas);

function TestRecord() {
  const [selectedDate, setSelectedDate] = useState(null);
  const { id } = useParams();
  const handleDateChange = (date1) => {
    const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;

    // Update formData state with the formatted date
    setFormData({ ...formData, date: formattedDate });
  }

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = async () => {
    await setFormData({
      id: "",
      name: "",
      sex: "M",
      phone_number: "",
      age_group: "adult",
      lab_no: "",
      test: "",
      organisation: id,
      organisation: id,
    });

    await setParameters([
      { test_value: '', test_id: '', params: [{ value: "", label: "" }], parameters: [{ parameters_id: '', param_value: '', result: '', unit: '' }] },
    ]);

    await setErrors();
    setShow(false);
    setOpdEdit(false);
  }
  const handleShow = () => setShow(true);

  const handleClose2 = async () => {
    setShow2(false);
    await setFormData({
      id: "",
      name: "",
      sex: "M",
      phone_number: "",
      age_group: "adult",
      lab_no: "",
      test: "",
      organisation: id,
      organisation: id,
    });

    await setErrors();
    setOpdEdit(false);
  }
  const handleShow2 = () => setShow2(true);



  const { getData, postData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    sex: "M",
    phone_number: "",
    age_group: "adult",
    lab_no: "",
    test: "",
    organisation: id,
  });

  const handleChange = async (e) => {
    if (
      e?.name === "test"
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.phone_number) {
      errors.phone_number = "Phone Number is required";
    } else if (formData.phone_number.length < 10) {
      errors.phone_number = "Invalid Phone number";
    }
    if (!formData.sex) {
      errors.sex = "Gender is required";
    }
    if (!formData.age_group) {
      errors.age_group = "Age Group is required";
    }
    if (!formData.lab_no) {
      errors.lab_no = "Lab No is required";
    }


    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    console.log(validationErrors);
    console.log(formData, 'llllllll');
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`name`, formData.name);
        finalData.append(`phone_number`, formData.phone_number);
        finalData.append(`age_group`, formData.age_group);
        finalData.append(`sex`, formData.sex);
        finalData.append(`lab_no`, formData.lab_no);
        finalData.append(`test`, JSON.stringify(formData.test));
        finalData.append(`organisation`, formData.organisation);


        const result = await postData(
          "organisation/dashboard/apis/test-records-api/",
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            name: "",
            sex: "M",
            phone_number: "",
            age_group: "adult",
            lab_no: "",
            test: "",
            organisation: id,
          });
          await setParameters([
            { test_value: '', test_id: '', params: [{ value: "", label: "" }], parameters: [{ parameters_id: '', param_value: '', result: '', unit: '' }] },
          ]);


          await setErrors();
          handleClose();
          getTestRecords();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`organisation_id `, formData.organisation_id);

        const result = await editPatchData(
          `organisation/dashboard/apis/opd-edit-api/${formData.id}/`,
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            name: "",
            sex: "M",
            phone_number: "",
            age_group: "adult",
            lab_no: "",
            test: "",
            organisation: id,
          });

          await setErrors();
          handleClose();
          getTestRecords();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [testList, setTestList] = useState();
  const [opdEdit, setOpdEdit] = useState(false);
  // const getTestRecords = async (page) => {
  //   var res;
  //   if (page) {
  //     res = await getData(`organisation/dashboard/apis/test-record-get/?${page}`);
  //   } else {
  //     res = await getData(`organisation/dashboard/apis/test-record-get/?organisation_id=${id}`);
  //   }

  //   console.log(res);
  //   setTestList(res);
  //   if (res?.success) {

  //   }
  // }
  const [search, setSearch] = useState();
  const getTestRecords = async (page, searchIn) => {
    setSearch(searchIn);
    var res;
    if (page) {
      if (searchIn) {
        res = await getData(
          `organisation/dashboard/apis/test-record-get/?${page}&number=${searchIn}`
        );
      } else {
        res = await getData(
          `organisation/dashboard/apis/test-record-get/?${page}`
        );
      }
    } else {
      if (searchIn) {
        res = await getData(
          `organisation/dashboard/apis/test-record-get/?organisation_id=${id}&number=${searchIn}`
        );
      } else {
        res = await getData(
          `organisation/dashboard/apis/test-record-get/?organisation_id=${id}`
        );
      }
    }
    setTestList(res);
  };


  const [testRecord, setTestRecord] = useState();
  const handleEdit = async (index, action) => {
    console.log(testList?.results[index]);
    await setFormData({
      id: testList?.results[index]?.id,
      name: testList?.results[index]?.name,
      description: testList?.results[index]?.description,
      price: testList?.results[index]?.price,
    });


    if (action === "edit") {
      handleShow();
      setOpdEdit(true);
    } else {
      setTestRecord(testList?.results[index]);
      handleShow2();
    }

  }

  const handleDelete = async (id) => {
    const res = await deleteData(`organisation/dashboard/apis/opd-edit-api/${id}/`);
    await getTestRecords();
  }
  const [tests, setTest] = useState();
  const [paramterType, setParameterType] = useState();
  const getMaster = async () => {
    {
      const res = await getData(`organisation/dashboard/apis/test-list-without-api/?organisation_id=${id}`);
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "test",
        }));
        setTest(result);
      }
    }
    {
      const res = await getData(`organisation/dashboard/apis/parameters-type-api/`);
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "parameter_type",
        }));
        setParameterType(result);
      }
    }
  };



  useEffect(() => {
    window.scrollTo(0, 0);
    getTestRecords();
    getMaster();
  }, []);

  const getParameters = async (id) => {
    try {
      const res = await getData(`organisation/dashboard/apis/parameters-test-get/?test_id=${id}`);
      if (res?.success) {
        const result = res.data?.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "parameter_type",
          type: data?.parameter_type?.name,
        }));
        return result;
      }
    } catch (error) {
      console.error("Error fetching parameters:", error);
      return [];
    }
  };

  const handleParameterForm = async () => {
    await setFormData({ ...formData, test: testSelect });
  }


  const [testSelect, setParameters] = useState([
    { test_value: '', test_id: '', params: [{ value: "", label: "" }], parameters: [{ parameters_id: '', param_value: '', result: '', unit: '' }] },
  ]);

  const handleAddParameter = () => {
    setParameters([...testSelect, { test_value: '', test_id: '', params: [{ value: "", label: "" }], parameters: [{ parameters_id: '', param_value: '', result: '', unit: '' }] }]);
  };

  const handleRemoveParameter = (index) => {
    const updatedParameters = [...testSelect];
    updatedParameters.splice(index, 1);
    setParameters(updatedParameters);
  };

  const handleAddReference = (paramIndex) => {
    const updatedParameters = [...testSelect];
    updatedParameters[paramIndex].parameters.push({ parameters_id: '', param_value: '', result: '', unit: '' });
    setParameters(updatedParameters);
  };

  const handleRemoveReference = (paramIndex, refIndex) => {
    const updatedParameters = [...testSelect];
    updatedParameters[paramIndex].parameters.splice(refIndex, 1);
    setParameters(updatedParameters);
  };

  const handleParameterChange = async (index, key, value) => {
    const updatedParameters = [...testSelect];
    updatedParameters[index][key] = value;
    setParameters(updatedParameters);

    if (key === 'test_id') {
      const params = await getParameters(value);
      updatedParameters[index]['params'] = params;
      setParameters(updatedParameters);
    }
  };

  const handleReferenceChange = (paramIndex, refIndex, attribute, value) => {
    const updatedParameters = [...testSelect];
    updatedParameters[paramIndex].parameters[refIndex][attribute] = value;
    setParameters(updatedParameters);
  };

  console.log(testSelect);

  const [smsmodal, setSmsModal] = useState(false);
  const handleSMS = async (phone) => {
    const res = await postData(`users/dashboard/apis/send-sms/`, { phone_number: phone });
    if (res?.success) {
      setSmsModal(true);
      setTimeout(() => {
        setSmsModal(false);
      }, 2000);
    }
  }

  const handlePdf = async (index) => {

    const data = {
      data: JSON.stringify(testList?.results[index]),
    }
    const res = await postData(`organisation/dashboard/apis/view-pdf/`, data);
  }

  useEffect(() => {
    handleParameterForm();
  }, [testSelect]);
  // console.log(testList, "tyuyutyuty oooooooooooooo");
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* doctor data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">Test Records</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  Search:{" "}
                </label>
                <Form.Control
                  className="serrr-innp"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => getTestRecords("", e?.target?.value)}
                ></Form.Control>
                <Button
                  variant="success"
                  onClick={handleShow}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Test Record
                </Button>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">Patient Name</span>
                    </th>

                    <th>
                      <span className="text table-data-heading">
                        Phone Number
                      </span>
                    </th>

                    <th>
                      <span className="text table-data-heading">Lab No.</span>
                    </th>

                    <th>
                      <span className="text table-data-heading">Date</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">PDF Download</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Send SMS</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {testList?.results?.map((value, index) => (
                    <tr>
                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.name}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.phone_number}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">{value?.lab_no}</p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.created_at}
                          </p>
                        </div>
                      </td>
                      <td >
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            <a href={imageUrl + '/media/' + value?.pdf} target="_blank">
                              <FontAwesomeIcon
                                className="text"
                                icon="fa-solid fa-download"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handlePdf(index)}
                              />
                            </a>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-message"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleSMS(value?.phone_number)}
                            />
                          </p>
                        </div>
                      </td>

                      <td>
                        <div className="action">
                          {/* <Link to="/PathologyDetails"> */}
                          <Button className="action-btn" onClick={() => handleEdit(index, "view")}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-eye"
                            />
                          </Button>
                          {/* </Link> */}
                          {/* <Button className="action-btn ">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>
                          <Button className="action-btn">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button> */}
                        </div>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  {testList?.previous && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getTestRecords(testList?.previous.split("?")[1], search)}>
                        <span aria-hidden="true">‹</span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[...Array(testList?.count && Math.ceil(testList?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getTestRecords(`organisation_id=${id}&page=` + (index + 1), search)}>
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                  {testList?.next && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getTestRecords(testList?.next.split("?")[1], search)}>
                        <span aria-hidden="true">›</span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </li>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* doctor data table end */}

          {/* add Visitor  modal start */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Test Record </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Patient Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={formData?.name}
                    className="text"
                    type="text"
                    placeholder="Enter Patient Name"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.name && (
                    <span style={errorStyle}>{errors?.name}</span>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Phone No.</Form.Label>
                  <Form.Control
                    name="phone_number"
                    className="number"
                    type="text"
                    value={formData?.phone_number}
                    placeholder="Enter Phone Number"

                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                    minlength="10"
                    maxlength="10"
                  />
                  {errors?.phone_number && (
                    <span style={errorStyle}>{errors?.phone_number}</span>
                  )}
                </Form.Group>
                <Form.Group className="mb-2 text">
                  <Form.Label className="text">Age Group</Form.Label>
                  {["Adult", "Child", "NewBorn"].map((value, radioIndex) => (
                    <div key={radioIndex} className="mb-2">
                      <Form.Check
                        type="radio"
                        label={value}
                        name="age_group"
                        value={value}
                        checked={formData?.age_group === value}
                        onChange={handleChange}
                      />
                    </div>
                  ))}
                </Form.Group>
                {errors?.age_group && (
                  <span style={errorStyle}>{errors?.age_group}</span>
                )}
                <Form.Group className="mb-2 text">
                  <Form.Label className="text">Sex</Form.Label>
                  {["M", "F", "B"].map((value, radioIndex) => (
                    <div key={radioIndex} className="mb-2">
                      <Form.Check
                        type="radio"
                        label={value}
                        name="sex"
                        value={value}
                        checked={formData?.sex === value}
                        onChange={handleChange}
                      />
                    </div>
                  ))}
                </Form.Group>
                {errors?.sex && (
                  <span style={errorStyle}>{errors?.sex}</span>
                )}
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Lab No.</Form.Label>
                  <Form.Control
                    name="lab_no"
                    value={formData?.lab_no}
                    className="text"
                    type="text"
                    placeholder="Enter Lab No."
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.lab_no && (
                    <span style={errorStyle}>{errors?.lab_no}</span>
                  )}
                </Form.Group>
                {testSelect.map((param, paramIndex) => (
                  <div key={paramIndex}>
                    <Form.Group className="mb-3 text">
                      <Form.Label className="text">Test</Form.Label>
                      <Select
                        placeholder="Select Test"
                        className="select-line"
                        value={param?.test_value}
                        onChange={(e) => { handleParameterChange(paramIndex, 'test_id', e.value); handleParameterChange(paramIndex, 'test_value', e); }}
                        options={tests}
                      />
                    </Form.Group>
                    {param?.parameters?.map((ref, refIndex) => (
                      <div key={refIndex}>
                        <Form.Group className="mb-2 text">
                          <Form.Label className="text">Parameter</Form.Label>
                          <Select
                            placeholder="Select Test"
                            className="select-line"
                            value={param?.param_value}
                            onChange={(e) => { handleReferenceChange(paramIndex, refIndex, 'parameters_id', e.value); handleReferenceChange(paramIndex, refIndex, 'param_value', e); }}
                            options={param?.params}
                          />
                        </Form.Group>
                        <Form.Group className="mb-2 text">
                          <Form.Label className="text">Result</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Result"
                            value={ref?.result}
                            onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'result', e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-2 text">
                          <Form.Label className="text">Unit</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Unit"
                            value={ref?.unit}
                            onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'unit', e.target.value)}
                          />
                        </Form.Group>

                        {refIndex !== 0 && (
                          <Button variant="danger" onClick={() => handleRemoveReference(paramIndex, refIndex)}>
                            Remove Parameter
                          </Button>
                        )}
                      </div>
                    ))}
                    <Button variant="info" onClick={() => handleAddReference(paramIndex)}>
                      Add Parameter
                    </Button>
                    <br />
                    {paramIndex !== 0 && (
                      <Button className="mt-3" variant="danger" onClick={() => handleRemoveParameter(paramIndex)}>
                        Remove Test
                      </Button>
                    )}
                  </div>
                ))}
                <Button className="mt-3" variant="primary" onClick={handleAddParameter}>
                  Add Test
                </Button>



              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" onClick={handleSubmit}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
          {/* add Visitor  modal end */}

          {/* details  modal start */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Test Record Details</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="Details-card">
                <Card>
                  <Card.Body>
                    <div className="visitor-patient-card">
                      <div className="row me-0 ms-0">
                        <div className="col-md-12">
                          <div className="details-card">
                            <p className="text">
                              Patient Name

                              <Card.Title>
                                <h4 className="text ">{testRecord?.name}</h4>
                              </Card.Title>
                            </p>
                            {testRecord?.test_records_tests?.map((value, index) => (
                              <Card.Text>
                                <div className="details">
                                  <div className="visitor-name ">
                                    <FontAwesomeIcon
                                      className="text me-2"
                                      icon="fa-solid fa-vial"
                                    />

                                    <p className="text mb-0">{value?.test?.name}</p>
                                  </div>

                                  {value?.parameters?.map((param, paramIndex) => (
                                    <>
                                      <p className="text">
                                        <b >Parameter: </b>{param?.parameters?.name}

                                        <b className="ms-2">Result: </b> {param?.result} {param?.unit}
                                      </p>
                                      {param?.references?.map((ref, refIndex) => (
                                        <p className="text">
                                          <b >{ref?.age_group}: {ref?.sex + ' ->'}</b> {ref?.min_value}-{ref?.max_value}
                                        </p>
                                      ))}
                                    </>
                                  ))}

                                </div>
                              </Card.Text>
                            ))}

                          </div>
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Close
              </Button>
              {/* <Button variant="success" onClick={handleClose2}>
                Add
              </Button> */}
            </Modal.Footer>
          </Modal>
          {/* details  modal end */}

          <Modal show={smsmodal} onHide={() => setSmsModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Sms Sent SuccessFully...!</h4>
              </Modal.Title>
            </Modal.Header>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default TestRecord;
