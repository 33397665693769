import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PatientSuceesfullModal from '../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';
import { postData } from '../../../../../utils/api';
import PathologyThirdAddModal from './PathologyThirdAddModal';

const PathologyFifthModal = ({ show, onHide, formData, setFormData, id,getTests }) => {
    const [modalThird, setModalThird] = useState(false);
    const [modalSuccessPatho, setModalSuccessPatho] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate form data if necessary
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            // formData.parameters = JSON.stringify(formData.parameters);
            const result = await postData(
                "/organisation/dashboard/apis/parameter-test/",
                formData
            );

            if (result?.success) {
                getTests()
                setFormData({
                    id: '',
                    test: '',
                    organisation_id: id,
                    parameters: [],
                    unit: '',
                    reference: '',
                });

                onHide();
                setModalSuccessPatho(true);
            } else {
                console.error('Failed to submit data:', result);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const validateForm = () => {
        const errors = {};
        // Add validation logic here if needed
        return errors;
    };

    useEffect(() => {
        let timer;
        if (modalSuccessPatho) {
            timer = setTimeout(() => {
                setModalSuccessPatho(false);
            }, 3000); // Modal will hide after 3 seconds
        }
        return () => clearTimeout(timer);
    }, [modalSuccessPatho]);

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='patient-modal'
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className='d-flex mb-3' onClick={() => setModalThird(true)}>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" className='modal-title mt-1' />
                        <span className='modal-title'>Add Parameters :</span>
                    </div> */}
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Reference Range</th>
                                <th>Unit</th>
                                <th>Default val.</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.parameters && formData.parameters.map((param, index) => (
                                <tr key={index}>
                                    <td>{param.name}</td>
                                    <td>{param.type}</td>
                                    <td>
                                        {param.reference.map((range, i) => (
                                            <div key={i}>
                                                {`${range.ageGroup}-${range.gender}: ${range.minValue} - ${range.maxValue}: ${range.value}`}
                                            </div>
                                        ))}
                                    </td>
                                    <td>{param.unit}</td>
                                    <td>{param.default}</td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon="fa-solid fa-trash"
                                            onClick={() => {
                                                const newParams = formData.parameters.filter((_, i) => i !== index);
                                                setFormData({ ...formData, parameters: newParams });
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSubmit} className='create-btn'>Submit</Button>
                </Modal.Footer>
            </Modal>

            <PatientSuceesfullModal
                textHeading="Pathology Test created successfully"
                show={modalSuccessPatho}
                onHide={() => setModalSuccessPatho(false)}
            />

        </>
    );
};

export default PathologyFifthModal;
