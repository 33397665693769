import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "./User.css";
import { useContext } from "react";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import Select from "react-select";
library.add(fas);

function User() {

  const { id, index } = useParams();
  const { getData, postData, editStatusData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);



  const [formData, setFormData] = useState({
    id: "",
    username: "",
    email: "",
    phone_number: "",
    password: "",
    is_superuser: false,
    is_hospital_admin: false,
    is_doctor: false,
    organisation: "",
    type: "",
  });

  console.log(formData)
  const [orgSelect, setOrgSelect] = useState(true);
  const handleChange = (e) => {
    if (e?.name) {
      setFormData({ ...formData, [e.name]: e });
    } else {
      console.log(e.target.name);
      if (e.target.name === "is_superuser") {
        setOrgSelect(false);
        setFormData({ ...formData, [e.target.name]: e.target.value, is_hospital_admin: false, is_doctor: false, organisation: "" });
      } else if (e.target.name === "is_hospital_admin") {
        setOrgSelect(true);
        setFormData({ ...formData, [e.target.name]: e.target.value, is_superuser: false, is_doctor: false, organisation: "" });
      } else if (e.target.name === "is_doctor") {
        setOrgSelect(true);
        setFormData({ ...formData, [e.target.name]: e.target.value, is_hospital_admin: false, is_superuser: false, organisation: "" });
      } else {

        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };



  const [superadmin, setSuperadmin] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [alluser, setAlluser] = useState([]);
  const [organisations, setOrganisations] = useState();
  const getAllData = async (page, data) => {
    {
      var response;
      if (page && data === 'is_superuser') {
        response = await getData(`users/dashboard/apis/get-users/?${page}`);
      } else {
        response = await getData(`users/dashboard/apis/get-users/?is_superuser=True`);
      }
      await setSuperadmin(response);
      console.log(response)
    }
    {
      var res2;
      if (page && data === 'is_hospital_admin') {
        res2 = await getData(`users/dashboard/apis/get-users/?${page}`);
      } else {
        res2 = await getData(`users/dashboard/apis/get-users/?is_hospital_admin=True`);
      }
      await setAdmin(res2);
    }
    {
      var res3;
      if (page && data === 'is_doctor') {
        res3 = await getData(`users/dashboard/apis/get-users/?${page}`);
      } else {
        res3 = await getData(`users/dashboard/apis/get-users/?is_doctor=True`);
      }
      await setDoctor(res3);
    }
    {
      var res3;
      if (page && data === 'get_user') {
        res3 = await getData(`users/dashboard/apis/get-users?${page}`);
      } else {
        res3 = await getData(`users/dashboard/apis/get-users`);
      }
      await setAlluser(res3);
    }
    {
      const res = await getData(`organisation/dashboard/apis/org-get/`);
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.title,
          name: "organisation",
        }));
        setOrganisations(result);
      }
    }
  };

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);


  const handleClose = async () => {
    await setFormData({
      id: "",
      username: "",
      email: "",
      phone_number: "",
      password: "",
      is_superuser: false,
      is_hospital_admin: false,
      is_doctor: false,
      organisation: "",
      type: "",
    });

    await setErrors();
    setShow(false);
    setUserEdit(false);
  }
  const handleShow = () => setShow(true);



  const handleClose2 = async () => {
    setShow2(false);
    await setFormData({
      id: "",
      username: "",
      email: "",
      phone_number: "",
      password: "",
      is_superuser: false,
      is_hospital_admin: false,
      is_doctor: false,
      organisation: "",
      type: "",
    });

    await setErrors();
    setUserEdit(false);
  }
  const handleShow2 = () => setShow2(true);


  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.username) {
      errors.username = "UserName is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Email is Invalid";
    }
    // if (!formData.password) {
    //   errors.password = "Password is required";
    // }
    if (!formData.phone_number) {
      errors.phone_number = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.phone_number)) {
      errors.phone_number = "Phone Number must be 10 digits";
    }

    if (formData.is_hospital_admin || formData.is_doctor) {
      if (!formData.organisation) {
        errors.organisation = "Organisation is required";
      }
    }
    // if (!formData.is_superuser) {
    //   errors.is_superuser = "Super User is required";
    // }
    // if (!formData.is_hospital_admin) {
    //   errors.is_hospital_admin = "Hospital Admin is required";
    // }
    // if (!formData.is_doctor) {
    //   errors.is_doctor = "Doctor is required";
    // }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`username `, formData?.username);
        finalData.append(`email`, formData.email);
        finalData.append(`phone_number`, formData.phone_number);
        if (formData.organisation?.value) {
          finalData.append(`organisation`, formData.organisation?.value);
        }
        finalData.append(`is_superuser`, formData.is_superuser);
        finalData.append(`is_hospital_admin`, formData.is_hospital_admin);
        finalData.append(`is_doctor`, formData.is_doctor);
        if (formData.is_superuser) {
          finalData.append(`type`, 'SUPERUSER');
        } if (formData.is_hospital_admin) {
          finalData.append(`type`, 'HOSPITAL_ADMIN');
        } if (formData.is_doctor) {
          finalData.append(`type`, 'DOCTOR');
        } if (!formData.is_superuser && !formData.is_hospital_admin && !formData.is_doctor) {
          finalData.append(`type`, 'OTHER');
        }
        const result = await postData(
          "users/dashboard/apis/post-users/",
          finalData
        );
        if (result?.success) {
          await setFormData({
            id: "",
            username: "",
            email: "",
            phone_number: "",
            password: "",
            is_superuser: false,
            is_hospital_admin: false,
            is_doctor: false,
            organisation: "",
            type: "",
          });

          await setErrors();
          handleClose();
          getUserEditData();
          getAllData();
        } else {
          console.log(result?.errors);
          let errors = {};
          if (result?.errors?.email) {
            errors.email = result?.errors?.email[0];
          } if (result?.errors?.phone_number) {
            errors.phone_number = result?.errors?.phone_number[0];
          }
          setErrors(errors);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`username `, formData.username);
        finalData.append(`email`, formData.email);
        finalData.append(`phone_number`, formData.phone_number);
        if (formData.organisation?.value) {
          finalData.append(`organisation`, formData.organisation?.value);
        }
        finalData.append(`is_superuser`, formData.is_superuser);
        finalData.append(`is_hospital_admin`, formData.is_hospital_admin);
        finalData.append(`is_doctor`, formData.is_doctor);
        if (formData.is_superuser) {
          finalData.append(`type`, 'SUPERUSER');
        } if (formData.is_hospital_admin) {
          finalData.append(`type`, 'HOSPITAL_ADMIN');
        } if (formData.is_doctor) {
          finalData.append(`type`, 'DOCTOR');
        } if (formData.is_superuser && formData.is_hospital_admin && formData.is_doctor) {
          finalData.append(`type`, 'OTHER');
        }
        const result = await editStatusData(
          `users/dashboard/apis/edit-users/${formData.id}/`,
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            username: "",
            email: "",
            phone_number: "",
            password: "",
            is_superuser: false,
            is_hospital_admin: false,
            is_doctor: false,
            organisation: "",
            type: "",
          });

          await setErrors();
          handleClose();
          getUserEditData();
          getAllData();
        } else {
          console.log(result?.errors);
          let errors = {};
          if (result?.errors?.email) {
            errors.email = result?.errors?.email[0];
          } if (result?.errors?.phone_number) {
            errors.phone_number = result?.errors?.phone_number[0];
          }
          setErrors(errors);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [user, setUser] = useState();
  const [userEdit, setUserEdit] = useState(false);
  const getUserEditData = async () => {
    try {

      const res = await editStatusData(`users/dashboard/apis/edit-users/${id}/`);

      console.log(res);

      setUser(res);

      if (res?.success) {

      }

    } catch (error) {
      // Handle errors here
      console.error("Error fetching user edit data:", error);
    }
  }



  const handleEdit = async (index, action) => {
    console.log(alluser?.results[index], 'lllllll');
    await setFormData({
      id: alluser?.results[index]?.id,
      username: alluser?.results[index]?.username,
      email: alluser?.results[index]?.email,
      phone_number: alluser?.results[index]?.phone_number,
      is_superuser: alluser?.results[index]?.is_superuser,
      is_hospital_admin: alluser?.results[index]?.is_hospital_admin,
      is_doctor: alluser?.results[index]?.is_doctor,

      organisation: { value: alluser?.results[index]?.organisation?.id, label: alluser?.results[index]?.organisation?.title, name: 'organisation' }
    });

    if (action === "edit") {
      handleShow();
      setUserEdit(true);
    } else {
      handleShow2();
    }
  }


  const handleDelete = async (id) => {
    const res = await deleteData(`users/dashboard/apis/edit-users/${id}`);

    await getUserEditData();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllData()
    getUserEditData()
  }, []);

  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* all text data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">All Super Admins</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search By username, phone:{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                {/* <Button
                  variant="success"
                  onClick={handleShow}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Super Admins
                </Button> */}
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">Username</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Phone</span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">
                        Organisation
                      </span>
                    </th> */}
                    <th>
                      <span className="text table-data-heading">
                        Created At
                      </span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">
                        Last Login
                      </span>
                    </th> */}
                    {/* <th>
                      <span className="text table-data-heading">Status</span>
                    </th> */}
                    {/* <th>
                      <span className="text table-data-heading">Action</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {superadmin?.results?.map((d, index) => (
                    <tr>
                      <td>
                        <p className="text text-size data-line ">{d?.username}</p>
                      </td>
                      <td>
                        <p className="text text-size data-line ">
                          {d?.phone_number}
                        </p>
                      </td>
                      {/* <td>
                        <p className="text text-size data-line"> </p>
                      </td> */}
                      <td>
                        <p className="text text-size data-line">
                          {d?.created_at}
                        </p>
                      </td>
                      {/* <td>
                        <p className="text text-size data-line">
                          {d.last_login}
                        </p>
                      </td> */}
                      {/* <td>
                        <span className="text text-size data-line">
                          <Button
                            variant="success"
                            className="btn-style bg-green "
                          >
                            Status
                          </Button>
                        </span>
                      </td> */}
                      <td>
                        {/* <div className="action">
                          <Button className="action-btn">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-eye"
                            />
                          </Button>
                          <Button className="action-btn ">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>
                          <Button className="action-btn" onClick={() => handleDelete(d?.id)}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button>
                        </div> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  {alluser?.previous && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getAllData(alluser?.previous.split("?")[1], 'is_superuser')}>
                        <span aria-hidden="true">‹</span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[...Array(alluser?.count && Math.ceil(alluser?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getAllData("is_superuser=True&page=" + (index + 1), 'is_superuser')}>
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                  {alluser?.next && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getAllData(alluser?.next.split("?")[1], 'is_superuser')}>
                        <span aria-hidden="true">›</span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </li>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* all text data table end */}

          {/* All Admins data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">All Hospital Admins</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search By username, phone:{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                {/* <Button
                  variant="success"
                  onClick={handleShow2}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Admin
                </Button> */}
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">Username</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Phone</span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">
                        Organisation
                      </span>
                    </th> */}
                    <th>
                      <span className="text table-data-heading">
                        Created At
                      </span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">
                        Last Login
                      </span>
                    </th> */}
                    {/* <th>
                      <span className="text table-data-heading">Status</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Action</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {admin?.results?.map((d, index) => (
                    <tr>
                      <td>
                        <p className="text text-size data-line ">{d?.username}</p>
                      </td>
                      <td>
                        <p className="text text-size data-line ">
                          {d?.phone_number}
                        </p>
                      </td>
                      {/* <td>
                        <p className="text text-size data-line"> </p>
                      </td> */}
                      <td>
                        <p className="text text-size data-line">
                          {d?.created_at}
                        </p>
                      </td>
                      {/* <td>
                        <p className="text text-size data-line">
                          {d.last_login}
                        </p>
                      </td> */}
                      {/* <td>
                        <span className="text text-size data-line">
                          <Button
                            variant="success"
                            className="btn-style bg-green "
                          >
                            Status
                          </Button>
                        </span>
                      </td> */}
                      {/* <td>
                        <div className="action">
                          <Button className="action-btn">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-eye"
                            />
                          </Button>
                          <Button className="action-btn ">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>
                          <Button className="action-btn" onClick={() => handleDelete(d?.id)}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  {alluser?.previous && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getAllData(alluser?.previous.split("?")[1], 'is_hospital_admin')}>
                        <span aria-hidden="true">‹</span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[...Array(alluser?.count && Math.ceil(alluser?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getAllData("is_hospital_admin=True&page=" + (index + 1), 'is_hospital_admin')}>
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                  {alluser?.next && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getAllData(alluser?.next.split("?")[1], 'is_hospital_admin')}>
                        <span aria-hidden="true">›</span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </li>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* All Admins data table end */}

          {/* All Doctors data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">All Doctors</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search By username, phone:{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                {/* <Button
                  variant="success"
                  onClick={handleShow3}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Doctors
                </Button> */}
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">Username</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Phone</span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">
                        Organisation
                      </span>
                    </th> */}
                    <th>
                      <span className="text table-data-heading">
                        Created At
                      </span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">
                        Last Login
                      </span>
                    </th> */}
                    {/* <th>
                      <span className="text table-data-heading">Status</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Action</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {doctor?.results?.map((d, index) => (
                    <tr>
                      <td>
                        <p className="text text-size data-line ">{d?.username}</p>
                      </td>
                      <td>
                        <p className="text text-size data-line ">
                          {d?.phone_number}
                        </p>
                      </td>
                      {/* <td>
                        <p className="text text-size data-line"> </p>
                      </td> */}
                      <td>
                        <p className="text text-size data-line">
                          {d?.created_at}
                        </p>
                      </td>
                      {/* <td>
                        <p className="text text-size data-line">
                          {d.last_login}
                        </p>
                      </td> */}
                      {/* <td>
                        <span className="text text-size data-line">
                          <Button
                            variant="success"
                            className="btn-style bg-green "
                          >
                            Status
                          </Button>
                        </span>
                      </td> */}
                      {/* <td>
                        <div className="action">
                          <Button className="action-btn">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-eye"
                            />
                          </Button>
                          <Button className="action-btn ">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>
                          <Button className="action-btn" onClick={() => handleDelete(d?.id)}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  {alluser?.previous && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getAllData(alluser?.previous.split("?")[1], 'is_doctor')}>
                        <span aria-hidden="true">‹</span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[...Array(alluser?.count && Math.ceil(alluser?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getAllData("is_doctor=True&page=" + (index + 1), 'is_doctor')}>
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                  {alluser?.next && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getAllData(alluser?.next.split("?")[1], 'is_doctor')}>
                        <span aria-hidden="true">›</span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </li>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* All Doctors  data table end */}

          {/* All Users data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">All Users</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search By username, phone:{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                <Button
                  variant="success"
                  onClick={handleShow}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Users
                </Button>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">Username</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Phone</span>
                    </th>

                    <th>
                      <span className="text table-data-heading">
                        Created At
                      </span>
                    </th>

                    {/* <th>
                      <span className="text table-data-heading">Status</span>
                    </th> */}
                    <th>
                      <span className="text table-data-heading">Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {alluser?.results?.map((d, index) => (
                    <tr>
                      <td>
                        <p className="text text-size data-line ">{d?.username}</p>
                      </td>
                      <td>
                        <p className="text text-size data-line ">
                          {d?.phone_number}
                        </p>
                      </td>

                      <td>
                        <p className="text text-size data-line">
                          {d?.created_at}
                        </p>
                      </td>

                      {/* <td>
                        <span className="text text-size data-line">
                          <Button
                            variant="success"
                            className="btn-style bg-green "
                          >
                            Status
                          </Button>
                        </span>
                      </td> */}
                      <td>
                        <div className="action">
                          {/* <Button className="action-btn" onClick={() => handleEdit(index, "view")}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-eye"
                            />
                          </Button> */}
                          {/* </Link> */}
                          <Button className="action-btn " onClick={() => handleEdit(index, "edit")}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>
                          {/* <Button className="action-btn" onClick={() => handleDelete(d?.id)}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  {alluser?.previous && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getAllData(alluser?.previous.split("?")[1], 'get_user')}>
                        <span aria-hidden="true">‹</span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[...Array(alluser?.count && Math.ceil(alluser?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getAllData("page=" + (index + 1), 'get_user')}>
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                  {alluser?.next && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getAllData(alluser?.next.split("?")[1], 'get_user')}>
                        <span aria-hidden="true">›</span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </li>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* all text data table end */}





          {/* Add Users  modal start */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">{userEdit ? 'Edit' : 'Add'}  User </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">User Name</Form.Label>
                  <Form.Control
                    name="username"
                    value={formData?.username}
                    className="text"
                    type="text"
                    placeholder="Enter User Name"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.username && (
                    <span style={errorStyle}>{errors?.username}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Phone Number</Form.Label>
                  <Form.Control
                    name="phone_number"
                    value={formData?.phone_number}
                    className="text"
                    type="text"
                    placeholder="Enter Phone Number"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                    minlength="10"
                    maxlength="10"
                  />
                  {errors?.phone_number && (
                    <span style={errorStyle}>{errors?.phone_number}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Email</Form.Label>
                  <Form.Control
                    name="email"
                    value={formData?.email}
                    className="text"
                    type="text"
                    placeholder="Enter Email"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.email && (
                    <span style={errorStyle}>{errors?.email}</span>
                  )}
                </Form.Group>

                {/* <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Password</Form.Label>
                  <Form.Control
                    name="password"
                    value={formData?.password}
                    className="text"
                    type="text"
                    placeholder="Enter Password"
                    autoFocus
                    onChange={handleChange}

                  />
                  {errors?.password && (
                    <span style={errorStyle}>{errors?.password}</span>
                  )}
                </Form.Group> */}
                {orgSelect && (
                  <Form.Group
                    className="mb-3 text"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="text">Organisation</Form.Label>
                    <Select
                      placeholder="Select Organisation"
                      className="select-line"
                      value={formData?.organisation}
                      onChange={handleChange}
                      options={organisations}
                    />
                    {errors?.organisation && (
                      <span style={errorStyle}>{errors?.organisation}</span>
                    )}
                  </Form.Group>
                )}

                <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                  <Form.Label className="text">Superuser</Form.Label>

                  {/* Radio buttons for true or false */}
                  <Form.Check
                    type="radio"
                    name="is_superuser"
                    id="superuserTrue"
                    label="True"
                    checked={formData?.is_superuser === true}
                    onChange={() => handleChange({ target: { name: 'is_superuser', value: true } })}
                  />

                  <Form.Check
                    type="radio"
                    name="is_superuser"
                    id="superuserFalse"
                    label="False"
                    checked={formData?.is_superuser === false}
                    onChange={() => handleChange({ target: { name: 'is_superuser', value: false } })}
                  />

                  {errors?.is_superuser && (
                    <span style={errorStyle}>{errors?.is_superuser}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                  <Form.Label className="text">Hospital Admin</Form.Label>

                  {/* Radio buttons for true or false */}
                  <Form.Check
                    type="radio"
                    name="is_hospital_admin"
                    id="hospitaladminTrue"
                    label="True"
                    checked={formData?.is_hospital_admin === true}
                    onChange={() => handleChange({ target: { name: 'is_hospital_admin', value: true } })}
                  />

                  <Form.Check
                    type="radio"
                    name="is_hospital_admin"
                    id="hospitaladminFalse"
                    label="False"
                    checked={formData?.is_hospital_admin === false}
                    onChange={() => handleChange({ target: { name: 'is_hospital_admin', value: false } })}
                  />

                  {errors?.is_hospital_admin && (
                    <span style={errorStyle}>{errors?.is_hospital_admin}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                  <Form.Label className="text">Doctor</Form.Label>

                  {/* Radio buttons for true or false */}
                  <Form.Check
                    type="radio"
                    name="is_doctor"
                    id="doctorTrue"
                    label="True"
                    checked={formData?.is_doctor === true}
                    onChange={() => handleChange({ target: { name: 'is_doctor', value: true } })}
                  />

                  <Form.Check
                    type="radio"
                    name="is_doctor"
                    id="doctorFalse"
                    label="False"
                    checked={formData?.is_doctor === false}
                    onChange={() => handleChange({ target: { name: 'is_doctor', value: false } })}
                  />

                  {errors?.is_superuser && (
                    <span style={errorStyle}>{errors?.is_superuser}</span>
                  )}
                </Form.Group>


              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              {userEdit ?
                (
                  <Button variant="success" onClick={handleEditSubmit}>
                    Edit
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleSubmit}>
                    Add
                  </Button>
                )}
            </Modal.Footer>
          </Modal>

          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">User Details</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="Details-card">
                <Card>
                  <Card.Body>
                    <div className="visitor-patient-card">
                      <div className="row me-0 ms-0">
                        <div className="col-md-12">
                          <div className="details-card">
                            <Card.Title>
                              <h4 className="text ">User Name</h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="details">
                                <div className="visitor-name ">
                                  <FontAwesomeIcon
                                    className="text me-2"
                                    icon="fa-solid fa-vial"
                                  />

                                  <p className="text">{formData?.username}</p>
                                </div>



                                <p className="text ">
                                  <b>Phone Number:- </b>{formData?.phone_number}
                                </p>



                                {/* <p className="text ">
                                  <b>Super Admin:- </b>{formData?.is_superuser}
                                </p>

                                <p className="text ">
                                  <b>Hospital Admin:- </b>{formData?.is_hospital_admin}
                                </p>

                                <p className="text ">
                                  <b>Doctor:- </b>{formData?.is_doctor}
                                </p> */}

                                {/* <p className="text ">
                                  <b>Date:- </b>12-01-2024{" "}
                                </p> */}
                              </div>
                            </Card.Text>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Close
              </Button>
              {/* <Button variant="success" onClick={handleClose2}>
                Add
              </Button> */}
            </Modal.Footer>
          </Modal>
          {/* Add Users  modal end */}
        </div>
      </div>
    </>
  );
}

export default User;
