import React, { useState } from 'react'
import { Button, Col, InputGroup, Nav, Row, Tab, Table } from 'react-bootstrap'
import PathologyModalfirst from './AllPathologyModal/PathologyModalfirst';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from "react-bootstrap/Form";
import RegisterPatientTabs from './RegisterPatientTabs/RegisterPatientTabs';
import PandingTabs from './PandingTabs/PandingTabs';
import PreparedDetails from './PreparedDetailsTab/PreparedDetails';
const PatienTRecord = () => {
    const [activeTab, setActiveTab] = useState('first');
    // const [search, setSearch] = useState();
    // const getTestRecords = async (page, searchIn) => {
    //     setSearch(searchIn);
    //     var res;
    //     if (page) {
    //         if (searchIn) {
    //             res = await getData(
    //                 `/organisation/dashboard/apis/parameter-test/?${page}&test_name=${searchIn}`
    //             );
    //         } else {
    //             res = await getData(
    //                 `/organisation/dashboard/apis/parameter-test/?${page}`
    //             );
    //         }
    //     } else {
    //         if (searchIn) {
    //             res = await getData(
    //                 `/organisation/dashboard/apis/parameter-test/?organisation_id=${id}&test_name=${searchIn}`
    //             );
    //         } else {
    //             res = await getData(
    //                 `/organisation/dashboard/apis/parameter-test/?organisation_id=${id}`
    //             );
    //         }
    //     }
    //     setTestList(res);
    // };
    const [patientname, setpatientname] = useState("");
    console.log(patientname, "patientname");
    return (
        <>
            <div className="main">
                <div className="container-fluid p-2">
                    <div className='row'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col lg={9}>
                                    <Nav variant="pills" className='patho-setting-tabs' activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" className='me-2'>Register-patient</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" className='me-2'>Pending</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third" className='me-2'>Prepared</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg={3}>
                                    {/* <div className='patho-setting-tabs'>
                                        {activeTab === 'second' && (
                                            <InputGroup className="mb-3 ">
                                                <Form.Control
                                                    placeholder="Search"
                                                    aria-label="Username"
                                                    value={patientname}
                                                    aria-describedby="basic-addon1"
                                                    onChange={(e) => setpatientname(e.target.value)}
                                                />
                                                <InputGroup.Text id="basic-addon1" >
                                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                        )}
                                    </div> */}
                                </Col>
                                <Col sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <RegisterPatientTabs />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <PandingTabs patientname={patientname}/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <PreparedDetails />
                                            {/* Your other tab content */}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PatienTRecord
