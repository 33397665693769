import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import Table from "react-bootstrap/Table";
import "./VisitorCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../../../utils/api";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
import Select from "react-select";
import { InputGroup } from "react-bootstrap";
import AllModalSec from "./AllModalSec/AllModalSec";
import EditModal from "./AllModalSec/EditModal";
library.add(fas);

function VisitorCard() {
  const [modalShow, setModalShow] = React.useState(false);

  const { id } = useParams();
  const { getData, postData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);

  console.log(id, 'kkkk')


  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = async () => {
    setShow(false);
    setFormData(initialFormData);
    // await setFormData({
    //   org_id: "",
    //   patient_id: "",
    //   bed_no: "",
    //   ward: "",
    //   patient_name: "",
    //   mobile_number: "",
    //   visitor_name: "",
    //   relation_with_patient: "",
    //   status_id: "",
    //   valid_till: "",

    // });
  }


  const handleShow = () => setShow(true);

  const handleClose2 = async () => {
    setShow2(false);
    setFormData(initialFormData);
    // await setFormData({
    //   org_id: id,
    //   patient_id: "",
    //   bed_no: "",
    //   ward: "",
    //   patient_name: "",
    //   mobile_number: "",
    //   visitor_name: "",
    //   relation_with_patient: "",
    //   status_id: "",
    //   valid_till: "",

    // });
  }
  const handleShow2 = () => setShow2(true);


  const [activeButton, setActiveButton] = useState(1);

  const handleStatus = (buttonIndex) => {
    setActiveButton(buttonIndex);
    // Add your custom logic here based on the button clicked
  };

  const initialFormData = {
    org_id: "",
    patient_id: "",
    bed_no: "",
    ward: "",
    patient_name: "",
    mobile_number: "",
    visitor_name: "",
    relation_with_patient: "",
    status_id: "",
    valid_till: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  // const [formData, setFormData] = useState({
  //   org_id: id,
  //   patient_id: "",
  //   bed_no: "",
  //   ward: "",
  //   patient_name: "",
  //   mobile_number: "",
  //   visitor_name: "",
  //   relation_with_patient: "",
  //   status_id: "",
  //   valid_till: "",

  // });

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);

    // Update formData with the selected date
    setFormData({
      ...formData,
      valid_till: date, // Assuming valid_till is a date field
    });
  };

  const handleChange = async (e) => {
    if (
      e?.name === "status_id"
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.patient_name) {
      errors.patient_name = "Patient Name Required";
    }
    if (!formData.patient_id) {
      errors.patient_id = "Patient ID Required";
    }
    if (!formData.visitor_name) {
      errors.visitor_name = "Visitor Name Required";
    }
    if (!formData.status_id) {
      errors.status_id = "Status Required";
    }
    if (!formData.mobile_number) {
      errors.mobile_number = "Mobile Number Required";
    } else if (formData.mobile_number.length < 10) {
      errors.mobile_number = "Invalid Mobile Number";
    }

    if (!formData.relation_with_patient) {
      errors.relation_with_patient = "Relation Required";
    }

    if (!formData.valid_till) {
      errors.valid_till = "Date is required";
    }
    else {
      const currentDate = new Date(); // Current date and time
      const enteredDate = new Date(formData?.valid_till);

      // Set hours, minutes, seconds, and milliseconds to 0 for both dates
      currentDate.setHours(0, 0, 0, 0);
      enteredDate.setHours(0, 0, 0, 0);

      if (enteredDate < currentDate) {
        errors.valid_till = "Date cannot be in the past";
      }
    }
    // if (!formData.date) {
    //   errors.date = "Date is Required";
    // }



    return errors;
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = validateForm();

  //   console.log(validationErrors);

  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //   } else {
  //     try {
  //       const finalData = new FormData();
  //       finalData.append(`org_id`, formData.org_id);
  //       finalData.append(`patient_id`, formData.patient_id);
  //       finalData.append(`bed_no`, formData.bed_no);
  //       finalData.append(`patient_name`, formData.patient_name);
  //       finalData.append(`mobile_number`, formData.mobile_number);
  //       finalData.append(`visitor_name`, formData.visitor_name);
  //       finalData.append(`relation_with_patient`, formData.relation_with_patient);
  //       finalData.append(`status_id`, formData.status_id?.value);
  //       finalData.append(`valid_till`, formData.valid_till);


  //       const result = await postData(
  //         "organisation/dashboard/apis/visitor-post/",
  //         finalData
  //       );

  //       if (result?.success) {
  //         getVisitors();
  //         await setFormData({
  //           org_id: id,
  //           patient_id: "",
  //           bed_no: "",
  //           patient_name: "",
  //           mobile_number: "",
  //           visitor_name: "",
  //           relation_with_patient: "",
  //           status_id: "",
  //           valid_till: "",

  //         });

  //         await setErrors();
  //         handleClose();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const finalData = new FormData();
      console.log(finalData, "asdas");
      finalData.append(`org_id`, id);
      finalData.append(`patient_id`, formData.patient_id);
      finalData.append(`bed_no`, formData.bed_no);
      finalData.append(`ward`, formData?.ward?.id);
      finalData.append(`patient_name`, formData.patient_name);
      finalData.append(`mobile_number`, formData.mobile_number);
      finalData.append(`visitor_name`, formData.visitor_name);
      finalData.append(`relation_with_patient`, formData.relation_with_patient);
      finalData.append(`status_id`, formData.status_id?.value);
      finalData.append(`valid_till`, formData?.valid_till);



      const result = await editPatchData(
        `organisation/dashboard/apis/visitor-edit/${formData?.id}/`,
        finalData
      );

      if (result?.success) {
        await setFormData({
          org_id: id,
          patient_id: "",
          bed_no: "",
          ward: "",
          patient_name: "",
          mobile_number: "",
          visitor_name: "",
          relation_with_patient: "",
          status_id: "",
          valid_till: "",

        });

        await setErrors();
        handleClose2();
        getVisitors();
      }
    } catch (error) {
      console.log(error);
    }
  };



  const [statusMaster, setStatusMaster] = useState();
  const getStatus = async () => {
    const res = await getData(`organisation/dashboard/apis/visitor-status/`);
    if (res?.success) {
      const result = res.data.map((value) => ({
        value: value?.id,
        label: value?.name,
        name: "status_id",
      }));
      await setStatusMaster(result);
      await setActiveButton(res?.data[0]?.id);

    }
  }

  const [visitors, setVisitor] = useState();
  const getVisitors = async (page) => {
    console.log(page,'page');
    var res;
    if (page) {
      res = await getData(`organisation/dashboard/apis/visitor-list/?status_id=${activeButton}&organisation_id=${id}&limit=20&offset=20&${page}`);
    } else {
      res = await getData(`organisation/dashboard/apis/visitor-list/?status_id=${activeButton}&organisation_id=${id}`);
    }

    await setVisitor(res);

  }

  const handleEdit = async (index, action) => {
    console.log(visitors?.results[index], "dfhgghfg");
    await setFormData({
      id: visitors?.results[index]?.id,
      org_id: id,
      patient_id: visitors?.results[index]?.patient_id,
      ward: visitors?.results[index]?.ward,
      bed_no: visitors?.results[index]?.bed_no,
      patient_name: visitors?.results[index]?.patient_name,
      mobile_number: visitors?.results[index]?.mobile_number,
      visitor_name: visitors?.results[index]?.visitor_name,
      relation_with_patient: visitors?.results[index]?.relation_with_patient,
      status_id: { value: visitors?.results[index]?.visitor_status?.id, label: visitors?.results[index]?.visitor_status?.name, name: "status_id" },
      valid_till: visitors?.results[index]?.valid_till,

    });

    handleShow2();

  }


  const handleDelete = async (id) => {
    const res = await deleteData(`organisation/dashboard/apis/visitor-edit/${id}/`);
    await getVisitors();
  }
  useEffect(() => {
    getVisitors();
  }, [activeButton]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getStatus();
  }, []);


  const [Edit, setEdit] = useState(false);
  const handleCloseEdit = async () => {
    setEdit(false);
    await setFormData({
      org_id: id,
      patient_id: "",
      bed_no: "",
      ward: "",
      patient_name: "",
      mobile_number: "",
      visitor_name: "",
      relation_with_patient: "",
      status_id: "",
      valid_till: "",

    });
  }


  const handleEditModal = () => setEdit(true);

  const handleEditData = async (index, action) => {
    console.log(visitors?.results[index]);
    const selectedVisitor = visitors?.results[index];
    await setFormData({
      org_id: id,
      id: selectedVisitor?.id,
      patient_id: selectedVisitor?.patient_id,
      bed_no: selectedVisitor?.bed_no,
      patient_name: selectedVisitor?.patient_name,
      ward: selectedVisitor?.ward,
      visitor_name: selectedVisitor?.visitor_name,
      mobile_number: selectedVisitor?.mobile_number,
      relation_with_patient: selectedVisitor?.relation_with_patient,
      valid_till: selectedVisitor?.valid_till,
      download: selectedVisitor?.download,
      status_id: selectedVisitor?.visitor_status?.id,

    });

    handleEditModal(true);

  };

  // Function to update visitor data
  const updateVisitorData = (index, updatedVisitor) => {
    const updatedVisitors = [...visitors];
    updatedVisitors[index] = updatedVisitor;
    setVisitor(updatedVisitors);
  };
  // const handleNewVisitor = (newVisitor) => {
  //   setVisitor((prev) => ({
  //     ...prev,
  //     results: [newVisitor, ...prev.results]
  //   }));
  // };
  const options = [
    { value: '', label: 'Icu' },
    { value: '1', label: 'One' },
    { value: '2', label: 'Two' },
    { value: '3', label: 'Three' },
  ];



  const [selectedWard, setSelectedWard] = useState();
  // const handleWardChange = (event) => {
  //   const selectedValue = event.target.value;
  //   setSelectedWard(selectedValue);
  //   console.log(selectedValue, "selectedValueselectedValueselectedValueselectedValueselectedValue");
  //   getTestRecords();
  // };
  // console.log(selectedWard, "selectedWard");

  const handleWardChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedWard(selectedValue);
  };

  useEffect(() => {
    if (selectedWard !== '') {
      getTestRecords();
    }
  }, [selectedWard]);

  const [search, setSearch] = useState('');
  const getTestRecords = async (page = '', searchIn = '') => {
    setSearch(searchIn);
    let res;
    if (page) {
      if (searchIn) {
        res = await getData(
          `organisation/dashboard/apis/visitor-list/?${page}&patient_name=${searchIn}`
        );
      } else {
        res = await getData(
          `organisation/dashboard/apis/visitor-list/?${page}`
        );
      }
    } else {
      if (searchIn) {
        res = await getData(
          `organisation/dashboard/apis/visitor-list/?status_id=${activeButton}&organisation_id=${id}&patient_name=${searchIn}`
        );
      } else {
        res = await getData(
          `organisation/dashboard/apis/visitor-list/?status_id=${activeButton}&organisation_id=${id}&ward=${selectedWard}`
        );
      }
    }
    setVisitor(res);

  };



  const [wardData, setWardData] = useState([]);
  const { SelectUserData } = useContext(Context);
  const getWardData = async () => {
    try {
      const response = await getData(`organisation/dashboard/apis/visitor-ward-list/?${id}`);
      const results = await SelectUserData(response?.results, 'name', false);
      setWardData(results);
    } catch (error) {


      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getVisitors();
    getWardData();
  }, [id]);

  // const findWardName = (wardId) => {
  //   const ward = wards.find(ward => ward.value === wardId);
  //   return ward ? ward.label : 'wardId';
  // };
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* doctor data table start */}

          <div className="row status-section">
            <div className="col-md-11">
              <div className="row">
                {statusMaster?.map((value, buttonIndex) => (
                  <div key={value?.value} className="col-md-3 col-sm-3 col-lg-2 me-xxl-0 me-xl-3 me-lg-5 me-md-5 ">
                    <button
                      variant="success"
                      onClick={() => handleStatus(value?.value)}
                      className={`search btn-style1 btn-size text mb-2 ${value?.value === activeButton ? '' : 'bg-green1'}`}
                    >
                      {value?.label}
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="col-md-1">
              <div className="text-end me-5">
                <FontAwesomeIcon icon="fa-solid fa-sliders" className="me-5" />
              </div>
            </div> */}
          </div>

          <section className="data-table">
            <div className="heading-holder">
              <div className="font-semibold text">
                {/* <Form.Select

                  aria-label="Ward select" onChange={handleWardChange}>
                  {wardData?.map((option, index) => (
                    <option key={index}
                      placeholder="Select Department"
                      value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select> */}
                <Form.Select aria-label="Ward select" onChange={handleWardChange} defaultValue="">
                  <option value="" disabled hidden>Select Department</option>
                  {wardData.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className=" row search-holder   flex items-center space-x-2">
                <div className="col-md-6">
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search"
                      aria-label="Search"
                      value={search}
                      aria-describedby="basic-addon1"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <InputGroup.Text id="basic-addon1" onClick={() => getTestRecords('', search)}>
                      <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="col-md-6">
                  <Button
                    variant="success"
                    onClick={handleShow}
                    className="search btn-style btn-size text bg-green"
                  >
                    Add Visitor
                  </Button>
                </div>

              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">
                        ID
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Bad No.
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Patient ID
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Patient Name
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Ward
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Visitor Name
                      </span>
                    </th>



                    <th>
                      <span className="text table-data-heading">
                        Visitor Phone No.
                      </span>
                    </th>

                    {/* <th>
                      <span className="text table-data-heading">
                        Patient Slot
                      </span>
                    </th> */}

                    <th>
                      <span className="text table-data-heading">Relation</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Valid Till
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Status</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Download
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {visitors?.results?.map((value, index) => (
                    <tr key={value?.index}>
                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.id}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.bed_no}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.patient_id}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.patient_name}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.ward?.name}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.visitor_name}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.mobile_number}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.relation_with_patient}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.valid_till}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <span>
                            {value?.visitor_status?.name}
                          </span>
                        </div>
                      </td>


                      <td>
                        <div class="user-name-holder">
                          <span>
                            <a href={imageUrl + value?.pdf} target="_blank">
                              <FontAwesomeIcon
                                className="text"
                                icon="fa-solid fa-download"
                                style={{ cursor: 'pointer' }}
                              // onClick={() => handlePdf(index)}
                              />
                            </a>
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className="action">
                          {/* <Link to="/visitorDetailsCard"> */}
                          {value?.visitor_status?.name === "Pending" && (
                            <Button className="action-btn" onClick={() => handleEdit(index, "edit")}>
                              <FontAwesomeIcon
                                className="text"
                                icon="fa-solid fa-eye"
                              />
                            </Button>
                          )}

                          {value?.visitor_status?.name === "Active" && (
                            <Button className="action-btn" onClick={() => handleEdit(index, "edit")}>
                              <FontAwesomeIcon
                                className="text"
                                icon="fa-solid fa-eye"
                              />
                            </Button>
                          )}

                          <Button className="action-btn " onClick={() => handleEditData(index, "edit")}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>

                          <Button className="action-btn" onClick={() => handleDelete(value?.id)}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul className="pagination">
                  {visitors?.previous && (
                    <li className="page-item">
                      <a className="page-link" role="button" tabIndex="0" onClick={() => getVisitors(visitors?.previous.split("?")[1])}>
                        <span aria-hidden="true">‹</span>
                        <span className="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[...Array(visitors?.count && Math.ceil(visitors?.count / 10)).keys()].map(index => (
                    <li key={index} className="page-item">
                      <a className="page-link" role="button" tabIndex="0" onClick={() => getVisitors(`page=${index + 1}`)}>
                        {index + 1}
                      </a>
                    </li>
                  ))}
                  {visitors?.next && (
                    <li className="page-item">
                      <a className="page-link" role="button" tabIndex="0" onClick={() => getVisitors(visitors?.next.split("?")[1])}>
                        <span aria-hidden="true">›</span>
                        <span className="visually-hidden">Next</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* doctor data table end */}

          {/* add Visitor  modal start */}
          {/* <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Add Visitor</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Patient Name</Form.Label>
                  <Form.Control
                    name="patient_name"
                    value={formData?.patient_name}
                    className="text"
                    type="text"
                    placeholder="Enter Patient name"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.patient_name && (
                    <span style={errorStyle}>{errors?.patient_name}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Visitor Name</Form.Label>
                  <Form.Control
                    name="visitor_name"
                    value={formData?.visitor_name}
                    className="text"
                    type="text"
                    placeholder="Enter Visitor name"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.visitor_name && (
                    <span style={errorStyle}>{errors?.visitor_name}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Patient ID</Form.Label>
                  <Form.Control
                    name="patient_id"
                    value={formData?.patient_id}
                    className="text"
                    type="text"
                    placeholder="Enter Patient ID"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.patient_id && (
                    <span style={errorStyle}>{errors?.patient_id}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Mobile Number</Form.Label>
                  <Form.Control
                    name="mobile_number"
                    value={formData?.mobile_number}
                    className="text"
                    type="text"
                    placeholder="Enter Mobile Number"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                    minlength="10"
                    maxlength="10"
                  />
                  {errors?.mobile_number && (
                    <span style={errorStyle}>{errors?.mobile_number}</span>
                  )}
                </Form.Group>

               

                <Form.Group className="mb-3 text">
                  <Form.Label className="text">Relation</Form.Label>
                  <Form.Control
                    name="relation_with_patient"
                    value={formData?.relation_with_patient}
                    className="text"
                    type="text"
                    placeholder="Enter Relation"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.relation_with_patient && (
                    <span style={errorStyle}>{errors?.relation_with_patient}</span>
                  )}
                </Form.Group>

            

                <Form.Group className="mb-3 text">
                  <Form.Label className="text">Status</Form.Label>
                  <Select
                    placeholder="Select Status"
                    className="select-line"
                    value={formData?.status_id}
                    onChange={handleChange}
                    options={statusMaster}
                  />
                  {errors?.status_id && (
                    <span style={errorStyle}>{errors?.status_id}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text">
                  <Form.Label className="text">Valid Till</Form.Label>
                  <DatePicker
                    className="date-input-field"
                    selected={selectedDate}
                    value={formData?.valid_till}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="yyyy-mm-dd"
                    shouldCloseOnSelect={false}
                  />

                  {errors?.valid_till && (
                    <span style={errorStyle}>{errors?.valid_till}</span>
                  )}
                </Form.Group>



              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" onClick={handleSubmit}>
                Add
              </Button>
            </Modal.Footer>
          </Modal> */}
          {/* add Visitor  modal end */}

          {/* details  modal start */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Visitors Details</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="Details-card">
                <Card>
                  <Card.Body className="no-scroll">
                    <div className="Details-card">
                      <div className="visitor-patient-card">
                        <div className="row me-0 ms-0">
                          <div className="col-md-6">
                            <div className="details-card border-right">
                              <Card.Title>
                                <h4 className="text ">Patient Details</h4>
                              </Card.Title>
                              <Card.Text>
                                <div className="details">
                                  <div className="visitor-name">
                                    <FontAwesomeIcon
                                      className="text me-2"
                                      icon="fa-solid fa-user"
                                    />
                                    <p className="text mb-0">
                                      {" "}
                                      {formData?.patient_name}
                                    </p>
                                  </div>

                                  <p className="text ">
                                    <b>{formData?.patient_id}</b>
                                  </p>
                                  {/* <p className="text ">5B22-A</p> */}
                                  <p className="text ">
                                    {" "}
                                    <b>{formData?.organisation}</b>
                                  </p>
                                </div>
                              </Card.Text>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="details-card">
                              <Card.Title>
                                <h4 className="text ">Visitor Details</h4>
                              </Card.Title>
                              <Card.Text>
                                <div className="details">
                                  <div className="visitor-name ">
                                    <FontAwesomeIcon
                                      className="text me-2 "
                                      icon="fa-solid fa-user"
                                    />
                                    <p className="text mb-0">
                                      {formData?.visitor_name}
                                    </p>
                                  </div>

                                  <p className="text">
                                    <b>{formData?.mobile_number}</b>
                                  </p>
                                  <p className="text ">{formData?.relation_with_patient}</p>
                                </div>
                              </Card.Text>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="status-div">
                        <div className="status-info">
                          <h6 className="visitor-status-text  mb-0 me-3">
                            Status:
                          </h6>
                          <Form.Group className="mb-3 text">
                            <Select
                              placeholder="Select OPD"
                              className="select-line"
                              value={formData?.status_id}
                              onChange={handleChange}
                              options={statusMaster}
                            />
                            {errors?.status_id && (
                              <span style={errorStyle}>{errors?.status_id}</span>
                            )}
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Close
              </Button>
              <Button variant="success" onClick={handleEditSubmit}>
                Edit
              </Button>
            </Modal.Footer>
          </Modal>
          {/* details  modal end */}


        </div>
      </div>
      <AllModalSec
        HedaingName="Add Patient Detail : (1/2)"
        show={show}
        getVisitors={getVisitors}
        onHide={handleClose}
        formData={formData}
        setFormData={setFormData}
      // handleNewVisitor={handleNewVisitor}
      />

      <EditModal
        HedaingName="EDIT Patient Detail : (1/2)"
        show={Edit}
        index={Edit.index}
        getVisitors={getVisitors}
        onHide={handleCloseEdit}
        formData={formData} // Pass the formData here
        updateVisitorData={updateVisitorData}
        setFormData={setFormData}
      />
    </>
  );
}

export default VisitorCard;
