import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form, Col, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { getData } from '../../../../../utils/api';
import PathologyFourthModal from './PathologyFourthModal';
import { useParams } from 'react-router-dom';
import { Context } from '../../../../../utils/context';

const PathologyThirdAddModal = ({ show, onHide, addParameter, setModalThird, formData, setFormData, getTests }) => {
    const { id } = useParams();
    const { SelectUserData } = useContext(Context);
    const [errors, setErrors] = useState({});
    
    const [parameterData, setParameterData] = useState({
        name: '',
        type: null,
        unit: '',
        default: '',
        reference: [],
        subtest: '' // Add subtest here
    });

    const [modalFourth, setModalFourth] = useState(false);
    const [parameterType, setParameterType] = useState([]);
    const [tests, setTest] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setParameterData({ ...parameterData, [name]: value });
    };

    const handleSelectChange = (selectedOption) => {
        setParameterData({ ...parameterData, type: selectedOption });
    };
    

    const handleAdd = async () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        await addParameter({
            ...parameterData,
            type: parameterData?.type?.value // Assuming type.value is the correct value
        });
        getTests()
        await setParameterData({
            name: '',
            type: null,
            unit: '',
            default: '',
            reference: [],
            subtest: ''
        });
        await onHide();
    };

    const getParameterData = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/parameters-type-api/?${id}`);
            const data = await SelectUserData(response?.data, 'type', false);
            setParameterType(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getParameterData();
    }, [id]);

    const addReferenceRange = (newRange) => {
        setParameterData((prevData) => ({
            ...prevData,
            reference: [...prevData.reference, newRange]
        }));
        setModalFourth(false);
        setModalThird(true); // Showing the third modal again
    };
    const validateForm = () => {
        const errors = {};
        if (!parameterData.name) {
            errors.name = "Parameter Name is required";
        }
        // if (!parameterData.reference.length > 0) {
        //     errors.reference = "reference is required";
        // }
        // if (!parameterData.type) {
        //     errors.type = "Parameter Type is required";
        // }
        return errors;
    };
    console.log(parameterData,"parameterData");
    const defaultValue = parameterData.type ? parameterType.find(option => option.value === parameterData.type) : parameterType[4];
    console.log(defaultValue,'defaaaaaaaa')
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='patient-modal'
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalSubtest">
                            <Form.Label column sm={3}>
                                Subtest
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    type="text"
                                    name="subtest"
                                    placeholder="Subtest"
                                    value={parameterData.subtest} // Use parameterData.subtest
                                    onChange={handleChange}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalName">
                            <Form.Label column sm={3}>
                                Parameter Name*
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    className="text"
                                    type="text"
                                    placeholder="Enter Parameter"
                                    name="name"
                                    value={parameterData.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
                            </Col>
                        </Form.Group>
                        
                        <Form>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalType">
                                <Form.Label column sm={3}>
                                    Parameter Type*
                                </Form.Label>
                                <Col sm={9}>
                                    <Select
                                        name="type"
                                        value={defaultValue}
                                        onChange={handleSelectChange}
                                        options={parameterType}
                                    />
                                    {errors.type && <span style={{ color: 'red' }}>{errors.type}</span>}
                                </Col>
                            </Form.Group>
                           
                        </Form>
                        <Row>
                            <Col lg={6}>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalUnit">
                                    <Form.Label column sm={3}>
                                        Unit:
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Unit"
                                            name="unit"
                                            value={parameterData.unit}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalDefault">
                                    <Form.Label column sm={5}>
                                        Default Value
                                    </Form.Label>
                                    <Col sm={7}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter value"
                                            name="default"
                                            value={parameterData.default}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <p className='reference-text' onClick={() => {
                            setModalFourth(true);
                            setModalThird(false); // Hiding the third modal
                        }}>Reference range: +</p>
                        {errors.reference && <span style={{ color: 'red' }}>{errors.reference}</span>}
                    </Form>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Age group</th>
                                <th>Gender</th>
                                <th>Range</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {parameterData.reference.map((range, index) => (
                                <tr key={index}>
                                    <td>{range.ageGroup}</td>
                                    <td>{range.gender}</td>
                                    <td>{range.type?.label === 'range' ? `${range.minValue} - ${range.maxValue}` : range.value}</td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon="fa-solid fa-trash"
                                            onClick={() => {
                                                const newReferenceRange = parameterData.reference.filter((_, i) => i !== index);
                                                setParameterData({ ...parameterData, reference: newReferenceRange });
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='create-btn' onClick={handleAdd}>Add</Button>
                </Modal.Footer>
            </Modal>

            <PathologyFourthModal
                show={modalFourth}
                onHide={() => {
                    setModalFourth(false);
                    setModalThird(true); // Showing the third modal again
                }}
                // addReferenceRange={(newRange) => setParameterData({ ...parameterData, reference: [...parameterData.reference, newRange] })}

                addReferenceRange={addReferenceRange} // Pass the addReferenceRange function
            />
        </>
    );
};

export default PathologyThirdAddModal;
