import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Context } from '../../../../../utils/context';
import './RegisterPatientTabs.css';
import 'react-calendar/dist/Calendar.css';
import PatientSuceesfullModal from '../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';

const RegisterPatientTabs = ({ initialTests = [] }) => {
    const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [time, setTime] = useState('');
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    
    const [allSource, setAllSource] = useState([]);
    const [patientType, setPatientType] = useState([]);
    const [sampleCollection, setSampleCollection] = useState([]);
    const [tests, setTests] = useState(initialTests);
    const [searchText, setSearchText] = useState('');
    const [filteredTests, setFilteredTests] = useState(initialTests);
   
  
    const [formData, setFormData] = useState({
        phone_number: "",
        patient_name: "",
        date: "",
        time: "",
        age: "",
        gender: "",
        lab_no: "",
        sdo: "",
        address: "",
        patient_type: "",
        source: "",
        sample_collected: "",
    });
    const { getData, SelectUserData, postData } = useContext(Context);
    const [patientNames, setPatientNames] = useState(['']);

    console.log(patientNames, "patientNamespatientNamespatientNames");

    const gender = [
        { value: 'M', label: 'M' },
        { value: 'F', label: 'F' },
        { value: 'Others', label: 'Others' }
    ];

    // useEffect(() => {
    //     const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //     setTime(currentTime);
    //     setFormData({ ...formData, date: startDate.toLocaleDateString(), time: currentTime });
    // }, []);
    useEffect(() => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        setTime(`${hours}:${minutes}`);
    }, []);

    // Fetch all sample collection data from the API
    const getSampleCollection = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/patientCollection/?${id}`);
            const data = await SelectUserData(response?.data, 'name', false)
            setSampleCollection(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getSampleCollection();
    }, [id]);

    // Fetch all Patient Type data from the API
    const getPatientType = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/patientType/?${id}`);
            const data = await SelectUserData(response?.data, 'name', false)

            setPatientType(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getPatientType();
    }, [id]);

    // Fetch all source data from the API
    const getSourceAll = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/patientStatus/?${id}`);
            const data = await SelectUserData(response?.data, 'name', false)
            setAllSource(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getSourceAll();
    }, [id]);

    // Fetch all test data from the API
    const getDataAll = async (testname) => {
        try {
            const response = await getData(`/organisation/dashboard/apis/parameter-test/?organisation_id=${id}&test_name=${testname || ''}`);
            if (response?.results) {
                const result = response.results.map((data) => ({
                    id: data.id,
                    name: data.name,
                    rate: data.discounted_price,
                    unit: 1,
                    isChecked: false
                }));
                setTests(result);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getDataAll();
    }, [id]);

    // const handleCheckboxChange = (id) => {
    //     setTests(tests.map(test =>
    //         test.id === id ? { ...test, isChecked: !test.isChecked } : test
    //     ));
    // };
    // const handleCheckboxChange = (id) => {
    //     const updatedTests = tests.map(test =>
    //         test.id === id ? { ...test, isChecked: !test.isChecked } : test
    //     );

    //     // Clear the general error if at least one test is checked
    //     if (updatedTests.some(test => test.isChecked)) {
    //         setErrors(prevErrors => {
    //             const newErrors = { ...prevErrors };
    //             delete newErrors.general;
    //             return newErrors;
    //         });
    //     }

    //     setTests(updatedTests);
    // };

    // const handleUnitChange = (id, unit) => {
    //     setTests(tests.map(test =>
    //         test.id === id ? { ...test, unit: unit } : test
    //     ));
    // };

    // const handleRemove = (id) => {
    //     setTests(tests.map(test =>
    //         test.id === id ? { ...test, isChecked: false } : test
    //     ));
    // };

    const handleAddPatientName = () => {
        setPatientNames([...patientNames, '']);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    

    useEffect(() => {
        setFilteredTests(
            tests.filter(test =>
                test.name.toLowerCase().includes(searchText.toLowerCase())
            )
        );
    }, [searchText, tests]);

    const handleInputChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleCheckboxChange = (id) => {
        const updatedTests = tests.map(test =>
            test.id === id ? { ...test, isChecked: !test.isChecked } : test
        );

        // Clear the general error if at least one test is checked
        if (updatedTests.some(test => test.isChecked)) {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors.general;
                return newErrors;
            });
        }

        setTests(updatedTests);
    };

    const handleUnitChange = (id, value) => {
        const updatedTests = tests.map(test =>
            test.id === id ? { ...test, unit: value } : test
        );
        setTests(updatedTests);
    };

    const handleRemove = (id) => {
        const updatedTests = tests.map(test =>
            test.id === id ? { ...test, isChecked: false } : test
        );
        setTests(updatedTests);
    };


    console.log(formData, "formdata lllllllll");
    const validateAtLeastOneChecked = () => {
        const isAtLeastOneChecked = tests.some(test => test.isChecked);
        if (!isAtLeastOneChecked) {
            setErrors(prevErrors => ({ ...prevErrors, general: 'At least one test must be checked' }));
            return false;
        } else {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors.general;
                return newErrors;
            });
            return true;
        }
    };
    const validateForm = () => {
        const errors = {};
        if (!formData.phone_number) {
            errors.phone_number = "phone_number is required";
        } else if (formData.phone_number.length < 10) {
            errors.phone_number = "Phone number must be at least 10 digits long";
        }
        if (!formData.patient_name) {
            errors.patient_name = "patient_name is required";
        }
        if (!formData.patient_type) {
            errors.patient_type = "date is required";
        }
        const isAtLeastOneChecked = tests.some(test => test.isChecked);
        if (!isAtLeastOneChecked) {
            errors.general = 'At least one test must be checked';
        }
        if (!formData.age) {
            errors.age = "phone_number is required";
        }
        if (!formData.gender) {
            errors.gender = "gender is required";
        }
        if (!formData.lab_no) {
            errors.lab_no = "lab_no is required";
        }
        // if (!formData.sdo) {
        //     errors.sdo = "sdo is required";
        // }

        // if (!formData.address) {
        //     errors.address = "address is required";
        // }
        if (!formData.patient_type) {
            errors.patient_type = "patient_type is required";
        }
        if (!formData.source) {
            errors.source = "source is required";
        }
        if (!formData.sample_collected) {
            errors.sample_collected = "sample_collected is required";
        }

        return errors;
    };

    

    const handleSelectChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            patient_type: selectedOption ? selectedOption.value : null,

        }));
    };

    const handleSelectChangeSource = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,

            source: selectedOption ? selectedOption.value : null,
        }));
    };
    const handleSelectChangegender = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,

            gender: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleSelectChangeSample = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,

            sample_collected: selectedOption ? selectedOption.value : null,
        }));
    };


    const filteredTestss = tests.filter(test => test.isChecked);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate form data if necessary
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            const finalFormData = {
                ...formData,
                // patient_name: patientNames,
                test: filteredTests,
                organisation: id,
            };

            console.log(finalFormData); // Debugging output

            const result = await postData(
                "/organisation/dashboard/apis/test-records-api/",
                finalFormData
            );

            if (result?.success) {

                setFormData({
                    phone_number: "",
                    patient_name: "",
                    date: "",
                    time: "",
                    age: "",
                    gender: "",
                    lab_no: "",
                    sdo: "",
                    address: "",
                    patient_type: "",
                    source: "",
                    sample_collected: "",
                });
                setPatientNames(['']);
                setTests(tests.map(test => ({ ...test, isChecked: false, unit: 1 })));
                setStartDate(null);
                setTime('');
                setModalSuccessPatient(true); 
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                console.log('Data submitted successfully!');

            } else {
                console.error('Failed to submit data:', result);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };
    useEffect(() => {
        let timer;
        if (modalSuccessPatient) {
            timer = setTimeout(() => {
                setModalSuccessPatient(false);
            }, 3000); // Modal will hide after 3 seconds
        }
        return () => clearTimeout(timer);
    }, [modalSuccessPatient]);
    const handleKeyPress = (event) => {
        const charCode = event.charCode;
        const char = String.fromCharCode(charCode);
        if (!/[a-zA-Z ]/.test(char)) {
            event.preventDefault();
        }
    };
    const handleKeyPressNumber = (event) => {
        const charCode = event.charCode;
        // Allow only numeric input
        if (!/[0-9]/.test(String.fromCharCode(charCode))) {
            event.preventDefault();
        }
    };
    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };

    // const handleInputChange = (e) => {
    //     const newPatientName = e.target.value;
    //     setTestname(newPatientName);
    //     getDataAll(newPatientName);
    // };
    console.log(tests,'tests tests');
    return (
        <>
            <section className='register-patient'>
                <div className='row'>
                    <Form onSubmit={handleSubmit}>
                        <div className='form-heading p-4'>
                            {/* <Form.Group as={Row} className="" controlId="formHorizontalEmail">
                                <Form.Label column xxl={2} xl={2} lg={2} md={3}>
                                    Enter Ph-no:
                                </Form.Label>
                                <Col xxl={4} xl={4} lg={5} md={5}>
                                    <Form.Control type="text" placeholder="" />
                                </Col>
                                <Col sm={3}>
                                    <Button type="search" className='search-btn'>Search</Button>
                                </Col>
                            </Form.Group> */}
                        </div>
                        <div className='bg-color'>
                            <div className='form-start'>
                                <div className='row'>
                                    <Col xxl={3} xl={4} lg={4} md={5}>
                                        <DatePicker
                                            name='date'
                                            selected={startDate}

                                            onChange={(date) => {
                                                setStartDate(date);
                                                setFormData({ ...formData, date: date.toLocaleDateString() });
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                        {/* {errors?.date && <span style={errorStyle}>{errors?.date}</span>} */}
                                    </Col>
                                    <Col xxl={3} xl={4} lg={4} md={4}>
                                        <Form.Control
                                            name='time'
                                            type="time"
                                            value={time}
                                            onChange={(e) => {
                                                setTime(e.target.value);
                                                setFormData({ ...formData, time: e.target.value });
                                            }}
                                            placeholder=""
                                        />
                                        {/* {errors?.time && <span style={errorStyle}>{errors?.time}</span>} */}
                                    </Col>
                                    <Col xxl={6} xl={4} lg={4} md={3}>
                                        {/* <div className='text-end'>
                                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                                    </div> */}
                                    </Col>
                                </div>
                            </div>
                            <div className='patient-details'>
                                <Row className='patient-row'>
                                    <p className=''>Patient Details:</p>
                                    <Col xxl={5} xl={5} lg={12}>
                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label column xxl={3} xl={3} lg={4} md={4}>
                                                Source:
                                            </Form.Label>
                                            <Col xxl={9} xl={9} lg={8} md={8}>
                                                <Select name='source'
                                                    value={formData?.source.name}
                                                    // value={allSource.find(option => option.value === formData.source?.name)}
                                                    options={allSource}
                                                    onChange={handleSelectChangeSource} />
                                                {errors?.source && <span style={errorStyle}>{errors?.source}</span>}
                                            </Col>

                                        </Form.Group>

                                        {/* {patientNames?.map((patientName, index) => ( */}
                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label column xxl={3} xl={3} lg={4} md={4}>
                                                Patient Name:
                                            </Form.Label>
                                            <Col xxl={9} xl={9} lg={8} md={8}>
                                                <Row>
                                                    <Col xxl={11} xl={11} lg={10} md={9}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Patient Name"
                                                            value={formData?.patient_name}
                                                            name="patient_name"
                                                            onKeyPress={handleKeyPress}
                                                            onChange={handleChange}
                                                        // onChange={(e) => {
                                                        //     const newPatientNames = [...patientNames];
                                                        //     newPatientNames[index] = e.target.value;
                                                        //     setPatientNames(newPatientNames);
                                                        // }}
                                                        />
                                                        {errors?.patient_name && <span style={errorStyle}>{errors?.patient_name}</span>}
                                                    </Col>
                                                    {/* {index === patientNames.length - 1 && (
                                                            <Col xxl={1} xl={1} lg={2} md={3}>
                                                                <FontAwesomeIcon
                                                                    icon="fa-solid fa-plus"
                                                                    onClick={handleAddPatientName}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            </Col>
                                                        )} */}
                                                </Row>
                                            </Col>
                                        </Form.Group>
                                        {/* ))} */}

                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label column xxl={3} xl={3} lg={4} md={4}>
                                                Age:
                                            </Form.Label>
                                            <Col xxl={9} xl={9} lg={8} md={8}>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Control type="text"
                                                            placeholder='Age'
                                                            name='age'
                                                            onKeyPress={handleKeyPressNumber}
                                                            value={formData?.age}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.age && <span style={errorStyle}>{errors?.age}</span>}
                                                    </Col>
                                                    <Col md={6}>
                                                        <Select
                                                            name="gender"
                                                            placeholder="Select Gender"
                                                            value={gender.find(option => option.value === formData.gender)}
                                                            onChange={handleSelectChangegender}
                                                            options={gender} />
                                                        {errors?.gender && <span style={errorStyle}>{errors?.gender}</span>}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label column xxl={3} xl={3} lg={4} md={4}>
                                                Lab no:
                                            </Form.Label>
                                            <Col xxl={9} xl={9} lg={8} md={8}>
                                                <Form.Control
                                                    type="text"
                                                    name="lab_no"
                                                    placeholder=""
                                                    value={formData?.lab_no}
                                                    onChange={handleChange}
                                                />
                                                {errors?.lab_no && <span style={errorStyle}>{errors?.lab_no}</span>}
                                            </Col>
                                        </Form.Group>
                                    </Col>

                                    <Col xxl={5} xl={5} lg={12}>
                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPatientType">
                                            <Form.Label column xxl={3} xl={3} lg={4} md={4}>
                                                Patient Type
                                            </Form.Label>
                                            <Col xxl={9} xl={9} lg={8} md={8}>
                                                <Select
                                                    name='Patient'
                                                    value={formData?.patient_type.name}
                                                    // value={patientType.find(option => option.value === formData.patient_type_id)}
                                                    options={patientType}
                                                    onChange={handleSelectChange}
                                                />
                                                {errors?.patient_type && <span style={errorStyle}>{errors?.patient_type}</span>}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label column xxl={3} xl={3} lg={4} md={4}>
                                                Ph.no.:
                                            </Form.Label>
                                            <Col xxl={9} xl={9} lg={8} md={8}>
                                                <Form.Control
                                                    name="phone_number"
                                                    value={formData.phone_number}
                                                    className="text"
                                                    type="text"
                                                    placeholder="Enter Phone Number"
                                                    autoFocus
                                                    onChange={handleChange}
                                                    onKeyDown={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            if (event.key !== "Backspace")
                                                                event.preventDefault();
                                                        }
                                                    }}
                                                    // minLength="10"
                                                    maxLength="10"
                                                />
                                                {errors?.phone_number && <span style={errorStyle}>{errors?.phone_number}</span>}
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label column xxl={3} xl={3} lg={4} md={4}>
                                                S/D/Wo:
                                            </Form.Label>
                                            <Col xxl={9} xl={9} lg={8} md={8}>
                                                <Form.Control type="text" name="sdo"
                                                    placeholder=""
                                                    value={formData?.sdo}
                                                    onChange={handleChange} />
                                                {errors?.sdo && <span style={errorStyle}>{errors?.sdo}</span>}
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label column xxl={3} xl={3} lg={4} md={4}>
                                                Address:
                                            </Form.Label>
                                            <Col xxl={9} xl={9} lg={8} md={8}>
                                                <Form.Control type="text" name="address"
                                                    placeholder="Enter Address"
                                                    value={formData?.address}
                                                    onChange={handleChange} />
                                                {errors?.address && <span style={errorStyle}>{errors?.address}</span>}
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label column xxl={3} xl={3} lg={4} md={4}>
                                                Sample collection:
                                            </Form.Label>
                                            <Col xxl={9} xl={9} lg={8} md={8}>
                                                <Select name='samplecollected'
                                                    value={formData?.sample_collected.name}
                                                    // value={sampleCollection.find(option => option.value === formData.sample_collected.name)}
                                                    options={sampleCollection}
                                                    onChange={handleSelectChangeSample} />
                                                {errors?.sample_collected && <span style={errorStyle}>{errors?.sample_collected}</span>}
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
            <Col xxl={7} xl={7} lg={12}>
                <div className='asign-test mt-3'>
                    <div className='text-name'>
                        <p>Assign test</p>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Username"
                            value={searchText}
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='unit-entry'>
                        {Array.isArray(filteredTests) && filteredTests.map(test => (
                            <InputGroup className="" key={test.id}>
                                <Form.Group className="mb-3 mt-2" id="formGridCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        value={test.name}
                                        label={test.name}
                                        checked={test.isChecked}
                                        onChange={() => handleCheckboxChange(test.id)}
                                    />
                                </Form.Group>
                                <InputGroup.Text id="basic-addon1">
                                    <Form.Control
                                        type="number"
                                        value={test.unit}
                                        onChange={(e) => handleUnitChange(test.id, e.target.value)}
                                    />
                                </InputGroup.Text>
                            </InputGroup>
                        ))}
                    </div>
                    {errors?.general && <span style={errorStyle}>{errors?.general}</span>}
                </div>
            </Col>
            <Col xxl={5} xl={5} lg={12}>
                <div className='asign-test mt-3'>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Test Name</th>
                                <th>Unit</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(tests) && tests.filter(test => test.isChecked).map(test => (
                                <tr key={test.id}>
                                    <td>{test.name}</td>
                                    <td>{test.unit}</td>
                                    <td>
                                        <Button type="button" className='btn btn-remove' onClick={() => handleRemove(test.id)}>Remove</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Col>
            <Col md={12}>
                <div className='register-form mb-3 mt-3 text-end'>
                    <button type="submit" className='btn btn-register'>Register</button>
                </div>
            </Col>
        </Row>
                        </div>
                    </Form>
                </div>
            </section>
            <PatientSuceesfullModal
                textHeading="Patient Details Submitted successfully"
                show={modalSuccessPatient}
                onHide={() => setModalSuccessPatient(false)}
            />
        </>
    );
};

export default RegisterPatientTabs;
