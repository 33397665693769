import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import "../Hospitals/Hospital.css";

import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";


import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../../../utils/context";

library.add(fas);

function Hospital() {

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { getData, postData, imageUrl, Select2Data, orgID,
    setOrgId,
    indexDetails,
    setIndexDetail,
    sidebarChange,
    editStatusData,
    editPatchData,
    deleteData,
    orgData,
    setOrgData,
    organisation,
    setSidebarChange } = useContext(Context);
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [visitorPreview, setVisiterPreview] = useState(false);
  const [technicianPreview, setTechnicianPreview] = useState(false);
  const [headPreview, setHeadPreview] = useState(false);
  const [iconPreview, setIconPreview] = useState(false);
  const [govtlogo, setGovtlogo] = useState(false);
  const [modalShow, setModalShow] = useState(false);


  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleClose = async () => {
    await setFormData({
      category_id: "",
      country: "",
      state: "",
      city: "",
      title: "",
      sub_title: "",
      image: undefined,
      icon: undefined,
      description: "",
      phone_number: "",
      address1: "",
      address2: "",
      x_cord: "",
      y_cord: "",
      pincode: "",
      org_type: { value: "HOSPITAL", label: "HOSPITAL", name: "org_type" },
      opd_available: "",
      hospital_category: ""
    });

    await setErrors();
    await setImagePreview(false);
    await setIconPreview(false);
    await setGovtlogo(false);
    setShow(false);
    setHospitalEdit(false)
  }
  const handleShow = () => setShow(true);



  const handleImageIconChange = async (e) => {
    const file = e.target.files[0];

    await setFormData({ ...formData, icon: file });

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setIconPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleVisitoreImageChange = async (e) => {
    const file = e.target.files[0];

    await setFormData({ ...formData, visitor_incharge: file });

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setVisiterPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };


  const handleImageTechnicianChange = async (e) => {
    const file = e.target.files[0];

    await setFormData({ ...formData, lab_technician: file });

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setTechnicianPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImageHeadChange = async (e) => {
    const file = e.target.files[0];

    await setFormData({ ...formData, lab_head: file });

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setHeadPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };



  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    await setFormData({ ...formData, image: file });
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };






  const [formData, setFormData] = useState({
    category_id: "",
    country: "",
    state: "",
    city: "",
    title: "",
    sub_title: "",
    image: undefined,
    icon: undefined,
    lab_technician: undefined,
    lab_head: undefined,
    visitor_incharge: undefined,
    description: "",
    phone_number: "",
    address1: "",
    address2: "",
    x_cord: "",
    y_cord: "",
    pincode: "",
    org_type: { value: "HOSPITAL", label: "HOSPITAL", name: "org_type" },
    hospital_category: "",
    uhid: "",
    ehr_id: "",
    lab_incharge: "",
    lab_name: "",

    rating: "",

  });
  const [countrys, setCountry] = useState();

  const [states, setState] = useState();
  const [cities, setCity] = useState();
  const [category, setCategory] = useState();

  const [opds, setOpd] = useState();
  const [organization, setOrganization] = useState([
    { value: "HOSPITAL", label: "HOSPITAL", name: "org_type" },
    // { value: "DIAGNOSIS", label: "DIAGNOSIS", name: "org_type" }
  ]);

  const [hospitalcategory, setHospitalcategory] = useState([
    { value: "PUBLIC", label: "PUBLIC", name: "hospital_category" },
    { value: "PRIVATE", label: "PRIVATE", name: "hospital_category" }
  ]);



  const getMaster = async () => {
    {
      const res = await getData("address/dashboard/apis/country-list-api");
      if (res?.success) {
        const data = await Select2Data(res.data, "country", false);
        setCountry(data);
      }
    }
    {
      const res = await getData("address/dashboard/apis/state-list-api");
      if (res?.success) {
        const data = await Select2Data(res.data, "state", false);
        setState(data);
      }
    }
    {
      const res = await getData("address/dashboard/apis/city-list-api");
      if (res?.success) {
        const data = await Select2Data(res.data, "city", false);
        setCity(data);
      }
    }

    {
      const res = await getData("organisation/dashboard/apis/category-list-api/");
      if (res?.success) {
        const data = await Select2Data(res.data, "category_id", false);
        setCategory(data);
      }
    }
    {
      const res = await getData("organisation/dashboard/apis/opd-list-without-api");
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "opd_available",
        }));
        setOpd(result);
      }
    }
  };

  const handleCurrentSelect2Change = async (selectedOption) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        opd_available: selectedOption.map((item) => item.value),
        opd_available_value: selectedOption,
      };
    });
  };


  const [selectedskills, setSelectedSkills] = useState([]);
  useEffect(() => {
    setFormData({
      ...formData,
      ["opd_available"]: selectedskills?.map((item) => item.value),
    });
  }, [selectedskills]);


  const handleChange = async (e) => {
    if (
      e?.name === "country" ||
      e?.name === "state" ||
      e?.name === "city" ||
      e?.name === "org_type" ||
      e?.name === "hospital_category" ||
      e?.name === "category_id"
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.image) {
      errors.image = "image is required";
    }
    if (!formData.icon) {
      errors.icon = "icon is required";
    }

    if (!formData.category_id) {
      errors.category_id = "Category is required";
    }
    if (!formData.title) {
      errors.title = "Title is required";
    }
    if (!formData.sub_title) {
      errors.sub_title = "Sub Title is required";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }
    if (!formData.phone_number) {
      errors.phone_number = "Phone number is required";
    } else if (formData.phone_number.length < 10) {
      errors.phone_number = "Invalid Phone number";
    }
    if (!formData.address1) {
      errors.address1 = "Address is required";
    }

    if (!formData.pincode) {
      errors.pincode = "Pincode is required";
    }
    // if (!formData.x_cord) {
    //   errors.x_cord = "X cordinate is required";
    // }
    // if (!formData.y_cord) {
    //   errors.y_cord = "Y cordinate is required";
    // }

    if (!formData.country) {
      errors.country = "Country is required";
    }

    if (!formData.state) {
      errors.state = "State is required";
    }

    if (!formData.city) {
      errors.city = "City is required";
    }

    if (!formData.org_type) {
      errors.org_type = "Organisation Type is required";
    }

    if (!formData.hospital_category) {
      errors.hospital_category = "Hospital Category is required";
    }

    if (!formData.uhid) {
      errors.uhid = "uhid is required";
    }

    if (!formData.ehr_id) {
      errors.ehr_id = "Ehr Id is required";
    }

    if (!formData.lab_name) {
      errors.lab_name = "Lab Name is required";
    }

    if (!formData.lab_incharge) {
      errors.lab_incharge = "Lab Incharge is required";
    }

    if (!formData.rating) {
      errors.rating = "rating is required";
    }

    return errors;
  };

  const validateEditForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    // if (!formData.image) {
    //   errors.image = "image is required";
    // }
    // if (!formData.icon) {
    //   errors.icon = "icon is required";
    // }

    if (!formData.category_id) {
      errors.category_id = "Category is required";
    }
    if (!formData.title) {
      errors.title = "Title is required";
    }
    if (!formData.sub_title) {
      errors.sub_title = "Sub Title is required";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }
    if (!formData.phone_number) {
      errors.phone_number = "Phone number is required";
    } else if (formData.phone_number.length < 10) {
      errors.phone_number = "Invalid Phone number";
    }
    if (!formData.address1) {
      errors.address1 = "Address is required";
    }

    if (!formData.pincode) {
      errors.pincode = "Pincode is required";
    }
    // if (!formData.x_cord) {
    //   errors.x_cord = "X cordinate is required";
    // }
    // if (!formData.y_cord) {
    //   errors.y_cord = "Y cordinate is required";
    // }

    if (!formData.country) {
      errors.country = "Country is required";
    }

    if (!formData.state) {
      errors.state = "State is required";
    }

    if (!formData.city) {
      errors.city = "City is required";
    }

    if (!formData.org_type) {
      errors.org_type = "Organisation Type is required";
    }

    if (!formData.hospital_category) {
      errors.hospital_category = "Hospital Category is required";
    }

    if (!formData.uhid) {
      errors.uhid = "uhid is required";
    }

    if (!formData.ehr_id) {
      errors.ehr_id = "Ehr Id is required";
    }

    if (!formData.lab_name) {
      errors.lab_name = "Lab Name is required";
    }

    if (!formData.lab_incharge) {
      errors.lab_incharge = "Lab Incharge is required";
    }

    if (!formData.rating) {
      errors.rating = "rating is required";
    }




    return errors;
  };

  const [hospitals, setHospital] = useState();
  const [hospitalEdit, setHospitalEdit] = useState(false);
  const getHospital = async (page) => {

    // let res;
    // if (page) {
    //   if (organisation) {
    //     res = await getData(
    //       `organisation/dashboard/apis/organisation-list-api/?${page}&org_id=${organisation}`
    //     );
    //   } else {
    //     res = await getData(
    //       `organisation/dashboard/apis/organisation-list-api/?${page}`
    //     );
    //   }
    // } else {
    //   if (organisation) {
    //     res = await getData(
    //       `organisation/dashboard/apis/organisation-list-api/`
    //     );
    //   } else {
    //     res = await getData(
    //       `organisation/dashboard/apis/organisation-list-api/`
    //     );
    //   }
    // }


    let res;
    if (page) {
      res = await getData(
        `organisation/dashboard/apis/organisation-list-api/?${page}`
      );
    } else {
      res = await getData(
        `organisation/dashboard/apis/organisation-list-api/`
      );
    }




    setHospital(res);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(formData);

    if (Object.keys(validationErrors).length > 0) {
      console.log(validationErrors);
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        if (formData.lab_head !== undefined) {
          finalData.append(`lab_head`, formData.lab_head);
        }
        if (formData.lab_technician !== undefined) {
          finalData.append(`lab_technician`, formData.lab_technician);
        }
        if (formData.visitor_incharge !== undefined) {
          finalData.append(`visitor_incharge`, formData.visitor_incharge);
        }
        finalData.append(`image`, formData.image);
        // finalData.append(`visitor_incharge`, formData.visitor_incharge);
        // finalData.append(`lab_technician`, formData.lab_technician);
        // finalData.append(`lab_head`, formData.lab_head);
        finalData.append(`icon`, formData.icon);
        finalData.append(`category_id`, formData.category_id?.value);
        finalData.append(`country`, formData.country?.value);
        finalData.append(`state`, formData.state?.value);
        finalData.append(`city`, formData.city?.value);
        finalData.append(`org_type`, formData.org_type?.value);
        finalData.append(`title`, formData.title);
        finalData.append(`sub_title`, formData.sub_title);
        finalData.append(`description`, formData.description);
        finalData.append(`phone_number`, formData.phone_number);
        finalData.append(`address1`, formData.address1);
        finalData.append(`address2`, formData.address2);
        finalData.append(`x_cord`, formData.x_cord);
        finalData.append(`y_cord`, formData.y_cord);
        finalData.append(`pincode`, formData.pincode);
        finalData.append(`hospital_category`, formData.hospital_category?.value);
        finalData.append(`uhid`, formData.uhid);
        finalData.append(`ehr_id`, formData.ehr_id);
        finalData.append(`lab_name`, formData.lab_name);
        finalData.append(`lab_incharge`, formData.lab_incharge);
        finalData.append(`rating`, formData.rating);


        const result = await postData(
          "organisation/dashboard/apis/organisation-create-api/",
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            country: "",
            state: "",
            city: "",
            title: "",
            sub_title: "",
            image: undefined,
            icon: undefined,
            lab_technician: undefined,
            lab_head: undefined,
            visitor_incharge: undefined,
            description: "",
            phone_number: "",
            address1: "",
            address2: "",
            x_cord: "",
            y_cord: "",
            pincode: "",
            org_type: { value: "HOSPITAL", label: "HOSPITAL", name: "org_type" },
            hospital_category: "",
            uhid: "",
            ehr_id: "",
            lab_incharge: "",
            lab_name: "",
            rating: "",

          });

          await setSelectedSkills([]);
          await setErrors();
          handleClose();
          getHospital();
          handleModalShow();
          setTimeout(() => {
            handleModalClose();
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateEditForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        if (formData.image !== undefined) {
          finalData.append(`image`, formData.image);
        }
        if (formData.icon !== undefined) {
          finalData.append(`icon`, formData.icon);
        }
        if (formData.lab_head !== undefined) {
          finalData.append(`lab_head`, formData.lab_head);
        }
        if (formData.lab_technician !== undefined) {
          finalData.append(`lab_technician`, formData.lab_technician);
        }
        if (formData.visitor_incharge !== undefined) {
          finalData.append(`visitor_incharge`, formData.visitor_incharge);
        }
        finalData.append(`category_id`, formData.category_id?.value);
        finalData.append(`country`, formData.country?.value);
        finalData.append(`state`, formData.state?.value);
        finalData.append(`city`, formData.city?.value);
        finalData.append(`org_type`, formData.org_type?.value);
        finalData.append(`title`, formData.title);
        finalData.append(`sub_title`, formData.sub_title);
        finalData.append(`description`, formData.description);
        finalData.append(`phone_number`, formData.phone_number);
        finalData.append(`address1`, formData.address1);
        finalData.append(`address2`, formData.address2);
        finalData.append(`x_cord`, formData.x_cord);
        finalData.append(`y_cord`, formData.y_cord);
        finalData.append(`pincode`, formData.pincode);
        finalData.append(`hospital_category`, formData.hospital_category?.value);
        finalData.append(`uhid`, formData.uhid);
        finalData.append(`ehr_id`, formData.ehr_id);
        finalData.append(`lab_name`, formData.lab_name);
        finalData.append(`lab_incharge`, formData.lab_incharge);

        finalData.append(`rating`, formData.rating);

        const result = await editPatchData(
          `organisation/dashboard/apis/organisation-edit-api/${formData.id}/`,
          finalData
        );

        // if (result?.success) {
        await setFormData({
          id: "",
          category_id: "",
          country: "",
          state: "",
          city: "",
          title: "",
          sub_title: "",
          image: undefined,
          icon: undefined,
          lab_technician: undefined,
          lab_head: undefined,
          visitor_incharge: undefined,
          description: "",
          phone_number: "",
          address1: "",
          address2: "",
          x_cord: "",
          y_cord: "",
          pincode: "",
          org_type: { value: "HOSPITAL", label: "HOSPITAL", name: "org_type" },
          hospital_category: "",
          uhid: "",
          ehr_id: "",
          lab_incharge: "",
          lab_name: "",

          rating: "",
        });

        await setErrors();
        handleClose();
        getMaster();
        getHospital();
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEdit = async (index, action) => {

    await setFormData({
      id: hospitals?.results[index]?.id,
      category_id: { value: hospitals?.results[index]?.category_id?.id, label: hospitals?.results[index]?.category_id?.title, name: "category_id" },
      country: { value: hospitals?.results[index]?.country?.id, label: hospitals?.results[index]?.country?.title, name: "country" },
      state: { value: hospitals?.results[index]?.state?.id, label: hospitals?.results[index]?.state?.title, name: "state" },
      city: { value: hospitals?.results[index]?.city?.id, label: hospitals?.results[index]?.city?.title, name: "city" },
      title: hospitals?.results[index]?.title,
      sub_title: hospitals?.results[index]?.sub_title,
      image: undefined,
      icon: undefined,
      description: hospitals?.results[index]?.description,
      phone_number: hospitals?.results[index]?.phone_number,
      address1: hospitals?.results[index]?.address1,
      address2: hospitals?.results[index]?.address2,
      x_cord: hospitals?.results[index]?.x_cord,
      y_cord: hospitals?.results[index]?.y_cord,
      pincode: hospitals?.results[index]?.pincode,
      org_type: { value: "HOSPITAL", label: "HOSPITAL", name: "org_type" },
      hospital_category: { value: hospitals?.results[index]?.hospital_category, label: hospitals?.results[index]?.hospital_category, name: "hospital_category" },
      uhid: hospitals?.results[index]?.uhid,
      ehr_id: hospitals?.results[index]?.ehr_id,
      lab_incharge: hospitals?.results[index]?.lab_incharge,
      lab_name: hospitals?.results[index]?.lab_name,

      rating: hospitals?.results[index]?.rating,
    });

    setImagePreview(imageUrl + hospitals?.results[index]?.image);
    setIconPreview(imageUrl + hospitals?.results[index]?.icon);
    setVisiterPreview(imageUrl + hospitals?.results[index]?.visitor_incharge);
    setTechnicianPreview(imageUrl + hospitals?.results[index]?.lab_technician);
    setHeadPreview(imageUrl + hospitals?.results[index]?.lab_head);

    if (action === "edit") {
      handleShow();
      setHospitalEdit(true)
    } else {

    }

  }


  const handleDetail = async (id, index) => {
    await setOrgId(id);
    await setIndexDetail(index);
    await setSidebarChange(true);
    await setOrgData(hospitals[index]);
    navigate(`/hospitalsDetails/${id}`);
  }

  const handleDelete = async (id) => {
    const res = await deleteData(`organisation/dashboard/apis/organisation-edit-api/${id}/`);
    await getHospital();
  }

  const generateMapUrl = () => {
    if (formData?.x_cord && formData?.y_cord) {
      return `https://www.google.com/maps?q=${formData?.x_cord},${formData?.y_cord}`;
    }
    return null;
  };
  useEffect(() => {
    getMaster();
    getHospital();
    console.log(Cookies.get("jwtToken"));
  }, []);
  return (
    <>
      <div className="main">
        <div className="container-fluid p-0">
          <section className="hospital">
            <div className="row me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="heading-holder">
                  <div className="heading">
                    <p className="text">Total Hospital: {hospitals?.count}</p>
                  </div>
                  <div className="search-holder  flex items-center space-x-2">
                    <label for="" className="search text">
                      {/* Search:{" "} */}
                    </label>
                    {/* <input type="text" className="search btn-style text" /> */}
                    <Button
                      variant="success"
                      onClick={handleShow}
                      className="search btn-style btn-size text bg-green"
                    >
                      Add Hospital
                    </Button>
                  </div>
                </div>

                {/* card section start */}
                <div className="hospitals-cards">
                  <div className="row me-0 ms-0">
                    {hospitals?.results?.map((value, index) => (
                      <div className="col-md-6 col-xl-4 ">
                        <div className="card-holder">
                          <Link >
                            <Card >
                              <div className="card-image-holder">
                                <div className="img-holder">
                                  <img
                                    className="card-img"
                                    src={
                                      imageUrl +
                                      value?.image
                                    }
                                    alt="hospital-img"
                                  />
                                </div>

                                <div className="rating d-flex justify-content-between item-center px-4">
                                  <div className="linking-logo">
                                    <div className="link-img">
                                      <img
                                        className="link-logo"
                                        src={
                                          imageUrl +
                                          value?.icon
                                        }
                                        alt="logo"
                                      />
                                    </div>
                                  </div>

                                  <div className="linking-logo">
                                    <div className="stars">
                                      {[...Array(value?.rating && (parseInt(value?.rating)))]?.map((index) => (
                                        <FontAwesomeIcon
                                          className="yellow"
                                          icon="fa-solid fa-star"
                                        />
                                      ))}
                                      {[...Array(value?.rating && (5 - parseInt(value?.rating)))]?.map((index) => (
                                        <FontAwesomeIcon
                                          className="grey"
                                          icon="fa-solid fa-star"
                                        />
                                      ))}
                                      {/* <FontAwesomeIcon
                                        className="yellow"
                                        icon="fa-solid fa-star"
                                      />
                                      <FontAwesomeIcon
                                        className="yellow"
                                        icon="fa-solid fa-star"
                                      />
                                      <FontAwesomeIcon
                                        className="yellow"
                                        icon="fa-solid fa-star"
                                      />
                                      <FontAwesomeIcon
                                        className="yellow"
                                        icon="fa-solid fa-star"
                                      />
                                      <FontAwesomeIcon
                                        className="yellow"
                                        icon="fa-solid fa-star"
                                      /> */}

                                    </div>
                                  </div>
                                </div>
                              </div>

                              <Card.Body onClick={() => handleDetail(value?.id, index)}>
                                <Card.Title>
                                  <h5 className="text">{value?.title}</h5>
                                </Card.Title>
                                <div className="sub-title text ">
                                  {" "}
                                  <p>{value?.org_type}</p>
                                </div>
                                <div className="number">
                                  <p class="d-flex justify-content-end ">
                                    <span>
                                      <FontAwesomeIcon
                                        className="text me-2"
                                        icon="fa-solid fa-phone"
                                      />
                                    </span>
                                    <span className="text">{value?.phone_number}</span>
                                  </p>
                                </div>

                                <Card.Text>
                                  <div className="details">
                                    <div class="">
                                      <h5 class="text">Description:</h5>
                                      <p class="mt-2 text description-text">
                                        {value?.description}
                                      </p>
                                    </div>

                                    <p class="mt-2 text ">
                                      <h5 class="text">Address 1:</h5>
                                      {value?.address1}
                                    </p>

                                    <p class="mt-2 text">
                                      <h5 class="text">Address 2:</h5>
                                      {value?.address2}
                                    </p>

                                    <p class="mt-2 text">
                                      <h5 class="text">State:</h5>

                                      {value?.state?.title}
                                    </p>

                                    <p class="mt-2 text">
                                      <h5 class="text">City:</h5>

                                      {value?.city?.title}
                                    </p>

                                    <p class="mt-2 text">
                                      <h5 class="text">Country:</h5>

                                      {value?.country?.title}
                                    </p>
                                  </div>
                                </Card.Text>
                              </Card.Body>
                              {/* <div className="btns d-flex justify-content-end">
                                <Button
                                  variant="danger"
                                  className="action-btn"
                                  onClick={() => handleDelete(value?.id)}
                                >
                                  Delete
                                </Button>
                                <Button
                                  variant="success"
                                  className="action-btn"
                                  onClick={() => handleEdit(index, "edit")}
                                >
                                  <h4 className="text">{hospitalEdit ? 'Edit' : 'Add'} organization </h4>
                                </Button>
                              </div> */}

                              <div className="btns d-flex justify-content-end">
                                <Button
                                  variant="danger"
                                  className=" btn-style mx-3"
                                  onClick={() => handleDelete(value?.id)}
                                >
                                  Delete
                                </Button>
                                <Button
                                  variant="info"
                                  className=" btn-style mx-3"
                                  onClick={() => handleEdit(index, 'edit')}
                                >
                                  Edit
                                </Button>
                                {/* <Button
                                  variant="success"
                                  className="btn-style bg-green mx-3"
                                >
                                  Status
                                </Button> */}
                              </div>
                            </Card>
                          </Link>
                        </div>
                      </div>
                    ))}


                    <div className="col-md-12">
                      <div class="Pagination-holder">
                        <ul class="pagination">
                          {hospitals?.previous && (
                            <li class="page-item">
                              <a class="page-link" role="button" tabindex="0" onClick={() => getHospital(hospitals?.previous.split("?")[1])}>
                                <span aria-hidden="true">‹</span>
                                <span class="visually-hidden">Previous</span>
                              </a>
                            </li>
                          )}
                          {[...Array(hospitals?.count && Math.ceil(hospitals?.count / 10))].map(function (_, index) {
                            return (
                              <li key={index} className="page-item">
                                <a className="page-link" role="button" tabIndex="0" onClick={() => getHospital(`page=` + (index + 1))}>
                                  {index + 1}
                                </a>
                              </li>
                            );
                          })}
                          {hospitals?.next && (
                            <li class="page-item">
                              <a class="page-link" role="button" tabindex="0" onClick={() => getHospital(hospitals?.next.split("?")[1])}>
                                <span aria-hidden="true">›</span>
                                <span class="visually-hidden">Next</span>
                              </a>
                            </li>
                          )
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* card section end */}
              </div>
            </div>
          </section>

          {/* add doctor  modal start */}
          <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">{hospitalEdit ? 'Edit' : 'Add'} Hospital </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* choose image start */}
                <div className="row ">

                  <Form.Group className="mb-3 text">
                    <Form.Label className="text">Category</Form.Label>
                    <Select
                      placeholder="Select Category"
                      value={formData?.category_id}
                      onChange={handleChange}
                      options={category}
                      isSearchable={true}
                      id="sector"
                    />
                    {errors?.category_id && (
                      <span style={errorStyle}>{errors?.category_id}</span>
                    )}
                  </Form.Group>

                  <div className="col-md-9">
                    <Form.Group
                      controlId="exampleForm.ControlInput2"
                      className="mb-3 text"
                    >
                      <Form.Label className="text">Image</Form.Label>
                      <Form.Control
                        type="file"
                        className="text"
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                      {errors?.image && (
                        <span style={errorStyle}>{errors?.image}</span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-md-3">
                    <div className="image-preview-holder">
                      <span className="text">preview</span>
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="Image Preview"
                          className="img-preview"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* choose image  end */}

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Name</Form.Label>
                  <Form.Control
                    name="title"
                    value={formData?.title}
                    className="text"
                    type="text"
                    placeholder="Enter Title"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.title && (
                    <span style={errorStyle}>{errors?.title}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Subtitle</Form.Label>
                  <Form.Control
                    name="sub_title"
                    value={formData?.sub_title}
                    className="text"
                    type="text"
                    placeholder="Enter Subtitle"

                    onChange={handleChange}
                  />
                  {errors?.sub_title && (
                    <span style={errorStyle}>{errors?.sub_title}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Description</Form.Label>
                  <Form.Control
                    name="description"
                    value={formData?.description}
                    className="text"
                    type="text"
                    placeholder="Enter Description"

                    onChange={handleChange}
                  />
                  {errors?.description && (
                    <span style={errorStyle}>{errors?.description}</span>
                  )}
                </Form.Group>



                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Phone Number</Form.Label>
                  <Form.Control
                    name="phone_number"
                    className="number"
                    type="text"
                    value={formData?.phone_number}
                    placeholder="Enter Phone Number"

                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                    minlength="10"
                    maxlength="10"
                  />
                  {errors?.phone_number && (
                    <span style={errorStyle}>{errors?.phone_number}</span>
                  )}
                </Form.Group>

                {/* choose image start */}
                <div className="row ">
                  <div className="col-md-9">
                    <Form.Group
                      controlId="exampleForm.ControlInput2"
                      className="mb-3 text"
                    >
                      <Form.Label className="text">Icon</Form.Label>
                      <Form.Control
                        type="file"
                        className="text"
                        onChange={handleImageIconChange}
                        accept="image/*"
                      />
                      {errors?.icon && (
                        <span style={errorStyle}>{errors?.icon}</span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-md-3">
                    <div className="image-preview-holder">
                      <span className="text">Preview</span>
                      {iconPreview && (
                        <img
                          src={iconPreview}
                          alt="Image Preview"
                          className="img-preview"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* choose image  end */}

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Address 1</Form.Label>
                  <Form.Control
                    name="address1"
                    value={formData?.address1}
                    className="text"
                    type="text"
                    placeholder="Enter Address 1"

                    onChange={handleChange}
                  />
                  {errors?.address1 && (
                    <span style={errorStyle}>{errors?.address1}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Address 2</Form.Label>
                  <Form.Control
                    name="address2"
                    value={formData?.address2}
                    className="text"
                    type="text"
                    placeholder="Enter Address 2"

                    onChange={handleChange}
                  />
                  {errors?.address2 && (
                    <span style={errorStyle}>{errors?.address2}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                  <Form.Label className="text">X Coordinate</Form.Label>
                  <Form.Control
                    name="x_cord"
                    value={formData?.x_cord}
                    className="text"
                    type="number"
                    placeholder="Enter X Coordinate"
                    onChange={handleChange}
                  />
                  {errors?.x_cord && <span style={errorStyle}>{errors?.x_cord}</span>}
                </Form.Group>

                <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput2">
                  <Form.Label className="text">Y Coordinate</Form.Label>
                  <Form.Control
                    name="y_cord"
                    value={formData?.y_cord}
                    className="text"
                    type="number"
                    placeholder="Enter Y Coordinate"
                    onChange={handleChange}
                  />
                  {errors?.y_cord && <span style={errorStyle}>{errors?.y_cord}</span>}
                </Form.Group>
                {generateMapUrl() && (
                  <a href={generateMapUrl()} target="_blank" rel="noopener noreferrer">
                    View on Map
                  </a>
                )}

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Pincode</Form.Label>
                  <Form.Control
                    name="pincode"
                    value={formData?.pincode}
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                    minlength="6"
                    maxlength="6"
                    className="text"
                    type="text"
                    placeholder="Enter Pincode"

                  />
                  {errors?.pincode && (
                    <span style={errorStyle}>{errors?.pincode}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text">
                  <Form.Label className="text">Country</Form.Label>
                  <Select
                    placeholder="Select Country"
                    value={formData?.country}
                    onChange={handleChange}
                    options={countrys}
                    isSearchable={true}
                    id="sector"
                  />
                  {errors?.country && (
                    <span style={errorStyle}>{errors?.country}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text">
                  <Form.Label className="text">State</Form.Label>
                  <Select
                    placeholder="Select State"
                    value={formData?.state}
                    onChange={handleChange}
                    options={states}
                    isSearchable={true}
                    id="sector"
                  />
                  {errors?.state && (
                    <span style={errorStyle}>{errors?.state}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text">
                  <Form.Label className="text">City</Form.Label>
                  <Select
                    placeholder="Select City"
                    value={formData?.city}
                    onChange={handleChange}
                    options={cities}
                    isSearchable={true}
                    id="sector"
                  />
                  {errors?.city && (
                    <span style={errorStyle}>{errors?.city}</span>
                  )}
                </Form.Group>


                <Form.Group className="mb-3 text">
                  <Form.Label className="text">Organization Type</Form.Label>
                  <Select
                    placeholder="Select Organization Type"
                    value={formData?.org_type}
                    onChange={handleChange}
                    options={organization}
                    isSearchable={true}
                    id="sector"
                  />
                  {errors?.org_type && (
                    <span style={errorStyle}>{errors?.org_type}</span>
                  )}
                </Form.Group>


                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Hospital Category</Form.Label>
                  <Select
                    placeholder="Select Hospital Category"
                    value={formData?.hospital_category}
                    onChange={handleChange}
                    options={hospitalcategory}
                    isSearchable={true}
                    id="hospital_category"
                  />
                  {errors?.hospital_category && (
                    <span style={errorStyle}>{errors?.hospital_category}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Uhid</Form.Label>
                  <Form.Control
                    name="uhid"
                    value={formData?.uhid}
                    className="text"
                    type="text"
                    placeholder="Enter uhid"

                    onChange={handleChange}
                  />
                  {errors?.uhid && (
                    <span style={errorStyle}>{errors?.uhid}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Ehr Id</Form.Label>
                  <Form.Control
                    name="ehr_id"
                    value={formData?.ehr_id}
                    className="text"
                    type="text"
                    placeholder="Enter EHR ID"

                    onChange={handleChange}
                  />
                  {errors?.ehr_id && (
                    <span style={errorStyle}>{errors?.ehr_id}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Lab Incharge</Form.Label>
                  <Form.Control
                    name="lab_incharge"
                    value={formData?.lab_incharge}
                    className="text"
                    type="text"
                    placeholder="Enter Lab Incharge"

                    onChange={handleChange}
                  />
                  {errors?.lab_incharge && (
                    <span style={errorStyle}>{errors?.lab_incharge}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Lab Name</Form.Label>
                  <Form.Control
                    name="lab_name"
                    value={formData?.lab_name}
                    className="text"
                    type="text"
                    placeholder="Enter Lab Name"

                    onChange={handleChange}
                  />
                  {errors?.lab_name && (
                    <span style={errorStyle}>{errors?.lab_name}</span>
                  )}
                </Form.Group>


                <div className="row ">
                  <div className="col-md-9">
                    <Form.Group
                      controlId="exampleForm.ControlInput2"
                      className="mb-3 text"
                    >
                      <Form.Label className="text">Visitor Incahnician</Form.Label>
                      <Form.Control
                        type="file"
                        className="text"
                        onChange={handleVisitoreImageChange}
                        accept="image/*"
                      />
                      {errors?.visitor_incharge && (
                        <span style={errorStyle}>{errors?.visitor_incharge}</span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-md-3">
                    <div className="image-preview-holder">
                      <span className="text">Preview</span>
                      {visitorPreview && (
                        <img
                          src={visitorPreview}
                          alt="Image Preview"
                          className="img-preview"
                        />
                      )}
                    </div>
                  </div>
                </div>


                <div className="row ">
                  <div className="col-md-9">
                    <Form.Group
                      controlId="exampleForm.ControlInput2"
                      className="mb-3 text"
                    >
                      <Form.Label className="text">Lab Technician</Form.Label>
                      <Form.Control
                        type="file"
                        className="text"
                        onChange={handleImageTechnicianChange}
                        accept="image/*"
                      />
                      {errors?.lab_technician && (
                        <span style={errorStyle}>{errors?.lab_technician}</span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-md-3">
                    <div className="image-preview-holder">
                      <span className="text">Preview</span>
                      {technicianPreview && (
                        <img
                          src={technicianPreview}
                          alt="Image Preview"
                          className="img-preview"
                        />
                      )}
                    </div>
                  </div>
                </div>


                <div className="row ">
                  <div className="col-md-9">
                    <Form.Group
                      controlId="exampleForm.ControlInput2"
                      className="mb-3 text"
                    >
                      <Form.Label className="text">Lab Head</Form.Label>
                      <Form.Control
                        type="file"
                        className="text"
                        onChange={handleImageHeadChange}
                        accept="image/*"
                      />
                      {errors?.lab_head && (
                        <span style={errorStyle}>{errors?.lab_head}</span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-md-3">
                    <div className="image-preview-holder">
                      <span className="text">Preview</span>
                      {headPreview && (
                        <img
                          src={headPreview}
                          alt="Image Preview"
                          className="img-preview"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row ">



                </div>

                <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                  <Form.Label className="text">Rating</Form.Label>
                  <div>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Form.Check
                        inline
                        key={star}
                        type="radio"
                        id={`star${star}`}
                        label={`${star} Star`}
                        name="rating"
                        value={star}
                        checked={formData?.rating === String(star)}
                        onChange={handleChange}
                        className="star-input" // Add a class to the Form.Check component
                      />
                    ))}
                  </div>
                  {errors?.rating && <span style={errorStyle}>{errors?.rating}</span>}
                </Form.Group>




              </Form >
            </Modal.Body >
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              {hospitalEdit ?
                (
                  <Button variant="success" onClick={handleEditSubmit}>
                    Edit
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleSubmit}>
                    Add
                  </Button>
                )}
            </Modal.Footer>
          </Modal >
          {/* add doctor  modal end */}

          <Modal show={modalShow} onHide={handleModalClose} >
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text"></h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Hospital Added Successfully...!</h4>
            </Modal.Body >
            <Modal.Footer>

            </Modal.Footer>
          </Modal >
        </div >
      </div >
    </>
  );
}

export default Hospital;
