import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "./AllModalSec.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PatientModalEdit from './PatientModalEdit';
import { editPatchData } from '../../../../../utils/api';
import Select from 'react-select';
import { Context } from '../../../../../utils/context';
const EditModal = ({ updateVisitorData, onHide, show, index, updatedVisitorData, formData, HedaingName, getVisitors, setFormData }) => {
    const { id } = useParams();
    const [modalEdit, setModalEdit] = useState(false);
    const [wardData, setWardData] = useState([]);
    const { getData, SelectUserData } = useContext(Context);
   
    const [errors, setErrors] = useState({});
    useEffect(() => {
        const fetchWardData = async () => {
            try {
                const response = await getData(`/organisation/dashboard/apis/visitor-ward-list/?${id}`);
                const results = await SelectUserData(response?.results, 'name', false);
                setWardData(results,"bhjhjgjhgj");
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchWardData();
    }, [id, getData, SelectUserData]);

    
 
    const [selectedWard , setSelectedWard] = useState(null)
    const [selectedWardsssss , setSelectedWardsssss] = useState(null)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (selectedOption) => {
        setFormData({ ...formData, ward: selectedOption });
        setSelectedWard(selectedOption);
      
    };
    const validateForm = () => {
        let errors = {};
        if (!formData.patient_name) {
            errors.patient_name = "Patient Name Required";
        }
        if (!formData.patient_id) {
            errors.patient_id = "Patient ID Required";
        }
        // if (!formData.ward) {
        //     errors.ward = "Ward is Required";
        // }
        return errors;
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                
                console.log(formData,"formDataformDataformDataformDataformDataformData/");
                if(formData.ward.value){
                    formData.ward = formData.ward.value;
                }else if (formData.ward.id) {
                    
                    formData.ward = formData.ward.id;
                }else{
                    formData.ward = formData.ward;
                
                }
              
                const result = await editPatchData(`organisation/dashboard/apis/visitor-edit/${formData?.id}/`,formData);
              
                if (result?.success) {
                    await setModalEdit(true);
                    await updateVisitorData(index, updatedVisitorData);
                    await onHide();
                }else{
                    console.log("dfddsfsdfsdfsd");
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };

    useEffect(() => {
        setSelectedWardsssss(formData?.ward)
    },[formData])

   

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='patient-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {HedaingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditSubmit}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                                Patient Name
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="patient_name"
                                    value={formData.patient_name}
                                    type="text"
                                    placeholder="Enter Patient name"
                                    autoFocus
                                    onChange={handleChange}
                                />
                                {errors.patient_name && (
                                    <span style={errorStyle}>{errors.patient_name}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Patient ID
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="patient_id"
                                    value={formData.patient_id}
                                    type="text"
                                    placeholder="Enter Patient ID"
                                    onChange={handleChange}
                                />
                                {errors.patient_id && (
                                    <span style={errorStyle}>{errors.patient_id}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Ward
                            </Form.Label>
                            <Col sm={9}>
                                <Select
                                    name="ward"                            
                                    onChange={handleSelectChange}
                                    options={wardData}
                                    value={wardData?.find(option => option.value === formData.ward?.id)}
                                    placeholder="Select Ward"
                                />
                                {errors.ward && (
                                    <span style={errorStyle}>{errors.ward}</span>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Bed No.
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="bed_no"
                                    value={formData.bed_no}
                                    type="text"
                                    placeholder="Bed no."
                                    onChange={handleChange}
                                />


                            </Col>
                        </Form.Group>
                        <Button type="submit" className='create-btn' >Next</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <PatientModalEdit
                HedaingName="Edit Visitor Detail : (2/2)"
                show={modalEdit}
                onHide={() => setModalEdit(false)}
                onHides={onHide}
                getVisitors={getVisitors}
                formData={formData}
                setFormData={setFormData}
                setErrors={setErrors}
                errors={errors}
                updateVisitorData={updateVisitorData}
            />
        </>
    )
}

export default EditModal;
