import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";


import Login from "../Login/LogIn";
import Hospital from "../dashboard/Hospital/Hospitals/Hospital";
import HospitalsDetails from "../dashboard/Hospital/HospitalsDetails/HospitalsDetails";
import Diagnostic from "../dashboard/Diagnostic/Diagnostics/Diagnostic";
import DiagnosticDetails from "../dashboard/Diagnostic/DiagnosticDetails/DiagnosticDetails";
import User from "../dashboard/User/User";
import Categories from "../dashboard/Categories/Categories";
import Dashboard from "../dashboard/Dashboard/Dashboard";
import DiagnosticSlot from "../dashboard/Diagnostic/DiagnosticSlot/DiagnosticSlot";
import HospitalSlot from "../dashboard/Hospital/HospitalSlot/HospitalSlot";
import VisitorCard from "../dashboard/VisitorDetails/VisitorCard/VisitorCard";
import OPDCard from "../dashboard/OPD/OPDCard/OPDCard";
import OPDOrg from "../dashboard/OPD/OPDOrg/OPDOrg";
import Doctor from "../dashboard/Doctor/Doctor";
import PathologyCard from "../dashboard/Pathology/PathologyCard/PathologyCard";
import Generalfare from "../dashboard/Generalfare/Generalfare";

import Middleware from "../../Middleware";
import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../utils/context.js";

const All_Routes = () => {
    const { signin, usertype, token } = useContext(Context);

    return (
        <>
            <Routes>
                <Route path="/" element={<Login />} />

                {signin && (
                    <>

                        <Route path="/dashboard" element={<Middleware component={Dashboard} />} />


                        <Route path="/hospital" element={<Hospital />} />
                        <Route
                            path="/hospitalsDetails/:id"
                            element={<HospitalsDetails />}
                        />
                        <Route path="/hospitalSlot/:id/:docId" element={<HospitalSlot />} />
                        <Route path="/diagnostic" element={<Diagnostic />} />
                        <Route
                            path="/diagnosticDetails/:id"
                            element={<DiagnosticDetails />}
                        />
                        <Route
                            path="/diagnosticSlot/:id/:testId"
                            element={<DiagnosticSlot />}
                        />
                        <Route path="/user" element={<User />} />
                        <Route path="/categories" element={<Categories />} />
                        <Route path="/visitorCard/:id" element={<VisitorCard />} />
                        <Route path="/opdOrg/:id" element={<OPDOrg />} />
                        <Route path="/opdCard" element={<OPDCard />} />
                        <Route path="/doctorCard/:id" element={<Doctor />} />
                        <Route path="/pathologyCard/:id" element={<PathologyCard />} />
                        <Route path="/generalfare/:id" element={<Generalfare />} />
                    </>
                )}
            </Routes>
        </>
    );
};

export default All_Routes;
