import React from "react";
import { useEffect } from "react";
 import "../Header/Header.css"
 import {  useLocation } from "react-router-dom";
 import { useContext } from "react";
 import { Context } from "../../../utils/context";
 import { useParams } from "react-router-dom";

function Header() {
  const location = useLocation()
  const isLoginRoute = location.pathname === "/login" || "/";

  const currentRoute = location.pathname.split('/')[1];
  const id = location.pathname.split('/')[2];
  const { 
    getData,
    orgData,
    setOrgData,organisation} = useContext(Context);
console.log(id);
      
    const getHospital = async () => {
        
        const res =await getData(`organisation/dashboard/apis/single-org/?organisation_id=${id}`);
        if(res?.success){
          setOrgData(res?.data);
        }
    }

useEffect(() => {
    getHospital();
  }, [id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className="main">
        <div className="container-fluid p-0">
          <section className="header">
            <div className="row me-0 ms-0">
              <div className="col-md-12">
                {/* header start */}
                <div className="heading-holder">
                {currentRoute === 'diagnosticDetails' || 
                currentRoute === 'hospitalSlot' || 
                currentRoute === 'hospitalsDetails' || 
                currentRoute === 'doctorCard' || 
                currentRoute === 'visitorCard' || 
                currentRoute === 'generalfare' || 
                currentRoute === 'opdOrg' || 
                currentRoute === 'pathologyCard' ? (
                  <h3 className="text">{orgData?.title}</h3>
                ):(
                  <h3 className="text">Dashboard</h3>
                )}
                </div>
                {/* header end */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Header;
