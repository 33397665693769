import React, { useContext, useEffect, useState } from 'react'
import { Row, Table, Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SampleCollectionModal from '../AllPathologyModal/SampleCollectionModal/SampleCollectionModal'
import AssignTestResultModal from '../AllPathologyModal/AssignTestResultModal/AssignTestResultModal'
import PendingPreviewModal from '../AllPathologyModal/PendingPreviewModal/PendingPreviewModal'
import EditPendingModal from '../AllPathologyModal/SampleCollectionModal/EditPendingModal'
import { Context } from '../../../../../utils/context'
import { useParams } from 'react-router-dom'
import { Col, InputGroup, Nav, Tab } from 'react-bootstrap'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Form from "react-bootstrap/Form";

const PreparedDetails = () => {
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const { getData, imageUrl ,deleteData} = useContext(Context); // Assuming you have a context for API operations
    const [patientData, setPatientData] = useState(null); // State to store patient data
    const [selectedPatient, setSelectedPatient] = useState(null); // State to store selected patient data
    const [patientname, setpatientname] = useState("");
    const [selectedPage, setSelectedPage] = useState(null);
    const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
    const getpatientData = async (page, patientname = '') => {
        try {
            // Initialize the base URL with the required organisation_id
            let apiUrl = `organisation/dashboard/apis/get-prepared/?organisation_id=${id}`;
    
            // Append the page parameter if it's a valid positive integer
            if (page && Number.isInteger(page) && page > 0) {
                apiUrl += `&page=${page}`;
            }
    
            // Append the patient_name parameter
            apiUrl += `&patient_name=${patientname}`;
    
            // Fetch data from the constructed URL
            const res = await getData(apiUrl);
    
            // Update the state with the response data
            setPatientData(res?.results);
            setSelectedPage(res);
        } catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };
    

    const toggleClickdelete = async (patient) => {
        setSelectedPatient(patient);
        console.log(patient, 'patient result');
    
        try {
            // Call your delete API here
            const result = await deleteData(`organisation/dashboard/apis/patient/${patient.id}/`);
           
            if (result?.success) {
                setModalSuccessPatient(true);
                getpatientData();
                
            }
        } catch (error) {
            console.error('Error deleting patient record:', error);
            alert('An error occurred while deleting patient record');
        }
    
        // setSelectedPatientTests(patient?.patient_tests); // Assuming `tests` is the array of test data for the patient
       
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        getpatientData();
    }, [id]);

    const toggleClick = (patient) => {
        setSelectedPatient(patient);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedPatient(null);
    };
    const handleDownload = async (patient) => {
        try {
            const pdfUrl = patient?.pdf; // Replace with the actual field name in your patient object
            if (pdfUrl) {
                const response = await fetch(imageUrl + "media/" + pdfUrl);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Patient_Report_${patient.patient_name}.pdf`); // Adjust filename as needed
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const handleInputChanges = (e) => {
        const newPatientName = e.target.value;
        setpatientname(newPatientName);
        getpatientData(" ", newPatientName);
    };
    console.log(patientData, "patientData patientData");
    return (
        <>
            <Col lg={3} className='ms-auto'>
                <div className='patho-setting-tabs'>
                    <InputGroup className="mb-3 ">
                        <Form.Control
                            placeholder="Search"
                            aria-label="Username"
                            value={patientname}
                            aria-describedby="basic-addon1"
                            onChange={(e) => handleInputChanges(e)}
                        />
                        <InputGroup.Text id="basic-addon1" >
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                        </InputGroup.Text>
                    </InputGroup>
                </div>
            </Col>
            <section className='panding-tabs'>
                <Row>
                    <div className='patient-table'>
                        <Table responsive >
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>ID</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Patient</th>
                                    <th>Lab No.</th>
                                    <th>Source</th>
                                    {/* <th>Test</th> */}
                                    <th>Sample</th>
                                    <th>Result</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {patientData?.map((patient, index) => (
                                    <tr key={patient.id}>
                                        <td>{index + 1}</td>
                                        <td>{patient.id}</td>
                                        <td>{patient.date}</td>
                                        <td>{patient.time}</td>
                                        <td>
                                            {patient.patient_name}
                                            <FontAwesomeIcon icon="fa-solid fa-eye" className='font-color ms-1' onClick={() => toggleClick(patient)} />
                                        </td>
                                        <td>{patient.lab_no}</td>
                                        <td>{patient?.source?.name}</td>
                                        <td>
                                                <td>
                                                    {patient?.patient_sample_name?.review_status ? (
                                                        <FontAwesomeIcon
                                                            icon="fa-solid fa-check"
                                                            className='font-color ms-1'
                                                        // onClick={() => toggleClicksample(patient)}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon="fa-solid fa-circle-plus"
                                                            className='font-color ms-1'
                                                        // onClick={() => toggleClicksample(patient)}
                                                        />
                                                    )}
                                                </td>

                                              
                                            </td>
                                            <td>
                                                {patient?.test_records ? (
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-check"
                                                        className='font-color ms-1'
                                                    // onClick={() => toggleClickResult(patient)}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-circle-plus"
                                                        className='font-color ms-1'

                                                    />
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    className='btn btn-pending'

                                                    disabled={patient?.patient_sample_name?.review_status && patient?.test_records && patient?.status}
                                                >
                                                    {patient?.patient_sample_name?.review_status && patient?.test_records && patient?.status ? (
                                                        <>
                                                            <FontAwesomeIcon icon="fa-solid fa-circle" className='circle-color me-1' /> Final
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon="fa-solid fa-circle" className='font-color ms-1' /> Pending
                                                        </>
                                                    )}
                                                </button>
                                            </td>
                                        
                                        <td>
                                        <td>
                                        <FontAwesomeIcon
                                            className="text me-2"
                                            icon="fa-solid fa-download"
                                            onClick={() => handleDownload(patient)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <FontAwesomeIcon
                                                className="text"
                                                icon={faTrash}
                                                onClick={() => toggleClickdelete(patient)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                    </td>
                                          
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* <tbody>
                                {patientData
                                    ?.filter(patient => patient.status) // Filter records with status true
                                    .map((patient, index) => (
                                        <tr key={patient.id}>
                                            <td>{index + 1}</td>
                                            <td>{patient.id}</td>
                                            <td>{patient.date}</td>
                                            <td>{patient.time}</td>
                                            <td>
                                                {patient.patient_name}
                                                <FontAwesomeIcon icon="fa-solid fa-eye" className='font-color ms-1' onClick={() => toggleClick(patient)} />
                                            </td>
                                            <td>{patient.lab_no}</td>
                                            <td>{patient?.source?.name}</td>
                                            <td>
                                                <td>
                                                    {patient?.patient_sample_name?.review_status ? (
                                                        <FontAwesomeIcon
                                                            icon="fa-solid fa-check"
                                                            className='font-color ms-1'
                                                        // onClick={() => toggleClicksample(patient)}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon="fa-solid fa-circle-plus"
                                                            className='font-color ms-1'
                                                        // onClick={() => toggleClicksample(patient)}
                                                        />
                                                    )}
                                                </td>

                                              
                                            </td>
                                            <td>
                                                {patient?.test_records ? (
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-check"
                                                        className='font-color ms-1'
                                                    // onClick={() => toggleClickResult(patient)}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-circle-plus"
                                                        className='font-color ms-1'

                                                    />
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    className='btn btn-pending'

                                                    disabled={patient?.patient_sample_name?.review_status && patient?.test_records && patient?.status}
                                                >
                                                    {patient?.patient_sample_name?.review_status && patient?.test_records && patient?.status ? (
                                                        <>
                                                            <FontAwesomeIcon icon="fa-solid fa-circle" className='circle-color me-1' /> Final
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon="fa-solid fa-circle" className='font-color ms-1' /> Pending
                                                        </>
                                                    )}
                                                </button>
                                            </td>
                                            <td>
                                                <FontAwesomeIcon
                                                    className="text me-2"
                                                    icon="fa-solid fa-download"
                                                    onClick={() => handleDownload(patient)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody> */}
                        </Table>

                        {/* Pagination start */}
                        <div className="row me-0 ms-0">
                            <div class="col-md-12">
                                <div class="Pagination-holder">
                                    <ul class="pagination">
                                        {selectedPage?.previous && (
                                            <li class="page-item">
                                                <a class="page-link" role="button" tabIndex="0" onClick={() => getpatientData(selectedPage?.previous.split("?")[1])}>
                                                    <span aria-hidden="true">‹</span>
                                                    <span class="visually-hidden">Previous</span>
                                                </a>
                                            </li>
                                        )}
                                        {[...Array(selectedPage?.count && Math.ceil(selectedPage?.count / 10))].map(function (_, index) {
                                            return (
                                                <li key={index} className="page-item">
                                                    <a className="page-link" role="button" tabIndex="0" onClick={() => getpatientData("page=" + (index + 1))}>
                                                        {index + 1}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                        {selectedPage?.next && (
                                            <li class="page-item">
                                                <a class="page-link" role="button" tabIndex="0" onClick={() => getpatientData(selectedPage?.next.split("?")[1])}>
                                                    <span aria-hidden="true">›</span>
                                                    <span class="visually-hidden">Next</span>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Pagination end */}
                    </div>
                </Row>
            </section>


            <Modal show={show} onHide={handleClose} centered className='patients-details'>
                <Modal.Header closeButton>
                    <Modal.Title >Patient Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedPatient && (
                        <div className="row box-details">
                            <div className='col-6'>
                                <p>Name:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.patient_name}</p>
                            </div>
                            <div className='col-6'>
                                <p>Number:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.phone_number}</p>
                            </div>
                            <div className='col-6'>
                                <p>Age:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.age_group}</p>
                            </div>
                            <div className='col-6'>
                                <p>Gender:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.gender}</p>
                            </div>
                            <div className='col-6'>
                                <p>Address:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.address}</p>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PreparedDetails
