import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PathologyModalSecond from './PathologyModalSecond';
import { useParams } from 'react-router-dom';
import { Context } from "../../../../../utils/context";
import Select from 'react-select';

const PathologyModalFirst = (props) => {
    const { id } = useParams();
    const { HedaingName } = props;
    const [modalSecond, setModalSecond] = useState(false);
    const { getData, SelectUserData } = useContext(Context);
    const [departmentData, setDepartmentData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [errors, setErrors] = useState({});
    const [conductionData, setConductionData] = useState([]);
    const initialFormData = {
        id: "",
        test: "",
        organisation_id: id,
        parameters: "",
        test_code: "",
        department: "",
        category: "",
        conduction: "",
        subtest: ""
    };
    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const selectedValue = selectedOption ? selectedOption.value : ''; // Default to empty string if selectedOption is null
        setFormData({ ...formData, [actionMeta.name]: selectedValue });
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.test_code) {
            errors.test_code = "Test code is required";
        }
        if (!formData.test) {
            errors.test = "Test name is required";
        }
        if (!formData.department) {
            errors.department = "Department is required";
        }
      
        if (!formData.category) {
            errors.category = "category is required";
        }
        return errors;
    };

    const getDepartmentData = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/test-dept/?${id}`);
            const data = await SelectUserData(response?.data, 'type', false);
            console.log("Department Data:", data); // Debugging output
            setDepartmentData(data);
        } catch (error) {
            console.error("Error fetching department data:", error);
        }
    };

    const getCategoryData = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/test-category/?${id}`);
            const data = await SelectUserData(response?.data, 'type', false);
            console.log("Category Data:", data); // Debugging output
            setCategoryData(data);
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    const getConductionData = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/test-conduction/?${id}`);
            const data = await SelectUserData(response?.data, 'type', false);
            console.log("Conduction Data:", data); // Debugging output
            setConductionData(data);
        } catch (error) {
            console.error("Error fetching conduction data:", error);
        }
    };

    useEffect(() => {
        getDepartmentData();
        getCategoryData();
        getConductionData();
    }, [id]);

    const handleNext = () => {
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            setModalSecond(true);
            props.onHide();
        } else {
            setErrors(errors);
        }
    };

    const handleClose = () => {
        setFormData(initialFormData);
        setErrors({});
        props.onHide();
    };

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='patient-modal'
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {HedaingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                                Test code*:
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    type="text"
                                    placeholder="B109"
                                    name="test_code"
                                    value={formData.test_code}
                                    onChange={handleChange}
                                />
                                {errors.test_code && <span style={{ color: 'red' }}>{errors.test_code}</span>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Test name*
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    type="text"
                                    name="test"
                                    placeholder="Enter Test Name"
                                    value={formData.test}
                                    onChange={handleChange}
                                />
                                {errors.test && <span style={{ color: 'red' }}>{errors.test}</span>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Department
                            </Form.Label>
                            <Col sm={9}>
                                <Select
                                    name="department"
                                    value={formData?.value}
                                    onChange={handleSelectChange}
                                    options={departmentData}
                                />
                                {errors.department && <span style={{ color: 'red' }}>{errors.department}</span>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Category
                            </Form.Label>
                            <Col sm={9}>
                                <Select
                                    name="category"
                                    value={formData?.value}
                                    onChange={handleSelectChange}
                                    options={categoryData}
                                />
                                {errors.category && <span style={{ color: 'red' }}>{errors.category}</span>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Conduction
                            </Form.Label>
                            <Col sm={9}>
                                <Select
                                    name="conduction"
                                    value={formData?.value}
                                    onChange={handleSelectChange}
                                    options={conductionData}
                                />

                            </Col>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleNext} className='create-btn'>Next <FontAwesomeIcon icon="fa-solid fa-arrow-right" /></Button>
                </Modal.Footer>
            </Modal>

            <PathologyModalSecond
                HedaingName="Add Parameters : "
                show={modalSecond}
                onHide={() => setModalSecond(false)}

                formData={formData}
                setFormData={setFormData}
                getTests={props.getTests}
            />
        </>
    );
}

export default PathologyModalFirst;
