import React from 'react'
import "./AllModalSec.css"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
library.add(fas);
const PatientSuceesfullModal = (props) => {
    const { textHeading ,icon} = props;
    const iconToRender = icon ? icon : faCheck;
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='patient-modal-sucessfull'
            >
            
                <Modal.Body>
                    <div className=" d-flex text-holder mx-auto">
                        <p> {textHeading}</p>
                        <div className='ms-auto'>
                            {/* <FontAwesomeIcon icon="fa-solid fa-check"  className='check-icon'/> */}
                            <FontAwesomeIcon icon={iconToRender} className='check-icon' />
                        </div>
                    </div>
                </Modal.Body>

            </Modal>


        </>
    )
}

export default PatientSuceesfullModal
