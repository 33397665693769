import { useEffect } from "react";
import ClockNew from '../../Clock'
import "./Dashboard.css"
const Dashboard = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className='Dashboard'>
        <div className='container-fluid'>
          <div className=" dashboardfront_txt">
            <h1 className="welcome_txt text-center">
              Welcome
            </h1>
            <ClockNew />
            <h1 className="welcome_txt text-center">

              9718577938
            </h1>
          </div>
        </div>
      </section>
    </>
  )
}

export default Dashboard