import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './SampleCollectionModal.css'
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { useParams } from 'react-router-dom';
import { Context } from '../../../../../../utils/context';
import PatientSuceesfullModal from '../../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';
const EditPendingModal = (props) => {
    const { HedaingName, SubHeading, SecondText, patient } = props;
    const [time, setTime] = useState('');
    useEffect(() => {
        const patientTests = props.patient?.patient_tests || [];
        setFormData(patient)
        setTime(formatTime(patient?.time))
    }, [patient]);
    console.log(patient?.time, "patient time");
    console.log(time, "time time");
    const [tests, setTests] = useState([]);
    const [allSource, setAllSource] = useState([]);
    const [patientType, setPatientType] = useState([]);
    const [errors, setErrors] = useState({});
    const [sampleCollection, setSampleCollection] = useState([]);
    const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const [formData, setFormData] = useState({
        phone_number: "",
        patient_name: "",
        date: "",
        time: "",
        age: "",
        gender: "",
        lab_no: "",
        sdo: "",
        address: "",
        patient_type: "",
        source: "",
        sample_collected: "",
        sample_collected_by: "",
    });
    const { getData, SelectUserData, postData } = useContext(Context);
    const [patientNames, setPatientNames] = useState(['']);

    const gender = [
        { value: 'M', label: 'M' },
        { value: 'F', label: 'F' }
    ];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSelectChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            patient_type: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleSelectChangeSource = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            source: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleSelectChangegender = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            gender: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleSelectChangeSample = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            sample_collected: selectedOption ? selectedOption.value : null,
        }));
    };

    const getSampleCollection = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/patientCollection/`);
            const data = await SelectUserData(response?.data, 'name', false);
            setSampleCollection(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getSampleCollection();
    }, []);

    const getPatientType = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/patientType/`);
            const data = await SelectUserData(response?.data, 'name', false);
            setPatientType(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getPatientType();
    }, []);

    const getSourceAll = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/patientStatus/`);
            const data = await SelectUserData(response?.data, 'name', false);
            setAllSource(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getSourceAll();
    }, []);


    const validateForm = () => {
        const errors = {};
        if (!formData.phone_number) {
            errors.phone_number = "phone_number is required";
        }
        if (!formData.patient_name) {
            errors.patient_name = "patient_name is required";
        }
        // if (!formData.date) {
        //     errors.date = "date is required";
        // }
        // if (!formData.time) {
        //     errors.time = "time is required";
        // }
        if (!formData.age_group) {
            errors.age_group = "age is required";
        }
        if (!formData.gender) {
            errors.gender = "gender is required";
        }
        if (!formData.lab_no) {
            errors.lab_no = "lab_no is required";
        }
        if (!formData.sdo) {
            errors.sdo = "sdo is required";
        }

        if (!formData.address) {
            errors.address = "address is required";
        }
        // if (!formData.patient_type) {
        //     errors.patient_type = "patient_type is required";
        // }
        if (!formData.source) {
            errors.source = "source is required";
        }
        if (!formData.sample_collected) {
            errors.sample_collected = "sample_collected is required";
        }

        return errors;
    };
    console.log(formData, "formData edit");

    const handleSubmit = async (e) => {
        console.log("gggggggggg");
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append('patient_id', patient?.id);
                finalData.append('sample_collected', formData.sample_collected);
                finalData.append('source', formData.source);
                finalData.append('patient_type', formData.patient_type);
                finalData.append('address', formData.address);
                finalData.append('sdo', formData.sdo);
                finalData.append('lab_no', formData.lab_no);
                finalData.append('gender', formData.gender);
                finalData.append('age', formData.age);
                finalData.append('time', formData.time);
                finalData.append('date', formData.date);
                finalData.append('patient_name', formData.patient_name);
                finalData.append('phone_number', formData.phone_number);
                // finalData.append('review_status', formData.review_status);
                const result = await postData(
                    `/organisation/dashboard/apis/edit-record/`,
                    finalData
                );

                if (result?.success) {
                    setFormData({
                        phone_number: "",
                        patient_name: "",
                        date: "",
                        time: "",
                        age: "",
                        gender: "",
                        lab_no: "",
                        sdo: "",
                        address: "",
                        patient_type: "",
                        source: "",
                        sample_collected: "",
                    });

                    await setErrors({});
                    setModalSuccessPatient(true);
                    setStartDate(null);
                    setTime('');
                    props.onHide();
                    props.getpatientData();


                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    useEffect(() => {
        let timer;
        if (modalSuccessPatient) {
            timer = setTimeout(() => {
                setModalSuccessPatient(false);
            }, 4000); // Modal will hide after 3 seconds
        }
        return () => clearTimeout(timer);
    }, [modalSuccessPatient]);

    const handleKeyPress = (event) => {
        const charCode = event.charCode;
        if (!/[a-zA-Z]/.test(String.fromCharCode(charCode))) {
            event.preventDefault();
        }
    };
    const handleKeyPressNumber = (event) => {
        const charCode = event.charCode;
        // Allow only numeric input
        if (!/[0-9]/.test(String.fromCharCode(charCode))) {
            event.preventDefault();
        }
    };

    const formatTime = (timeString) => {
        if (!timeString) {
            return '';
        }

        const [hours, minutes] = timeString?.split(':');
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='sample-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='mx-auto'>
                        {HedaingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form onSubmit={handleSubmit}>
                        <div className='text-heading  mb-4'>
                            <h2>{SubHeading}</h2>
                        </div>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Patient Name:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control type="text"
                                    placeholder='patient_name'
                                    name='patient_name'
                                    onKeyPress={handleKeyPress}
                                    value={formData?.patient_name}
                                    onChange={handleChange}
                                   
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Age:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control type="text"
                                    placeholder='Age'
                                    name='age'
                                    onKeyPress={handleKeyPressNumber}
                                    value={formData?.age_group}
                                    onChange={handleChange}
                                 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Gender:
                            </Form.Label>
                            <Col md={7}>
                                <Select
                                    name="gender"
                                    placeholder="Select Gender"
                                    value={gender?.find(option => option?.value === formData?.gender)}
                                    onChange={handleSelectChangegender}
                                    options={gender}
                                   
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Phone:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    name="phone_number"
                                    value={formData?.phone_number}
                                    className="text"
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    autoFocus
                                    onChange={handleChange}
                                    onKeyDown={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            if (event.key !== "Backspace")
                                                event.preventDefault();
                                        }
                                    }}
                                    minLength="10"
                                    maxLength="10"
                                  
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Address:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control type="text" name="address"
                                    placeholder="Harmu"
                                    value={formData?.address}
                                    onChange={handleChange}
                                    
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Patient Type:
                            </Form.Label>
                            <Col md={7}>
                                <Select
                                    name='Patient'
                                    // value={formData?.patient_type?.name}
                                    value={patientType?.find(option => option?.value === formData?.patient_type?.id)}
                                    options={patientType}
                                    onChange={handleSelectChange}
                                    
                                />
                            </Col>
                        </Form.Group>
                        <div className='text-heading mt-3'>
                            <h2>{SecondText}</h2>
                        </div>
                        {/* <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Sample collected by:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control type="text" placeholder="Shushil" />
                            </Col>
                        </Form.Group> */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Date of collection:
                            </Form.Label>
                            <Col md={5}>
                                <DatePicker
                                    name='date'
                                    // selected={startDate}
                                    value={formData?.date}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setFormData({ ...formData, date: date.toLocaleDateString() });
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="DD/MM/YYYY"
                                />
                            </Col>
                            {/* <Col md={2}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"

                                />
                            </Col> */}
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Time of collection:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    name='time'
                                    type="time"
                                    value={time}
                                    onChange={(e) => {
                                        setTime(e.target.value);
                                        setFormData({ ...formData, time: e.target.value });
                                    }}
                                    placeholder=""
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                S/D/Wo:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control type="text" name="sdo"
                                    placeholder=""
                                    value={formData?.sdo}
                                    onChange={handleChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                source:
                            </Form.Label>
                            <Col md={7}>
                                <Select name='source'
                                    // value={formData?.source.name}
                                    value={allSource?.find(option => option.value === formData?.source?.id)}
                                    // value={allSource.find(option => option.value === formData.source?.name)}
                                    options={allSource}
                                    onChange={handleSelectChangeSource}
                                    isDisabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Sample type:
                            </Form.Label>
                            <Col md={7}>
                                <Select name='samplecollected'
                                    // value={formData?.sample_collected.name}
                                    value={sampleCollection?.find(option => option.value === formData?.sample_collected?.id
                                    )}
                                    options={sampleCollection}
                                    onChange={handleSelectChangeSample}
                                    isDisabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Lab no:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    type="text"
                                    name="lab_no"
                                    placeholder=""
                                    value={formData?.lab_no}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Form.Group>
                        <Button className='confirm-btn' type='submit'>Confirm</Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    {/* <Button className='delete-btn' onClick={props.onHide}>Delete</Button> */}
                </Modal.Footer>

            </Modal>
            <PatientSuceesfullModal
                textHeading="Patient Details Updated successfully"
                show={modalSuccessPatient}
                onHide={() => setModalSuccessPatient(false)}
            />
        </>
    )
}

export default EditPendingModal
