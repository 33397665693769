import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PatientSuceesfullModal from "./PatientSuceesfullModal";
import { editPatchData } from "../../../../../utils/api";

const PatientModalEdit = (props) => {
    const { HedaingName, show, onHide, getVisitors } = props;
    const initialFormData = props.formData; // Ensure this is set correctly
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [modalSuccess, setModalSuccess] = useState(false);

    useEffect(() => {
        if (props.formData) {
            setFormData(props.formData);
        }
    }, [props.formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "mobile_number") {
            // Allow only numeric values and limit to 10 digits
            const numericValue = value.replace(/\D/g, '').slice(0, 10);
            setFormData({ ...formData, [name]: numericValue });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

   
    const validateForm = () => {
        let errors = {};
        if (!formData.visitor_name) {
            errors.visitor_name = "Visitor Name is required";
        }
        if (!formData.mobile_number) {
            errors.mobile_number = "Mobile Number is required";
        } else if (formData.mobile_number.length !== 10) {
            errors.mobile_number = "Mobile Number must be exactly 10 digits";
        }
        return errors;
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const result = await editPatchData(
                    `organisation/dashboard/apis/visitor-edit/${formData?.id}/`,
                    formData
                );
                if (result?.success) {
                    props.onHide();
                    props.onHides();
                    props.getVisitors();
                    setModalSuccess(true);
                    setFormData(initialFormData); // Reset form data
                    setErrors({});
                    setTimeout(() => {
                        setModalSuccess(false);
                    }, 3000);
                    props.getVisitors();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };

    const handleModalClose = () => {
        setFormData(initialFormData); // Reset the form data to its initial state
        setErrors({}); // Clear any errors
        onHide(); // Close the modal
    };
    return (
        <>
            <Modal
                show={show}
                onHide={handleModalClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="patient-modal"

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {HedaingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditSubmit}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                                Visitor Name
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="visitor_name"
                                    value={formData.visitor_name}
                                    type="text"
                                    placeholder="Enter Visitor name"
                                    autoFocus
                                    onChange={handleChange}
                                />
                                {errors.visitor_name && (
                                    <span style={errorStyle}>{errors.visitor_name}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Mobile Number
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    type="text"
                                    placeholder="Enter Mobile Number"
                                    onChange={handleChange}
                                />
                                {errors.mobile_number && (
                                    <span style={errorStyle}>{errors.mobile_number}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Relation
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="relation_with_patient"
                                    value={formData.relation_with_patient}
                                    type="text"
                                    placeholder="Enter Relation"
                                    onChange={handleChange}
                                />
                                {errors.relation_with_patient && (
                                    <span style={errorStyle}>{errors.relation_with_patient}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Valid Till
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="valid_till"
                                    value={formData.valid_till}
                                    type="date"
                                    placeholder="Enter Valid Till Date"
                                    onChange={handleChange}
                                />
                                {errors.valid_till && (
                                    <span style={errorStyle}>{errors.valid_till}</span>
                                )}
                            </Col>
                        </Form.Group>

                        {/* <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Status
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="status_id"
                                    value={formData.status_id}
                                    type="text"
                                    placeholder="Enter Status"
                                    onChange={handleChange}
                                />
                                {errors.status_id && (
                                    <span style={errorStyle}>{errors.status_id}</span>
                                )}
                            </Col>
                        </Form.Group> */}

                        <Button type="submit" className="create-btn">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <PatientSuceesfullModal
                textHeading="Visitor card updated successfully"
                show={modalSuccess}
                onHide={() => setModalSuccess(false)}
            />
        </>
    );
};

export default PatientModalEdit;