import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Table from "react-bootstrap/Table";
import "./OPDOrg.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
import Select from "react-select";
library.add(fas);

function OPDAppointments() {


  const { id } = useParams();
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date1) => {
    const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;

    // Update formData state with the formatted date
    setFormData({ ...formData, date: formattedDate });
  }
  const handleSearchDateChange = (date1) => {
    const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;

    // Update formData state with the formatted date
    setSearchData({ ...searchData, date: formattedDate });
  }

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = async () => {
    await setFormData({
      organisation: id,
      fees: "",
      opd_id: "",
      unit_number: "",
      patient_type: "",
      description: "",
      name: "",
      billing_type: "",
      consulting_room_no: "",
      days: [],
      days_value: [],
    });

    await setErrors();
    setShow(false);
    setOpdEdit(false);
  }
  const handleShow = () => setShow(true);

  const handleClose2 = async () => {
    setShow2(false);
    await setFormData({
      organisation: id,
      fees: "",
      opd_id: "",
      unit_number: "",
      patient_type: "",
      description: "",
      name: "",
      billing_type: "",
      consulting_room_no: "",
      days: [],
      days_value: [],
    });

    await setErrors();
    setOpdEdit(false);
  }
  const handleShow2 = () => setShow2(true);



  const { getData, postData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);

  const [formData, setFormData] = useState({
    organisation: id,
    fees: "",
    opd_id: "",
    unit_number: "",
    patient_type: "",
    description: "",
    name: "",
    billing_type: "",
    consulting_room_no: "",
    days: [],
    days_value: [],
  });
  const [days, setDays] = useState([
    { value: "Monday", label: "Monday", name: "Monday" },
    { value: "Tuesday", label: "Tuesday", name: "Tuesday" },
    { value: "Wednesday", label: "Wednesday", name: "Wednesday" },
    { value: "Thursday", label: "Thursday", name: "Thursday" },
    { value: "Friday", label: "Friday", name: "Friday" },
    { value: "Saturday", label: "Saturday", name: "Saturday" },
    { value: "Sunday", label: "Sunday", name: "Sunday" },
  ]);

  const [searchData, setSearchData] = useState({
    date: "",
    opd_status: "",
    opd_status_value: "",
    opd_dept: "",
    opd_dept_value: "",
    mobile_number: "",
  });

  const [formEditData, setFormEditData] = useState({
    id: "",
    patient_name: "",
    date: "",
    token: "",
    opd_status: "",
    opd_dept: "",
    first_opd:"",
    changed:false,
  });


  const handleCurrentSelect2Change = async (selectedOption) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        days: selectedOption.map((item) => item.name),
        days_value: selectedOption,
      };
    });
  };

  const handleChange = async (e) => {
    if (
      e?.name === "opd" ||
      e?.name === "opd_id"
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    }
  }
  const handleSearchChange = async (e) => {
    if (
      e?.name === "opd" ||
      e?.name === "opd_id"
    ) {
      setSearchData({ ...searchData, [e?.name]: e });
    } else {
      setSearchData({ ...searchData, [e?.target?.name]: e?.target?.value });
    }
  }
  const handleEditChange = async (e) => {
    if (
      e?.name === "opd_status" ||
      e?.name === "opd_dept"  
    ) {

      if(e?.name === "opd_dept" ){
            if(e?.value === formEditData?.first_opd?.value){
              setFormEditData({ ...formEditData, [e?.name]: e,changed: false });
            }else{
              setFormEditData({ ...formEditData, [e?.name]: e,changed: true });
            }
      }else{
        setFormEditData({ ...formEditData, [e?.name]: e });
      }
      
    } else {
      setFormEditData({ ...formEditData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Name Is Required";
    } if (!formData.days) {
      errors.days = "Days Is Required";
    } if (!formData.patient_type) {
      errors.patient_type = "Patient Type Is Required";
    } if (!formData.consulting_room_no) {
      errors.consulting_room_no = "Consulting Room No Is Required";
    } if (!formData.billing_type) {
      errors.billing_type = "Billing Type Is Required";
    } if (!formData.fees) {
      errors.fees = "Fees Is Required";
    } if (!formData.unit_number) {
      errors.unit_number = "Unit Number Is Required";
    } if (!formData.description) {
      errors.description = "Description Is Required";
    }


    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`opd_id`, formData.opd_id?.value);
        finalData.append(`fees`, formData.fees);
        finalData.append(`unit_number`, formData.unit_number);
        finalData.append(`patient_type`, formData.patient_type);
        finalData.append(`description`, formData.description);
        finalData.append(`name`, formData.name);
        finalData.append(`billing_type`, formData.billing_type);
        finalData.append(`consulting_room_no`, formData.consulting_room_no);
        finalData.append(`days`, JSON.stringify(formData.days_value));

        const result = await postData(
          "organisation/dashboard/apis/opt-department-api/",
          finalData
        );

        if (result?.success) {
          await setFormData({
            organisation: id,
            fees: "",
            opd_id: "",
            unit_number: "",
            patient_type: "",
            description: "",
            name: "",
            billing_type: "",
            consulting_room_no: "",
            days: [],
            days_value: [],
          });

          await setErrors();
          getMaster();
          handleClose();
          getOpdAppointments();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
      console.log(formEditData);
    try {
      const finalData = new FormData();
      if(formEditData?.changed === true){
        finalData.append(`opd_dept`, formEditData?.opd_dept?.value);
      }
      finalData.append(`opd_status`, formEditData?.opd_status?.value);
      finalData.append(`date`, formEditData?.date);
      finalData.append(`patient_name`, formEditData?.patient_name);
      finalData.append(`address`, formEditData?.address);
      finalData.append(`mobile_number`, formEditData?.mobile_number);
      finalData.append(`father_name`, formEditData?.father_name);
      finalData.append(`gender`, formEditData?.gender);
      finalData.append(`age`, formEditData?.age);
      finalData.append(`token`, formEditData?.token);
      finalData.append(`hospital_name`, formEditData?.hospital_name);

      const result = await editPatchData(
        `slot_booking/dashboard/apis/change-dept/${formEditData.id}/`,
        finalData
      );

      if (result?.success) {
        await setFormEditData({
          id: "",
          patient_name: "",
          date: "",
          token: "",
          opd_status: "",
          opd_dept: "",
        });

        await setErrors();
        await handleClose2();
        await getOpdAppointments();
        getMaster();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = async (index, action) => {
    
    await setFormEditData({
      id: opds?.results[index]?.id,
      token: opds?.results[index]?.token,
      patient_name: opds?.results[index]?.patient_name,
      hospital_name: opds?.results[index]?.hospital_name?.id,
      date: opds?.results[index]?.date,
      address: opds?.results[index]?.address,
      mobile_number: opds?.results[index]?.mobile_number,
      gender: opds?.results[index]?.gender,
      father_name: opds?.results[index]?.father_name,
      age: opds?.results[index]?.age,
      opd_status: { value: opds?.results[index]?.opd_status?.id, label: opds?.results[index]?.opd_status?.name, name: "opd_status" },
      opd_dept: { value: opds?.results[index]?.opd_dept?.id, label: opds?.results[index]?.opd_dept?.name, name: "opd_dept" },
      first_opd: { value: opds?.results[index]?.opd_dept?.id, label: opds?.results[index]?.opd_dept?.name, name: "first_opd" },
      changed:false,
    });


    if (action === "edit") {
      handleShow();
      setOpdEdit(true);
    } else {
      handleShow2();
    }

  }

  const [opdMaster, setOpdMaster] = useState();
  const [opdStatusMaster, setOpdStatusMaster] = useState();
  const [opdOrgMaster, setOpdOrgMaster] = useState();
  const getMaster = async () => {
    {
      const res = await getData(`organisation/dashboard/apis/opdorg-list-api/?organisation_id=${id}`);
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "opd_dept",
          days: data?.days,
        }));
        setOpdMaster(result);
      }
    }
    {
      const res = await getData("slot_booking/dashboard/apis/opd-status-get/");
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "opd_status",
        }));
        setOpdStatusMaster(result);
      }
    }
    {
      const res = await getData(`organisation/dashboard/apis/opd-list-api-without/?organisation_id=${id}`);
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.opd?.name,
          name: "opd_id",
        }));
        setOpdOrgMaster(result);
      }
    }
  };

  const [opds, setOpd] = useState();
  const [opdEdit, setOpdEdit] = useState(false);
  const getOpdAppointments = async (page) => {
    console.log(searchData);
    var res;
    if (page) {
      res = await getData(`slot_booking/dashboard/apis/opd-bookings-get/?${page}`);
    } else {
      res = await getData(`slot_booking/dashboard/apis/opd-bookings-get/?organisation_name=${id}&opd_dept=${searchData?.opd_dept}&date=${searchData?.date}&opd_status=${searchData?.opd_status}&number=${searchData?.mobile_number}`);
    }

    console.log(res);
    setOpd(res);
    
  }

 

  const handleDelete = async (id) => {
    const res = await deleteData(`organisation/dashboard/apis/opd-org-edit-api/${id}/`);
    await getOpdAppointments();
  }

  const [activeDefault, setActiveDefault] = useState(true);
  const handleTabs = async (tab) => {
    if (tab === 1) {
      await setActiveDefault(true);
    } else {
      await setActiveDefault(false);
    }

  }
  const [depSelect, setDepSelect] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
  const handleDepChange = async (e) => {
    console.log(e);

    if (e?.name === 'opd_status') {
      setSearchData({ ...searchData, opd_status: e.value, opd_status_value: e });
    }
    if (e?.name === 'opd_dept') {

      setSearchData({ ...searchData, opd_dept: e.value, opd_dept_value: e });
      setDepSelect(e.days);
    }
  }
  const isDisabledDate = (date) => {
    const day = date.getDay();


    // Check if the current day is in the disabledDays array
    return depSelect.some(dayObj => dayObj.name === getDayName(day));
  };

  const getDayName = (day) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[day];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOpdAppointments();
    getMaster();
  }, []);
  return (
    <>

      <section className="data-table">
        <div className="heading-holder">
          <div className="row">
          <div className="col-md-3 col-lg-3">
          <h6 className="font-semibold text">OPD Departments</h6>
          <Select
            placeholder="Select OPD"
            className="select-line"
            value={searchData?.opd_dept_value}
            onChange={handleDepChange}
            options={opdMaster}
          />
          </div>
          <div className="col-md-2 col-lg-3">
          <h6 className="font-semibold text">Date:</h6>
          <DatePicker
            className="date-input-field"
            selected={searchData?.date ? new Date(searchData?.date) : null}
            onChange={handleSearchDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText="yyyy-mm-dd"
            shouldCloseOnSelect={true}
            filterDate={(date) => isDisabledDate(date)}
          />
          </div>
          <div className="col-md-3 col-lg-3">
          <h6 className="font-semibold text">Status:</h6>
          <Select
            placeholder="Select OPD Status"
            className="select-line"
            value={formData?.opd_status_value}
            onChange={handleDepChange}
            options={opdStatusMaster}
          />
          </div>
          <div className="col-md-4 col-lg-3">
          <h6 className="font-semibold text">Mobile:</h6>
          <Form.Control
                name="mobile_number"
                value={searchData?.mobile_number}
                className="text"
                type="text"
                placeholder="Enter Mobile Number"
                autoFocus
                onChange={handleSearchChange}
              />
          </div>
          </div>
          <div className="search-holder  flex items-center space-x-2">
            <Button

              variant="info"
              onClick={() => getOpdAppointments()}
              className="search btn-style btn-size text bg-green"
            >
              Search
            </Button>

            <Button
              variant="success"
              onClick={handleShow}
              className="search btn-style btn-size text bg-green"
            >
              Add OPD Dept.
            </Button>
          </div>
        </div>
        <div className="table-container">
          <Table striped bordered hover className="table">
            <thead>
              <tr>
                <th>
                  <span className="text table-data-heading">Patient Name</span>
                </th>
                <th>
                  <span className="text table-data-heading">Mobile Number</span>
                </th>

                <th>
                  <span className="text table-data-heading">
                    Token
                  </span>
                </th>

                <th>
                  <span className="text table-data-heading">Date</span>
                </th>
                <th>
                  <span className="text table-data-heading">Status</span>
                </th>


                <th>
                  <span className="text table-data-heading">
                    OPD Department
                  </span>
                </th>
                <th>
                  <span className="text table-data-heading">
                    OPD Form
                  </span>
                </th>

                <th>
                  <span className="text table-data-heading">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {opds?.results?.map((value, index) => (
                <tr>
                  <td>
                    <div class="user-name-holder">
                      <p className="text text-size mb-0 data-line ">
                        {value?.patient_name}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="user-name-holder">
                      <p className="text text-size mb-0 data-line ">
                        {value?.mobile_number}
                      </p>
                    </div>
                  </td>

                  <td>
                    <div class="user-name-holder">
                      <p className="text text-size mb-0 data-line ">
                        {value?.token}
                      </p>
                    </div>
                  </td>

                  <td>
                    <div class="user-name-holder">
                      <p className="text text-size mb-0 data-line ">{value?.date}</p>
                    </div>
                  </td>
                  <td>
                    <div class="user-name-holder">
                      <p className="text text-size mb-0 data-line ">{value?.opd_status?.name}</p>
                    </div>
                  </td>
                  <td>
                    <div class="user-name-holder">
                      <p className="text text-size mb-0 data-line ">{value?.opd_dept?.name}</p>
                    </div>
                  </td>
                  <td >
                    <div class="user-name-holder">
                      <p className="text text-size mb-0 data-line ">
                        {value?.pdf !== null && (
                          <a href={imageUrl + '/media/' + value?.pdf} target="_blank">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-download"
                              style={{ cursor: 'pointer' }}

                            />
                          </a>
                        )}


                      </p>
                    </div>
                  </td>

                  {/* <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.date}
                          </p>
                        </div>
                      </td> */}

                  <td>
                    <div className="action">
                      {/* <Link to="/OPDCardDetails"> */}
                      <Button className="action-btn" onClick={() => handleEdit(index, "view")}>
                        <FontAwesomeIcon
                          className="text"
                          icon="fa-solid fa-eye"
                        />
                      </Button>
                      {/* </Link> */}
                      {/* <Button className="action-btn " onClick={() => handleEdit(index, "edit")}>
                        <FontAwesomeIcon
                          className="text"
                          icon="fa-solid fa-pen-to-square"
                        />
                      </Button> */}
                      {/* <Button className="action-btn" onClick={() => handleDelete(value?.id)}>
                        <FontAwesomeIcon
                          className="text"
                          icon="fa-solid fa-trash"
                        />
                      </Button> */}
                    </div>
                  </td>
                </tr>

              ))}

            </tbody>
          </Table>
        </div>
      </section>

      {/* pegignation start */}
      <div className="row me-0 ms-0">
        <div class="col-md-12">
          <div class="Pagination-holder">
            <ul class="pagination">
              {opds?.previous && (
                <li class="page-item">
                  <a class="page-link" role="button" tabindex="0" onClick={() => getOpdAppointments(opds?.previous.split("?")[1])}>
                    <span aria-hidden="true">‹</span>
                    <span class="visually-hidden">Previous</span>
                  </a>
                </li>
              )}
              {[...Array(opds?.count && Math.ceil(opds?.count / 10))].map(function (_, index) {
                return (
                  <li key={index} className="page-item">
                    <a className="page-link" role="button" tabIndex="0" 
                    onClick={() => 
                    getOpdAppointments(`organisation_name=${id}&opd_dept=${searchData?.opd_dept}&date=${searchData?.date}&opd_status=${searchData?.opd_status}&number=${searchData?.mobile_number}&page=` + (index + 1))}>
                      {index + 1}
                    </a>
                  </li>
                );
              })}
              {opds?.next && (
                <li class="page-item">
                  <a class="page-link" role="button" tabindex="0" onClick={() => getOpdAppointments(opds?.next.split("?")[1])}>
                    <span aria-hidden="true">›</span>
                    <span class="visually-hidden">Next</span>
                  </a>
                </li>
              )
              }
            </ul>
          </div>
        </div>
      </div>


      {/* pegignation end */}

      {/* doctor data table end */}

      {/* add Visitor  modal start */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text">{opdEdit ? 'Edit' : 'Add'} OPD </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3 text"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="text">OPD</Form.Label>
              <Select
                placeholder="Select OPD"
                className="select-line"
                value={searchData?.opd_id}
                onChange={handleChange}
                options={opdOrgMaster}
              />
              {errors?.opd_id && (
                <span style={errorStyle}>{errors?.opd_id}</span>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3 text"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="text">OPD Department Name</Form.Label>
              <Form.Control
                name="name"
                value={formData?.name}
                className="text"
                type="text"
                placeholder="Enter OPD Department Name"
                autoFocus
                onChange={handleChange}
              />
              {errors?.name && (
                <span style={errorStyle}>{errors?.name}</span>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3 text"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="text">Description</Form.Label>
              <Form.Control
                name="description"
                value={formData?.description}
                className="text"
                type="text"
                placeholder="Enter Description"
                autoFocus
                onChange={handleChange}
              />
              {errors?.description && (
                <span style={errorStyle}>{errors?.description}</span>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3 text"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="text">Days Open</Form.Label>
              <Select
                placeholder="Select Days"
                className="select-line"
                value={formData?.days_value}
                isMulti
                onChange={(selectOption) => handleCurrentSelect2Change(selectOption)}
                options={days}
              />
              {errors?.days && (
                <span style={errorStyle}>{errors?.days}</span>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3 text"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="text">Billing Type</Form.Label>
              <Form.Control
                name="billing_type"
                value={formData?.billing_type}
                className="text"
                type="text"
                placeholder="Enter Billing Type"
                autoFocus
                onChange={handleChange}
              />
              {errors?.billing_type && (
                <span style={errorStyle}>{errors?.billing_type}</span>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3 text"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="text">Consulting Room No.</Form.Label>
              <Form.Control
                name="consulting_room_no"
                value={formData?.consulting_room_no}
                className="text"
                type="text"
                placeholder="Enter Consulting Room No."
                autoFocus
                onChange={handleChange}
              />
              {errors?.consulting_room_no && (
                <span style={errorStyle}>{errors?.consulting_room_no}</span>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3 text"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="text">Patient Type</Form.Label>
              <Form.Control
                name="patient_type"
                value={formData?.patient_type}
                className="text"
                type="text"
                placeholder="Enter Patient Type"
                autoFocus
                onChange={handleChange}
              />
              {errors?.patient_type && (
                <span style={errorStyle}>{errors?.patient_type}</span>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3 text"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="text">Unit Number</Form.Label>
              <Form.Control
                name="unit_number"
                value={formData?.unit_number}
                className="text"
                type="text"
                placeholder="Enter Unit Number"
                autoFocus
                onChange={handleChange}
              />
              {errors?.unit_number && (
                <span style={errorStyle}>{errors?.unit_number}</span>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3 text"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="text">Fees</Form.Label>
              <Form.Control
                name="fees"
                value={formData?.fees}
                className="text"
                type="text"
                placeholder="Enter Fees"
                autoFocus
                onChange={handleChange}
                onKeyDown={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    if (event.key != "Backspace")
                      event.preventDefault();
                  }
                }}
              />
              {errors?.Fees && (
                <span style={errorStyle}>{errors?.Fees}</span>
              )}
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          {opdEdit ?
            (
              <Button variant="success" onClick={handleEditSubmit}>
                Edit
              </Button>
            ) : (
              <Button variant="success" onClick={handleSubmit}>
                Add
              </Button>
            )}



        </Modal.Footer>
      </Modal>
      {/* add Visitor  modal end */}

      {/* details  modal start */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text">OPD Booking Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Details-card">
            <Card>
              <Card.Body className="no-scroll">
                <div className="Details-card">
                  <div className="visitor-patient-card">
                    <div className="row me-0 ms-0">
                      <div className="details-card border-right">
                        <Card.Title>
                          <h4 className="text ">Patient Details</h4>
                        </Card.Title>
                        <Card.Text>
                          <div className="details">
                            <div className="visitor-name">
                              <FontAwesomeIcon
                                className="text me-2"
                                icon="fa-solid fa-user"
                              />
                              <p className="text mb-0">
                                {" "}
                                <b>{formEditData?.patient_name}</b>
                              </p>
                            </div>

                            <p className="text ">
                              Date :
                              <b>{formEditData?.date}</b>
                            </p>
                            {/* <p className="text ">5B22-A</p> */}
                            <p className="text ">
                              {" "}Token :
                              <b>{formEditData?.token}</b>
                            </p>
                          </div>
                        </Card.Text>
                      </div>
                    </div>
                  </div>

                  <div className="status-div">
                    <div className="status-info">
                      <h6 className="visitor-status-text  mb-0 me-3">
                        Status:
                      </h6>
                      <Form.Group className="mb-3 text">
                        <Select
                          placeholder="Select Status"
                          className="select-line"
                          value={formEditData?.opd_status}
                          onChange={handleEditChange}
                          options={opdStatusMaster}
                        />
                        {errors?.opd_status && (
                          <span style={errorStyle}>{errors?.opd_status}</span>
                        )}
                      </Form.Group>
                    </div>
                    <div className="status-info">
                      <h6 className="visitor-status-text  mb-0 me-3">
                        Department:
                      </h6>
                      <Form.Group className="mb-3 text">
                        <Select
                          placeholder="Select OPD Department"
                          className="select-line"
                          value={formEditData?.opd_dept}
                          onChange={handleEditChange}
                          options={opdMaster}
                        />
                        {errors?.opd_dept && (
                          <span style={errorStyle}>{errors?.opd_dept}</span>
                        )}
                      </Form.Group>
                    </div>
                  </div>



                </div>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="success" onClick={handleEditSubmit}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* details  modal end */}

    </>
  );
}

export default OPDAppointments;
