import axiosClient from "./ApiInstance";
import Cookies from "js-cookie";

export const getData = async (url, data) => {
  try {
    const response = await axiosClient.get(
      process.env.REACT_APP_BASE_URL + url,
      data
    );
    return response.data;
  } catch (error) {
    // Cookies.remove("jwtToken", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("jwtToken", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const editStatusData = async (url, data) => {
  try {
    const response = await axiosClient.put(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("jwtToken", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const editPatchData = async (url, data) => {
  try {
    const response = await axiosClient.patch(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("jwtToken", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const deleteData = async (url, data = {}) => {
  try {
    const response = await axiosClient.delete(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("jwtToken", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

// Download Apis
export const getDownloadDataExcel = async (aurl, data, name) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + aurl,
      data,
      {
        responseType: "blob", // Set responseType to "blob"
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${name + " " + new Date().toLocaleDateString()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();

    // Clean up after the download
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // Cookies.remove("jwtToken", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const Select2Data = async (data, name, other = false) => {

  const result = data.map((data) => ({
    value: data?.id,
    label: data?.title,
    name: name,
  }));


  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};


export const getVisitorData = async (visitorId) => {
  try {
    // Make a request to fetch visitor data based on the provided ID
    const response = await fetch(`organisation/dashboard/apis/visitor-get/${visitorId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch visitor data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching visitor data:', error);
    throw error;
  }
};



export const SelectUserData = async (data, name, other = false) => {

  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));


  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};