import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { Link, useParams } from "react-router-dom";
import "./Generalfare.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../utils/context";
library.add(fas);


function Generalfare() {

  
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);

  const handleClose = async () => {
    await setFormData({
      organisation_id:id,
      title : "",
      description: "",
      icon:undefined,
    });
    await setErrors();
    setIconPreview(false);
    setShow(false);
  }
  const handleShow = async () => {
    setShow(true);
    await setOpdEdit(false);
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };


  
  const [iconPreview, setIconPreview] = useState(false);


  const handleImageIconChange = async (e) => {
    const file = e.target.files[0];

    await setFormData({ ...formData, icon: file });

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setIconPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const { getData, postData,editPatchData,editStatusData,deleteData, imageUrl, Select2Data,orgID, setOrgId} = useContext(Context);

  const [formData, setFormData] = useState({
    organisation_id:id,
    id : "",
   title : "",
   description: "",
   icon:undefined,
  });

  const handleChange = async (e) => {
    if (
      e?.name === "country_id" 
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.service_name) {
      errors.service_name = "Service is required";
    }
    if (!formData.detail) {
      errors.detail = "Detail is required";
    }
    if (!formData.price) {
      errors.price = "Price is required";
    }

    // if (formData.icon === undefined) {
    //   errors.icon = "Image is required";
    // }

    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`organisation`, formData.organisation_id);
        finalData.append(`service_name`, formData.service_name);
        finalData.append(`detail`, formData.detail);
        finalData.append(`price`, formData.price);

        const result = await postData(
          "organisation/dashboard/apis/general-fare/",
          finalData
        );

        if (result?.success) {
          await setFormData({
            organisation_id:id,
            id : "",
            title : "",
            description: "",
            icon:undefined,
          });

          await setErrors();
          getCategory();
          handleClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();


    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        
        finalData.append(`organisation`, formData.organisation_id);
        finalData.append(`service_name`, formData.service_name);
        finalData.append(`detail`, formData.detail);
        finalData.append(`price`, formData.price);

        const result = await editPatchData(
          `organisation/dashboard/apis/general-fare/${formData.id}/`,
          finalData
        );

        if (result?.success) {
          await setFormData({
            organisation_id:id,
            id : "",
            service_name : "",
            detail: "",
            price:"",
          });

          await setErrors();
          getCategory();
          handleClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteCategory = async (id) =>{
      const res = await deleteData(`/organisation/dashboard/apis/general-fare/${id}/`);
      getCategory();
  }

  const [categorys, setCategory] = useState();
  const [opdEdit, setOpdEdit] = useState(false);
  const getCategory = async (page) => {
    var res;
    if(page){
      res = await getData(`organisation/dashboard/apis/category-list-api-with/?${page}`);
    }else{
      res = await getData(`organisation/dashboard/apis/general-fare/?organisation_id=${id}`);
    }
      setCategory(res);
  }

  const handleEdit = async (index,action) => {
    console.log(categorys?.data[index]);
    await setFormData({
      id: categorys?.data[index]?.id,
      service_name: categorys?.data[index]?.service_name,
      detail: categorys?.data[index]?.detail,
      price: categorys?.data[index]?.price,
      organisation_id:id,
    });
    setIconPreview(imageUrl+categorys?.data[index]?.icon);

    if(action === "edit"){
      handleShow(); 
      setOpdEdit(true);
    }
   
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategory();
  }, []);


   
  
  return (
    <>
        <div className="main">
        <div className="container-fluid p-4">
          {/* Categories data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">All Service</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search :{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                <Button
                  variant="success"
                  onClick={handleShow}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Service
                </Button>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    
                    <th>
                      <span className="text table-data-heading">Service Name</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Detail
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Price
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categorys?.data?.map((value,index) => (
                    <tr>
                    
                    <td>
                      <p className="text text-size data-line ">
                        {value?.service_name}
                      </p>
                    </td>
                    <td>
                      <p className="text text-size data-line">{value?.detail}</p>
                    </td>
                    <td>
                      <p className="text text-size data-line">{value?.price}</p>
                    </td>
                    <td>
                        <div className="action">
                          {/* <Link to="/OPDCardDetails"> */}
                          
                          {/* </Link> */}
                          <Button className="action-btn " onClick={() => handleEdit(index,"edit")}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>
                          <Button className="action-btn" onClick={() => deleteCategory(value?.id)}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button>
                        </div>
                      </td>
                  </tr>
                  ))}
                  
                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                {categorys?.previous && (
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0"  onClick={() => getCategory( categorys?.previous.split("?")[1])}>
                      <span aria-hidden="true">‹</span>
                      <span class="visually-hidden">Previous</span>
                    </a>
                  </li>
                )}
                  {[...Array(categorys?.count && Math.ceil(categorys?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getCategory("page="+(index+1))}>
                          {index+1}
                        </a>
                      </li>
                    );
                  })}
                    {categorys?.next && (
                      <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" onClick={() => getCategory( categorys?.next.split("?")[1])}>
                      <span aria-hidden="true">›</span>
                      <span class="visually-hidden">Next</span>
                    </a>
                  </li>
                    )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}
          {/* Categories data table end */}

{/* Add Category modal start */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">ADD Service</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">SERVICE NAME</Form.Label>
                  <Form.Control
                    name="service_name"
                    value={formData?.service_name}
                    className="text"
                    type="text"
                    placeholder="Enter Service Name"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.service_name && (
                    <span style={errorStyle}>{errors?.service_name}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">DETAIL</Form.Label>
                  <Form.Control
                    name="detail"
                    value={formData?.detail}
                    className="text"
                    type="text"
                    placeholder="Enter Detail"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.detail && (
                    <span style={errorStyle}>{errors?.detail}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Price</Form.Label>
                  <Form.Control
                    name="price"
                    value={formData?.price}
                    className="text"
                    type="text"
                    placeholder="Enter Price"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                  />
                  {errors?.price && (
                    <span style={errorStyle}>{errors?.price}</span>
                  )}
                </Form.Group>

                
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              {opdEdit ?
                (
                  <Button variant="success" onClick={handleEditSubmit}>
                    Edit
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleSubmit}>
                    Add
                  </Button>
                )}
            </Modal.Footer>
          </Modal>
          {/* Add Category modal end */}
        </div>
      </div>
    </>
  );
}

export default Generalfare;
