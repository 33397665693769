import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "./AllModalSec.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PatientModaltwo from './PatientModaltwo';
import Select from 'react-select';
import { Context } from '../../../../../utils/context';
const AllModalSec = (props) => {
    const { HedaingName, handleNewVisitor } = props;
    const { id } = useParams();
    const [modalPatient, setModalPatient] = useState(false);
    const [wardData, setWardData] = useState([]);
    const { getData, SelectUserData } = useContext(Context);
    const initialFormData = {
        org_id: id,
        patient_id: "",
        bed_no: "",
        patient_name: "",
        ward: "",
        visitor_name: "",
        mobile_number: "",
        relation_with_patient: "",
        status_id: "",
        valid_till: "",
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const getWardData = async () => {
        try {
            const response = await getData(`organisation/dashboard/apis/visitor-ward-list/?${id}`);
            const results = await SelectUserData(response?.results, 'name', false);
            setWardData(results);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getWardData();
    }, [id]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSelectChange = (selectedOption) => {
        setFormData({ ...formData, ward: selectedOption });
    };
    const validateForm = () => {
        let errors = {};
        if (!formData.patient_name) {
            errors.patient_name = "Patient Name Required";
        }
        if (!formData.patient_id) {
            errors.patient_id = "Patient ID Required";
        }
        // if (!formData.mobile_number) {
        //     errors.mobile_number = "mobile_number";
        // }

        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setModalPatient(true);

            props.onHide();
        }
    };

    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };
    const handleModalClose = () => {
        console.log("ASFasf");
        setFormData(initialFormData);
        props.onHide();
    };
    useEffect(() => {
        if (!modalPatient) {
            setFormData(initialFormData);
        }
    }, [modalPatient]);
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='patient-modal'
                onHide={handleModalClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {HedaingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                                Patient Name
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="patient_name"
                                    value={formData.patient_name}
                                    type="text"
                                    placeholder="Enter Patient name"
                                    autoFocus
                                    onChange={handleChange}
                                />
                                {errors.patient_name && (
                                    <span style={errorStyle}>{errors.patient_name}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Patient ID
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="patient_id"
                                    value={formData.patient_id}
                                    type="text"
                                    placeholder="Enter Patient ID"
                                    onChange={handleChange}
                                />
                                {errors.patient_id && (
                                    <span style={errorStyle}>{errors.patient_id}</span>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Ward
                            </Form.Label>
                            <Col sm={9}>
                                <Select
                                    name="ward"
                                    value={wardData?.find(option => option.value === formData.ward.id)}
                                    onChange={handleSelectChange}
                                    options={wardData}
                                    placeholder="Select Ward"
                                />
                                {errors.ward && (
                                    <span style={errorStyle}>{errors.ward}</span>
                                )}
                            </Col>

                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Bed No.
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="bed_no"
                                    value={formData.bed_no}
                                    type="text"
                                    placeholder="Bed no."
                                    onChange={handleChange}
                                />

                            </Col>
                        </Form.Group>
                        <Button type="submit" className='create-btn'>Next</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <PatientModaltwo
                org_id={id}
                HedaingName="Add Visitor Detail : (2/2)"
                show={modalPatient}
                onHide={() => setModalPatient(false)}
                formData={formData}
                getVisitors={props.getVisitors}
                setFormData={setFormData}
                setErrors={setErrors}
                errors={errors}
                handleNewVisitor={handleNewVisitor}
            />

        </>
    );
}

export default AllModalSec;
