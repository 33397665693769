import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Table from "react-bootstrap/Table";
import "./OPDCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
library.add(fas);

function OPDCard() {


  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date1) => {
    const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;

    // Update formData state with the formatted date
    setFormData({ ...formData, date: formattedDate });
  }

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = async () => {
    await setFormData({
      id: "",
      name: "",
      description: "",
      // price: "",
      // date: "",
    });

    await setErrors();
    setShow(false);
    setOpdEdit(false);
  }
  const handleShow = () => setShow(true);

  const handleClose2 = async () => {
    setShow2(false);
    await setFormData({
      id: "",
      name: "",
      description: "",
      // price: "",
      // date: "",
    });

    await setErrors();
    setOpdEdit(false);
  }
  const handleShow2 = () => setShow2(true);



  const { getData, postData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    // price: "",
    // date: "",
  });

  const handleChange = async (e) => {
    if (
      e?.name === "country_id"
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }
    // if (!formData.price) {
    //   errors.price = "Price is required";
    // }
    // if (!formData.date) {
    //   errors.date = "Date is required";
    // }


    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`name`, formData.name);
        finalData.append(`description`, formData.description);
        // finalData.append(`price`, formData.price);
        // finalData.append(`date`, formData.date);

        const result = await postData(
          "organisation/dashboard/apis/opd-create-api/",
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            name: "",
            description: "",
            // price: "",
            // date: "",
          });

          await setErrors();
          handleClose();
          getOPD();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`name`, formData.name);
        finalData.append(`description`, formData.description);
        // finalData.append(`price`, formData.price);
        // finalData.append(`date`, formData.date);

        const result = await editPatchData(
          `organisation/dashboard/apis/opd-edit-api/${formData.id}/`,
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            name: "",
            description: "",
            // price: "",
            // date: "",
          });

          await setErrors();
          handleClose();
          getOPD();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [opds, setOpd] = useState();
  const [opdEdit, setOpdEdit] = useState(false);
  const getOPD = async (page) => {
    var res;
      if(page){
         res = await getData(`organisation/dashboard/apis/opd-list-api/?${page}`);
      }else{
         res = await getData(`organisation/dashboard/apis/opd-list-api/`);
      }
    
    console.log(res);
    setOpd(res);
    if (res?.success) {

    }
  }

  const handleEdit = async (index, action) => {
    console.log(opds?.results[index]);
    await setFormData({
      id: opds?.results[index]?.id,
      name: opds?.results[index]?.name,
      description: opds?.results[index]?.description,
      price: opds?.results[index]?.price,
      date: new Date(opds?.results[index]?.date).toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    });


    if (action === "edit") {
      handleShow();
      setOpdEdit(true);
    } else {
      handleShow2();
    }

  }

  const handleDelete = async (id) => {
    const res = await deleteData(`organisation/dashboard/apis/opd-edit-api/${id}/`);
    await getOPD();
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getOPD();
  }, [formData]);
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* doctor data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">OPD Card</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search:{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                <Button
                  variant="success"
                  onClick={handleShow}
                  className="search btn-style btn-size text bg-green"
                >
                  Add OPD
                </Button>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">OPD Name</span>
                    </th>

                    <th>
                      <span className="text table-data-heading">
                        Description
                      </span>
                    </th>

                    {/* <th>
                      <span className="text table-data-heading">Price</span>
                    </th> */}

                    {/* <th>
                      <span className="text table-data-heading">
                        Booking Date
                      </span>
                    </th> */}

                    <th>
                      <span className="text table-data-heading">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {opds?.results?.map((value, index) => (
                    <tr>
                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.name}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.description}
                          </p>
                        </div>
                      </td>

                      {/* <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">{value?.price}</p>
                        </div>
                      </td>

                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line ">
                            {value?.date}
                          </p>
                        </div>
                      </td> */}

                      <td>
                        <div className="action">
                          {/* <Link to="/OPDCardDetails"> */}
                          <Button className="action-btn" onClick={() => handleEdit(index, "view")}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-eye"
                            />
                          </Button>
                          {/* </Link> */}
                          <Button className="action-btn " onClick={() => handleEdit(index, "edit")}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>
                          <Button className="action-btn" onClick={() => handleDelete(value?.id)}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                            />
                          </Button>
                        </div>
                      </td>
                    </tr>

                  ))}

                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                {opds?.previous && (
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0"  onClick={() => getOPD( opds?.previous.split("?")[1])}>
                      <span aria-hidden="true">‹</span>
                      <span class="visually-hidden">Previous</span>
                    </a>
                  </li>
                )}
                  {[...Array(opds?.count && Math.ceil(opds?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getOPD("page="+(index+1))}>
                          {index+1}
                        </a>
                      </li>
                    );
                  })}
                    {opds?.next && (
                      <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" onClick={() => getOPD( opds?.next.split("?")[1])}>
                      <span aria-hidden="true">›</span>
                      <span class="visually-hidden">Next</span>
                    </a>
                  </li>
                    )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* doctor data table end */}

          {/* add Visitor  modal start */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">{opdEdit ? 'Edit' : 'Add'} OPD </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">OPD Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={formData?.name}
                    className="text"
                    type="text"
                    placeholder="Enter Name"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.name && (
                    <span style={errorStyle}>{errors?.name}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Description</Form.Label>
                  <Form.Control
                    name="description"
                    value={formData?.description}
                    className="text"
                    type="text"
                    placeholder="Enter Description"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.description && (
                    <span style={errorStyle}>{errors?.description}</span>
                  )}
                </Form.Group>

                {/* <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Price</Form.Label>
                  <Form.Control
                    name="price"
                    value={formData?.price}
                    className="text"
                    type="text"
                    placeholder="Enter Price"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace")
                          event.preventDefault();
                      }
                    }}
                  />
                  {errors?.price && (
                    <span style={errorStyle}>{errors?.price}</span>
                  )}
                </Form.Group> */}
{/* 
                <Form.Group
                  className="mb-3 text"
                // controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Booking Date</Form.Label>
                  <DatePicker
                    className="date-input-field"
                    selected={formData?.date ? new Date(formData?.date) : null}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="yyyy-mm-dd"
                    shouldCloseOnSelect={true}
                  />
                  {errors?.date && (
                    <span style={errorStyle}>{errors?.date}</span>
                  )}
                </Form.Group> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              {opdEdit ?
                (
                  <Button variant="success" onClick={handleEditSubmit}>
                    Edit
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleSubmit}>
                    Add
                  </Button>
                )}



            </Modal.Footer>
          </Modal>
          {/* add Visitor  modal end */}

          {/* details  modal start */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">OPD Details</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="Details-card">
                <Card>
                  <Card.Body>
                    <div className="visitor-patient-card">
                      <div className="row me-0 ms-0">
                        <div className="col-md-12">
                          <div className="details-card">
                            <Card.Text>
                              <div className="details">
                                <div className="visitor-name ">
                                  <FontAwesomeIcon
                                    className="text me-2 "
                                    icon="fa-solid fa-user"
                                  />
                                  <p className="text mb-0">{formData?.name}</p>
                                </div>

                                <p className="text">
                                  <b>Description</b>
                                </p>

                                <p className="text ">
                                  {formData?.description}
                                </p>
{/* 
                                <p className="text ">
                                  <b>Price:- </b> {formData?.price}
                                </p>

                                <p className="text ">
                                  <b>Date:- </b>{formData?.date}
                                </p> */}
                              </div>
                            </Card.Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
          {/* details  modal end */}
        </div>
      </div>
    </>
  );
}

export default OPDCard;
