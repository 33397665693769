import React from "react";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import "./Login.css";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import { Context } from "../../utils/context";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
function LogIn() {
  const { getData, postData, imageUrl, Select2Data, setSignin,setOrganisation, setOrgType } = useContext(Context);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [otpsendStatus, setOtpsendStatus] = useState(true);
  const [otpVerify, setOtpVerify] = useState("");
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  // console.log(mobile);
  const [show2, setShow2] = useState(false);
  const [showContent, setShowContent] = useState("");

  const handleClose2 = async () => {
    setShowContent("");
    setShow2(false);
  }
  const handleShow2 = (content) => {
    setOtp("");
    setShowContent(content);
    setShow2(true);
  }

  const SendOTP = async () => {
    if (mobile.length === 10) {

      
  
      // console.log(mobile.length === 10);
      const result = await postData(
        "users/dashboard/apis/send-otp-api/",
        {
          phone_number: mobile
        }
      );
      console.log("kkkkk");

      if (result?.success) {
        setOtpsendStatus(true);
        handleShow2("OTP Sent!");
        setTimeout(() => {
          handleClose2();
        }, 2000);
      }

    }
  }


  const SignIn = async () => {

      if(!mobile){
        setOtpVerify("Enter Mobile Number");
      }else{
        try {
          const result = await postData(
            "users/dashboard/apis/log-in-api/",
            {
              phone_number: mobile,
              entered_otp: otp,
            }
          );
          if (result?.success) {
            setOtpsendStatus(true);
            Cookies.set("jwtToken", result.token, {
              expires: 2,
            });
            Cookies.set("jwtType", result.type, {
              expires: 2,
            });
            if(result?.organisation_id !== null){
              Cookies.set("jwtOrganisation", result.organisation_id, {
                expires: 2,
              });
              Cookies.set("jwtOrgType", result.org_type, {
                expires: 2,
              });
            }
            setSignin(true);
            setOrganisation(Cookies.get("jwtOrganisation"));
            setOrgType(Cookies.get("jwtOrgType"));

            console.log(result.type,'login');
            if(result?.org_type === 'HOSPITAL'){
              navigate(`/hospitalsDetails/${result?.organisation_id}`);
            }if(result?.org_type === 'DIAGNOSIS'){
              navigate(`/diagnosticDetails/${result?.organisation_id}`);
            }if(result?.type === 'SUPERUSER' ){
              navigate("/dashboard");
            }
            
          } else {
            setOtpVerify(result?.message);
            
            await handleShow2(result?.message);
             
          }
        } catch (error) {
         console.log(error);
        }
      }
  };

  return (
    <>
   
      <section className="login-panel p-0">
        <div className="container-fluid">
          <div className=" row login-panel-raw align-items-center">
            <div className="col col-xxl-8 col-xl-8 col-lg-8 col-md-7 p-0">
              <div className="image-div">
                <img
                  className="image"
                  src={
                    process.env.PUBLIC_URL +
                    "./assets/image/login/login_background.jpg"
                  }
                />
              </div>
            </div>
            <div className=" col col-xxl-4 col-xl-4 col-lg-4 col-md-5 column1">
              <div className="second-coloumn">
                <div className="logo-div">
                  <img
                    className="logo-size"
                    src={
                      process.env.PUBLIC_URL + "./assets/image/login/logo_2.png"
                    }
                  />
                </div>

                <div className="login-input">
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicPhoneNumber"
                    >
                      <Form.Label className="text-colors">
                        Phone Number
                      </Form.Label>
                      <Form.Control placeholder="Enter Mobile Number" type="text" minLength={10} maxLength={10} onChange={(e) => { setMobile(e.target.value); setOtpVerify("");}} onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          if (event.key != "Backspace")
                            event.preventDefault();
                        }
                      }} />
                      <div className="send-otp-text">
                        <Form.Text className="otp-text" style={{ cursor: 'pointer' }}>
                          <b onClick={SendOTP}>Send OTP</b>
                        </Form.Text>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicOtp">
                      <Form.Label className="text-colors">OTP</Form.Label>
                      <Form.Control placeholder="Enter Sent OTP" type="text" value={otp} minLength={4} maxLength={4} onChange={(e) => { setOtp(e.target.value); setOtpVerify(""); }} onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          if (event.key != "Backspace")
                            event.preventDefault();
                        }
                      }} />
                    </Form.Group>
                    {/* <Link to={"/dashboard"}> */}
                    <span className="text text-danger">{otpVerify}</span>
                    {
                      otpsendStatus ?
                        <Button

                          type="button"
                          variant="success"
                          className="sign-in-button"
                          onClick={SignIn}
                        >
                          Sign In
                        </Button>
                        : <></>
                    }
                    {/* </Link> */}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton >
          <Modal.Title>
            <h4 className="text">{showContent}</h4>
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </>
  );
}

export default LogIn;
