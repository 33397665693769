import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PathologyFifthModal from './PathologyFifthModal';
import { Context } from '../../../../../utils/context';
import { useParams } from 'react-router-dom';
import { getData } from '../../../../../utils/api';
import Select from 'react-select';

const PathologyFourthModal = ({ show, onHide, addReferenceRange }) => {
    const [rangeValue, setRangeValue] = useState([]);
    const { id } = useParams();
    const { SelectUserData } = useContext(Context);
    const [rangeData, setRangeData] = useState({
        ageGroup: '',
        gender: '',
        remark: '',
        type: null,
        minValue: '',
        maxValue: '',
        value: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setRangeData({ ...rangeData, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (selectedOption) => {
        setRangeData({ ...rangeData, type: selectedOption, minValue: '', maxValue: '', value: '' });
    };

    const validateForm = () => {
        const errors = {};
        if (!rangeData.ageGroup) {
            errors.ageGroup = "Age group is required";
        }
        if (!rangeData.gender) {
            errors.gender = "Gender is required";
        }
        if (rangeData.type?.label === 'range') {
            if (rangeData.minValue === '' || rangeData.maxValue === '') {
                errors.range = "Both Min and Max values are required";
            }
        } else if (rangeData.type?.label && rangeData.value === '') {
            errors.range = "Value is required";
        }
        return errors;
    };

    const handleAdd = async () => {
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            await addReferenceRange(rangeData);
            await setRangeData({
                ageGroup: '',
                gender: '',
                type: null,
                minValue: '',
                maxValue: '',
                value: ''
            });
            await onHide();
        } else {
            setErrors(errors);
        }
    };

    const getrangeValue = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/get-ref/?${id}`);
            console.log("API response:", response.data); // Debugging log
            const data = await SelectUserData(response.data, 'type', false);
            console.log("Processed data:", data); // Debugging log
            setRangeValue(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getrangeValue();
    }, [id]);


    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className='patient-modal'
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            Age group*:
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Select
                                name="ageGroup"
                                value={rangeData.ageGroup}
                                onChange={handleChange}
                            >
                                <option value="">Select Age Group</option>
                                <option value="Adult">Adult</option>
                                <option value="Child">Child</option>
                                <option value="Infant">Infant</option>
                            </Form.Select>
                            {errors.ageGroup && <span style={{ color: 'red' }}>{errors.ageGroup}</span>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={3}>
                            Gender*:
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Select
                                name="gender"
                                value={rangeData.gender}
                                onChange={handleChange}
                            >
                                <option value="">Select Gender</option>
                                <option value="M">M</option>
                                <option value="F">F</option>
                                <option value="B">B(Both)</option>
                            </Form.Select>
                            {errors.gender && <span style={{ color: 'red' }}>{errors.gender}</span>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={3}>
                            Range*:
                        </Form.Label>
                        <Col sm={9}>
                            <Select
                                name="type"
                                value={rangeData.type}
                                onChange={handleSelectChange}
                                options={rangeValue}
                            />
                            {rangeData.type && rangeData.type.label === 'range' ? (
                                <Row className='mt-3'>
                                    <Col sm={6}>
                                        <Form.Control
                                            type="number"
                                            name="minValue"
                                            placeholder="Min Value"
                                            value={rangeData.minValue}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control
                                            type="number"
                                            name="maxValue"
                                            placeholder="Max Value"
                                            value={rangeData.maxValue}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                rangeData.type && (
                                    <Form.Control
                                        className='mt-3'
                                        type="number"
                                        name="value"
                                        placeholder="Value"
                                        value={rangeData.value}
                                        onChange={handleChange}
                                    />
                                )
                            )}
                            {errors.range && <span style={{ color: 'red' }}>{errors.range}</span>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            Remark*:
                        </Form.Label>
                        <Col sm={9}>
                        <Form.Control type="text" name="remark"
                                    placeholder="Remark"
                                    value={rangeData?.remark}
                                    onChange={handleChange} />
                            {/* {errors.remark && <span style={{ color: 'red' }}>{errors.remark}</span>} */}
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleAdd} className='create-btn'>Add</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PathologyFourthModal;
