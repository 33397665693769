import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './SampleCollectionModal.css';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Context } from '../../../../../../utils/context';
import PatientSuceesfullModal from '../../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
const SampleCollectionModal = (props) => {
    const { HedaingName } = props;
    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };
    const { id } = useParams();

    const [errors, setErrors] = useState([]);
    const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
    const { getData, SelectUserData, postData } = useContext(Context);
    const [startDate, setStartDate] = useState(new Date());
    const [time, setTime] = useState(''); 
    const [editData, setEditData] = useState('');  
    const [sampleCollection, setSampleCollection] = useState([]);
    const [formData, setFormData] = useState({
        sample_collected_by: '',
        time: '',
        date: '',
        location: '',
        sample_collected:[],
        review_status:'',
    });

    const status = [
        { value:false, label: 'In-Review' },
        { value:true, label: 'Final' }
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    console.log(formData,"formData formData gggggggggggg");
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append('patient_id', props.patient.id);
                finalData.append('location', formData.location);
                finalData.append('time', formData.time);
                finalData.append('date', formData.date);
                finalData.append('sample_collected_by', formData.sample_collected_by);
                finalData.append('sample_collected',  JSON.stringify(formData.sample_collected));
                finalData.append('review_status', formData.review_status === false ? "0": "1");
                const result = await postData(
                    `/organisation/dashboard/apis/save-sample/`,
                    finalData
                );

                if (result?.success) {
                    await setFormData({
                        sample_collected_by: '',
                        patient_id: '',
                        date: '',
                        time: '',
                        review_status: '',
                        sample_collected:'',
                    });

                    await setErrors({});
                    setModalSuccessPatient(true);
                    setStartDate(null);
                    setTime('');
                    props.onHide();
                    props.getpatientData();
                  
                   
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    // const handleSelectChangeSample = (selectedOption) => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         sample_collected: selectedOption ? selectedOption.value : null,
    //     }));
    // };

    

    const geteditData = async () => {
        try {
            const apiUrl = `/organisation/dashboard/apis/sample-get/?sample_id=${props?.patient?.patient_sample_name?.id || ""}`;
            const res = await getData(apiUrl);
            
            if (res?.success) {
                // Assuming `res.success[0]` is the response containing your edit data
                const fetchedEditData = res.success[0];
    
                setEditData(fetchedEditData);
                
                const sampleCollectedValues = fetchedEditData?.sample_types?.map(sample => sample.sample_type.id);
              
                setFormData({
                    sample_collected_by: fetchedEditData?.sample_collected_by || '',
                    patient_id: fetchedEditData?.patient_id || '',
                    date: fetchedEditData?.date,
                    time: fetchedEditData?.time || '',
                    review_status: fetchedEditData?.review_status,
                    sample_collected: sampleCollectedValues,
                    location: fetchedEditData?.location || '',
                });
    
                console.log('Patient data fetched successfully:', fetchedEditData);
            } else {
                console.error('No results found or invalid response format.');
            }
        } catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };
    
    // useEffect to watch for changes in editData and update formData accordingly
    useEffect(() => {
        const sampleCollectedValues = editData?.sample_types?.map(sample => sample.sample_type.id);
        const editDate = editData?.date ? new Date(editData.date) : null;
        if (editData) {
            setFormData({
                sample_collected_by: editData?.sample_collected_by || '',
                patient_id: editData?.patient_id || '',
                date: editDate ? editDate.toLocaleDateString('en-GB') : '',
                time: editData?.time || '',
                review_status: editData?.review_status,
                review_status: editData?.review_status,
                sample_collected: sampleCollectedValues,
                location: editData?.location || '',
            });
        }
    }, [props.patient, id]); 
    
  
    
    
    useEffect(() => {
        geteditData();
    },[props.patient, id] );

    const handleSelectChangeSample = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData((prevData) => ({
            ...prevData,
            sample_collected: selectedValues,
        }));
    };

    const handleSelectChangestatus = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            review_status: selectedOption ? selectedOption.value : null,
        }));
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.location) {
            errors.location = "Location Required";
        }
        // if (!formData.date) {
        //     errors.date = "date is Required";
        // }
        if (!formData.time) {
            errors.time = "time is Required";
        }
        if (!formData.sample_collected_by) {
            errors.sample_collected_by = "sample collected by is Required";
        }
        return errors;
    };

    const getSampleCollection = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/sampleType/`);
            const data = await SelectUserData(response?.data, 'name', false);
            setSampleCollection(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getSampleCollection();
    }, []);

    useEffect(() => {
        let timer;
        if (modalSuccessPatient) {
            timer = setTimeout(() => {
                setModalSuccessPatient(false);
            }, 3000); // Modal will hide after 3 seconds
        }
        return () => clearTimeout(timer);
    }, [modalSuccessPatient]);

 
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='sample-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {HedaingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='home-collection mb-3'>
                        <FontAwesomeIcon icon="fa-solid fa-circle" className='circle-color me-1' /><span>Home collection</span>
                    </div>

                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                <div className='home-collection mb-3'>
                                    <span>Add location of collection: </span><FontAwesomeIcon icon="fa-solid fa-location-dot" className='ms-1' />
                                </div>
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    className="text"
                                    type="text"
                                    name="location"
                                    placeholder="Enter location"
                                    value={formData?.location}
                                    onChange={handleChange}
                                />
                                {errors?.location && <span style={errorStyle}>{errors?.location}</span>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Date collection:
                            </Form.Label>
                            <Col md={7}>
                                <DatePicker
                                    name='date'
                                    value={formData?.date}
                                    // selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setFormData({ ...formData, date: date.toLocaleDateString() });
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="DD/MM/YYYY"
                                />
                                {errors?.date && <span style={errorStyle}>{errors?.date}</span>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Time collection:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    name='time'
                                    type="time"
                                    value={formData?.time}
                                    onChange={(e) => {
                                        setTime(e.target.value);
                                        setFormData({ ...formData, time: e.target.value });
                                    }}
                                    placeholder=""
                                />
                                {errors?.time && <span style={errorStyle}>{errors?.time}</span>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Sample collected by:
                            </Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    className="text"
                                    type="text"
                                    name="sample_collected_by"
                                    placeholder="Enter Sample collected by"
                                    value={formData?.sample_collected_by}
                                    onChange={handleChange}
                                />
                                {errors?.sample_collected_by && <span style={errorStyle}>{errors?.sample_collected_by}</span>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column md={5}>
                                Sample type:
                            </Form.Label>
                            <Col md={7}>
                                <Select
                                    name='samplecollected'
                                    isMulti
                                    // value={sampleCollection.find(option => option.value === formData.sample_collected)}
                                    value={sampleCollection?.filter(option => formData?.sample_collected?.includes(option.value))}
                                    options={sampleCollection}
                                    onChange={handleSelectChangeSample}
                                />
                            </Col>
                        </Form.Group>
                        <Row className='mt-5'>
                            <Col lg={6}>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    <Form.Label className='status-label' column md={4}>
                                        Status:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Select
                                            name="status"
                                            placeholder="status"
                                            value={status.find(option => option.value === formData.review_status)}
                                            // value={status.find(option => option.value === (formData.review_status ? '1' : '0'))}
                                            onChange={handleSelectChangestatus}
                                            options={status}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Button className='confirm-btn' type="submit">Confirm</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <PatientSuceesfullModal
                textHeading="Patient Sample Submitted successfully"
                show={modalSuccessPatient}
                onHide={() => setModalSuccessPatient(false)}
            />
        </>
    );
};

export default SampleCollectionModal;
