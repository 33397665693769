// import React, { useContext, useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import { Form, Table } from 'react-bootstrap';
// import Select from 'react-select';
// import PatientSuceesfullModal from '../../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';
// import { useParams } from 'react-router-dom';
// import { Context } from '../../../../../../utils/context';

// const AssignTestResultModal = (props) => {
//     const { id } = useParams();
//     const { postData, getData } = useContext(Context);
//     const [data, setData] = useState([]);
//     const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
//     const [sampleData, setModalsampleData] = useState(false);
//     const [editData, setEditData] = useState([]);
//     const [errors, setErrors] = useState({});
//     const [formData, setFormData] = useState({
//         organization_id: id,
//         patient_id: props.patient?.id,
//         tests: []
//     });

//     useEffect(() => {
//         const patientTests = editData || [];
//         const formattedTests = patientTests.map(test => ({
//             test_id: test?.test_id,
//             sample_status: false,
//             parameters: test?.parameters.map(param => ({
//                 parameter_id: param.id,
//                 result: param.result,
//                 test_status: param.test_status,
//                 is_checked: param.is_checked,
//             }))
//         }));

//         setData(patientTests || []);
//         setFormData({
//             organization_id: id,
//             patient_id: props.patient?.id,
//             tests: formattedTests
//         });
//     }, [editData, id, props.patient]);

//     useEffect(() => {
//         let timer;
//         if (modalSuccessPatient) {
//             timer = setTimeout(() => {
//                 setModalSuccessPatient(false);
//             }, 3000);
//         }
//         return () => clearTimeout(timer);
//     }, [modalSuccessPatient]);

//     useEffect(() => {
//         let timer;
//         if (sampleData) {
//             timer = setTimeout(() => {
//                 setModalsampleData(false);
//             }, 3000);
//         }
//         return () => clearTimeout(timer);
//     }, [sampleData]);

//     const statusOptions = [
//         { value: false, label: 'In-Review' },
//         { value: true, label: 'Final' }
//     ];

//     const handleCheckboxChange = (testId, parameterId) => {
//         setFormData(prevState => ({
//             ...prevState,
//             tests: prevState.tests.map(test =>
//                 test.test_id === testId ? {
//                     ...test,
//                     parameters: test.parameters.map(param =>
//                         param.parameter_id === parameterId ? { ...param, is_checked: !param.is_checked } : param
//                     )
//                 } : test
//             )
//         }));
//     };

//     const handleResultChange = (testId, parameterId, value) => {
//         setFormData(prevState => ({
//             ...prevState,
//             tests: prevState.tests.map(test =>
//                 test.test_id === testId ? {
//                     ...test,
//                     parameters: test.parameters.map(param =>
//                         param.parameter_id === parameterId ? { ...param, result: value } : param
//                     )
//                 } : test
//             )
//         }));
//     };

//     const handleStatusChange = (testId, parameterId, selectedOption) => {
//         setFormData(prevState => ({
//             ...prevState,
//             tests: prevState.tests.map(test =>
//                 test.test_id === testId ? {
//                     ...test,
//                     parameters: test.parameters.map(param =>
//                         param.parameter_id === parameterId ? { ...param, test_status: selectedOption.value } : param
//                     )
//                 } : test
//             )
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (!props.patient?.patient_sample_name?.review_status) {
//             setModalsampleData(true);
//             props.onHide();
//             return;
//         }

//         let hasErrors = false;
//         const newErrors = {};
//         let isAnyRecordValid = false;
//         formData.tests.forEach(test => {
//             test.parameters.forEach(param => {
//                 if (param.is_checked && param.result && param.test_status) {
//                     isAnyRecordValid = true; // At least one record is valid
//                 } else if (param.is_checked && param.test_status === true && !param.result) {
//                     newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
//                     newErrors[`final`] = 'Selected Data Can Not Be Null';
//                     isAnyRecordValid = true;
//                     hasErrors = true;
//                 } else if (param.is_checked && !param.result) {
//                     newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
//                     newErrors[`final`] = 'Selected Data Can Not Be Null';
//                     isAnyRecordValid = true;
//                     hasErrors = true;
//                 } else if (param.test_status === true && !param.result) {
//                     newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
//                     newErrors[`final`] = 'Final Data Can Not Be Null';
//                     isAnyRecordValid = true;
//                     hasErrors = true;
//                 } else if (!param.is_checked && param.result) {
//                     newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
//                     newErrors[`final`] = 'Checked is Required';
//                     isAnyRecordValid = true;
//                     hasErrors = true;
//                 }
//             });
//         });

//         if (!isAnyRecordValid) {
//             newErrors[`record`] = 'At least one record must be selected and have a result';
//             hasErrors = true;
//         }

//         setErrors(newErrors);
//         if (hasErrors) return;

//         try {
//             const result = await postData('/organisation/dashboard/apis/save-params/', formData);
//             if (result?.success) {
//                 setModalSuccessPatient(true);
//                 props.onHide();
//                 props.getpatientData();
//             }
//         } catch (error) {
//             console.error("Error submitting data:", error);
//         }
//     };

//     const errorStyle = {
//         color: "red",
//         marginLeft: "5px",
//     };

//     return (
//         <>
//             <Modal
//                 {...props}
//                 size="lg"
//                 aria-labelledby="contained-modal-title-vcenter"
//                 centered
//                 className='sample-modal'
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title id="contained-modal-title-vcenter">
//                         {props.HedaingName}
//                     </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form onSubmit={handleSubmit}>
//                         <Table>
//                             <thead>
//                                 <tr>
//                                     <th>Test</th>
//                                     <th>Parameter</th>
//                                     <th>Result</th>
//                                     <th>Select</th>
//                                     <th>Status</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {formData.tests.map((test, index) => (
//                                     test.parameters.map((param, index2) => (
//                                         <tr key={`${test.test_id}-${param.parameter_id}`}>
//                                             {index2 === 0 && (
//                                                 <td rowSpan={test.parameters.length}>{test.test_name}</td>
//                                             )}
//                                             <td>{param.name}</td>
//                                             <td>
//                                                 <Form.Control
//                                                     type="text"
//                                                     placeholder="Enter result"
//                                                     value={param.result}
//                                                     onChange={(e) => handleResultChange(test.test_id, param.parameter_id, e.target.value)}
//                                                     isInvalid={!!errors[`${test.test_id}-${param.parameter_id}`]}
//                                                 />
//                                             </td>
//                                             <td>
//                                                 <Form.Check
//                                                     type="checkbox"
//                                                     checked={param.is_checked}
//                                                     onChange={() => handleCheckboxChange(test.test_id, param.parameter_id)}
//                                                     isInvalid={!!errors[`${test.test_id}-${param.parameter_id}`]}
//                                                 />
//                                             </td>
//                                             {index2 === 0 && (
//                                                 <td rowSpan={test.parameters.length}>
//                                                     <Select
//                                                         name="status"
//                                                         placeholder="Status"
//                                                         value={statusOptions.find(option => option.value === param.test_status)}
//                                                         onChange={(selectedOption) => handleStatusChange(test.test_id, param.parameter_id, selectedOption)}
//                                                         options={statusOptions}
//                                                     />
//                                                 </td>
//                                             )}
//                                         </tr>
//                                     ))
//                                 ))}
//                             </tbody>
//                         </Table>
//                         <span style={{ color: 'red' }}>{errors["final"]}</span>
//                         <span style={{ color: 'red' }}>{errors["record"]}</span>
//                         {errors?.tests && <span style={errorStyle}>{errors?.tests}</span>}
//                         <Modal.Footer>
//                             <Button className='confirm-btn' type="submit">Confirm</Button>
//                         </Modal.Footer>
//                     </Form>
//                 </Modal.Body>
//             </Modal>
//             <PatientSuceesfullModal
//                 textHeading={<span style={{ color: 'red' }}>Patient Sample Data Not Filled</span>}
//                 show={sampleData}
//                 onHide={() => setModalsampleData(false)}
//             />
//             <PatientSuceesfullModal
//                 textHeading="Patient Result Submitted successfully"
//                 show={modalSuccessPatient}
//                 onHide={() => setModalSuccessPatient(false)}
//             />
//         </>
//     );
// };

// export default AssignTestResultModal;


import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Table } from 'react-bootstrap';
import { Context } from '../../../../../../utils/context';
import Select from 'react-select';
import PatientSuceesfullModal from '../../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logDOM } from '@testing-library/react';

const AssignTestResultModal = (props) => {
    const { HedaingName, show, patient, getpatientData, onHide } = props;
    const { id } = useParams();

    const [data, setData] = useState([]);
    const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
    const [sampleData, setModalsampleData] = useState(false);
    const { postData, getData } = useContext(Context);
    const [editData, setEditData] = useState([]);
    const [sampleStatus, setSampleStatus] = useState("");
    const [errors, setErrors] = useState({});
    const [errorsBackend, setBackendErrors] = useState("");
    const [statusValue, setStateValue] = useState({});
    const [formData, setFormData] = useState({
        organization_id: id,
        patient_id: patient?.id,
        tests: [],
       
    });

    const statusOptions = [
        { value: false, label: 'In-Review' },
        { value: true, label: 'Final' }
    ];

    useEffect(() => {
        if (show) {
            geteditData();
        }
    }, [show]);

    useEffect(() => {
        const patientTests = patient?.patient_tests || [];
        const formattedTests = patientTests.map(test => ({
            test_id: test.test.id,
            sample_status: false,
            parameters: test.test.parameters.map(param => ({
                parameter_id: param.id,
                result: '',
                test_status: false,
                is_checked: false
            }))
        }));

        setData(patientTests || []);
        setFormData({
            organization_id: id,
            patient_id: patient?.id,
            tests: formattedTests
        });
    }, [patient, id]);

    const handleResultChange = (testId, parameterId, result) => {
        setFormData((prevData) => ({
            ...prevData,
            tests: prevData.tests.map(test =>
                test.test_id === testId
                    ? {
                        ...test,
                        parameters: test.parameters.map(param =>
                            param.parameter_id === parameterId
                                ? { ...param, result }
                                : param
                        )
                    }
                    : test
            )
        }));
    };

    // const handleStatusChange = (testId, parameterId, selectedOption) => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         tests: prevData.tests.map(test =>
    //             test.test_id === testId
    //                 ? {
    //                     ...test,
    //                     parameters: test.parameters.map(param =>
    //                         param.parameter_id === parameterId
    //                             ? { ...param, test_status: selectedOption ? selectedOption.value : '' }
    //                             : param
    //                     )
    //                 }
    //                 : test
    //         )
    //     }));
    // };

    const handleStatusChange = (testId, parameterId, selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            tests: prevData.tests.map(test =>
                test.test_id === testId
                    ? {
                        ...test,
                        parameters: test.parameters.map(param => ({
                            ...param,
                            test_status: selectedOption ? selectedOption.value : '',
                        }))
                    }
                    : test
            )
        }));
    };

    const handleCheckboxChange = (testId, parameterId) => {
        setFormData((prevData) => ({
            ...prevData,
            tests: prevData.tests.map(test =>
                test.test_id === testId
                    ? {
                        ...test,
                        parameters: test.parameters.map(param =>
                            param.parameter_id === parameterId
                                ? { ...param, is_checked: !param.is_checked }
                                : param
                        )
                    }
                    : test
            )
        }));
    };

    console.log(patient,"patient patient");
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!patient?.patient_sample_name?.review_status) {
            setModalsampleData(true);
            onHide();
            return;
        }
        if (patient?.patient_sample_name?.sample_status=="1" && formData?.sample_status=="1") {
            setModalsampleData(true);
            onHide();
            return;
        }


        let hasErrors = false;
        const newErrors = {};
        let isAnyRecordValid = false;
        formData.tests.forEach(test => {
            test.parameters.forEach(param => {
                if (param.is_checked && param.result &&  param.test_status) {
                    isAnyRecordValid = true; // At least one record is valid
                }else
                if (param.is_checked && param.test_status === true && !param.result) {
                    newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
                    newErrors[`final`] = 'Selected Data Can Not Be Null';
                    isAnyRecordValid = true;
                    hasErrors = true;
                }else  if (param.is_checked && !param.result) {
                    newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
                    newErrors[`final`] = 'Selected Data Can Not Be Null';
                    isAnyRecordValid = true;
                    hasErrors = true;
                }  
                else  if (param.test_status === true && !param.result) {
                    newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
                    newErrors[`final`] = 'Final Data Can Not Be Null';
                    isAnyRecordValid = true;
                    hasErrors = true;
                } else  if (!param.is_checked === true && param.result) {
                    newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
                    newErrors[`final`] = 'Checked is Required';
                    isAnyRecordValid = true;
                    hasErrors = true;
                } else  if (param.is_checked === true && param.result) {
                    // newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
                    // newErrors[`final`] = 'Final is Required';
                    isAnyRecordValid = true;
                    // hasErrors = true;
                } 
            });
        });
        if (!isAnyRecordValid) {
            newErrors[`record`] = 'At least one record must be selected and have a result';
            hasErrors = true;
        }

        setErrors(newErrors);
        if (hasErrors) return;

        const transformedData = {
            ...formData,
            sample_status: formData.sample_status ? "1" : "0", // Convert boolean to string "1" or "0"
          };
        try {
            const result = await postData('/organisation/dashboard/apis/save-params/', transformedData);
            if (result?.success) {
                setModalSuccessPatient(true);
                onHide();
                getpatientData();
                await setBackendErrors("")
            }
            else{
                
                await setBackendErrors(result?.data);
            }
        } catch (error) {
           
            console.error("Error submitting data:", error);
        }
    };

    useEffect(() => {
        let timer;
        if (modalSuccessPatient) {
            timer = setTimeout(() => {
                setModalSuccessPatient(false);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [modalSuccessPatient]);

    useEffect(() => {
        let timer;
        if (sampleData) {
            timer = setTimeout(() => {
                setModalsampleData(false);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [sampleData]);

    const geteditData = async () => {
        try {
            const apiUrl = `organisation/dashboard/apis/test-get/?patient_id=${patient?.id}`;
            const res = await getData(apiUrl);
           const sample = res?.sample_collected[0]?.sample_status
            console.log(sampleStatus,"sampleStatus sampleStatus");
            await setSampleStatus(sample)
            console.log(sampleStatus, "sampleStatus ''''''''''''''''''''");
            setEditData(res?.success);
            if (res?.success) {
                const fetchedEditData = res.success;
                const formattedData = fetchedEditData.map(item => ({
                    test_name:item.test_name,
                    test_id: item.test_id,
                    parameters: item.parameters.map(param => ({
                        name:param.name,
                        parameter_id: param.id,
                        result: param.result,
                        test_status: item.test_status,
                        is_checked: item.is_checked
                    })),
                    test_status: item.test_status
                }));
    
                setFormData({
                    organization_id: id,
                    patient_id: patient?.id,
                    // sample_status: sampleStatus,
                    sample_status: sample === "1" ? true : false,
                    tests: formattedData
                });
    
                console.log('Patient data fetched successfully:', fetchedEditData);
            } else {
                console.error('No results found or invalid response format.');
            }
        } catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };

    console.log(errors,"errors errors");
    const handleStatusCheckChange = (value) => {
       console.log(value,"value value");
        setFormData(prevFormData => ({
          ...prevFormData,
          sample_status: value
        }));
      };

      console.log(formData,"formData yyyyyyyyyyyyy");
    //   useEffect(() => {
    //     // Set initial state based on the sample_status from the API response
    //     setFormData({
    //       sample_status: sampleStatus === "1" ? true : false,
    //     });
    //   }, [sampleStatus]);
    // console.log(sampleStatus,"sampleStatus");
     
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='sample-modal'
                show={show}
                onHide={onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {HedaingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                    <div className='founder-text'>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Found/Not Fount (sample)"
                        checked={formData?.sample_status}
                        onChange={(e) => handleStatusCheckChange(e.target.checked ? 1 : 0)}
                        />
                                </div>
                        <Table >
                            <thead>
                                <tr>
                                    <th>Test</th>
                                    <th>Parameter</th>
                                    <th>Result</th>
                                    <th>Select</th>
                                    <th>Status:</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData?.tests?.map((test,index) => (
                                    test?.parameters.map((param,index2) => (
                                        <tr >
                                             {index2 === 0 && (
                                            <td rowSpan={test?.parameters.length}>{test?.test_name || ''}</td> 
                                        )}
                                            {/* <td>{editData?.find(item => item.test.id === test.test_id)?.test.name || ''}</td> */}
                                            <>
                                            <td>{editData?.find(item => item.parameters.id === param.parameter_id)?.parameters.name || ''}</td>
                                           
                                             <td>
                                                <div className='row'>
                                                    <div className='col-md-9'>
                                                    <Form.Control
                                                    type="text"
                                                    placeholder="Enter result"
                                                    value={param.result}
                                                    onChange={(e) => handleResultChange(test.test_id, param.parameter_id, e.target.value)}
                                                    isInvalid={!!errors[`${test.test_id}-${param.parameter_id}`]}
                                                />
                                               
                                                </div>
                                               
                                                <div className='col-md-3'>
                                                <span >{editData?.find(item => item.parameters.id === param.parameter_id)?.parameters.unit || ''}</span>
                                                </div>
                                                </div>
                                            </td>
                                            
                                            <td>
                                                <Form.Group className="mb-3" id="formGridCheckbox">
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        checked={param.is_checked}
                                                        onChange={() => handleCheckboxChange(test.test_id, param.parameter_id)}
                                                    />
                                                </Form.Group>
                                            </td>
                                            </>
                                            {index2 === 0 && (

                                            <td rowSpan={test.parameters.length}>
                                                <Select
                                                    name="status"
                                                    placeholder="Status"
                                                    value={statusOptions.find(option => option.value === param.test_status)}
                                                    onChange={(selectedOption) => handleStatusChange(test.test_id, param.parameter_id, selectedOption)}
                                                    options={statusOptions}
                                                />
                                            </td>
                                             )}
                                        </tr>
                                    ))
                                ))}
                            </tbody>
                        </Table>
                        {/* {errors[`final`]} */}
                        <span style={{ color: 'red' }}>{errors["final"]}</span>
                        <span style={{ color: 'red' }}>{errors["record"]}</span>
                        {/* <span style={{ color: 'red' }}>{errorsBackend}</span> */}
                        {errorsBackend && <div className="error-message" style={{ color: 'red' }}>{errorsBackend}</div>}
                        <Modal.Footer>
                            <Button className='confirm-btn' type="submit" >Confirm</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
            <PatientSuceesfullModal
                // textHeading="Patient Sample Data Not Filled"
                textHeading={<span style={{ color: 'red' }}>Patient Sample Data Not Filled</span>}
                show={sampleData}
                onHide={() => setModalsampleData(false)}
            />
            <PatientSuceesfullModal
                textHeading="Patient Result updated successfully"
                show={modalSuccessPatient}
                onHide={() => setModalSuccessPatient(false)}
            />
        </>
    );
};

export default AssignTestResultModal;


