import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { Link, useParams } from "react-router-dom";
import "./HospitalSlot.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Input } from "@mui/material";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
import Select from "react-select";
import Slots from "./Slots";
library.add(fas);

function HospitalSlot() {
  const { docId, id } = useParams();

  const { getData, postData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);


  const [hospitalslot, setHospitalSlot] = useState();
  const [count, setCount] = useState(0);

  const getHospitalslot = async () => {
    const res = await getData(`organisation/dashboard/apis/doctor-list-api-with/?id=${id}`);
    if (res?.success) {
      setHospitalSlot(res.data);
    }
  }

  const [hospitaltotalslot, setHospitalTotalSlot] = useState();
  const getHospitalTotalSlot = async (page) => {
    var res;
      if(page){
         res = await getData(`slot_booking/dashboard/apis/get-doctor-booking/?${page}`);
      }else{
         res = await getData(`slot_booking/dashboard/apis/get-doctor-booking/?hospital_name=${id}&doctor_id=${docId}`);
      }

     
      setCount(res.count)
    // if (res?.success) {
      setHospitalTotalSlot(res);
 

    // }
  }


  const getDoctor = async () => {
    const res = await getData(`slot_booking/dashboard/apis/get-doctor-id/?id=${docId}`);
    if (res?.success) {
      setHospitalSlot(res.data);
    }
  }


  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date1) => {
    const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;

    // Update formData state with the formatted date
    setFormData({ ...formData, date: formattedDate });
  }



  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);

  const handleClose = async () => {
    await setFormData({
      start_time: "",
      end_time: "",
      doctor_id: docId,
      date: "",
    });
    await setShow(false);
  }

  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [expandedRows, setExpandedRows] = useState([]);


  const toggleRow = (index) => {
    const updatedExpandedRows = [...expandedRows];
    updatedExpandedRows[index] = !updatedExpandedRows[index];
    setExpandedRows(updatedExpandedRows);
  };


  const [formData, setFormData] = useState({
    start_time: "",
    end_time: "",
    doctor_id: docId,
    date: "",
  });
  const [formEditData, setFormEditData] = useState({
    id:"",
    start_time: "",
    end_time: "",
    doctor_id: docId,
    date: "",
    is_completed : "",
  });
  const handleChange = async (e) => {
    if (
      e?.name === "is_completed"
    ) {
      setFormEditData({ ...formEditData, [e?.name]: e });
    } else {
      setFormEditData({ ...formEditData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
 
  const validateEditForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formEditData.start_time) {
      errors.start_time = "Start Time is required";
    }
    if (!formEditData.end_time) {
      errors.end_time = "End Time is required";
    }
    if (!formEditData.date) {
      errors.date = "Date is required";
    }
    else {
      const currentDate = new Date(); // Current date and time
      const enteredDate = new Date(formData?.date);
    
      // Set hours, minutes, seconds, and milliseconds to 0 for both dates
      currentDate.setHours(0, 0, 0, 0);
      enteredDate.setHours(0, 0, 0, 0);
    
      if (enteredDate < currentDate) {
        errors.date = "Date cannot be in the past";
      }
    }
    // else {
    //   const currentDate = new Date();
    //   const enteredDate = new Date(formEditData?.date);

    //   if (enteredDate < currentDate) {
    //     errors.date = "Date cannot be in the past";
    //   }
    // }
    if (!formEditData.is_completed) {
      errors.is_completed = "Status is required";
    }
    return errors;
  };

  
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateEditForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(formEditData);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`is_completed`, formEditData.is_completed?.value);

        const result = await editPatchData(
          `slot_booking/dashboard/apis/doctor-status/${formEditData.id}/`,
          finalData
        );
       

        if (result?.success) {
          await setFormEditData({
            id:"",
            start_time: "",
            end_time: "",
            doctor_id: docId,
            date:"",
            is_completed:"",
          });

          await setErrors();
          handleClose2();
          getHospitalTotalSlot();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEdit = async (index, action) => {
    console.log(hospitaltotalslot?.results[index]);
    await setFormEditData({
      id: hospitaltotalslot?.results[index]?.id,
      start_time: hospitaltotalslot?.results[index]?.booking_details?.start_time,
      end_time: hospitaltotalslot?.results[index]?.booking_details?.end_time,
      date: new Date(hospitaltotalslot?.results[index]?.booking_details?.date).toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      is_completed:{value:hospitaltotalslot?.results[index]?.is_completed?.id, label:hospitaltotalslot?.results[index]?.is_completed?.name,name:"is_completed"},
    });


    if (action === "edit") {
      handleShow2();
    }

  }
    const [status, setStatus] = useState();
  const getStatus = async () => {
    const res = await getData(`slot_booking/dashboard/apis/get-status/`);
    if(res?.success){
      setStatus(res.data);
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "is_completed",
        }));
        setStatus(result);
      }
    }
  }

  const [slotCount, setSlotCount] = useState();
  const slotCountFunction = async (page) => {
    var res = await getData(`slot_booking/dashboard/apis/add-slot/?doctor_id=${docId}`);
     

      setSlotCount(res?.count);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    slotCountFunction();
    getHospitalTotalSlot();
    getHospitalslot();
    getDoctor();
    getStatus();
  }, []);
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          <section className="hospital-details">
            <div className="row me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="top-section">
                  <div className="img-holder">
                    <img
                      className="details-img"
                      src={
                        imageUrl +
                        hospitalslot?.organisation?.icon
                      }
                      alt="hospitaldetails-img"
                    />
                  </div>

                  <div className="small-img">
                    <img
                      className="small-hospital-img"
                      src={
                        imageUrl +
                        hospitalslot?.image
                      }
                      alt="hospital-img"
                    />
                  </div>
                </div>

                <div className="hospital-info">
                  <div className="info-details">
                    <div className="hospital-name ">
                      <h4 className="text info-style">{hospitalslot?.name}</h4>
                    </div>

                    <div className="info ">
                      <div className="text info-style slot-info">
                        <p className="info-title">User</p>
                      </div>

                      <div className="text slot-info">
                        <p className="data-line">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-phone"
                          />
                          {hospitalslot?.mobile_number}
                        </p>
                      </div>

                      <div className="text slot-info">
                        <p>
                          {/* <strong>Category:</strong> */}
                        </p>
                        {/* <p className="data-line">{hospitalslot?.doc_category}</p> */}
                      </div>

                      {/* <div className="text slot-info">s
                          <p className="data-line">
                            <strong>Sub Category:</strong>
                          </p>
                          <p>DENTIST</p>
                        </div> */}
                    </div>
                  </div>
                </div>

                <div className="description slot-details">
                  <div className="slot-counts ">
                    <strong className="text">{hospitalslot?.experience}</strong>
                    <p className="text">Experience</p>
                  </div>

                  <div className="slot-counts">
                    <strong className="text">{hospitalslot?.price}</strong>
                    <p className="text">Price</p>
                  </div>

                  <div className="slot-counts">
                    <strong className="text ">{slotCount}</strong>
                    <p className="text">Slots</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* all text data table start */}
                        <Slots slotCountFunction={slotCountFunction}/>
          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">Total Bookings</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search By Booking ID:{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    {/* <th>
                      <span className="text table-data-heading"></span>
                    </th> */}
                    <th>
                      <span className="text table-data-heading">
                        Patient Name
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Mobile Number
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Booking ID
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Date
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Start's At
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">End's At</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">PDF</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Booking Status
                      </span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">Records</span>
                    </th> */}
                    <th>
                      <span className="text table-data-heading">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  

                  {expandedRows[0] && (
                    <tr>
                      <td colSpan="7">
                        {/* Content to show below the row when expanded */}
                        <div className="expanded-content">
                          {/* Your expanded content goes here */}
                          <p>This is the expanded content for the first row.</p>
                        </div>
                      </td>
                    </tr>
                  )}


                  {hospitaltotalslot?.results?.map((value, index) => (
                    <tr>
                      {/* <td>
                        <span className="text text-size data-line">
                          <FontAwesomeIcon
                            className={`text ${expandedRows[1] ? "fa-angle-down" : "fa-angle-right"
                              }`}
                            icon="fa-solid fa-angle-right"
                            onClick={() => toggleRow(1)}
                          />
                        </span>
                      </td> */}
                      <td>
                        <p className="text text-size data-line ">
                          {value?.patient_name}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line ">
                          {value?.mobile_number}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line ">
                          {value?.id}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line">
                          {value?.booking_details?.date}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line">
                        {value?.booking_details?.start_time}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line">
                        {value?.booking_details?.end_time}
                        </p>
                      </td>
                      <td >
                    <div class="user-name-holder">
                      <p className="text text-size mb-0 data-line ">
                        {value?.pdf !== null && (
                          <a href={imageUrl + '/media'+value?.pdf.split('media')[2]} target="_blank">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-download"
                              style={{ cursor: 'pointer' }}

                            />
                          </a>
                        )}
                      </p>
                    </div>
                  </td>
                      {/* <td>
                        <p className="text text-size data-line  pending">
                          {value.name}
                        </p>
                      </td> */}
                      <td>
                        <p className="text text-size data-line">{value?.is_completed?.name}</p>
                      </td>
                      <td>
                        <div className="action">
                          <Button className="action-btn " onClick={handleShow2}>
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                              onClick={() => handleEdit(index,"edit")}
                            />
                          </Button>
                          {/* <Button
                            variant="outline-success"
                            className="search btn-style btn-size text bg-green"
                          >
                            Add Records
                          </Button> */}
                        </div>
                      </td>
                    </tr>
                  ))}

                  {expandedRows[1] && (
                    <tr>
                      <td colSpan="7">
                        {/* Content to show below the row when expanded */}
                        <div className="expanded-content">
                          {/* Your expanded content goes here */}
                          <p>This is the expanded content for the first row.</p>
                        </div>
                      </td>
                    </tr>
                  )}




                </tbody>
              </Table>

            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                {hospitaltotalslot?.previous && (
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0"  onClick={() => getHospitalTotalSlot( hospitaltotalslot?.previous.split("?")[1])}>
                      <span aria-hidden="true">‹</span>
                      <span class="visually-hidden">Previous</span>
                    </a>
                  </li>
                )}
                  {[...Array( hospitaltotalslot?.count && Math.ceil(hospitaltotalslot?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getHospitalTotalSlot(`hospital_name=${id}&doctor_id=${docId}&page=`+(index+1))}>
                          {index+1}
                        </a>
                      </li>
                    );
                  })}
                    {hospitaltotalslot?.next && (
                      <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" onClick={() => getHospitalTotalSlot( hospitaltotalslot?.next.split("?")[1])}>
                      <span aria-hidden="true">›</span>
                      <span class="visually-hidden">Next</span>
                    </a>
                  </li>
                    )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* all text data table end */}
        

          {/* edit slot  modal start */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Edit Slot</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                // controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Date</Form.Label>
                  <DatePicker
                    className="date-input-field"
                    selected={formEditData?.date ? new Date(formEditData?.date) : null}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="yyyy-mm-dd"
                    shouldCloseOnSelect={true}
                    disabled={true}
                  />
                  {errors?.date && (
                    <span style={errorStyle}>{errors?.date}</span>
                  )}
                </Form.Group>


                {/* Add Start Time */}
                <Form.Group className="mb-3 text">
                  <Form.Label className="text">Start Time</Form.Label>
                  <input
                    type="time"
                    className="form-control"
                    value={formEditData?.start_time}
                    onChange={(e) => setFormEditData({ ...formEditData, start_time: e.target.value })}
                    
                    disabled={true}
                  />
                  {errors?.start_time && (
                    <span style={errorStyle}>{errors?.start_time}</span>
                  )}
                </Form.Group>

                {/* Add End Time */}
                <Form.Group className="mb-3 text">
                  <Form.Label className="text">End Time</Form.Label>
                  <input
                    type="time"
                    className="form-control"
                    value={formEditData?.end_time}
                    onChange={(e) => setFormEditData({ ...formEditData, end_time: e.target.value })}
                    
                    disabled={true}
                  />
                  {errors?.end_time && (
                    <span style={errorStyle}>{errors?.end_time}</span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 text">
                    <Form.Label className="text">Status</Form.Label>
                    <Select
                      placeholder="Select Status"
                      value={formEditData?.is_completed}
                      onChange={handleChange}
                      options={status}
                      isSearchable={true}
                      id="status"
                    />
                    {errors?.category_id && (
                      <span style={errorStyle}>{errors?.category_id}</span>
                    )}
                  </Form.Group>


              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Close
              </Button>
              <Button variant="success" onClick={handleEditSubmit}>
                Edit
              </Button>
            </Modal.Footer>
          </Modal>
          {/* edit slot  modal end */}
        </div>
      </div >
    </>
  );
}

export default HospitalSlot;
