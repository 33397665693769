import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Table from "react-bootstrap/Table";
import "./PathologyCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import Select from "react-select";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
library.add(fas);

function Tests() {
  const [selectedDate, setSelectedDate] = useState(null);
  const { id } = useParams();
  const handleDateChange = (date1) => {
    const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;

    // Update formData state with the formatted date
    setFormData({ ...formData, date: formattedDate });
  }

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = async () => {
    await setFormData({
      id: "",
      test: "",
      organisation_id: id,
      parameters: "",
    });

    await setErrors();
    setShow(false);
    setOpdEdit(false);
  }
  const handleShow = () => setShow(true);

  const handleClose2 = async () => {
    setShow2(false);
    await setFormData({
      id: "",
      test: "",
      organisation_id: id,
      parameters: "",
    });

    await setErrors();
    setOpdEdit(false);
  }
  const handleShow2 = () => setShow2(true);

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const { getData, postData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);

  const [formData, setFormData] = useState({
    id: "",
    test: "",
    organisation_id: id,
    parameters: "",
  });

  const handleChange = async (e) => {
    if (
      e?.name === "test"
    ) {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    }
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    // if (!formData.name) {
    //   errors.name = "Name is required";
    // }
    // if (!formData.description) {
    //   errors.description = "Description is required";
    // }
    // if (!formData.price) {
    //   errors.price = "Price is required";
    // }
    // if (!formData.discounted_price) {
    //   errors.discounted_price = "Discounted Price is required";
    // }

    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    console.log(validationErrors);
    console.log(formData, 'llllllll');
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`organisation_id`, formData.organisation_id);
        finalData.append(`test`, formData.test);
        finalData.append(`parameters`, JSON.stringify(formData.parameters));


        const result = await postData(
          "organisation/dashboard/apis/parameter-test/",
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            test: "",
            organisation_id: id,
            parameters: "",
          });

          await setErrors();
          handleClose();
          getTests();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`organisation_id `, formData.organisation_id);

        const result = await editPatchData(
          `organisation/dashboard/apis/opd-edit-api/${formData.id}/`,
          finalData
        );

        if (result?.success) {
          await setFormData({
            id: "",
            test: "",
            organisation_id: id,
            parameters: "",
          });

          await setErrors();
          handleClose();
          getTests();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [testList, setTestList] = useState();
  const [opdEdit, setOpdEdit] = useState(false);
  const getTests = async (page) => {
    var res;
    if (page) {
      res = await getData(`organisation/dashboard/apis/parameter-test/?${page}`);
    } else {
      res = await getData(`organisation/dashboard/apis/parameter-test/?organisation_id=${id}`);
    }

    console.log(res);
    setTestList(res);
    if (res?.success) {

    }
  }

  const handleEdit = async (index, action) => {
    console.log(testList?.results[index]);
    await setFormData({
      id: testList?.results[index]?.id,
      name: testList?.results[index]?.name,
      description: testList?.results[index]?.description,
      price: testList?.results[index]?.price,
    });


    if (action === "edit") {
      handleShow();
      setOpdEdit(true);
    } else {
      handleShow2();
    }

  }

  const handleDelete = async (id) => {
    const res = await deleteData(`organisation/dashboard/apis/opd-edit-api/${id}/`);
    await getTests();
  }
  const [tests, setTest] = useState();
  const [paramterType, setParameterType] = useState();
  const getMaster = async () => {
    {
      const res = await getData(`organisation/dashboard/apis/test-list-without-api/?organisation_id=${id}`);
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "test",
        }));
        setTest(result);
      }
    }
    {
      const res = await getData(`organisation/dashboard/apis/parameters-type-api/`);
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "parameter_type",
        }));
        setParameterType(result);
      }
    }
  };



  useEffect(() => {
    window.scrollTo(0, 0);
    getTests();
    getMaster();
  }, []);

  const handleParameterForm = async () => {
    await setFormData({ ...formData, parameters: parameters });
  }


  const [parameters, setParameters] = useState([
    { name: '', type_value: '', type: '', reference: [{ age: 'adult', sex: 'M', minValue: '', maxValue: '', lessThanValue: '', moreThanValue: '' }] },
  ]);

  const handleAddParameter = () => {
    setParameters([...parameters, { name: '', type_value: '', type: '', reference: [{ age: 'adult', sex: 'M', minValue: '', maxValue: '', lessThanValue: '', moreThanValue: '' }] }]);
  };

  const handleRemoveParameter = (index) => {
    const updatedParameters = [...parameters];
    updatedParameters.splice(index, 1);
    setParameters(updatedParameters);
  };

  const handleAddReference = (paramIndex) => {
    const updatedParameters = [...parameters];
    updatedParameters[paramIndex].reference.push({ age: 'adult', sex: 'M', minValue: '', maxValue: '', lessThanValue: '', moreThanValue: '' });
    setParameters(updatedParameters);
  };

  const handleRemoveReference = (paramIndex, refIndex) => {
    const updatedParameters = [...parameters];
    updatedParameters[paramIndex].reference.splice(refIndex, 1);
    setParameters(updatedParameters);
  };

  const handleParameterChange = (index, key, value) => {
    const updatedParameters = [...parameters];
    updatedParameters[index][key] = value;
    setParameters(updatedParameters);
  };

  const handleReferenceChange = (paramIndex, refIndex, attribute, value, counterpartValue) => {
    const updatedParameters = [...parameters];
    const currentReference = updatedParameters[paramIndex].reference[refIndex];

    // Update the attribute
    currentReference[attribute] = value;


    setParameters(updatedParameters);
  };

  console.log(parameters);

  useEffect(() => {
    handleParameterForm();
  }, [parameters]);
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* doctor data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">Tests</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search:{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                <Button
                  variant="success"
                  onClick={handleShow}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Tests
                </Button>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">Test Name</span>
                    </th>

                    <th>
                      <span className="text table-data-heading">
                        Parameters
                      </span>
                    </th>


                    <th>
                      <span className="text table-data-heading">Parameter Type</span>
                    </th>

                    <th>
                      <span className="text table-data-heading">Age Group</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Sex</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Min Value</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Max Value</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Less Than Value</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">More Than Value</span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">Actions</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {testList?.results?.map((value, index) => (
                    <>
                      {value?.parameters?.map((param, paramIndex) => (
                        <>
                          {param?.reference?.map((ref, refIndex) => (
                            <tr key={`${index}-${paramIndex}`}>
                              {paramIndex === 0 && refIndex === 0 && (
                                <td rowSpan={param?.reference.length}>
                                  <div className="user-name-holder">
                                    <p className="text text-size mb-0 data-line">{value?.name}</p>
                                  </div>
                                </td>
                              )}
                              {paramIndex !== 0 && refIndex === 0 && (
                                <td rowSpan={param?.reference.length}>
                                  <div className="user-name-holder">
                                    <p className="text text-size mb-0 data-line"></p>
                                  </div>
                                </td>
                              )}


                              {refIndex === 0 && (
                                <>
                                  <td rowSpan={param?.reference.length}>
                                    <div className="user-name-holder">
                                      <p className="text text-size mb-0 data-line">{param?.name}</p>
                                    </div>
                                  </td>
                                  <td rowSpan={param?.reference.length}>
                                    <div className="user-name-holder">
                                      <p className="text text-size mb-0 data-line">{param?.parameter_type?.name}</p>
                                    </div>
                                  </td>
                                </>
                              )}

                              {/* Additional rows for other columns based on your requirements */}
                              <td>
                                <div className="user-name-holder">
                                  <p className="text text-size mb-0 data-line">{ref?.age_group}</p>
                                </div>
                              </td>

                              <td>
                                <div className="user-name-holder">
                                  <p className="text text-size mb-0 data-line">{ref?.sex}</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-name-holder">
                                  <p className="text text-size mb-0 data-line">{ref?.min_value}</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-name-holder">
                                  <p className="text text-size mb-0 data-line">{ref?.max_value}</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-name-holder">
                                  <p className="text text-size mb-0 data-line">{ref?.less_than_value}</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-name-holder">
                                  <p className="text text-size mb-0 data-line">{ref?.more_than_value}</p>
                                </div>
                              </td>

                              <td>
                                <div className="action">

                                  {/* <Button className="action-btn">
                                    <FontAwesomeIcon className="text" icon="fa-solid fa-eye" />
                                  </Button> */}

                                  {/* <Button className="action-btn ">
                                    <FontAwesomeIcon className="text" icon="fa-solid fa-pen-to-square" />
                                  </Button> */}
                                  {/* <Button className="action-btn">
                                    <FontAwesomeIcon className="text" icon="fa-solid fa-trash" />
                                  </Button> */}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </>
                  ))}



                </tbody>
              </Table>
            </div>
          </section>

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  {testList?.previous && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getTests(testList?.previous.split("?")[1])}>
                        <span aria-hidden="true">‹</span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[...Array(testList?.count && Math.ceil(testList?.count / 10))].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a className="page-link" role="button" tabIndex="0" onClick={() => getTests(`organisation_id=${id}&page=` + (index + 1))}>
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                  {testList?.next && (
                    <li class="page-item">
                      <a class="page-link" role="button" tabindex="0" onClick={() => getTests(testList?.next.split("?")[1])}>
                        <span aria-hidden="true">›</span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </li>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* doctor data table end */}

          {/* add Visitor  modal start */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Pathology </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Test Name</Form.Label>
                  <Form.Control
                    className="text"
                    type="text"
                    name="test"
                    placeholder="Enter Test Name"
                    value={formData?.test}
                    onChange={handleChange}
                  />
                  {errors?.test && (
                    <span style={errorStyle}>{errors?.test}</span>
                  )}
                </Form.Group>
                {parameters.map((param, paramIndex) => (
                  <div key={paramIndex}>
                    <Form.Group className="mb-3 text">
                      <Form.Label className="text">Parameter</Form.Label>
                      <Form.Control
                        className="text"
                        type="text"
                        placeholder="Enter Parameter"
                        value={param.name}
                        onChange={(e) => handleParameterChange(paramIndex, 'name', e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 text">
                      <Form.Label className="text">Parameter Type</Form.Label>

                      <Select
                        placeholder="Select Parameter Type"
                        className="select-line"
                        value={formData?.type_value}
                        onChange={(e) => { handleParameterChange(paramIndex, 'type', e.value); handleParameterChange(paramIndex, 'type_value', e); }}
                        options={paramterType}
                      />

                    </Form.Group>
                    {param?.reference?.map((ref, refIndex) => (
                      <div key={refIndex}>
                        <Form.Group className="mb-2 text">
                          <Form.Label className="text">Age Group</Form.Label>
                          {["Adult", "Child", "Newborn"].map((value, radioIndex) => (
                            <div key={radioIndex} className="mb-2">
                              <Form.Check
                                type="radio"
                                label={value}
                                name={`referenceRadio_${paramIndex}_${refIndex}_age`}
                                value={value}
                                checked={ref?.age === value}
                                onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'age', e.target.value)}
                              />
                            </div>
                          ))}
                        </Form.Group>

                        <Form.Group className="mb-2 text">
                          <Form.Label className="text">Sex</Form.Label>
                          {["M", "F", "B"].map((value, radioIndex) => (
                            <div key={radioIndex} className="mb-2">
                              <Form.Check
                                type="radio"
                                label={value}
                                name={`referenceRadio_${paramIndex}_${refIndex}_sex`}
                                value={value}
                                checked={ref?.sex === value}
                                onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'sex', e.target.value)}
                              />
                            </div>
                          ))}
                        </Form.Group>
                        <Form.Group className="mb-2 text">
                          <Form.Label className="text">Max Value</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Maximum Range"

                            onKeyPress={handleKeyPressContact}

                            value={ref?.maxValue}
                            onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'maxValue', e.target.value, ref?.minValue)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-2 text">
                          <Form.Label className="text">Min Value</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Minimum Range"
                            step="any"

                            value={ref?.minValue}
                            onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'minValue', e.target.value, ref?.maxValue)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-2 text">
                          <Form.Label className="text">More Than Value</Form.Label>
                          <Form.Control
                            type="number"

                            placeholder="More Than Value"
                            step="any"
                            value={ref?.moreThanValue}
                            onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'moreThanValue', e.target.value, ref?.moreThanValue)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-2 text">
                          <Form.Label className="text">Less Than Value</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Less Than Value"
                            step="any"

                            value={ref?.lessThanValue}
                            onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'lessThanValue', e.target.value, ref?.lessThanValue)}
                          />
                        </Form.Group>


                        {refIndex !== 0 && (
                          <Button variant="danger" onClick={() => handleRemoveReference(paramIndex, refIndex)}>
                            Remove Reference
                          </Button>
                        )}
                      </div>
                    ))}
                    <Button variant="info" onClick={() => handleAddReference(paramIndex)}>
                      Add Reference
                    </Button>
                    <br />
                    {paramIndex !== 0 && (
                      <Button className="mt-3" variant="danger" onClick={() => handleRemoveParameter(paramIndex)}>
                        Remove Parameter
                      </Button>
                    )}
                  </div>
                ))}
                <Button className="mt-3" variant="primary" onClick={handleAddParameter}>
                  Add Parameter
                </Button>



              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" onClick={handleSubmit}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
          {/* add Visitor  modal end */}

          {/* details  modal start */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Pathology Details</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="Details-card">
                <Card>
                  <Card.Body>
                    <div className="visitor-patient-card">
                      <div className="row me-0 ms-0">
                        <div className="col-md-12">
                          <div className="details-card">
                            <Card.Title>
                              <h4 className="text ">Test Details</h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="details">
                                <div className="visitor-name ">
                                  <FontAwesomeIcon
                                    className="text me-2"
                                    icon="fa-solid fa-vial"
                                  />

                                  <p className="text mb-0">Blood Test</p>
                                </div>

                                <p className="text">
                                  <b>Description</b>
                                </p>

                                <p className="text ">
                                  Lorem ipsum dolor, sit amet consectetur
                                  adipisicing elit. Debitis, et aperiam
                                  cupiditate culpa ad perferendis modi
                                  accusamus, porro facere eaque eos delectus,
                                  illo quis nostrum voluptatem numquam ut.
                                  Explicabo, tempora.
                                </p>

                                <p className="text ">
                                  <b>Price:- </b>200.00
                                </p>

                                <p className="text ">
                                  <b>Date:- </b>12-01-2024{" "}
                                </p>
                              </div>
                            </Card.Text>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Close
              </Button>
              <Button variant="success" onClick={handleClose2}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
          {/* details  modal end */}
        </div>
      </div>
    </>
  );
}

export default Tests;
