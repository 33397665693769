import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "./HospitalsDetails.css";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
library.add(fas);

function HospitalsDetails() {


  const { id } = useParams();
  const { getData, postData, imageUrl, Select2Data, orgID, setOrgId, indexDetails, setIndexDetail } = useContext(Context);




  

  const [hospitals, setHospital] = useState();
  const getHospital = async () => {
   
    const res =await getData(`organisation/dashboard/apis/single-org/?organisation_id=${id}`);
    if(res?.success){
      setHospital(res?.data);
    }
     
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    getHospital();
  }, []);
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          <section className="hospital-details">
            <div className="row me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="top-section">
                  <div className="img-holder">
                    <img
                      className="details-img"
                      src={
                        imageUrl +
                        hospitals?.image
                      }
                      alt="hospitaldetails-img"
                    />
                  </div>

                  <div className="small-img">
                    <img
                      className="small-hospital-img"
                      src={
                        imageUrl +
                        hospitals?.icon
                      }
                      alt="hospital-img"
                    />
                  </div>
                </div>

                <div className="hospital-info">
                  <div className="info-details">
                    <div className="hospital-name">
                      <h4 className="text info-style data-line">{hospitals?.title}</h4>
                    </div>

                    <div className="info ">
                      <div className="text slot-info">
                        <p className="info-title data-line">{hospitals?.org_type}</p>
                      </div>

                      <div className="text slot-info">
                        <p className="data-line">
                          {" "}
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-phone"
                          />
                          {hospitals?.phone_number}
                        </p>
                      </div>

                      <div className="text slot-info">
                        <p className="data-line" >
                          {" "}
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-location-dot"
                          />
                          <strong>State:</strong>
                        </p>
                        <p className="data-line">{hospitals?.state?.title}</p>
                      </div>

                      <div className="text slot-info">
                        <p className="data-line">
                          <strong>City:</strong>
                        </p>
                        <p className="data-line">{hospitals?.city?.title}</p>
                      </div>

                      <div className="text slot-info">
                        <span className="">
                          <p className="data-line">
                            <strong>Country:</strong>
                          </p>
                          <p className="data-line">{hospitals?.country?.title}</p>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="hospital-rating ">
                  {[...Array(hospitals?.rating && (parseInt(hospitals?.rating)))]?.map((index) => (
                                        <FontAwesomeIcon
                                          className="yellow"
                                          icon="fa-solid fa-star"
                                        />
                                      ))}
                                      {[...Array(hospitals?.rating && (5 - parseInt(hospitals?.rating)))]?.map((index) => (
                                        <FontAwesomeIcon
                                          className="grey"
                                          icon="fa-solid fa-star"
                                        />
                                      ))}
                  </div>
                </div>

                <div className="description">
                  <strong className="text">Address 1:</strong>
                  <p className="text">{hospitals?.address1}</p>
                  <strong className="text">Address 2:</strong>
                  <p className="text">{hospitals?.address2}</p>
                  <strong className="text mt-2">Description: </strong>
                  <p className="text">{hospitals?.description}</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default HospitalsDetails;
