import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "./Doctor.css";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { useContext } from "react";
import { Context } from "../../../utils/context";
import { formatDate } from "canvasjs-react-charts/canvasjs.min";
import { editPatchData } from "../../../utils/api";
library.add(fas);

function Doctor() {
  const { id } = useParams();
  const {
    getData,
    postData,
    imageUrl,
    Select2Data,
    orgID,
    setOrgId,
    indexDetails,
    setIndexDetail,
    deleteData,
  } = useContext(Context);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);

  const handleClose = async () => {
    await setDoctorData({
      organisation_id: id,
      id: "",
      name: "",
      phone_number: "",
      price: "",
      experience: "",
      about: "",
    });
    await setErrors();
    setImagePreview(false);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setOpdEdit(false);
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    await setDoctorData({ ...docterData, image: file });
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const [docterData, setDoctorData] = useState({
    organisation_id: id,
    id: "",
    name: "",
    phone_number: "",
    price: "",
    experience: "",
    about: "",
    image: undefined,
  });
  const [docterCatData, setDoctorCatData] = useState({
    organisation_id: id,
    category: "",
    doctor_id: "",
    title: "",
  });

  const [category, setCategory] = useState();
  const [doctorMaster, setDoctorMaster] = useState();
  const getMaster = async () => {
    {
      const res = await getData(
        "organisation/dashboard/apis/category-list-api"
      );
      console.log(res, "lllll");
      if (res?.success) {
        const data = await Select2Data(res.data, "category", false);
        setCategory(data);
      }
    }
    {
      const res = await getData(
        `organisation/dashboard/apis/doctor-list-api/?organisation_id=${id}`
      );
      console.log(res, "lllll");
      if (res?.success) {
        const result = res.data.map((data) => ({
          value: data?.id,
          label: data?.name,
          name: "doctor_id",
        }));
        setDoctorMaster(result);
      }
    }
  };

  const handleChange = async (e) => {
    if (e?.name === "category") {
      setDoctorData({ ...docterData, [e?.name]: e });
    } else {
      setDoctorData({ ...docterData, [e?.target?.name]: e?.target?.value });
    }
  };
  const handleDelete = async (id) => {
    const res = await deleteData(
      `organisation/dashboard/apis/doctor-edit/${id}/`
    );
    await getDoctor();
  };

  const handleDeletecategory = async (id) => {
    const res = await deleteData(
      `organisation/dashboard/apis/category-edits/${id}/`
    );
    await getCategory();
  };
  const handleCatChange = async (e) => {
    console.log(e?.target?.value);
    if (e?.name === "category" || e?.name === "doctor_id") {
      setDoctorCatData({ ...docterCatData, [e?.name]: e });
    } else {
      setDoctorCatData({
        ...docterCatData,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!docterData.name) {
      errors.name = "Name is required";
    }
    if (!docterData.phone_number) {
      errors.phone_number = "Phone Number is required";
    } else if (docterData.phone_number.length < 10) {
      errors.phone_number = "Invalid Phone number";
    }

    if (!docterData.price) {
      errors.price = "Price is required";
    }
    if (!docterData.experience) {
      errors.experience = "Experience is required";
    }

    if (!docterData.about) {
      errors.about = "About is required";
    }
    // if (!docterData.image) {
    //   errors.image = "Image is required";
    // }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`image`, docterData.image);
        finalData.append(`name`, docterData.name);
        finalData.append(`mobile_number`, docterData.phone_number);
        finalData.append(`about`, docterData.about);
        finalData.append(`experience`, docterData.experience);
        finalData.append(`price`, docterData.price);
        finalData.append(`organisation_id`, docterData.organisation_id);

        const result = await postData(
          "organisation/dashboard/apis/organisation-doctor-detail-generic-api/",
          finalData
        );

        if (result?.success) {
          await setDoctorData({
            organisation_id: id,
            id: "",
            name: "",
            phone_number: "",
            price: "",
            experience: "",
            about: "",
          });
          await setImagePreview(false);
          await setErrors();
          handleClose();
          getDoctor();
        } else {
          let errors = {};
          errors.phone_number = result?.mobile_number;
          setErrors(errors);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        if (docterData.image !== undefined) {
          finalData.append(`image`, docterData.image);
        }
        finalData.append(`name`, docterData.name);
        finalData.append(`mobile_number`, docterData.phone_number);
        finalData.append(`about`, docterData.about);
        finalData.append(`experience`, docterData.experience);
        finalData.append(`price`, docterData.price);
        finalData.append(`organisation_id`, docterData.organisation_id);

        const result = await editPatchData(
          `organisation/dashboard/apis/doctor-edit/${docterData.id}/`,
          finalData
        );

        if (result?.success) {
          await setDoctorData({
            organisation_id: id,
            id: "",
            name: "",
            phone_number: "",
            price: "",
            experience: "",
            about: "",
          });
          await setImagePreview(false);
          await setErrors();
          handleClose();
          getDoctor();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateCatForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!docterCatData.category) {
      errors.category = "Category is required";
    }
    if (!docterCatData.title) {
      errors.title = "Title is required";
    }

    if (!docterCatData.doctor_id) {
      errors.doctor_id = "Doctor is required";
    }
    return errors;
  };
  const handleCatSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateCatForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`category`, docterCatData.category);
        finalData.append(`doctor_id`, docterCatData.doctor_id?.value);
        finalData.append(`title`, docterCatData.title);

        const result = await postData(
          "organisation/dashboard/apis/doc-cat-create-api/",
          finalData
        );

        // if (result?.success) {
        await setDoctorCatData({
          organisation_id: id,
          category: "",
          doctor_id: "",
          title: "",
        });
        await setErrors();
        handleClose2();
        getCategory();
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditSubmitcategory = async (e) => {
    e.preventDefault();

    const validation = validateCatForm();

    console.log(validation);

    if (Object.keys(validation).length > 0) {
      setErrors(validation);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`category`, docterCatData.category);
        finalData.append(`doctor_id`, docterCatData.doctor_id?.value);
        finalData.append(`title`, docterCatData.title);

        const result = await editPatchData(
          `organisation/dashboard/apis/category-edits/${docterCatData.id}/`,
          finalData
        );
        console.log(docterCatData.id);

        if (result?.success) {
          await setDoctorCatData({
            organisation_id: id,
            category: "",
            doctor_id: "",
            title: "",
          });
          await setImagePreview(false);
          await setErrors();
          handleClose2();
          getCategory();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [doctors, setDoctor] = useState();
  const [opdEdit, setOpdEdit] = useState(false);
  const [catEdit, setCatEdit] = useState(false);
  const [search, setSearch] = useState();

  const getDoctor = async (page, searchIn) => {
    setSearch(searchIn);
    var res;
    if (page) {
      if (searchIn) {
        res = await getData(
          `organisation/dashboard/apis/doctor-list-with-api/?${page}&doctor=${searchIn}`
        );
      } else {
        res = await getData(
          `organisation/dashboard/apis/doctor-list-with-api/?${page}`
        );
      }
    } else {
      if (searchIn) {
        res = await getData(
          `organisation/dashboard/apis/doctor-list-with-api/?organisation_id=${id}&doctor=${searchIn}`
        );
      } else {
        res = await getData(
          `organisation/dashboard/apis/doctor-list-with-api/?organisation_id=${id}`
        );
      }
    }
    setDoctor(res);
  };

  const handleEdit = async (index, action) => {
    console.log(doctors?.results[index]);
    await setDoctorData({
      id: doctors?.results[index]?.id,
      name: doctors?.results[index]?.name,
      phone_number: doctors?.results[index]?.mobile_number,
      price: doctors?.results[index]?.price,
      experience: doctors?.results[index]?.experience,
      about: doctors?.results[index]?.about,
      organisation_id: id,
    });
    setImagePreview(imageUrl + doctors?.results[index]?.image);

    if (action === "edit") {
      handleShow();
      setOpdEdit(true);
    }
  };

  const handleEditcategory = async (index, action) => {
    console.log(categoryList?.results, "hello");
    await setDoctorCatData({
      // doctor_id: docterCatData?.results[index]?.doctor_id,
      organisation_id: id,
    });

    if (action === "edit") {
      handleShow2();
      setCatEdit(true);
    }
  };

  const [categoryList, setCategoryList] = useState();
  const getCategory = async () => {
    const res = await getData(
      `organisation/dashboard/apis/doctor-category-list-api/?organisation_id=${id}`
    );
    if (res?.success) {
      setCategoryList(res.data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMaster();
    getDoctor();
    getCategory();
  }, []);
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* doctor data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">Doctors</h4>
              <div className="search-holder  flex items-center space-x-2">
                {/* <label for="" className="search text">
                  Search:{" "}
                </label>
                <Form.Control
                  className="serrr-innp"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => getDoctor("", e?.target?.value)}
                ></Form.Control> */}
                <Button
                  variant="success"
                  onClick={handleShow}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Doctor
                </Button>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading">Username</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Phone</span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">Category</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">
                        Sub-Category
                      </span>
                    </th> */}
                    <th>
                      <span className="text table-data-heading">
                        Experience
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Price</span>
                    </th>
                    {/* <th>
                      <span className="text table-data-heading">
                        Slots Available
                      </span>
                    </th> */}
                    {/* <th>
                      <span className="text table-data-heading">Rating</span>
                    </th> */}
                    <th>
                      <span className="text table-data-heading">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {doctors?.results?.map((value, index) => (
                    <tr>
                      <td>
                        <div class="user-name-holder">
                          <img
                            src={
                              value?.image
                                ? imageUrl + value?.image
                                : process.env.PUBLIC_URL +
                                  "public/assets/image/doctor/maleDoctor.png"
                            }
                            alt="user-img "
                            class="username-img"
                          />
                          <p className="text text-size mb-0 data-line ">
                            {value?.name}
                          </p>
                        </div>
                      </td>
                      <td>
                        <p className="text text-size data-line ">
                          {value?.mobile_number}
                        </p>
                      </td>
                      {/* <td>
                      <p className="text text-size data-line">DENTIST</p>
                    </td>
                    <td>
                      <p className="text text-size data-line">
                        ADVANCE DENTAL CLINIC DENTIST @
                      </p>
                    </td> */}
                      <td>
                        <p className="text text-size data-line">
                          {value?.experience}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line">
                          {value?.price}
                        </p>
                      </td>
                      {/* <td>
                        <p className="text text-size data-line">0</p>
                      </td> */}
                      {/* <td>
                        <FontAwesomeIcon
                          className="yellow"
                          icon="fa-solid fa-star"
                        />
                        <FontAwesomeIcon
                          className="yellow"
                          icon="fa-solid fa-star"
                        />
                        <FontAwesomeIcon
                          className="yellow"
                          icon="fa-solid fa-star"
                        />
                        <FontAwesomeIcon
                          className="yellow"
                          icon="fa-solid fa-star"
                        />
                        <FontAwesomeIcon
                          className="yellow"
                          icon="fa-solid fa-star"
                        />
                      </td> */}
                      <td>
                        <div className="action">
                          <Link
                            to={`/hospitalSlot/${value?.organisation?.id}/${value.id}`}
                          >
                            <Button className="action-btn">
                              <FontAwesomeIcon
                                className="text"
                                icon="fa-solid fa-eye"
                              />
                            </Button>
                          </Link>
                          <Button
                            className="action-btn "
                            onClick={() => handleEdit(index, "edit")}
                          >
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </Button>
                          <Button className="action-btn">
                            <FontAwesomeIcon
                              className="text"
                              icon="fa-solid fa-trash"
                              onClick={() => handleDelete(value?.id)}
                            />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </section>
          {/* add doctor  modal start */}
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Add Doctors</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* choose image start */}
                <div className="row ">
                  <div className="col-md-9">
                    <Form.Group
                      controlId="exampleForm.ControlInput2"
                      className="mb-3 text"
                    >
                      <Form.Label className="text">Upload Image</Form.Label>
                      <Form.Control
                        type="file"
                        className="text"
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                      {errors?.image && (
                        <span style={errorStyle}>{errors?.image}</span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-md-3">
                    <div className="image-preview-holder">
                      <span className="text">preview</span>
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="Image Preview"
                          className="img-preview"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* choose image  end */}

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={docterData?.name}
                    className="text"
                    type="text"
                    placeholder="Enter name"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.name && (
                    <span style={errorStyle}>{errors?.name}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Phone Number</Form.Label>
                  <Form.Control
                    name="phone_number"
                    value={docterData?.phone_number}
                    className="text"
                    type="text"
                    placeholder="Enter Phone Number"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace") event.preventDefault();
                      }
                    }}
                    minlength="10"
                    maxlength="10"
                  />
                  {errors?.phone_number && (
                    <span style={errorStyle}>{errors?.phone_number}</span>
                  )}
                </Form.Group>

                {/* <Form.Group className="mb-3 text">
                  <Form.Label className="text">Organizations</Form.Label>
                  <Form.Select>
                    <option className="text">
                      ADVANCE DENTAL CLINIC DENTIST
                    </option>
                    <option className="text">
                      ADVANCE DENTAL CLINIC DENTIST
                    </option>
                    <option className="text">
                      ADVANCE DENTAL CLINIC DENTIST
                    </option>
                    <option className="text">
                      ADVANCE DENTAL CLINIC DENTIST
                    </option>
                    <option className="text">
                      ADVANCE DENTAL CLINIC DENTIST
                    </option>
                  </Form.Select>
                </Form.Group> */}

                {/* <Form.Group className="mb-3 text">
                  <Form.Label className="text">Catagory</Form.Label>
                  <Select
                    placeholder="Select Country"
                    value={docterData?.category_id}
                    onChange={handleChange}
                    options={category}
                    isSearchable={true}
                    id="category_id"
                  />
                  {errors?.category_id && (
                    <span style={errorStyle}>{errors?.category_id}</span>
                  )}
                </Form.Group> */}

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Price</Form.Label>
                  <Form.Control
                    name="price"
                    value={docterData?.price}
                    className="text"
                    type="text"
                    placeholder="Enter Price"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace") event.preventDefault();
                      }
                    }}
                  />
                  {errors?.price && (
                    <span style={errorStyle}>{errors?.price}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Experience(in years)</Form.Label>
                  <Form.Control
                    name="experience"
                    value={docterData?.experience}
                    className="text"
                    type="text"
                    placeholder="Enter Experience"
                    autoFocus
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        if (event.key != "Backspace") event.preventDefault();
                      }
                    }}
                  />
                  {errors?.experience && (
                    <span style={errorStyle}>{errors?.experience}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">About</Form.Label>
                  <Form.Control
                    name="about"
                    value={docterData?.about}
                    className="text"
                    type="text"
                    placeholder="Enter About"
                    autoFocus
                    onChange={handleChange}
                  />
                  {errors?.about && (
                    <span style={errorStyle}>{errors?.about}</span>
                  )}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              {opdEdit ? (
                <Button variant="success" onClick={handleEditSubmit}>
                  Edit
                </Button>
              ) : (
                <Button variant="success" onClick={handleSubmit}>
                  Add
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          {/* add doctor  modal end */}

          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  {doctors?.previous && (
                    <li class="page-item">
                      <a
                        class="page-link"
                        role="button"
                        tabindex="0"
                        onClick={() =>
                          getDoctor(doctors?.previous.split("?")[1], search)
                        }
                      >
                        <span aria-hidden="true">‹</span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                    </li>
                  )}
                  {[
                    ...Array(doctors?.count && Math.ceil(doctors?.count / 10)),
                  ].map(function (_, index) {
                    return (
                      <li key={index} className="page-item">
                        <a
                          className="page-link"
                          role="button"
                          tabIndex="0"
                          onClick={() =>
                            getDoctor(
                              `organisation_id=${id}&page=` + (index + 1),
                              search
                            )
                          }
                        >
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                  {doctors?.next && (
                    <li class="page-item">
                      <a
                        class="page-link"
                        role="button"
                        tabindex="0"
                        onClick={() =>
                          getDoctor(doctors?.next.split("?")[1], search)
                        }
                      >
                        <span aria-hidden="true">›</span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* doctor data table end */}

          {/* all category data table start */}

          <section className="data-table">
            <div className="heading-holder">
              <h4 className="font-semibold text">All Catagories</h4>
              <div className="search-holder  flex items-center space-x-2">
                <label for="" className="search text">
                  {/* Search:{" "} */}
                </label>
                {/* <input type="text" className="search btn-style text" /> */}
                <Button
                  variant="success"
                  onClick={handleShow2}
                  className="search btn-style btn-size text bg-green"
                >
                  Add Category
                </Button>
              </div>
            </div>

            <div className="table-container">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="text table-data-heading ">
                        Doctor Name
                      </span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Title</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Category</span>
                    </th>
                    <th>
                      <span className="text table-data-heading">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categoryList?.map((value, index) => (
                    <tr>
                      <td>
                        <div class="user-name-holder">
                          <p className="text text-size mb-0 data-line">
                            {value?.doctor?.name}
                          </p>
                        </div>
                      </td>
                      <td>
                        <p className="text text-size data-line">
                          {" "}
                          {value?.title}
                        </p>
                      </td>
                      <td>
                        <p className="text text-size data-line">
                          {" "}
                          {value?.category}
                        </p>
                      </td>
                      <td>
                        {/* <Button
                          className="action-btn "
                          onClick={() => handleEditcategory(index, "edit")}
                        >
                          <FontAwesomeIcon
                            className="text"
                            icon="fa-solid fa-pen-to-square"
                          />
                        </Button> */}
                        <Button className="action-btn">
                          <FontAwesomeIcon
                            className="text"
                            icon="fa-solid fa-trash"
                            onClick={() => handleDeletecategory(value?.id)}
                          />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </section>
          {/* pegignation start */}
          <div className="row me-0 ms-0">
            <div class="col-md-12">
              <div class="Pagination-holder">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" href="#">
                      <span aria-hidden="true">‹</span>
                      <span class="visually-hidden">Previous</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" href="#">
                      4
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" href="#">
                      5
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" role="button" tabindex="0" href="#">
                      <span aria-hidden="true">›</span>
                      <span class="visually-hidden">Next</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* pegignation end */}

          {/* all category data table end */}

          {/* add doctor  modal start */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="text">Add Category</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Doctor</Form.Label>
                  <Select
                    placeholder="Select Doctor"
                    value={docterCatData?.doctor_id}
                    onChange={handleCatChange}
                    options={doctorMaster}
                    isSearchable={true}
                    id="doctor_id"
                  />
                  {errors?.doctor_id && (
                    <span style={errorStyle}>{errors?.doctor_id}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={docterCatData?.title}
                    className="text"
                    type="text"
                    placeholder="Enter Title"
                    onChange={handleCatChange}
                  />
                  {errors?.title && (
                    <span style={errorStyle}>{errors?.title}</span>
                  )}
                </Form.Group>

                {/* <Form.Group
                  className="mb-3 text"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text">Subtitle</Form.Label>
                  <Form.Control
                    className="text"
                    type="text"
                    placeholder="Enter Subtitle"
                    autoFocus
                  />
                </Form.Group> */}

                <Form.Group className="mb-3 text">
                  <Form.Label className="text">Catagory</Form.Label>
                  {/* <Select
                    placeholder="Select Catagory"
                    value={docterCatData?.category_id}
                    onChange={handleCatChange}
                    options={category}
                    isSearchable={true}
                    id="category_id"
                  /> */}
                  <Form.Control
                    name="category"
                    value={docterCatData?.category}
                    className="text"
                    type="text"
                    placeholder="Enter Category"
                    onChange={handleCatChange}
                  />
                  {errors?.category && (
                    <span style={errorStyle}>{errors?.category}</span>
                  )}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Close
              </Button>
              <Button variant="success" onClick={handleCatSubmit}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
          {/* add doctor  modal end */}
        </div>
      </div>
    </>
  );
}

export default Doctor;