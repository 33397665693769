import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PathologyThirdAddModal from './PathologyThirdAddModal';
import PathologyFifthModal from './PathologyFifthModal';

const PathologyModalSecond = ({ show, onHide, formData, setFormData ,getTests}) => {
    const [modalThird, setModalThird] = useState(false);
    const [modalFifth, setModalFifth] = useState(false);

    // const handleNext = () => {
    //     setModalFifth(true);
    //     onHide();
    // };

    const [errorMessage, setErrorMessage] = useState('');

    const handleNext = () => {
        if (!formData.parameters || formData.parameters.length === 0) {
            setErrorMessage('Please add at least one parameter before proceeding.');
            return;
        }
        setModalFifth(true);
        onHide();
    };

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='patient-modal'
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex mb-3'>
                        {/* <div onClick={() => setModalThird(true)}>
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left" className='modal-title mt-1' />
                            <span className='modal-title'>Add Parameters :</span>
                        </div> */}
                        {/* <div className='button-add'>
                            <span className='text-subtest'><FontAwesomeIcon icon="fa-solid fa-plus" /> Add Subtest</span>
                        </div> */}
                         {errorMessage && <div className='text-danger mb-3'>{errorMessage}</div>}
                        <div className='button-add'>
                            <Button onClick={() => setModalThird(true)} className='adding-btn'><FontAwesomeIcon icon="fa-solid fa-plus" /> Add</Button>
                        </div>
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Reference Range</th>
                                <th>Unit</th>
                                <th>Default val.</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.parameters && formData.parameters.map((param, index) => (
                                <tr key={index}>
                                    <td>{param.name}</td>
                                    <td>{param.type}</td>
                                    <td>
                                        {param.reference.map((range, i) => (
                                            <div key={i}>
                                                {`${range.ageGroup}-${range.gender}: ${range.minValue} - ${range.maxValue}: ${range.value}`}
                                            </div>
                                        ))}
                                    </td>
                                    <td>{param.unit}</td>
                                    <td>{param.default}</td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon="fa-solid fa-trash"
                                            onClick={() => {
                                                const newParams = formData.parameters.filter((_, i) => i !== index);
                                                setFormData({ ...formData, parameters: newParams });
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleNext} className='create-btn'>Next <FontAwesomeIcon icon="fa-solid fa-arrow-right" /></Button>
                </Modal.Footer>
               
            </Modal>

            <PathologyThirdAddModal
                show={modalThird}
                onHide={() => setModalThird(false)}
                addParameter={(newParam) => {
                    setFormData({ ...formData, parameters: [...formData.parameters, newParam] });
                }}
                setModalThird={setModalThird} // Passing setModalThird as a prop
                formData={formData}
                setFormData={setFormData}
                getTests={getTests}
            />

            <PathologyFifthModal
                HedaingName="Add Parameters : "
                show={modalFifth}
                onHide={() => setModalFifth(false)}
                formData={formData}
                setFormData={setFormData}
                getTests={getTests}
            />
        </>
    );
};

export default PathologyModalSecond;
