import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Table from "react-bootstrap/Table";
import "./PathologyCard.css";
import Price from "./Price";
import Tests from "./Tests";
import TestAppointment from "./TestAppointment";
import TestRecord from "./TestRecord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import PatienTRecord from "./PatienTRecord";
import PathoSetting from "./PathoSetting";
library.add(fas);

function PathologyCard() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => setSelectedDate(date);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [activeDefault, setActiveDefault] = useState(5);
  const handleTabs = async (tab) => {

    await setActiveDefault(tab);


  }
  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* doctor data table start */}


          <div className="row status-section">
            {/* <div className="col-md-3 col-sm-3 col-lg-2  me-5">
              <button
                variant="success"
                onClick={() => handleTabs(1)}
                className={`search btn-style btn-size text ${activeDefault === 1 ? '' : 'bg-green'}`}
              >
                Price & details
              </button>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-2   me-5">
              <button
                variant="success"
                onClick={() => handleTabs(2)}
                className={`search btn-style btn-size text ${activeDefault === 2 ? '' : 'bg-green'}`}
              >
                Test
              </button>
            </div>
        */}
            {/* <div className="col-md-3 col-sm-3 col-lg-2">
              <button
                variant="success"
                onClick={() => handleTabs(4)}
                className={`search btn-style btn-size text ${activeDefault  === 4? '':'bg-green'}`}
              >
                Test Appointments
              </button>
            </div> */}
            {/* <div className="col-md-3 col-sm-3 col-lg-2  me-5">
              <button
                variant="success"
                onClick={() => handleTabs(3)}
                className={`search btn-style btn-size text ${activeDefault === 3 ? '' : 'bg-green'}`}
              >
                Test Records
              </button>
            </div>  */}
          </div>
          <div className="row status-section mt-2 mb-3">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-3 col-sm-3 col-lg-3 col-xl-2 col-xxl-2  me-xxl-0 me-xl-0 me-lg-4 me-md-0 ">
                  <button
                    variant="success"
                    onClick={() => handleTabs(5)}
                    className={`search btn-style1 btn-size text ${activeDefault === 5 ? '' : 'bg-green1'}`}
                  >
                    Patient record
                  </button>
                </div>
                <div className="col-md-3 col-sm-3 col-lg-3 col-xl-2 col-xxl-2 me-xxl-0 me-xl-0 me-lg-5">
                  <button
                    variant="success"
                    onClick={() => handleTabs(6)}
                    className={`search btn-style1 btn-size text ${activeDefault === 6 ? '' : 'bg-green1'}`}
                  >
                    Patho-settings
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-1">
              <div className="text-end me-5">
                <FontAwesomeIcon icon="fa-solid fa-sliders" className="me-5" />
              </div>
            </div>
          </div>


          {activeDefault === 1 && (
            <Price />
          )}
          {activeDefault === 2 && (
            <Tests />
          )}
          {activeDefault === 3 && (
            <TestRecord />
          )}
          {activeDefault === 4 && (
            <TestAppointment />
          )}
          {activeDefault === 5 && (
            <PatienTRecord />
          )}
          {activeDefault === 6 && (
            <PathoSetting />
          )}
        </div>
      </div>
    </>
  );
}

export default PathologyCard;
