import React, { useContext, useEffect, useState } from 'react';
import './PendingPreviewModal.css'
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { useParams } from 'react-router-dom';
import { Context } from '../../../../../../utils/context';
import PatientSuceesfullModal from '../../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';
// import { Link, useParams } from 'react-router-dom';
const PendingPreviewModal = (props) => {
    const { HedaingName, SubHeading, SecondText, patient } = props;
    const [time, setTime] = useState('');
    console.log(patient,"patient patient patient pre");
    useEffect(() => {
        const patientTests = props.patient?.patient_tests || [];
        setFormData(patient)
        setTime(formatTime(patient?.time))
    }, [patient]);

    const { id } = useParams();
    const [tests, setTests] = useState([]);
    const [allSource, setAllSource] = useState([]);
    const [patientType, setPatientType] = useState([]);
    const [sampleCollection, setSampleCollection] = useState([]);
    const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        phone_number: "",
        patient_name: "",
        date: "",
        time: "",
        age: "",
        gender: "",
        lab_no: "",
        sdo: "",
        address: "",
        patient_type: "",
        source: "",
        sample_collected: "",
        sample_collected_by: "",
    });
    const { getData, SelectUserData, postData } = useContext(Context);
    const [patientNames, setPatientNames] = useState(['']);

    const genderOptions = [
        { value: 'M', label: 'M' },
        { value: 'F', label: 'F' }
    ];

    const statusOptions = [
        { value: false, label: 'Pending' },
        { value: true, label: 'Prepared' }
    ];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    console.log(formData,"formData formData hhhhhhhhhhhhhh");
    const handleSelectChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            patient_type: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleSelectChangeSource = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            source: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleSelectChangeGender = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            gender: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleSelectChangeStatus = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            status: selectedOption ? selectedOption.value : null,
        }));
       
    };

    const handleSelectChangeSample = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            sample_collected: selectedOption ? selectedOption.value : null,
        }));
    };

    const getSampleCollection = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/patientCollection/`);
            const data = await SelectUserData(response?.data, 'name', false);
            setSampleCollection(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getSampleCollection();
    }, []);

    const getPatientType = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/patientType/`);
            const data = await SelectUserData(response?.data, 'name', false);
            setPatientType(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getPatientType();
    }, []);

    const getSourceAll = async () => {
        try {
            const response = await getData(`/organisation/dashboard/apis/patientStatus/`);
            const data = await SelectUserData(response?.data, 'name', false);
            setAllSource(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getSourceAll();
    }, []);

    useEffect(() => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        setTime(`${hours}:${minutes}`);
    }, []);
    const validateForm = () => {
        const errors = {};
        if (!formData.status) {
            errors.status = "Status Required";
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        console.log("ggggggggg");
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append('organisation_id', id);
                finalData.append('patient_id', patient?.id);
                finalData.append('status', formData.status === false ? "0": "1");
               
                const result = await postData(
                    `/organisation/dashboard/apis/update-status/`,
                    finalData
                );

                if (result?.success) {
                    // await setFormData({
                    //     sample_collected_by: '',
                    //     patient_id: '',
                    //     date: '',
                    //     time: '',
                    //     review_status: '',
                    //     sample_collected:'',
                    // });

                    await setErrors({});
                    setModalSuccessPatient(true);
                    setStartDate(null);
                    setTime('');
                    props.getpatientData();
                    props.onHide();
                    window.location.reload();
                  
                   
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    useEffect(() => {
        let timer;
        if (modalSuccessPatient) {
            timer = setTimeout(() => {
                setModalSuccessPatient(false);
            }, 3000); // Modal will hide after 3 seconds
        }
        return () => clearTimeout(timer);
    }, [modalSuccessPatient]);
    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };
    const formatTime = (timeString) => {
        if (!timeString) {
            return '';
        }
    
        const [hours, minutes] = timeString?.split(':');
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='sample-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='mx-auto'>
                        {HedaingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className='text-heading mb-4'>
                            <h2>{SubHeading}</h2>
                        </div>
                        <Form.Group as={Row} className="mb-3" controlId="formPatientName">
                            <Form.Label column md={5}>Patient Name:</Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    type="text"
                                    placeholder="Patient Name"
                                    name="patient_name"
                                    value={formData?.patient_name}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAge">
                            <Form.Label column md={5}>Age:</Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    type="text"
                                    placeholder="Age"
                                    name="age"
                                    
                                    value={formData?.age}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formGender">
                            <Form.Label column md={5}>Gender:</Form.Label>
                            <Col md={7}>
                                <Select
                                    name="gender"
                                    placeholder="Select Gender"
                                    value={genderOptions.find(option => option.value === formData?.gender)}
                                    onChange={handleSelectChangeGender}
                                    options={genderOptions}
                                    isDisabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPhoneNumber">
                            <Form.Label column md={5}>Phone:</Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    type="text"
                                    placeholder="Phone Number"
                                    name="phone_number"
                                    value={formData?.phone_number}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAddress">
                            <Form.Label column md={5}>Address:</Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    type="text"
                                    placeholder="Address"
                                    name="address"
                                    value={formData?.address}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPatientType">
                            <Form.Label column md={5}>Patient Type:</Form.Label>
                            <Col md={7}>
                                <Select
                                    name='Patient'
                                    value={patientType?.find(option => option.value === formData?.patient_type?.id)}
                                    options={patientType}
                                    onChange={handleSelectChange}
                                    isDisabled
                                />
                            </Col>
                        </Form.Group>
                        <div className='text-heading mt-3'>
                            <h2>{SecondText}</h2>
                        </div>
                        <Form.Group as={Row} className="mb-3" controlId="formDate">
                            <Form.Label column md={5}>Date of Collection:</Form.Label>
                            <Col md={7}>
                                <DatePicker
                                    name='date'
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setFormData({ ...formData, date: date.toLocaleDateString() });
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="DD/MM/YYYY"
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formTime">
                            <Form.Label column md={5}>Time of Collection:</Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    name='time'
                                    type="time"
                                    value={time}
                                    onChange={(e) => {
                                        setTime(e.target.value);
                                        setFormData({ ...formData, time: e.target.value });
                                    }}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formSdo">
                            <Form.Label column md={5}>S/D/Wo:</Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    type="text"
                                    placeholder="S/D/Wo"
                                    name="sdo"
                                    value={formData?.sdo}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formSource">
                            <Form.Label column md={5}>Source:</Form.Label>
                            <Col md={7}>
                                <Select
                                    name='source'
                                    value={allSource?.find(option => option.value === formData?.source?.id)}
                                    options={allSource}
                                    onChange={handleSelectChangeSource}
                                    isDisabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formSampleType">
                            <Form.Label column md={5}>Sample Type:</Form.Label>
                            <Col md={7}>
                                <Select
                                    name='samplecollected'
                                    value={sampleCollection?.find(option => option.value === formData?.sample_collected?.id)}
                                    options={sampleCollection}
                                    onChange={handleSelectChangeSample}
                                    isDisabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formLabNo">
                            <Form.Label column md={5}>Lab No:</Form.Label>
                            <Col md={7}>
                                <Form.Control
                                    type="text"
                                    placeholder="Lab No"
                                    name="lab_no"
                                    value={formData?.lab_no}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formGender">
                            <Form.Label column md={5}>Status:</Form.Label>
                            <Col md={7}>
                                <Select
                                    name="status"
                                    placeholder="Select Status"
                                    value={statusOptions?.find(option => option.value === formData?.status)}
                                    // value={formData?.status}
                                    // value={statusOptions.find(option => option.value === formData?.gender)}
                                    // value={formData?.status ? statusOptions.find(option => option.value === formData?.status) : "pending"}
                                    onChange={handleSelectChangeStatus}
                                    options={statusOptions}
                                  
                                />
                                 {errors?.status && <span style={errorStyle}>{errors?.status}</span>}
                            </Col>
                        </Form.Group>
                        <Button className='confirm-btn'  type="submit">Confirm</Button>
                        {/* <Button className='delete-btn' onClick={props.onHide}>Delete</Button> */}
                    </Form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button className='confirm-btn'  type="submit">Confirm</Button>
                    <Button className='delete-btn' onClick={props.onHide}>Delete</Button>
                </Modal.Footer> */}
              
            </Modal>
            <PatientSuceesfullModal
                textHeading="Patient Status Updated successfully"
                show={modalSuccessPatient}
                onHide={() => setModalSuccessPatient(false)}
            />
        </>
    )
}

export default PendingPreviewModal;
