


// import React, { useContext, useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import { Form, Table } from 'react-bootstrap';
// import { Context } from '../../../../../../utils/context';
// import Select from 'react-select';
// import PatientSuceesfullModal from '../../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';
// import { useParams } from 'react-router-dom';

// const AssignTestResultModal = (props) => {
//     const { HedaingName } = props;
//     const { id } = useParams();

//     const [data, setData] = useState([]);
//     const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
//     const [sampleData, setModalsampleData] = useState(false);
//     const { postData ,getData} = useContext(Context);
//     const [editData, setEditData] = useState('');  
//     const [errors, setErrors] = useState({});
//     const [errorstest, setErrorsTest] = useState({});

//     const [formData, setFormData] = useState({
//         organization_id: id,
//         patient_id: props.patient?.id,
//         sample_status:false,
//         tests: []
//     });

//     console.log(props.patient,"props.patient result");
//     useEffect(() => {
//         const patientTests = props.patient?.patient_tests || [];
//         const formattedTests = patientTests.map(test => ({
//             test_id: test.id,
//             parameters: test.test.parameters.map(param => ({
//                 parameter_id: param.id,
//                 result: '',
//                 test_status: false,
//                 is_checked: false
//             }))
//         }));

//         setData(patientTests || []);
//         setFormData({
//             organization_id: id,
//             patient_id: props.patient?.id,
//             tests: formattedTests
//         });
//     }, [props.patient, id]);

//     const statusOptions = [
//         { value: false, label: 'In-Review' },
//         { value: true, label: 'Final' }
//     ];

   
//     const handleCheckboxChange = (testId, parameterId) => {
//         setFormData(prevState => ({
//             ...prevState,
//             tests: prevState.tests.map(test =>
//                 test.test_id === testId ? {
//                     ...test,
//                     parameters: test.parameters.map(param =>
//                         param.parameter_id === parameterId ? { ...param, is_checked: !param.is_checked } : param
//                     )
//                 } : test
//             )
//         }));
//     };
//     const handleResultChange = (testId, parameterId, value) => {
//         console.log(value);
//         setFormData(prevState => ({
//             ...prevState,
//             tests: prevState.tests.map(test =>
//                 test.test_id === testId ? {
//                     ...test,
//                     parameters: test.parameters.map(param =>
//                         param.parameter_id === parameterId ? { ...param, result: value } : param
//                     )
//                 } : test
//             )
//         }));
//     };
    
//     const handleStatusChange = (testId, parameterId, selectedOption) => {
//         setFormData(prevState => ({
//             ...prevState,
//             tests: prevState.tests.map(test => 
//                 test.test_id === testId ? {
//                     ...test,
//                     parameters: test.parameters.map(param => 
//                         param.parameter_id === parameterId ? { ...param, test_status: selectedOption.value } : param
//                     )
//                 } : test
//             )
//         }));
//     };
    

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // if (!props.patient?.patient_sample_name?.review_status ) {
//         //     setModalsampleData(true);
//         //     props.onHide();
//         //     return;
//         // }

//         if (!props.patient?.patient_sample_name?.review_status) {
//             setModalsampleData(true);
//             props.onHide();
//             return;
//         }
//         // else
//         // if ( !props.patient?.sample_collected?.id == 2) {
            
//         //     setModalsampleData(true);
//         //     props.onHide();
//         //     return;
//         // }
//         if(formData.tests===0){
//             setErrorsTest("At List One tests require")
//             return;
//         }

    
//         let hasErrors = false;
//         const newErrors = {};
//         let isAnyRecordValid = false;
//         formData.tests.forEach(test => {
//             test.parameters.forEach(param => {
//                 if (param.is_checked && param.result &&  param.test_status) {
//                     isAnyRecordValid = true; // At least one record is valid
//                 }else
//                 if (param.is_checked && param.test_status === true && !param.result) {
//                     newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required ';
//                     newErrors[`final`] = 'Selected Data Can Not Be Null';
//                     isAnyRecordValid = true;
//                     hasErrors = true;
//                 }
//                 else  if (param.is_checked && !param.result) {
//                     newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
//                     newErrors[`final`] = 'Selected Data Can Not Be Null';
//                     isAnyRecordValid = true;
//                     hasErrors = true;
//                 } else  if (param.test_status === true && !param.result) {
//                     newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
//                     newErrors[`final`] = 'Final Data Can Not Be Null';
//                     isAnyRecordValid = true;
//                     hasErrors = true;
//                 } else  if (!param.is_checked === true && param.result) {
//                     newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
//                     newErrors[`final`] = 'Checked is Required';
//                     isAnyRecordValid = true;
//                     hasErrors = true;
//                 } 
//                 else  if (param.is_checked === true && param.result) {
//                     // newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
//                     // newErrors[`final`] = 'Final is Required';
//                     isAnyRecordValid = true;
//                     // hasErrors = true;
//                 } 
//             });
//         });
//         if (!isAnyRecordValid) {
//             newErrors[`record`] = 'At least one record must be selected and have a result';
//             hasErrors = true;
//         }

//         setErrors(newErrors);
//         if (hasErrors) return;

        
//         try {
//             const result = await postData('/organisation/dashboard/apis/save-params/', formData);
//             if (result?.success) {
//                 setModalSuccessPatient(true);
//                 props.onHide();
//                 props.getpatientData();
//             }
//         } catch (error) {
//             console.error("Error submitting data:", error);
//         }
//     };

//     console.log(formData.tests,"formData.tests");

//     useEffect(() => {
//         let timer;
//         if (modalSuccessPatient) {
//             timer = setTimeout(() => {
//                 setModalSuccessPatient(false);
//             }, 3000);
//         }
//         return () => clearTimeout(timer);
//     }, [modalSuccessPatient]);

//     useEffect(() => {
//         let timer;
//         if (sampleData) {
//             timer = setTimeout(() => {
//                 setModalsampleData(false);
//             }, 3000);
//         }
//         return () => clearTimeout(timer);
//     }, [sampleData]);

//     const errorStyle = {
//         color: "red",
//         marginLeft: "5px",
//     };
//     console.log(formData,"kkkkkkkkkkk jjjjjjjjjjjjj");
//     const handleStatusCheckChange = (value) => {
       
//         setFormData(prevFormData => ({
//           ...prevFormData,
//           status: value,
//           sample_status : value
//         }));
//       };

//       console.log(props.patient,"props.patient props.patient");
//     return (
//         <>
//             <Modal
//                 {...props}
//                 size="lg"
//                 aria-labelledby="contained-modal-title-vcenter"
//                 centered
//                 className='sample-modal'
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title id="contained-modal-title-vcenter">
//                         {HedaingName}
                  
//                     </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
            
//                     <Form onSubmit={handleSubmit}>
//                     <div className='founder-text'>
//                     <Form.Check
//   type="switch"
//   id="custom-switch"
//   label="Found/Not Fount (sample)"
//   onChange={(e) => handleStatusCheckChange(e.target.checked ? 1 : 0)}
// />
//                                 </div>
//                         <Table >
//                             <thead>
//                                 <tr>
//                                     <th>Test</th>
//                                     <th>Parameter</th>
//                                     <th>Result</th>
//                                     <th>Select</th>
//                                     <th>Status:</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {props.patient?.patient_tests.map((test,index) => (
//                                     test.test?.parameters?.map((param, index2) => (
//                                         <tr key={param.id}>
                                          
//                                             {/* <td>{test.test.name}</td> */}
//                                             {index2 === 0 && (
//                                                     <td rowSpan={test.test.parameters.length}>
//                                                         {test.test.name}
//                                                     </td>
//                                                 )}
//                                             <td>{param.name}</td>
                                           
//                                             <td>
//                                                 <div className='row'>
//                                                     <div className='col-md-9'>
//                                                 <Form.Control
//                                                     type="text"
//                                                     placeholder={param.default}
//                                                     value={
//                                                         formData.tests
//                                                             .find(t => t.test_id === test.test.id)
//                                                             ?.parameters.find(p => p.parameter_id === param.id)?.result || ''
//                                                     }
//                                                     onChange={(e) => handleResultChange(test.test.id, param.id, e.target.value)}
//                                                     isInvalid={!!errors[`${test.test.id}-${param.id}`]}
//                                                 />
//                                                 </div>
                                               
//                                                 <div className='col-md-3'>
//                                                 <span >{param.unit}</span>
//                                                 </div>
//                                                 </div>
//                                             </td>
                                            
//                                             <td>
//                                                 <Form.Group className="mb-3" id="formGridCheckbox">
//                                                     <Form.Check 
//                                                         type="checkbox" 
//                                                         checked={
//                                                             formData.tests
//                                                                 .find(t => t.test_id === test.test.id)
//                                                                 ?.parameters.find(p => p.parameter_id === param.id)?.is_checked 
//                                                         }
//                                                         onChange={() => handleCheckboxChange(test.test.id, param.id)}
//                                                         isInvalid={!!errors[`${test.test_id}-${param.parameter_id}`]}
//                                                     />
//                                                 </Form.Group>
//                                             </td>
// {/*                                          
//                                             <td>
//                                                 <Select
//                                                     name="status"
//                                                     placeholder="Status"
//                                                     value={
//                                                         statusOptions.find(option =>
//                                                             option.value ===
//                                                             formData.tests
//                                                                 .find(t => t.test_id === test.test.id)
//                                                                 ?.parameters.find(p => p.parameter_id === param.id)?.test_status
//                                                         )
//                                                     }
//                                                     onChange={(selectedOption) =>
//                                                         handleStatusChange(test.test.id, param.id, selectedOption)
//                                                     }
//                                                     options={statusOptions}
//                                                 />
//                                             </td> */}
//                                             {index2 === 0 && (
//                                                     <td rowSpan={test.test.parameters.length}>
//                                                       <Select
//                                                     name="status"
//                                                     placeholder="Status"
//                                                     value={
//                                                         statusOptions.find(option =>
//                                                             option.value ===
//                                                             formData.tests
//                                                                 .find(t => t.test_id === test.test.id)
//                                                                 ?.parameters.find(p => p.parameter_id === param.id)?.test_status
//                                                         )
//                                                     }
//                                                     onChange={(selectedOption) =>
//                                                         handleStatusChange(test.test.id, param.id, selectedOption)
//                                                     }
//                                                     options={statusOptions}
//                                                 />
//                                                     </td>
//                                                 )}
                                           
//                                         </tr>
//                                     ))
//                                 ))}
//                             </tbody>
                          
//                         </Table>
//                         <span style={{ color: 'red' }}>{errors["final"]}</span>
//                         <span style={{ color: 'red' }}>{errors["record"]}</span>
//                         {errors?.tests && <span style={errorStyle}>{errors?.tests}</span>}
//                         <Modal.Footer>
//                             <Button className='confirm-btn' type="submit">Confirm</Button>
//                         </Modal.Footer>
//                     </Form>
//                 </Modal.Body>
                
//             </Modal>
//             <PatientSuceesfullModal
//                 // textHeading="Patient Sample Data Not Filled "
//                 textHeading={<span style={{ color: 'red' }}>Patient Sample Data Not Filled</span>}
                
//                 show={sampleData}
//                 onHide={() => setModalsampleData(false)}
//             />
//             <PatientSuceesfullModal
//                 textHeading="Patient Result Submitted successfully"
//                 show={modalSuccessPatient}
//                 onHide={() => setModalSuccessPatient(false)}
//             />
//         </>
//     );
// };

// export default AssignTestResultModal;



// // import React, { useContext, useEffect, useState } from 'react';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import Button from 'react-bootstrap/Button';
// // import Modal from 'react-bootstrap/Modal'
// // import { Col, Form, ModalFooter, Row, Table } from 'react-bootstrap';
// // import { Context } from '../../../../../../utils/context';
// // import Select from 'react-select';

// // const AssignTestResultModal = (props) => {
// //     const { HedaingName } = props;
// //     const [errors, setErrors] = useState([]);
// //     const [data, setData] = useState([]);
// //     const [patient, setpatient] = useState([]);
// //     const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
// //     const { getData, SelectUserData, postData } = useContext(Context);
// //     console.log(props.patient,"props.id result");
// //     const [formData, setFormData] = useState({
// //         patient_id: props.patient.id,
// //         test_id: '',
// //         result: '',
       
// //     });
 
// // console.log(data,"data result result");
// //     useEffect(() => {
// //         setData(props.patient?.patient_tests[0]?.test?.parameters);
// //     }, [props.patient]);

// //     const status = [
// //         { value: '0', label: 'In-Review' },
// //         { value: '1', label: 'Final' }
// //     ];
// //     const handleSelectChangestatus = (selectedOption) => {
// //         setFormData((prevData) => ({
// //             ...prevData,
// //             review_status: selectedOption ? selectedOption.value : null,
// //         }));
// //     };
// //     return (
// //         <>
// //             <Modal
// //                 {...props}
// //                 size="md"
// //                 aria-labelledby="contained-modal-title-vcenter"
// //                 centered
// //                 scrollable
// //                 className='sample-modal'
// //             >
// //                 <Modal.Header closeButton>
// //                     <Modal.Title id="contained-modal-title-vcenter">
// //                         {HedaingName}
// //                     </Modal.Title>
// //                 </Modal.Header>
// //                 <Modal.Body>
// //                     <Table responsive >
// //                         <thead>
// //                             <tr>
// //                                 <th>Test</th>
// //                                 <th>Parameter</th>
// //                                 <th>Result</th>
// //                                 {/* <th>Select</th> */}
// //                                 <th>Status:</th>

// //                             </tr>
// //                         </thead>
// //                         <tbody>
// //                         {data && data.length > 0 ? (
// //                         data.map((d) => (
// //                             <tr>
// //                                 <td>BD</td>
// //                                 <td>{d?.name}</td>
// //                                 <td><Form>
// //                                     <div className='d-flex'>
// //                                         <Form.Control type="text" placeholder="15" />
// //                                         <span>{d?.unit}</span>
// //                                     </div>
// //                                 </Form>
// //                                 </td>
// //                                 {/* <td>
// //                                     <Form.Group className="mb-3" id="formGridCheckbox">
// //                                         <Form.Check type="checkbox" />
// //                                     </Form.Group>
// //                                 </td> */}
// //                                 <td>
// //                                     <Form>
// //                                         {/* <Form.Control type="text" placeholder="In review" /> */}
// //                                         <Select
// //                                             name="status"
// //                                             placeholder="status"
// //                                             value={status.find(option => option.value === formData.review_status)}
// //                                             onChange={handleSelectChangestatus}
// //                                             options={status}
// //                                         />
// //                                     </Form>
// //                                 </td>
// //                             </tr>
// //                             ))
// //                         ) : (
// //                             <p>No tests available</p>
// //                         )}

// //                         </tbody>

// //                     </Table>

// //                 </Modal.Body>
// //                 <Modal.Footer>
// //                     <Button className='confirm-btn' onClick={props.onHide}>Confirm</Button>
// //                 </Modal.Footer>
// //             </Modal>
// //         </>
// //     )
// // }

// // export default AssignTestResultModal


// // import React, { useContext, useEffect, useState } from 'react';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import Button from 'react-bootstrap/Button';
// // import Modal from 'react-bootstrap/Modal';
// // import { Form, Table } from 'react-bootstrap';
// // import { Context } from '../../../../../../utils/context';
// // import Select from 'react-select';
// // import PatientSuceesfullModal from '../../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';
// // import DatePicker from "react-datepicker";
// // import { Link, useParams } from 'react-router-dom';
// // const AssignTestResultModal = (props) => {
// //     const { HedaingName } = props;
// //     const [errors, setErrors] = useState([]);
// //     const { id } = useParams();
   
// //     const [data, setData] = useState([]);
// //     const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
// //     const [sampleData, setModalsampleData] = useState(false);
// //     const { getData, SelectUserData, postData } = useContext(Context);
    
// //     const [formData, setFormData] = useState({
// //         organization_id:id,
// //         patient_id: props.patient?.id,
// //         tests: []
// //     });

// //     // Update formData when props.patient changes
// //     useEffect(() => {
// //         const patientTests = props.patient?.patient_tests || [];
// //         const formattedTests = patientTests.map(test => ({
// //             test_id: test.test.id,
// //             parameters: test.test.parameters.map(param => ({
// //                 parameter_id: param.id,
// //                 result: '',
// //                 review_status: ''
// //             }))
// //         }));

// //         setData(patientTests|| []);
// //         setFormData({
// //             organization_id:id,
// //             patient_id: props.patient?.id,
// //             tests: formattedTests
// //         });
// //     }, [props.patient]);

// //     const statusOptions = [
// //         { value: '0', label: 'In-Review' },
// //         { value: '1', label: 'Final' }
// //     ];

// //     const handleResultChange = (testId, parameterId, result) => {
// //         setFormData((prevData) => ({
// //             ...prevData,
// //             tests: prevData.tests.map(test =>
// //                 test.test_id === testId
// //                     ? {
// //                         ...test,
// //                         parameters: test.parameters.map(param =>
// //                             param.parameter_id === parameterId
// //                                 ? { ...param, result }
// //                                 : param
// //                         )
// //                     }
// //                     : test
// //             )
// //         }));
// //     };

// //     const handleStatusChange = (testId, parameterId, selectedOption) => {
// //         setFormData((prevData) => ({
// //             ...prevData,
// //             tests: prevData.tests.map(test =>
// //                 test.test_id === testId
// //                     ? {
// //                         ...test,
// //                         parameters: test.parameters.map(param =>
// //                             param.parameter_id === parameterId
// //                                 ? { ...param, review_status: selectedOption ? selectedOption.value : '' }
// //                                 : param
// //                         )
// //                     }
// //                     : test
// //             )
// //         }));
// //     };

// //     console.log(formData, "formData formData");

// //     const handleSubmit = async (e) => {
// //         if(!props.patient?.patient_sample_name?.review_status){
// //             setModalsampleData(true)
// //         }
// //         e.preventDefault();
// //         try {
// //             const result = await postData('/organisation/dashboard/apis/save-params/', formData);
// //             if (result?.success) {
// //                 setModalSuccessPatient(true);
// //                 props.onHide();
// //                props.getpatientData();
// //             }
// //         } catch (error) {
// //             console.error("Error submitting data:", error);
// //         }
// //     };
// //     useEffect(() => {
// //         let timer;
// //         if (modalSuccessPatient) {
// //             timer = setTimeout(() => {
// //                 setModalSuccessPatient(false);
// //             }, 3000);
// //         }
// //         return () => clearTimeout(timer);
// //     }, [modalSuccessPatient]);

// //     console.log(props.patient,"props.patient");
// //     return (
// //         <>
// //             <Modal
// //                 {...props}
// //                 size="md"
// //                 aria-labelledby="contained-modal-title-vcenter"
// //                 centered
// //                 // scrollable
// //                 className='sample-modal'
// //             >
// //                 <Modal.Header closeButton>
// //                     <Modal.Title id="contained-modal-title-vcenter">
// //                         {HedaingName}
// //                     </Modal.Title>
// //                 </Modal.Header>
// //                 <Modal.Body>
// //                     <Form onSubmit={handleSubmit}>
// //                         <Table responsive>
// //                             <thead>
// //                                 <tr>
// //                                     <th>Test</th>
// //                                     <th>Parameter</th>
// //                                     <th>Result</th>
// //                                     <th>Status:</th>
// //                                 </tr>
// //                             </thead>
// //                             <tbody>
// //                                 {props.patient?.patient_tests.map((test) => (
// //                                     test.test.parameters.map((param) => (
// //                                         <tr key={param.id}>
// //                                             <td>{test.test.name}</td>
// //                                             <td>{param.name}</td>
// //                                             <td>
// //                                                 <Form.Control
// //                                                     type="text"
// //                                                     placeholder="Enter result"
// //                                                     value={
// //                                                         formData.tests
// //                                                             .find(t => t.test_id === test.test.id)
// //                                                             ?.parameters.find(p => p.parameter_id === param.id)?.result || ''
// //                                                     }
// //                                                     onChange={(e) => handleResultChange(test.test.id, param.id, e.target.value)}
// //                                                 />
// //                                                 <span>{param.unit}</span>
// //                                             </td>
// //                                             <td>
// //                                                 <Select
// //                                                     name="status"
// //                                                     placeholder="Status"
// //                                                     value={
// //                                                         statusOptions.find(option =>
// //                                                             option.value ===
// //                                                             formData.tests
// //                                                                 .find(t => t.test_id === test.test.id)
// //                                                                 ?.parameters.find(p => p.parameter_id === param.id)?.review_status
// //                                                         )
// //                                                     }
// //                                                     onChange={(selectedOption) =>
// //                                                         handleStatusChange(test.test.id, param.id, selectedOption)
// //                                                     }
// //                                                     options={statusOptions}
// //                                                 />
// //                                             </td>
// //                                         </tr>
// //                                     ))
// //                                 ))}
// //                             </tbody>
// //                         </Table>
// //                         <Modal.Footer>
// //                             <Button className='confirm-btn' type="submit">Confirm</Button>
// //                         </Modal.Footer>
// //                     </Form>
// //                 </Modal.Body>
// //             </Modal>
// //             <PatientSuceesfullModal
// //                 textHeading="Patient Sample Data Not Filled"
// //                 show={sampleData}
// //                 onHide={() => setModalsampleData(false)}
// //             />
// //             <PatientSuceesfullModal
// //                 textHeading="Patient Sample Submitted successfully"
// //                 show={modalSuccessPatient}
// //                 onHide={() => setModalSuccessPatient(false)}
// //             />
// //         </>
// //     );
// // };

// // export default AssignTestResultModal;




import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Table } from 'react-bootstrap';
import { Context } from '../../../../../../utils/context';
import Select from 'react-select';
import PatientSuceesfullModal from '../../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';
import { useParams } from 'react-router-dom';

const AssignTestResultModal = (props) => {
    const { HedaingName } = props;
    const { id } = useParams();

    const [data, setData] = useState([]);
    const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
    const [sampleData, setModalsampleData] = useState(false);
    const { postData ,getData} = useContext(Context);
    const [editData, setEditData] = useState('');  
    const [errors, setErrors] = useState({});
    const [errorstest, setErrorsTest] = useState({});

    const [formData, setFormData] = useState({
        organization_id: id,
        patient_id: props.patient?.id,
        sample_status:false,
        tests: []
    });

    console.log(props.patient,"props.patient result");
    useEffect(() => {
        const patientTests = props.patient?.patient_tests || [];
        const formattedTests = patientTests.map(test => ({
            test_id: test.test.id,
            parameters: test.test.parameters.map(param => ({
                parameter_id: param.id,
                result: param.default,
                test_status: false,
                is_checked: true
            }))
        }));

        setData(patientTests || []);
        setFormData({
            organization_id: id,
            patient_id: props.patient?.id,
            tests: formattedTests
        });
    }, [props.patient, id]);

    const statusOptions = [
        { value: false, label: 'In-Review' },
        { value: true, label: 'Final' }
    ];

   
    const handleCheckboxChange = (testId, parameterId) => {
        setFormData(prevState => ({
            ...prevState,
            tests: prevState.tests.map(test =>
                test.test_id === testId ? {
                    ...test,
                    parameters: test.parameters.map(param =>
                        param.parameter_id === parameterId ? { ...param, is_checked: !param.is_checked } : param
                    )
                } : test
            )
        }));
    };
    const handleResultChange = (testId, parameterId, value) => {
        console.log(value);
        setFormData(prevState => ({
            ...prevState,
            tests: prevState.tests.map(test =>
                test.test_id === testId ? {
                    ...test,
                    parameters: test.parameters.map(param =>
                        param.parameter_id === parameterId ? { ...param, result: value } : param
                    )
                } : test
            )
        }));
    };
    
    const handleStatusChange = (testId, parameterId, selectedOption) => {
        setFormData(prevState => ({
            ...prevState,
            tests: prevState.tests.map(test => 
                test.test_id === testId ? {
                    ...test,
                    parameters: test.parameters.map(param => 
                        param.parameter_id === parameterId ? { ...param, test_status: selectedOption.value } : param
                    )
                } : test
            )
        }));
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        // if (!props.patient?.patient_sample_name?.review_status ) {
        //     setModalsampleData(true);
        //     props.onHide();
        //     return;
        // }

        if (!props.patient?.patient_sample_name?.review_status) {
            setModalsampleData(true);
            props.onHide();
            return;
        }
        // if (props.patient?.patient_sample_name?.sample_status=="1" && formData?.sample_status=="1") {
        //     setModalsampleData(true);
        //     props.onHide();
        //     return;
        // }
        if (formData?.sample_status =="1") {           
            setModalsampleData(true);
            props.onHide();
            return;
        }

        if(formData.tests===0){
            setErrorsTest("At List One tests require")
            return;
        }

    
        let hasErrors = false;
        const newErrors = {};
        let isAnyRecordValid = false;
        formData.tests.forEach(test => {
            test.parameters.forEach(param => {
                if (param.is_checked && param.result &&  param.test_status) {
                    isAnyRecordValid = true; // At least one record is valid
                }else
                if (param.is_checked && param.test_status === true && !param.result) {
                    newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required ';
                    newErrors[`final`] = 'Selected Data Can Not Be Null';
                    isAnyRecordValid = true;
                    hasErrors = true;
                }
                else  if (param.is_checked && !param.result) {
                    newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
                    newErrors[`final`] = 'Selected Data Can Not Be Null';
                    isAnyRecordValid = true;
                    hasErrors = true;
                } else  if (param.test_status === true && !param.result) {
                    newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
                    newErrors[`final`] = 'Final Data Can Not Be Null';
                    isAnyRecordValid = true;
                    hasErrors = true;
                } else  if (!param.is_checked === true && param.result) {
                    newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
                    newErrors[`final`] = 'Checked is Required';
                    isAnyRecordValid = true;
                    hasErrors = true;
                } 
                else  if (param.is_checked === true && param.result) {
                    // newErrors[`${test.test_id}-${param.parameter_id}`] = 'Result is required';
                    // newErrors[`final`] = 'Final is Required';
                    isAnyRecordValid = true;
                    // hasErrors = true;
                } 
            });
        });
        if (!isAnyRecordValid) {
            newErrors[`record`] = 'At least one record must be selected and have a result';
            hasErrors = true;
        }

        setErrors(newErrors);
        if (hasErrors) return;

        
        try {
            const result = await postData('/organisation/dashboard/apis/save-params/', formData);
            if (result?.success) {
                setModalSuccessPatient(true);
                props.onHide();
                props.getpatientData();
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    console.log(formData.tests,"formData.tests");

    useEffect(() => {
        let timer;
        if (modalSuccessPatient) {
            timer = setTimeout(() => {
                setModalSuccessPatient(false);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [modalSuccessPatient]);

    useEffect(() => {
        let timer;
        if (sampleData) {
            timer = setTimeout(() => {
                setModalsampleData(false);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [sampleData]);

    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };
    console.log(formData,"kkkkkkkkkkk jjjjjjjjjjjjj");
    const handleStatusCheckChange = (value) => {
       
        setFormData(prevFormData => ({
          ...prevFormData,
          status: value,
          sample_status : value
        }));
      };

      console.log(props.patient,"props.patient props.patient");
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='sample-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {HedaingName}
                  
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
            
                    <Form onSubmit={handleSubmit}>
                    <div className='founder-text'>
                    <Form.Check
  type="switch"
  id="custom-switch"
  label="Found/Not Fount (sample)"
  onChange={(e) => handleStatusCheckChange(e.target.checked ? 1 : 0)}
/>
                                </div>
                        <Table >
                            <thead>
                                <tr>
                                    <th>Test</th>
                                    <th>Parameter</th>
                                    <th>Result</th>
                                    <th>Select</th>
                                    <th>Status:</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.patient?.patient_tests.map((test,index) => (
                                    test.test.parameters.map((param, index2) => (
                                        <tr key={param.id}>
                                          
                                            {/* <td>{test.test.name}</td> */}
                                            {index2 === 0 && (
                                                    <td rowSpan={test.test.parameters.length}>
                                                        {test.test.name}
                                                    </td>
                                                )}
                                            <td>{param.name}</td>
                                           
                                            <td>
                                                <div className='row'>
                                                    <div className='col-md-9'>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter result"
                                                    value={
                                                        formData.tests
                                                            .find(t => t.test_id === test.test.id)
                                                            ?.parameters.find(p => p.parameter_id === param.id)?.result || param.default
                                                    }
                                                    onChange={(e) => handleResultChange(test.test.id, param.id, e.target.value)}
                                                    isInvalid={!!errors[`${test.test.id}-${param.id}`]}
                                                />
                                                </div>
                                               
                                                <div className='col-md-3'>
                                                <span >{param.unit}</span>
                                                </div>
                                                </div>
                                            </td>
                                            
                                            <td>
                                                <Form.Group className="mb-3" id="formGridCheckbox">
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        checked={
                                                            formData.tests
                                                                .find(t => t.test_id === test.test.id)
                                                                ?.parameters.find(p => p.parameter_id === param.id)?.is_checked || false
                                                        }
                                                        onChange={() => handleCheckboxChange(test.test.id, param.id)}
                                                        isInvalid={!!errors[`${test.test_id}-${param.parameter_id}`]}
                                                    />
                                                </Form.Group>
                                            </td>
{/*                                          
                                            <td>
                                                <Select
                                                    name="status"
                                                    placeholder="Status"
                                                    value={
                                                        statusOptions.find(option =>
                                                            option.value ===
                                                            formData.tests
                                                                .find(t => t.test_id === test.test.id)
                                                                ?.parameters.find(p => p.parameter_id === param.id)?.test_status
                                                        )
                                                    }
                                                    onChange={(selectedOption) =>
                                                        handleStatusChange(test.test.id, param.id, selectedOption)
                                                    }
                                                    options={statusOptions}
                                                />
                                            </td> */}
                                            {index2 === 0 && (
                                                    <td rowSpan={test.test.parameters.length}>
                                                      <Select
                                                    name="status"
                                                    placeholder="Status"
                                                    value={
                                                        statusOptions.find(option =>
                                                            option.value ===
                                                            formData.tests
                                                                .find(t => t.test_id === test.test.id)
                                                                ?.parameters.find(p => p.parameter_id === param.id)?.test_status
                                                        )
                                                    }
                                                    onChange={(selectedOption) =>
                                                        handleStatusChange(test.test.id, param.id, selectedOption)
                                                    }
                                                    options={statusOptions}
                                                />
                                                    </td>
                                                )}
                                           
                                        </tr>
                                    ))
                                ))}
                            </tbody>
                          
                        </Table>
                        <span style={{ color: 'red' }}>{errors["final"]}</span>
                        <span style={{ color: 'red' }}>{errors["record"]}</span>
                        {errors?.tests && <span style={errorStyle}>{errors?.tests}</span>}
                        <Modal.Footer>
                            <Button className='confirm-btn' type="submit">Confirm</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
                
            </Modal>
            <PatientSuceesfullModal
                // textHeading="Patient Sample Data Not Filled "
                textHeading={<span style={{ color: 'red' }}>Patient Sample Data Not Filled</span>}
                
                show={sampleData}
                onHide={() => setModalsampleData(false)}
            />
            <PatientSuceesfullModal
                textHeading="Patient Result Submitted successfully"
                show={modalSuccessPatient}
                onHide={() => setModalSuccessPatient(false)}
            />
        </>
    );
};

export default AssignTestResultModal;