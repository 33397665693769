import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

import "./DiagnosticSlot.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Input } from "@mui/material";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
library.add(fas);

function Slots() {

    const { testId, id } = useParams();

    const { getData, postData, editPatchData, deleteData, imageUrl, Select2Data, orgID, setOrgId } = useContext(Context);




    const [hospitalslot, setHospitalSlot] = useState();
    // const getHospitalslot = async () => {
    //   const res = await getData(`organisation/dashboard/apis/doctor-list-api-with/?id=${id}`);
    //   if (res?.success) {
    //     setHospitalSlot(res.data);
    //   }
    // }

    const [hospitaltotalslot, setHospitalTotalSlot] = useState();
    const getHospitalTotalSlot = async (page) => {

        var res;
        if (page) {
            res = await getData(`slot_booking/dashboard/apis/test-slot/?${page}`);
        } else {
            res = await getData(`slot_booking/dashboard/apis/test-slot/?test_id=${testId}`);
        }

        // if (res?.success) {
        setHospitalTotalSlot(res);
        // }
    }

    const getDoctor = async () => {
        const res = await getData(`slot_booking/dashboard/apis/get-doctor-id/?id=${testId}`);
        if (res?.success) {
            setHospitalSlot(res.data);
        }
    }


    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date1) => {
        const formattedDate = date1 ? date1.toISOString().split('T')[0] : null;

        // Update formData state with the formatted date
        setFormData({ ...formData, date: formattedDate });
        setFormEditData({ ...formEditData, date: formattedDate });
    }



    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [imagePreview, setImagePreview] = useState(false);

    const handleClose = async () => {
        await setFormData({
            start_time: "",
            end_time: "",
            test_id: testId,
            date: "",
        });
        await setShow(false);
    }

    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [expandedRows, setExpandedRows] = useState([]);


    const toggleRow = (index) => {
        const updatedExpandedRows = [...expandedRows];
        updatedExpandedRows[index] = !updatedExpandedRows[index];
        setExpandedRows(updatedExpandedRows);
    };


    const [formData, setFormData] = useState({
        start_time: "",
        end_time: "",
        test_id: testId,
        date: "",
    });
    const [formEditData, setFormEditData] = useState({
        id: "",
        start_time: "",
        end_time: "",
        test_id: testId,
        date: "",
    });
    const handleChange = async (e) => {
        if (
            e?.name === "is_completed"
        ) {
            setFormEditData({ ...formEditData, [e?.name]: e });
        } else {
            setFormEditData({ ...formEditData, [e?.target?.name]: e?.target?.value });
        }
    }
    const errorStyle = {
        color: "red",
        marginLeft: "5px",
        // fontSize: "11px",
    };
    const [errors, setErrors] = useState();
    const validateForm = () => {
        let errors = {};
        const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
        const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

        if (!formData.start_time) {
            errors.start_time = "Start Time is required";
        }
        if (!formData.end_time) {
            errors.end_time = "End Time is required";
        }
        if (!formData.test_id) {
            errors.test_id = "Test is required";
        }
        if (!formData.date) {
            errors.date = "Date is required";
        }
        else {
            const currentDate = new Date(); // Current date and time
            const enteredDate = new Date(formData?.date);

            // Set hours, minutes, seconds, and milliseconds to 0 for both dates
            currentDate.setHours(0, 0, 0, 0);
            enteredDate.setHours(0, 0, 0, 0);

            if (enteredDate < currentDate) {
                errors.date = "Date cannot be in the past";
            }
        }
        //  else {
        //     const currentDate = new Date();
        //     const enteredDate = new Date(formData?.date);

        //     if (enteredDate < currentDate) {
        //         errors.date = "Date cannot be in the past";
        //     }
        // }


        return errors;
    };
    const validateEditForm = () => {
        let errors = {};
        const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
        const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

        if (!formEditData.start_time) {
            errors.start_time = "Start Time is required";
        }
        if (!formEditData.end_time) {
            errors.end_time = "End Time is required";
        }
        if (!formEditData.date) {
            errors.date = "Date is required";
        }
        else {
            const currentDate = new Date(); // Current date and time
            const enteredDate = new Date(formData?.date);

            // Set hours, minutes, seconds, and milliseconds to 0 for both dates
            currentDate.setHours(0, 0, 0, 0);
            enteredDate.setHours(0, 0, 0, 0);

            if (enteredDate < currentDate) {
                errors.date = "Date cannot be in the past";
            }
        }
        // else {
        //     const currentDate = new Date();
        //     const enteredDate = new Date(formEditData?.date);

        //     if (enteredDate < currentDate) {
        //         errors.date = "Date cannot be in the past";
        //     }
        // }


        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();

        console.log(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append(`start_time`, formData.start_time);
                finalData.append(`end_time`, formData.end_time);
                finalData.append(`test`, formData.test_id);
                finalData.append(`date`, formData.date);

                const result = await postData(
                    "slot_booking/dashboard/apis/test-slot/",
                    finalData
                );

                if (result?.success) {
                    await setFormData({
                        start_time: "",
                        end_time: "",
                        test_id: testId,
                        date: "",
                    });

                    await setErrors();
                    handleClose();
                    getHospitalTotalSlot();
                }
                else {
                    let errors = {};
                    errors.date = result?.error;
                    setErrors(errors);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    const handleEditSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateEditForm();

        console.log(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append(`start_time`, formEditData.start_time);
                finalData.append(`end_time`, formEditData.end_time);
                finalData.append(`date`, formEditData.date);

                const result = await editPatchData(
                    `slot_booking/dashboard/apis/change-test/${formEditData.id}/`,
                    finalData
                );

                // if (result?.success) {
                await setFormEditData({
                    id: "",
                    start_time: "",
                    end_time: "",
                    test_id: testId,
                    date: "",
                });

                await setErrors();
                handleClose2();
                getHospitalTotalSlot();
                // }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleEdit = async (index, action) => {
        console.log(hospitaltotalslot?.results[index]);
        await setFormEditData({
            id: hospitaltotalslot?.results[index]?.id,
            start_time: hospitaltotalslot?.results[index]?.start_time,
            end_time: hospitaltotalslot?.results[index]?.end_time,
            test_id: hospitaltotalslot?.results[index]?.test,
            date: new Date(hospitaltotalslot?.results[index]?.date).toLocaleDateString("en-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
        });


        if (action === "edit") {
            handleShow2();
        }

    }
    const [status, setStatus] = useState();
    const getStatus = async () => {
        const res = await getData(`slot_booking/dashboard/apis/get-status/`);
        if (res?.success) {
            setStatus(res.data);
            if (res?.success) {
                const result = res.data.map((data) => ({
                    value: data?.id,
                    label: data?.name,
                    name: "is_completed",
                }));
                setStatus(result);
            }
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        getHospitalTotalSlot();
        getDoctor();
        getStatus();
    }, []);

    return (
        <>
            <div className="main">
                <div className="container-fluid p-4">

                    {/* all text data table start */}

                    <section className="data-table">
                        <div className="heading-holder">
                            <h4 className="font-semibold text">Test Slots</h4>
                            <div className="search-holder  flex items-center space-x-2">
                                <label for="" className="search text">
                                    {/* Search By Booking ID:{" "} */}
                                </label>
                                {/* <input type="text" className="search btn-style text" /> */}
                                <Button
                                    variant="success"
                                    onClick={handleShow}
                                    className="search btn-style btn-size text bg-green"
                                >
                                    Add Test Slots
                                </Button>
                            </div>
                        </div>

                        <div className="table-container">
                            <Table striped bordered hover className="table">
                                <thead>
                                    <tr>

                                        <th>
                                            <span className="text table-data-heading">
                                                Date
                                            </span>
                                        </th>
                                        <th>
                                            <span className="text table-data-heading">
                                                Start Time
                                            </span>
                                        </th>
                                        <th>
                                            <span className="text table-data-heading">
                                                End Time
                                            </span>
                                        </th>
                                        {/* <th>
                                            <span className="text table-data-heading">Actions</span>
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>





                                    {hospitaltotalslot?.results?.map((value, index) => (
                                        <tr>

                                            <td>
                                                <p className="text text-size data-line">
                                                    {value?.date}
                                                </p>
                                            </td>
                                            <td>
                                                <p className="text text-size data-line">
                                                    {value?.start_time}
                                                </p>
                                            </td>
                                            <td>
                                                <p className="text text-size data-line">
                                                    {value?.end_time}
                                                </p>
                                            </td>
                                            {/* <td>
                                                <div className="action">
                                                    <Button className="action-btn " onClick={handleShow2}>
                                                        <FontAwesomeIcon
                                                            className="text"
                                                            icon="fa-solid fa-pen-to-square"
                                                            onClick={() => handleEdit(index, "edit")}
                                                        />
                                                    </Button>
                                                    <Button
                                                        variant="outline-success"
                                                        className="search btn-style btn-size text bg-green"
                                                    >
                                                        Add Records
                                                    </Button>
                                                </div>
                                            </td> */}
                                        </tr>
                                    ))}



                                </tbody>
                            </Table>
                        </div>
                    </section>
                    {/* pegignation start */}
                    <div className="row me-0 ms-0">
                        <div class="col-md-12">
                            <div class="Pagination-holder">
                                <ul class="pagination">
                                    {hospitaltotalslot?.previous && (
                                        <li class="page-item">
                                            <a class="page-link" role="button" tabindex="0" onClick={() => getHospitalTotalSlot(hospitaltotalslot?.previous.split("?")[1])}>
                                                <span aria-hidden="true">‹</span>
                                                <span class="visually-hidden">Previous</span>
                                            </a>
                                        </li>
                                    )}
                                    {[...Array(hospitaltotalslot?.count && Math.ceil(hospitaltotalslot?.count / 10))].map(function (_, index) {
                                        return (
                                            <li key={index} className="page-item">
                                                <a className="page-link" role="button" tabIndex="0" onClick={() => getHospitalTotalSlot(`test_id=${testId}&page=` + (index + 1))}>
                                                    {index + 1}
                                                </a>
                                            </li>
                                        );
                                    })}
                                    {hospitaltotalslot?.next && (
                                        <li class="page-item">
                                            <a class="page-link" role="button" tabindex="0" onClick={() => getHospitalTotalSlot(hospitaltotalslot?.next.split("?")[1])}>
                                                <span aria-hidden="true">›</span>
                                                <span class="visually-hidden">Next</span>
                                            </a>
                                        </li>
                                    )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* pegignation end */}

                    {/* all text data table end */}

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h4 className="text">Add Slot</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Form>
                                <Form.Group
                                    className="mb-3 text"
                                // controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label className="text">Date</Form.Label>
                                    
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={formData?.date}
                                        onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                    />
                                    {errors?.date && (
                                        <span style={errorStyle}>{errors?.date}</span>
                                    )}
                                </Form.Group>


                                {/* Add Start Time */}
                                <Form.Group className="mb-3 text">
                                    <Form.Label className="text">Start Time</Form.Label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        value={formData?.start_time}
                                        onChange={(e) => setFormData({ ...formData, start_time: e.target.value })}
                                    />
                                    {errors?.start_time && (
                                        <span style={errorStyle}>{errors?.start_time}</span>
                                    )}
                                </Form.Group>

                                {/* Add End Time */}
                                <Form.Group className="mb-3 text">
                                    <Form.Label className="text">End Time</Form.Label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        value={formData?.end_time}
                                        onChange={(e) => setFormData({ ...formData, end_time: e.target.value })}
                                    />
                                    {errors?.end_time && (
                                        <span style={errorStyle}>{errors?.end_time}</span>
                                    )}
                                </Form.Group>


                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="success" onClick={handleSubmit}>
                                Add
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* edit slot  modal start */}
                    <Modal show={show2} onHide={handleClose2}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h4 className="text">Edit Slot</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group
                                    className="mb-3 text"
                                // controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label className="text">Date</Form.Label>
                                    <DatePicker
                                        className="date-input-field"
                                        selected={formEditData?.date ? new Date(formEditData?.date) : null}
                                        onChange={handleDateChange}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="yyyy-mm-dd"
                                        shouldCloseOnSelect={true}

                                    />
                                    {errors?.date && (
                                        <span style={errorStyle}>{errors?.date}</span>
                                    )}
                                </Form.Group>


                                {/* Add Start Time */}
                                <Form.Group className="mb-3 text">
                                    <Form.Label className="text">Start Time</Form.Label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        value={formEditData?.start_time}
                                        onChange={(e) => setFormEditData({ ...formEditData, start_time: e.target.value })}


                                    />
                                    {errors?.start_time && (
                                        <span style={errorStyle}>{errors?.start_time}</span>
                                    )}
                                </Form.Group>

                                {/* Add End Time */}
                                <Form.Group className="mb-3 text">
                                    <Form.Label className="text">End Time</Form.Label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        value={formEditData?.end_time}
                                        onChange={(e) => setFormEditData({ ...formEditData, end_time: e.target.value })}


                                    />
                                    {errors?.end_time && (
                                        <span style={errorStyle}>{errors?.end_time}</span>
                                    )}
                                </Form.Group>








                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose2}>
                                Close
                            </Button>
                            <Button variant="success" onClick={handleEditSubmit}>
                                Edit
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* edit slot  modal end */}
                </div>
            </div>
        </>
    );
}

export default Slots;
