import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Button } from "react-bootstrap";
import "./Sidebar.css";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../utils/context";
library.add(fas);

function Sidebar() {


  const location = useLocation();
  const currentRoute = location.pathname.split('/')[1];
  const id = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const logout = () => {
    // Clear token cookies
    Cookies.remove("jwtToken");
    Cookies.remove("jwtType");
    Cookies.remove("jwtOrganisation");
    Cookies.remove("jwtOrgType");
    setSignin(false);
    navigate("/");
  };

  const { orgData,
    setOrgData,
    setSignin, orgType, getData } = useContext(Context);

  useEffect(() => {
    console.log(orgData, 'orgData');
  }, [orgData]);

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  const [authToken, setAuthToken] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [authOrganisation, setAuthOrganisation] = useState(null);
  const [authOrgType, setAuthOrgType] = useState(null);
  useEffect(() => {
    // console.log(Cookies.get('hiclousia_website'), 'UserAccess');
    if (Cookies.get("jwtToken") != null) {
      setAuthToken(Cookies.get("jwtToken"));
      setAuthType(Cookies.get("jwtType"));
      setAuthOrgType(Cookies.get("jwtOrgType"));
      setAuthOrganisation(Cookies.get("jwtOrganisation"));

      console.log(Cookies.get("jwtType"), 'sidebar');
    }
  }, [Cookies.get("jwtToken")]);
  return (
    <>
      <div className="main">
        <div className="container-fluid p-0">
          {/* sidebar section start */}
          <section className="sidebar">
            <div className="row me-0 ms-0">
              {/* sidebar start*/}
              <div className="col-md-12 p-0">
                <div className="sidebar-holder">
                  <Link to="/dashboard">
                    <div className="logo-holder">
                      <img
                        className="logo-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/dashboard/sidebar/logo_2.png"
                        }
                        alt="logo-img"
                      />
                    </div>
                  </Link>

                  <div className="user">
                    <div className="user-holder">
                      <img
                        className="user-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/dashboard/sidebar/user.png"
                        }
                        alt="user-img"
                      />
                    </div>
                    <div className="text-holder">
                      <h4 className="text ">User</h4>
                    </div>
                  </div>

                  {/* sidebar tabs start */}
                  <div className="sidebar-tabs">
                    <Nav className="flex-column">
                      {authType != 'HOSPITAL_ADMIN' && (
                        <Link to={"/dashboard"}>
                          <Nav.Item>
                            <Nav.Link href="/dashboard" eventKey="first">
                              <FontAwesomeIcon
                                className="me-2"
                                icon="fa-solid fa-house"
                              />{" "}
                              Dashboard
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                      )}


                      {currentRoute === 'generalfare' || currentRoute === 'diagnosticSlot' || currentRoute === 'diagnosticDetails' || currentRoute === 'hospitalSlot' || currentRoute === 'hospitalsDetails' || currentRoute === 'doctorCard' || currentRoute === 'visitorCard' || currentRoute === 'opdOrg' || currentRoute === 'pathologyCard' ? (
                        <>
                          {orgData?.org_type === "DIAGNOSIS" && (
                            <>
                              <Link to={`/pathologyCard/${id}`}>
                                <Nav.Item>
                                  <Nav.Link href={`/pathologyCard/${id}`} eventKey="eighth">
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon="fa-solid fa-vial"
                                    />
                                    Pathology
                                  </Nav.Link>
                                </Nav.Item>
                              </Link>
                              <Link to={`/generalfare/${id}`}>
                                <Nav.Item>
                                  <Nav.Link href={`/generalfare/${id}`} eventKey="nineth">
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon="fa-solid fa-address-card"
                                    />
                                    General fare
                                  </Nav.Link>
                                </Nav.Item>
                              </Link>
                            </>
                          )}
                          {orgData?.org_type === "HOSPITAL" && (
                            <>
                              <Link to={`/doctorCard/${id}`}>
                                <Nav.Item>
                                  <Nav.Link href={`/doctorCard/${id}`} eventKey="ninth">
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon="fa-solid fa-table-cells-large"
                                    />
                                    Doctors
                                  </Nav.Link>
                                </Nav.Item>
                              </Link>
                              <Link to={`/visitorCard/${id}`}>
                                <Nav.Item>
                                  <Nav.Link href={`/visitorCard/${id}`} eventKey="sixth">
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon="fa-solid fa-table-cells-large"
                                    />
                                    Visitor Card
                                  </Nav.Link>
                                </Nav.Item>
                              </Link>

                              <Link to={`/opdOrg/${id}`}>
                                <Nav.Item>
                                  <Nav.Link href={`/opdOrg/${id}`} eventKey="seventh">
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon="fa-solid fa-address-card"
                                    />
                                    OPD
                                  </Nav.Link>
                                </Nav.Item>
                              </Link>

                              <Link to={`/pathologyCard/${id}`}>
                                <Nav.Item>
                                  <Nav.Link href={`/pathologyCard/${id}`} eventKey="eighth">
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon="fa-solid fa-vial"
                                    />
                                    Pathology
                                  </Nav.Link>
                                </Nav.Item>
                              </Link>
                              <Link to={`/generalfare/${id}`}>
                                <Nav.Item>
                                  <Nav.Link href={`/generalfare/${id}`} eventKey="nineth">
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon="fa-solid fa-address-card"
                                    />
                                    General fare
                                  </Nav.Link>
                                </Nav.Item>
                              </Link>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {authType === 'SUPERUSER' && (
                            <>
                              <Link to={"/hospital"}>
                                <Nav.Item>
                                  <Nav.Link href="/hospital" eventKey="second ">
                                    {" "}
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon="fa-solid fa-hospital"
                                    />
                                    Hospitals
                                  </Nav.Link>
                                </Nav.Item>
                              </Link>
                              <Link to={"/diagnostic"}>
                                <Nav.Item>
                                  <Nav.Link href="/diagnostic" eventKey="third">
                                    {" "}
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon="fa-solid fa-house-medical-flag"
                                    />
                                    Diagnostic Centers
                                  </Nav.Link>
                                </Nav.Item>
                              </Link>
                            </>
                          )}
                          {authType !== 'SUPERUSER' && (
                            <>
                              {authOrgType === 'HOSPITAL' && (
                                <Link to={"/hospital"}>
                                  <Nav.Item>
                                    <Nav.Link href="/hospital" eventKey="second ">
                                      {" "}
                                      <FontAwesomeIcon
                                        className="me-2"
                                        icon="fa-solid fa-hospital"
                                      />
                                      Hospitals
                                    </Nav.Link>
                                  </Nav.Item>
                                </Link>
                              )}
                              {authOrgType === 'DIAGNOSIS' && (
                                <Link to={"/diagnostic"}>
                                  <Nav.Item>
                                    <Nav.Link href="/diagnostic" eventKey="third">
                                      {" "}
                                      <FontAwesomeIcon
                                        className="me-2"
                                        icon="fa-solid fa-house-medical-flag"
                                      />
                                      Diagnostic Centres
                                    </Nav.Link>
                                  </Nav.Item>
                                </Link>
                              )}
                            </>
                          )}
                          {authType === 'SUPERUSER' && (
                            <Link to={"/user"}>
                              <Nav.Item>
                                <Nav.Link href="/user" eventKey="fourth">
                                  <FontAwesomeIcon
                                    className="me-2"
                                    icon="fa-solid fa-users"
                                  />
                                  Users
                                </Nav.Link>
                              </Nav.Item>
                            </Link>
                          )}

                          {authType === 'SUPERUSER' && (
                            <Link to={"/categories"}>
                              <Nav.Item>
                                <Nav.Link href="/categories" eventKey="fifth">
                                  <FontAwesomeIcon
                                    className="me-2"
                                    icon="fa-solid fa-list"
                                  />{" "}
                                  Categories
                                </Nav.Link>
                              </Nav.Item>
                            </Link>
                          )}
                          {authType === 'SUPERUSER' && (
                            <Link to={`/opdCard`}>
                              <Nav.Item>
                                <Nav.Link href={`/opdCard`} eventKey="seventh">
                                  <FontAwesomeIcon
                                    className="me-2"
                                    icon="fa-solid fa-address-card"
                                  />
                                  OPD
                                </Nav.Link>
                              </Nav.Item>
                            </Link>
                          )}


                        </>
                      )}





                    </Nav>
                  </div>
                  {/* sidebar tabs end */}

                  {/* sidebar tabs start */}
                  <div class="log-out">
                    <Button
                      variant="danger"
                      className=" logout-btn btn-style btn  mx-3"
                      onClick={logout}
                    >
                      Logout
                    </Button>
                  </div>
                  {/* sidebar tabs end */}
                </div>
              </div>
              {/* sidebar end*/}
            </div>
          </section>
          {/* sidebar section end */}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
