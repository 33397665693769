import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Table from "react-bootstrap/Table";
import "./OPDOrg.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
import Select from "react-select";
import OPDPrice from "./OPDPrice";
import OPDAppointments from "./OPDAppointments";
library.add(fas);

function OPDOrg() {


  const { id } = useParams();



  const [activeDefault , setActiveDefault] = useState(true);
  const handleTabs = async (tab) => {
    if(tab === 1){
      await setActiveDefault(true);
    }else{
      await setActiveDefault(false);
    }
    
  }

  return (
    <>
      <div className="main">
        <div className="container-fluid p-4">
          {/* doctor data table start */}

          <div className="row status-section">
            <div className="col-md-3 col-sm-3 col-lg-2  me-5">
              <button
                variant="success"
                onClick={() => handleTabs(1)}
                className={`search btn-style btn-size text ${activeDefault ? '':'bg-green'}`}
              >
                Price & details
              </button>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-2  me-5">
              <button
                variant="success"
                onClick={() => handleTabs(2)}
                className={`search btn-style btn-size text ${activeDefault ? 'bg-green':''}`}
              >
                OPD Appointments
              </button>
            </div>
          </div>
          {activeDefault ? (
            <OPDPrice />
          ) : (
            <OPDAppointments />
          )}

        </div>
      </div >
    </>
  );
}

export default OPDOrg;
