import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { editPatchData } from '../../../../../utils/api';


const EditTestModal = ({
    show, handleClose, formData, setFormData, parameters, setErrors, errors, paramterType, handleParameterChange, handleReferenceChange, getTests
}) => {
    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const finalData = new FormData();
                finalData.append('organisation_id', formData.organisation_id);

                const result = await editPatchData(
                    `organisation/dashboard/apis/opd-edit-api/${formData.id}/`,
                    finalData
                );

                if (result?.success) {
                    await setFormData({
                        id: '',
                        test: '',
                        organisation_id: '',
                        parameters: '',
                        unit: '',
                        reference: '',
                    });

                    await setErrors({});
                    handleClose();
                    getTests();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.test) {
            errors.test = "Test Name Required";
        }
        if (!formData.unit) {
            errors.unit = "Unit is Required";
        }
        if (!formData.reference) {
            errors.reference = "Reference is Required";
        }
        // Add other validations as necessary
        return errors;
    };

    return (
        <Modal show={show} onHide={handleClose} className='sample-modal' scrollable>
            <Modal.Header closeButton>
                <Modal.Title>Pathology Edit Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleEditSubmit}>
                    <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                        <Form.Label className="text">Test Name</Form.Label>
                        <Form.Control
                            className="text"
                            type="text"
                            name="test"
                            placeholder="Enter Test Name"
                            value={formData?.test}
                            onChange={handleChange}
                        />
                        {errors?.test && <span style={errorStyle}>{errors?.test}</span>}
                    </Form.Group>

                    {parameters.map((param, paramIndex) => (
                        <div key={paramIndex}>
                            <Form.Group className="mb-3 text">
                                <Form.Label className="text">Parameter</Form.Label>
                                <Form.Control
                                    className="text"
                                    type="text"
                                    placeholder="Enter Parameter"
                                    value={param.name}
                                    onChange={(e) => handleParameterChange(paramIndex, 'name', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 text">
                                <Form.Label className="text">Parameter Type</Form.Label>
                                <Select
                                    placeholder="Select Parameter Type"
                                    className="select-line"
                                    value={formData?.type_value}
                                    onChange={(e) => { handleParameterChange(paramIndex, 'type', e.value); handleParameterChange(paramIndex, 'type_value', e); }}
                                    options={paramterType}
                                />
                            </Form.Group>
                            {param?.reference?.map((ref, refIndex) => (
                                <div key={refIndex}>
                                    <Form.Group className="mb-2 text">
                                        <Form.Label className="text">Age Group</Form.Label>
                                        {["Adult", "Child", "Newborn"].map((value, radioIndex) => (
                                            <div key={radioIndex} className="mb-2">
                                                <Form.Check
                                                    type="radio"
                                                    label={value}
                                                    name={`referenceRadio_${paramIndex}_${refIndex}_age`}
                                                    value={value}
                                                    checked={ref?.age === value}
                                                    onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'age', e.target.value)}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                    <Form.Group className="mb-2 text">
                                        <Form.Label className="text">Gender</Form.Label>
                                        {["M", "F", "B"].map((value, radioIndex) => (
                                            <div key={radioIndex} className="mb-2">
                                                <Form.Check
                                                    type="radio"
                                                    label={value}
                                                    name={`referenceRadio_${paramIndex}_${refIndex}_sex`}
                                                    value={value}
                                                    checked={ref?.sex === value}
                                                    onChange={(e) => handleReferenceChange(paramIndex, refIndex, 'sex', e.target.value)}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </div>
                            ))}
                        </div>
                    ))}
                    <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                        <Form.Label className="text">Unit</Form.Label>
                        <Form.Control
                            className="text"
                            type="text"
                            name="unit"
                            placeholder="Enter Unit"
                            value={formData?.unit}
                            onChange={handleChange}
                        />
                        {errors?.unit && <span style={errorStyle}>{errors?.unit}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3 text" controlId="exampleForm.ControlInput1">
                        <Form.Label className="text">Reference</Form.Label>
                        <Form.Control
                            className="text"
                            type="text"
                            name="reference"
                            placeholder="Enter Reference"
                            value={formData?.reference}
                            onChange={handleChange}
                        />
                        {errors?.reference && <span style={errorStyle}>{errors?.reference}</span>}
                    </Form.Group>
                    {/* <Button variant="secondary" className='me-2 close-button' onClick={handleClose}>
                        Close
                    </Button> */}
                    <Button variant="primary" className='confirm-btn' type="submit">
                        Save Changes
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditTestModal;
