import React from 'react';
import "./AllModalSec.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PatientSuceesfullModal from './PatientSuceesfullModal';
import { postData } from '../../../../../utils/api';

const PatientModaltwo = (props) => {
    const { HedaingName, show, onHide, formData, setFormData, setErrors, errors, handleNewVisitor, org_id } = props;
    const [modalSuccess, setModalSuccess] = React.useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "mobile_number") {
            // Allow only numeric values and limit to 10 digits
            const numericValue = value.replace(/\D/g, '').slice(0, 10);
            setFormData({ ...formData, [name]: numericValue });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.visitor_name) {
            errors.visitor_name = "Visitor Name Required";
        }

        if (!formData.mobile_number) {
            errors.mobile_number = "Mobile Number Required";
        } else if (formData.mobile_number.length !== 10) {
            errors.mobile_number = "Mobile Number must be exactly 10 digits";
        }
        if (!formData.relation_with_patient) {
            errors.relation_with_patient = "Relation Required";
        }
        if (!formData.valid_till) {
            errors.valid_till = "Date is required";
        } else {
            const currentDate = new Date(); // Current date and time
            const enteredDate = new Date(formData.valid_till);
            currentDate.setHours(0, 0, 0, 0);
            enteredDate.setHours(0, 0, 0, 0);
            if (enteredDate < currentDate) {
                errors.valid_till = "Date cannot be in the past";
            }
        }
        // if (!formData.status_id) {
        //     errors.status_id = "Status Required";
        // }
        return errors;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        // finalData.append(`opd`, formData.opd?.value);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {

                formData.ward = formData.ward.value;
                // formData.wardname = formData.ward;
                const result = await postData(
                    "organisation/dashboard/apis/visitor-post/",
                    formData
                );

                if (result?.success) {
                    // handleNewVisitor(result.data); 
                    setModalSuccess(true);
                    setFormData({
                        org_id: org_id,
                        patient_id: "",
                        bed_no: "",
                        patient_name: "",
                        ward: "",
                        visitor_name: "",
                        mobile_number: "",
                        relation_with_patient: "",
                        status_id: "",
                        valid_till: "",

                    });
                    props.getVisitors()
                    onHide();
                    setTimeout(() => {
                        setModalSuccess(false);
                    }, 3000);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='patient-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {HedaingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                                Visitor Name
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="visitor_name"
                                    value={formData.visitor_name}
                                    type="text"
                                    placeholder="Enter Visitor name"
                                    autoFocus
                                    onChange={handleChange}
                                />
                                {errors.visitor_name && (
                                    <span style={errorStyle}>{errors.visitor_name}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Mobile Number
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    type="text"
                                    placeholder="Enter Mobile Number"
                                    onChange={handleChange}
                                />
                                {errors.mobile_number && (
                                    <span style={errorStyle}>{errors.mobile_number}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Relation
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="relation_with_patient"
                                    value={formData.relation_with_patient}
                                    type="text"
                                    placeholder="Enter Relation"
                                    onChange={handleChange}
                                />
                                {errors.relation_with_patient && (
                                    <span style={errorStyle}>{errors.relation_with_patient}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                                Valid Till
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    name="valid_till"
                                    value={formData.valid_till}
                                    type="date"
                                    placeholder="Enter Valid Till Date"
                                    onChange={handleChange}
                                />
                                {errors.valid_till && (
                                    <span style={errorStyle}>{errors.valid_till}</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Button type="submit" className='create-btn'>Create</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <PatientSuceesfullModal
                textHeading="Visitor card created successfully"
                show={modalSuccess}
                onHide={() => setModalSuccess(false)}
            />
        </>
    );
};

export default PatientModaltwo;
